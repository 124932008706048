import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

// :: Components
import Input from '../../components/Input/Input';
import Dropdown from '../../components/Dropdown/Dropdown';
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';
import ValidationToastHandler from '../../components/ValidationToastHandler/ValidationToastHandler';
import PermissionsField from '../../components/PermissionField/PermissionField';
import Textarea from '../../components/Textarea/Textarea';
import Button from '../../components/Button/Button';

// :: Lib
import { getTestProps } from '../../lib/helpers';

const TYPES = [
  {
    value: 'CO',
    label: 'CO',
  },
  {
    value: 'CTD',
    label: 'CTD',
  },
];

const getInitialValues = (role) => ({
  ...role,
  permissions: (role.permissions || []).map((permission) => ({
    ...permission,
    contentTypeDefinition: {
      id: permission.contentTypeDefinition?.id || '',
    },
  })),
});

const HeadlessRoleForm = ({
  role,
  onSave,
  ctdsOptions,
  ctdsFilterCallback,
  disabled,
  navigateOnSave,
  testId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('Form.FormErrorNotBlank')),
        description: yup.string().required(t('Form.FormErrorNotBlank')),
        permissions: yup
          .array()
          .of(
            yup.object().shape({
              type: yup.string().required(t('Form.FormErrorNotBlank')),
              contentTypeDefinition: yup.object().shape({
                id: yup.string().test({
                  name: 'id',
                  message: t('ApiKeys.ErrorSelectedNoLongerValid'),
                  test: (value) => {
                    if (!value) return true;
                    return (
                      ctdsOptions?.findIndex((el) => el.value === value) >= 0
                    );
                  },
                }),
              }),
            }),
          )
          .min(1, t('ContentForm.Errors.MinLength'))
          .required(t('ContentForm.Errors.MinLength')),
      }),
    [ctdsOptions, t],
  );

  const handleSubmit = useCallback(
    async (values, formik) => {
      const [newRole, errors, hasErrors] = await onSave(values);
      formik.setStatus({ ...formik.status, errors });
      if (!hasErrors) {
        if (navigateOnSave?.current) {
          navigate(`/headless-roles`);
        } else
          formik.resetForm({
            values: getInitialValues(newRole),
          });
      }
    },
    [navigate, navigateOnSave, onSave],
  );

  const ruleColumn = useMemo(() => {
    return ['Read', 'Create', 'Update', 'Delete'].map((perm) => ({
      id: `can${perm}`,
      name: `can${perm}`,
      label: t(`Global.Permission.${perm}`),
    }));
  }, [t]);

  const initialValues = useMemo(
    () =>
      role
        ? getInitialValues(role)
        : { name: '', description: '', permissions: [] },
    [role],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
    >
      {(formik) => (
        <form
          className="max-w-4xl py-4"
          id="role-form"
          onSubmit={formik.handleSubmit}
          noValidate={true}
        >
          <div className="flex flex-col gap-4 md:gap-6 w-full px-5 mb-2">
            <Input
              name="name"
              label={t('Global.Name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched?.name
                  ? formik.errors.name || formik.status?.errors?.name
                  : ''
              }
              disabled={disabled}
              required
              {...getTestProps(testId, 'name', 'testId')}
            />
            <Textarea
              name="description"
              label={t('HeadlessRoles.Description')}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched?.description
                  ? formik.errors.description ||
                    formik.status?.errors?.description
                  : ''
              }
              disabled={disabled}
              required
              {...getTestProps(testId, 'description', 'testId')}
            />
          </div>
          <PermissionsField
            permissions={formik.values?.permissions}
            ruleColumn={ruleColumn}
            options={ctdsOptions}
            optionsFilterCallback={ctdsFilterCallback}
            renderBeforeDropdown={(name, index) => (
              <Field name={`${name}.${index}.type`}>
                {({ field, meta }) => (
                  <Dropdown
                    options={TYPES}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    name={field.name}
                    error={
                      meta.touched
                        ? field.errors?.name?.[index]?.type ||
                          formik.status?.errors?.name?.[index]?.type
                        : ''
                    }
                    additionalContainerClasses="w-[5.5rem] mr-2"
                    hideSearch
                  />
                )}
              </Field>
            )}
            renderButtons={(push) => {
              const formikError = formik.errors?.permissions;
              return (
                <div className="flex flex-col w-full p-5">
                  <Button
                    onClick={() =>
                      push({
                        canCreate: false,
                        canRead: false,
                        canUpdate: false,
                        canDelete: false,
                        contentTypeDefinition: { id: '' },
                        type: 'CO',
                      })
                    }
                    buttonSize="sm"
                    additionalClasses="w-fit"
                    {...getTestProps(testId, 'permissions-add', 'testId')}
                  >
                    {t('ApiKeys.AddRule')}
                  </Button>
                  {formik.touched?.permissions &&
                    formikError &&
                    typeof formikError === 'string' && (
                      <div
                        className="text-red text-sm"
                        {...getTestProps(testId, 'permissions-error')}
                      >
                        {formikError}
                      </div>
                    )}
                </div>
              );
            }}
            {...getTestProps(testId, 'permissions', 'testId')}
          />
          <ValidationToastHandler />
          <DirtyHandler />
        </form>
      )}
    </Formik>
  );
};

export default HeadlessRoleForm;

HeadlessRoleForm.propTypes = {
  /**
   * Headless role item
   */
  role: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    permissions: PropTypes.arrayOf(
      PropTypes.shape({
        contentTypeDefinition: PropTypes.shape({ id: PropTypes.string }),
        type: PropTypes.string,
      }),
    ),
  }),
  /**
   * On save callback
   */
  onSave: PropTypes.func.isRequired,
  /**
   * Content types data
   */
  ctdsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  /**
   * Callback for filtering content types
   */
  ctdsFilterCallback: PropTypes.func,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Test id for form
   */
  testId: PropTypes.string,
};

HeadlessRoleForm.defaultProps = {
  ctdsOptions: [],
  ctdsFilterCallback: /* istanbul ignore next */ () => null,
  disabled: false,
  testId: '',
};
