/**
 * This handler is passed to each plugin instance to allow it to register callbacks for events or run new events.
 *
 * @memberof FlotiqPlugins
 * @name PluginEventHandler
 * @class
 *
 * @property {PluginInfo} plugin Plugin info
 * @property {FlotiqPlugins} registry Plugin registry
 *
 */
class PluginEventHandler {
  plugin;
  registry;

  /**
   * @type {Object.<string, Object.<string, function[]>>} callbackRegister
   * @private
   */
  #callbackRegister;

  /**
   * @type {Object.<string, PluginEventHandler>} registeredPluginHandlers
   * @private
   */
  #registeredPluginHandlers;

  /**
   *
   * @param {PluginInfo} pluginInfo
   * @param {FlotiqPlugins} registry
   */
  constructor(
    pluginInfo,
    registry,
    callbackRegister,
    registeredPluginHandlers,
  ) {
    this.plugin = pluginInfo;
    this.registry = registry;
    this.#callbackRegister = callbackRegister;
    this.#registeredPluginHandlers = registeredPluginHandlers;
  }

  /**
   * Registers a callback for given event
   *
   * @param {string} event
   * @param {function} callback
   */
  on(event, callback) {
    if (!this.#callbackRegister[event]) this.#callbackRegister[event] = {};
    if (!this.#callbackRegister[event][this.plugin.id])
      this.#callbackRegister[event][this.plugin.id] = [];

    this.#callbackRegister[event][this.plugin.id].push(callback);
  }

  /**
   * Runs an event with given params
   */
  run(event, ...params) {
    this.registry.run(event, ...params);
  }

  /**
   * Unregisters all callbacks for given plugin
   */
  unregister() {
    Object.keys(this.#callbackRegister).forEach((event) => {
      if (this.#callbackRegister[event][this.plugin.id]) {
        delete this.#callbackRegister[event][this.plugin.id];
      }
    });
    delete this.#registeredPluginHandlers[this.plugin.id];
  }
}

export { PluginEventHandler };
