import { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Lib
import { getTestProps } from '../../lib/helpers';

const Button = forwardRef(
  (
    {
      as,
      children,
      onClick,
      disabled,
      buttonSize,
      buttonColor,
      buttonShadow,
      type,
      iconImage,
      iconColor,
      iconPosition,
      noPaddings,
      additionalClasses,
      additionalIconClasses,
      additionalChildrenClasses,
      testId,
      ...props
    },
    ref,
  ) => {
    const ButtonComponent = as;
    const filteredProps = useMemo(
      () =>
        Object.keys(props).reduce((newProps, key) => {
          if (key === 'className') return newProps;
          newProps[key] = props[key];
          return newProps;
        }, {}),
      [props],
    );
    const sizeClasses = {
      xs: 'h-8 text-xs 2xl:text-sm',
      sm: 'h-10 text-sm 2xl:text-base',
      base: 'h-12 text-lg 2xl:text-xl',
      lg: 'h-14 text-xl 2xl:text-2xl',
    };

    const colorClasses = {
      blue: 'bg-blue border-blue text-white hover:bg-blue-600 hover:border-blue-600 hover:text-white',
      blueBordered:
        'bg-white dark:bg-transparent border-blue text-indigo-950 dark:text-white hover:text-white ' +
        'hover:bg-blue dark:hover:bg-blue hover:border-blue',
      redBordered:
        'bg-white dark:bg-transparent border-red text-indigo-950 dark:text-white hover:text-white hover:bg-red ' +
        'dark:hover:bg-red hover:border-red hover:text-white',
      gray:
        'bg-gray dark:bg-gray-700 border-gray dark:border-slate-800 text-indigo-950 dark:text-white ' +
        'hover:text-indigo-950 hover:bg-slate-100 dark:hover:bg-gray-700 hover:border-slate-100 ' +
        'dark:hover:border-slate-800',
      grayBordered:
        'bg-white dark:bg-transparent border-slate-200 text-indigo-950 dark:text-white hover:text-indigo-950 ' +
        'hover:bg-white hover:border-blue',
      borderless:
        '!bg-transparent border-0 text-indigo-950 dark:text-white hover:underline h-auto hover:text-indigo-950',
    };

    const shadowClasses = {
      none: '',
      sm: 'shadow-sm',
      base: 'shadow-md',
      lg: 'shadow-lg',
    };

    const sizeIconContainerClasses = {
      xs: 'w-8',
      sm: 'w-10',
      base: 'w-12',
      lg: 'w-14',
    };

    const sizeIconClasses = {
      xs: 'min-w-3 w-3',
      sm: 'min-w-4 w-4',
      base: 'min-w-5 w-5',
      lg: 'min-w-5 w-5',
    };

    const paddingClasses = {
      xs: 'px-3',
      sm: 'px-5',
      base: 'px-6',
      lg: 'px-8',
    };

    const disabledClass =
      'bg-slate-200 dark:bg-slate-400 cursor-not-allowed text-slate-400 dark:text-slate-600 border-slate-200 ' +
      'dark:border-slate-400 hover:bg-slate-200 hover:border-slate-200 hover:text-slate-400 ' +
      'active:bg-slate-200 active:!slate-200 active:!text-slate-400';

    const iconPositionClasses = {
      top: 'flex-col-reverse',
      bottom: 'flex-col',
      start: 'flex-row-reverse',
      end: 'flex-row',
    };

    const iconPositionSpacingClasses = {
      start: 'mr-2',
      end: 'ml-2',
    };

    const iconColorClasses = {
      inherit: 'text-inherit',
      blue: 'text-blue',
      black: 'text-indigo-950',
      white: 'text-white',
      red: 'text-red',
    };

    return (
      <ButtonComponent
        ref={ref}
        type={type}
        onClick={onClick}
        className={twMerge(
          'relative flex justify-between items-center ',
          'transition duration-200 ease-in-out',
          'leading-none rounded-lg font-medium border-2',
          'focus:outline-none cursor-pointer',
          sizeClasses[buttonSize],
          shadowClasses[buttonShadow],
          colorClasses[buttonColor],
          iconPositionClasses[iconPosition],
          noPaddings ? 'p-0' : paddingClasses[buttonSize],
          !children && sizeIconContainerClasses[buttonSize],
          !children && 'justify-center',
          disabled && disabledClass,
          additionalClasses,
        )}
        disabled={disabled}
        {...filteredProps}
        {...getTestProps(testId)}
      >
        <span
          className={twMerge(additionalChildrenClasses)}
          {...getTestProps(testId, 'children')}
        >
          {children}
        </span>

        {iconImage && (
          <div
            className={twMerge(
              'group flex items-center',
              'justify-center bg-transparent',
              sizeIconClasses[buttonSize],
              iconColorClasses[iconColor],
              children && iconPositionSpacingClasses[iconPosition],
              additionalIconClasses,
            )}
            {...getTestProps(testId, 'icon')}
          >
            {iconImage}
          </div>
        )}
      </ButtonComponent>
    );
  },
);

export default Button;

Button.propTypes = {
  /**
   * Button should be displayed as
   */
  as: PropTypes.elementType,
  /**
   * Button contents
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Is buton disabled
   */
  disabled: PropTypes.bool,
  /**
   * Button color
   */
  buttonColor: PropTypes.oneOf([
    'blue',
    'blueBordered',
    'borderless',
    'gray',
    'grayBordered',
    'redBordered',
  ]),
  /**
   * Button size
   */
  buttonSize: PropTypes.oneOf(['xs', 'sm', 'base', 'lg']),
  /**
   * Button drop shadow
   */
  buttonShadow: PropTypes.oneOf(['none', 'sm', 'base', 'lg']),
  /**
   * Button type
   */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /**
   * Button icon image
   */
  iconImage: PropTypes.node,
  /**
   * Button icon color
   */
  iconColor: PropTypes.oneOf(['inherit', 'black', 'blue', 'white', 'red']),
  /**
   * Button icon position
   */
  iconPosition: PropTypes.oneOf(['start', 'end', 'top', 'bottom']),
  /**
   * If button should have paddings
   */
  noPaddings: PropTypes.bool,
  /**
   * Button additional CSS classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Children additional CSS classes
   */
  additionalChildrenClasses: PropTypes.string,
  /**
   * Button icon position
   */
  additionalIconClasses: PropTypes.string,
  /**
   * Test id for button
   */
  testId: PropTypes.string,
};

Button.defaultProps = {
  as: 'button',
  disabled: false,
  onClick: undefined,
  buttonColor: 'blue',
  buttonSize: 'base',
  buttonShadow: 'none',
  type: 'button',
  iconImage: undefined,
  iconColor: 'inherit',
  iconPosition: 'end',
  noPaddings: false,
  additionalClasses: '',
  additionalChildrenClasses: '',
  additionalIconClasses: '',
  testId: '',
};
