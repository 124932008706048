import PropTypes from 'prop-types';
import { FieldArray, getIn, useFormikContext } from 'formik';
import Switch from '../../../components/Switch/Switch';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Input/Input';
import { QuestionMarkCircleIcon } from '../../../images/shapes';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { getTestProps } from '../../../lib/helpers';
import { MIN_PROPERTY, PROPERTIES_FIELDS } from './propertiesFields';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Dropdown from '../../../components/Dropdown/Dropdown';
import CtoCustomField from '../../../components/CtoCustomField/CtoCustomField';
import OptionsRender from './OptionsRender/OptionsRender';
import ListDefaultRender from './ListDefaultRender/ListDefaultRender';
import ContentTypeFormContext from '../../../contexts/ContentTypeFormContext';
import PropertyModalCtdsContext from '../../../contexts/PropertyModalCtdsContext';
import ListPropertiesContext from '../../../contexts/ListPropertiesContext';

const BLOCK_TOOLS = {
  list: 'List',
  header: 'Header',
  image: 'Image',
  youtubeEmbed: 'YoutubeEmbed',
  quote: 'Quote',
  warning: 'Warning',
  delimiter: 'Delimiter',
  code: 'Code',
  table: 'Table',
};

const VALIDATION = {
  unique: { key: 'config.unique', label: 'Unique' },
  required: { key: 'required', label: 'Required' },
  multiple: { key: 'config.validation.relationMultiple', label: 'Multiple' },
  showTime: { key: 'config.showTime', label: 'ShowTimePicker' },
};

const ADDITIONAL = {
  readonly: { key: 'config.readonly', label: 'Readonly' },
  hidden: { key: 'config.hidden', label: 'Hidden' },
  isTitlePart: { key: 'config.isTitlePart', label: 'PartOfTitle' },
};

const findErrors = (name, propertyErrors, formik) => {
  const errorsFromFormik = getIn(formik.touched, name)
    ? getIn(formik.errors, name)
    : null;
  const errors = getIn(propertyErrors, name) || errorsFromFormik;
  return errors;
};

const PropertiesSettings = ({ inputType, propertyErrors, isMedia, testId }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const { setDirty } = useContext(ContentTypeFormContext);
  const { ctds, ctdsAreLoading, filterCtds, getValidationType } = useContext(
    PropertyModalCtdsContext,
  );
  const { isNestedList } = useContext(ListPropertiesContext);

  const [extraValidationType, setExtraValidationType] = useState(null);

  useEffect(() => {
    const contentTypeName =
      formik.values.config?.validation?.relationContenttype;
    if (!contentTypeName) setExtraValidationType(null);
    else
      getValidationType(contentTypeName).then((ctd) =>
        setExtraValidationType(ctd),
      );
  }, [
    formik.values.config?.validation?.relationContenttype,
    getValidationType,
  ]);

  const handleFieldChange = useCallback(
    (e) => {
      formik.handleChange(e);
      setDirty(true);
    },
    [setDirty, formik],
  );

  const handleRequiredFieldChange = useCallback(
    (e, minProperty) => {
      handleFieldChange(e);
      if (!minProperty?.key) return;
      const isRequired = e.target.value;
      if (isRequired) {
        formik.setFieldValue(
          `schema.${minProperty.key}`,
          minProperty.value || 1,
        );
      } else {
        formik.setFieldValue(`schema.${minProperty.key}`, undefined);
      }
    },
    [handleFieldChange, formik],
  );

  const handleBlockTypesChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (value.length) {
        formik.setFieldValue('config.blockEditorTypes', value);
      } else {
        formik.setFieldValue('config.blockEditorTypes', undefined);
      }
      setDirty(true);
    },
    [formik, setDirty],
  );

  const handleDateTimeChange = useCallback(
    (e) => {
      const value = formik.values.schema.default;
      const showTime = formik.values.config.showTime;

      if (value) {
        if (showTime) {
          formik.setFieldValue('schema.default', value.split('T')[0]);
        } else {
          formik.setFieldValue('schema.default', value + 'T00:00');
        }
      }

      formik.handleChange(e);
      setDirty(true);
    },
    [formik, setDirty],
  );

  const handleRelationTypeChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (!formik.values.schema.default) {
        formik.setFieldValue('config.validation.relationContenttype', value);
      } else {
        const newValues = { ...formik.values };
        newValues.config.validation.relationContenttype = value;
        newValues.schema.default = newValues.schema.default.filter(
          (relationValue) => {
            const splited = relationValue.dataUrl.split('/');
            const relationType = splited[splited.length - 2];
            return relationType === value;
          },
        );
        formik.setValues(newValues);
      }
      setDirty(true);
    },
    [formik, setDirty],
  );

  const handleValidationOnChange = useCallback(
    (e, type) => {
      switch (type) {
        case 'required':
          handleRequiredFieldChange(e, MIN_PROPERTY[inputType]);
          break;
        case 'showTime':
          handleDateTimeChange(e);
          break;
        default:
          handleFieldChange(e);
          break;
      }
    },
    [
      handleDateTimeChange,
      handleFieldChange,
      handleRequiredFieldChange,
      inputType,
    ],
  );

  const additionalCtoFieldClasses = useMemo(
    () =>
      inputType === 'select'
        ? { additionalDropdownClasses: '!top-auto bottom-full' }
        : {},
    [inputType],
  );

  return (
    <>
      <div
        className="flex flex-col xs:flex-row items-start justify-between w-full py-6 gap-2 sm:gap-8 md:gap-14
        border-y border-gray dark:border-slate-800"
      >
        <div className="flex flex-row items-center gap-2 sm:gap-8 md:gap-14 w-full">
          {Object.keys(VALIDATION).map((configName) => {
            if (!PROPERTIES_FIELDS[inputType].includes(configName)) return null;
            const name = VALIDATION[configName].key;
            return (
              <div key={configName}>
                <Switch
                  name={name}
                  checked={getIn(formik.values, name)}
                  label={t(
                    `ContentTypeForm.Descriptions.${VALIDATION[configName].label}`,
                  )}
                  onChange={(e) => handleValidationOnChange(e, configName)}
                  onBlur={formik.handleBlur}
                  error={findErrors(name, propertyErrors, formik)}
                  additionalClasses="justify-items-start"
                  labelUp
                  {...getTestProps(testId, `${configName}`, 'testId')}
                />
              </div>
            );
          })}
        </div>
        <div className="flex flex-row items-center justify-start sm:justify-end gap-2 sm:gap-8 md:gap-14 w-full">
          {Object.keys(ADDITIONAL).map((configName) => {
            if (
              !PROPERTIES_FIELDS[inputType].includes(configName) ||
              (isNestedList && ['isTitlePart'].includes(configName))
            )
              return null;
            const name = ADDITIONAL[configName].key;
            return (
              <div key={configName}>
                <Switch
                  name={name}
                  checked={getIn(formik.values, name)}
                  label={t(
                    `ContentTypeForm.Descriptions.${ADDITIONAL[configName].label}`,
                  )}
                  onChange={handleFieldChange}
                  onBlur={formik.handleBlur}
                  error={findErrors(name, propertyErrors, formik)}
                  additionalClasses="justify-items-start"
                  labelUp
                  {...getTestProps(testId, `${configName}`, 'testId')}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Input
        name="config.helpText"
        value={formik.values.config.helpText}
        onChange={handleFieldChange}
        onBlur={formik.handleBlur}
        error={findErrors('config.helpText', propertyErrors, formik)}
        label={t('PropertyForm.HelpText')}
        {...getTestProps(testId, 'help-text', 'testId')}
      />
      {PROPERTIES_FIELDS[inputType].includes('blockEditorTypes') && (
        <Dropdown
          name="config.blockEditorTypes"
          value={formik.values.config.blockEditorTypes}
          options={Object.keys(BLOCK_TOOLS).map((tool) => ({
            value: tool,
            label: t(`PropertyForm.BlockTools.${BLOCK_TOOLS[tool]}`),
          }))}
          onChange={handleBlockTypesChange}
          onBlur={formik.handleBlur}
          error={findErrors('config.blockEditorTypes', propertyErrors, formik)}
          label={t('PropertyForm.RestrictBlockTypes')}
          placeholder={t('PropertyForm.RestrictBlockTypes')}
          multiple
          additionalDropdownClasses="!top-auto bottom-full"
          {...getTestProps(testId, 'block-tools', 'testId')}
        />
      )}
      {PROPERTIES_FIELDS[inputType].includes('options') && (
        <FieldArray name="config.options">
          {(arrayHelpers) => (
            <OptionsRender
              arrayHelpers={arrayHelpers}
              label={t('PropertyForm.Options')}
              error={findErrors('config.options', propertyErrors, formik)}
              {...getTestProps(testId, 'options', 'testId')}
            />
          )}
        </FieldArray>
      )}
      {inputType === 'datasource' && !isMedia && (
        <Dropdown
          name="config.validation.relationContenttype"
          value={formik.values.config.validation.relationContenttype}
          options={ctds}
          isDataLoading={ctdsAreLoading}
          filterCallback={filterCtds}
          onChange={handleRelationTypeChange}
          onBlur={formik.handleBlur}
          error={findErrors(
            'config.validation.relationContenttype',
            propertyErrors,
            formik,
          )}
          label={t('PropertyForm.RestrictType')}
          ignoreNotFound
          debounceTime={300}
          extraOptions={
            extraValidationType
              ? [
                  {
                    label: extraValidationType.label,
                    value: extraValidationType.name,
                  },
                ]
              : []
          }
          {...getTestProps(testId, 'ctds', 'testId')}
        />
      )}
      {PROPERTIES_FIELDS[inputType].includes('default') &&
      inputType === 'object' ? (
        <div>
          <label
            className="text-sm text-slate-400 mb-1"
            {...getTestProps(testId, 'label')}
          >
            {t('PropertyForm.Default')}
          </label>
          <ListDefaultRender
            itemsProps={formik.values.config.items}
            itemsSchema={formik.values.schema.items}
            helpText={formik.values.config.helpText}
            listName={formik.values.key}
            testId={testId}
          />
        </div>
      ) : (
        <CtoCustomField
          name="schema.default"
          properties={formik.values.config}
          schema={formik.values.schema}
          label={t('PropertyForm.Default')}
          ignoreHidden
          testId={testId}
          {...additionalCtoFieldClasses}
        />
      )}
      {PROPERTIES_FIELDS[inputType].includes('pattern') && (
        <Input
          name="schema.pattern"
          value={formik.values.schema.pattern}
          onChange={handleFieldChange}
          onBlur={formik.handleBlur}
          error={findErrors('schema.pattern', propertyErrors, formik)}
          label={
            <Tooltip
              tooltip={t('PropertyForm.PatternTooltip')}
              tooltipPlacement="topLeft"
              phoneTooltipPlacement="topLeft"
              additionalClasses="w-fit inline-flex"
            >
              {t('PropertyForm.Pattern')}
              <QuestionMarkCircleIcon className="text-blue w-3 ml-1" />
            </Tooltip>
          }
          {...getTestProps(testId, 'pattern', 'testId')}
        />
      )}
    </>
  );
};

export default PropertiesSettings;

PropertiesSettings.propTypes = {
  /**
   * Property input type
   */
  inputType: PropTypes.string.isRequired,
  /**
   * Property errors
   */
  propertyErrors: PropTypes.object,
  /**
   * If datasource is media type
   */
  isMedia: PropTypes.bool,
  /**
   * Property setting test id
   */
  testId: PropTypes.string,
};

PropertiesSettings.defaultProps = {
  propertyErrors: {},
  isMedia: false,
  testId: '',
};
