import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';

// :: Hooks
import { useContentObjects, useMediaTags } from '../../../hooks/api';
import useApiErrorsToast from '../../../hooks/api/useApiErrorsToast';
import useToken from '../../../hooks/useToken';
import useDebounceCallback from '../../../hooks/useDebounceCallback';

// :: Components
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Dropdown from '../../../components/Dropdown/Dropdown';
import FileButton from '../../../components/FileButton/FileButton';
import Heading from '../../../components/Heading/Heading';
import Input from '../../../components/Input/Input';
import MediaCard from '../../../components/MediaCard/MediaCard';
import MediaGrid from '../../../components/MediaGrid/MediaGrid';
import MediaDataGrid from '../../../components/MediaDataGrid/MediaDataGrid';

// :: Components Inner
import TagsManagementModal from '../TagsManagementModal/TagsManagementModal';

// :: Images
import { ArrowUpIcon } from '../../../images/shapes';

// :: Lib
import { getTagUrl, getTestProps } from '../../../lib/helpers';
import {
  batchDeleteMedia,
  batchUpdateMedia,
  updateMedia,
} from '../../../lib/flotiq-client';
import {
  ResponseError,
  checkResponseStatus,
} from '../../../lib/flotiq-client/response-errors';
import {
  getMediaUrl,
  getTagsFromQuery,
} from '../../../lib/flotiq-client/api-helpers';
import {
  findBorderColor,
  findThumbnail,
  getMediaData,
} from '../../../lib/newMediaHelpers';

// :: Context
import { useModals } from '../../../contexts/ModalContext';
import NewMediaContext from '../../../contexts/NewMediaContext';

// :: Other
import { getExtensionOptions, parseExtensionFilters } from './extenstions';

const UserMedia = ({
  mediaOnPageLimit,
  setMediaOnPageLimit,
  onUpload,
  onRemove,
  multipleUpload,
  isEditble,
  selectMedia,
  selectedMedia,
  clearSelection,
  hasPanel,
  hasGridView,
  additionalGridContainerClasses,
  testId,
}) => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [order, setOrder] = useState({
    order_by: 'internal.createdAt',
    order_direction: 'desc',
  });
  const [managementErrors, setManagementErrors] = useState({});
  const [tagsData, setTagsData] = useState([]);
  const { t } = useTranslation();
  const { newMedia, setNewMedia } = useContext(NewMediaContext);
  const jwt = useToken();

  const [activeFilters, setActiveFilters] = useState({});

  const handlePageChange = useCallback((pageNo) => {
    setPage(pageNo);
    setManagementErrors({});
  }, []);

  const modal = useModals();

  const handleFilters = useCallback((name, value, type = 'contains') => {
    setPage(1);
    setFilters((prev) => {
      const newFilters = { ...prev };
      if (!value) delete newFilters[name];
      else newFilters[name] = { filter: value, type: type };
      return newFilters;
    });
    setManagementErrors({});
  }, []);

  const debauncedHandleSearch = useDebounceCallback(
    (value) => handleFilters('fileName', value),
    300,
  );

  const handleOrder = useCallback((name, value) => {
    setOrder((prev) => ({
      ...prev,
      [name]: value,
    }));
    setPage(1);
    setManagementErrors({});
  }, []);

  const tagsParams = useMemo(() => {
    return {
      page: 1,
      limit: 20,
      order_by: 'name',
    };
  }, []);

  const { data: tagData, isLoading: isLoadingTag } = useMediaTags(tagsParams);

  const sortOptions = useMemo(() => {
    return [
      { value: 'fileName', label: t('Media.Name') },
      { value: 'extension', label: t('Media.Extension') },
      { value: 'size', label: t('Media.Size') },
      {
        value: 'internal.createdAt',
        label: t('Media.UploadDate'),
      },
      { value: 'type', label: t('Media.Type') },
    ];
  }, [t]);

  useEffect(
    () =>
      setTagsData(() =>
        tagData.map((element) => ({
          value: getTagUrl(element.id),
          label: element.name,
        })),
      ),
    [tagData],
  );

  const orderOptions = useMemo(() => {
    return [
      {
        value: 'asc',
        searchString: t('Media.Filters.OrderOptions.Asc'),
        label: (
          <div className="h-full flex items-center">
            <ArrowUpIcon className={'mr-2 h-3 text-blue'} />
            {t('Media.Filters.OrderOptions.Asc')}
          </div>
        ),
      },
      {
        value: 'desc',
        searchString: t('Media.Filters.OrderOptions.Desc'),
        label: (
          <div className="h-full flex items-center">
            <ArrowUpIcon className={'mr-2 h-3 text-blue rotate-180'} />
            {t('Media.Filters.OrderOptions.Desc')}
          </div>
        ),
      },
    ];
  }, [t]);

  const extensionOptions = useMemo(
    () => getExtensionOptions(filters.extension?.filter || [], t),
    [filters.extension?.filter, t],
  );

  const customFilters = useCallback(
    async (query, _, setIsLoading) => {
      setIsLoading(true);
      const newOptions = await getTagsFromQuery(jwt, query, t, tagsParams);
      setIsLoading(false);
      return newOptions.map((element) => ({
        value: getTagUrl(element.id),
        label: element.name,
      }));
    },
    [t, tagsParams, jwt],
  );

  const handleActiveFilters = useCallback((key, value) => {
    setActiveFilters((prevState) => {
      const newFilters = { ...prevState };
      if (value) newFilters[key] = value;
      else delete newFilters[key];
      return newFilters;
    });
  }, []);

  const onExtenionFilterChange = useCallback(
    (event) => {
      const newValueWithoutDuplications = parseExtensionFilters(
        event.target.value || [],
      );

      handleFilters(
        'extension',
        newValueWithoutDuplications.length ? newValueWithoutDuplications : null,
        'equals',
      );
      handleActiveFilters(
        'extension',
        (newValueWithoutDuplications || [])
          .map((extension) => extension)
          .join(', '),
      );
    },
    [handleActiveFilters, handleFilters],
  );

  const filtersNode = useMemo(() => {
    return (
      <div
        className={
          'md:grid-flow-dense md:grid md:gap-6 md:grid-cols-3 xl:grid-cols-5 '
        }
      >
        <Input
          label={t('Media.Filters.SearchByName')}
          placeholder={t('Media.Filters.SearchByName')}
          onChange={(event) => {
            const value = event.target.value;
            if (!value) handleFilters('fileName', value);
            else debauncedHandleSearch(value);
            handleActiveFilters('fileName', value);
          }}
          value={filters?.fileName?.filter}
          type="search"
          {...getTestProps(testId, 'filter-name', 'testId')}
        />
        <Dropdown
          label={t('Media.Filters.SearchByTag')}
          placeholder={t('Media.Filters.SearchByTag')}
          options={tagsData}
          isDataLoading={isLoadingTag}
          onChange={(event) => {
            handleFilters('tags[*].dataUrl', event.target.value, '_contains');
            handleActiveFilters('tag', event.option?.label);
          }}
          filterCallback={customFilters}
          emptyOptions={<div className="py-2 px-4">{t('Media.EmptyTags')}</div>}
          value={filters?.['tags[*].dataUrl']?.filter}
          additionalOptionsClasses="py-1.5"
          debounceTime={500}
          nullable
          ignoreNotFound
          {...getTestProps(testId, 'filter-tag', 'testId')}
        />
        <Dropdown
          label={t('Media.Filters.SearchByExtention')}
          placeholder={t('Media.Filters.SearchByExtention')}
          options={extensionOptions}
          onChange={onExtenionFilterChange}
          value={filters?.extension?.filter}
          additionalOptionsClasses="py-1.5"
          additionalDropdownClasses="max-h-60"
          nullable
          multiple
          {...getTestProps(testId, 'filter-extension', 'testId')}
        />
        <Dropdown
          label={t('Media.Filters.SortBy')}
          placeholder={t('Media.Filters.SortBy')}
          options={sortOptions}
          onChange={(event) => {
            handleOrder('order_by', event.target.value);
          }}
          value={order?.order_by}
          additionalOptionsClasses="py-1.5"
          additionalDropdownClasses="max-h-44"
          nullable
          {...getTestProps(testId, 'order-by', 'testId')}
        />
        <div className={'w-full xl:w-2/3'}>
          <Dropdown
            testId={`${testId}-Order`}
            label={t('Media.Filters.Order')}
            placeholder={t('Media.Filters.Order')}
            options={orderOptions}
            onChange={(event) => {
              handleOrder('order_direction', event.target.value);
            }}
            value={order?.order_direction}
            additionalOptionsClasses="py-1.5"
            nullable
            {...getTestProps(testId, 'order', 'testId')}
          />
        </div>
      </div>
    );
  }, [
    t,
    filters,
    testId,
    tagsData,
    isLoadingTag,
    customFilters,
    extensionOptions,
    onExtenionFilterChange,
    sortOptions,
    order?.order_by,
    order?.order_direction,
    orderOptions,
    handleFilters,
    debauncedHandleSearch,
    handleActiveFilters,
    handleOrder,
  ]);

  const params = useMemo(() => {
    return {
      limit: mediaOnPageLimit,
      page: page,
      filters: JSON.stringify(filters),
      hydrate: 1,
      ...order,
    };
  }, [mediaOnPageLimit, page, filters, order]);

  const { data, errors, isLoading, pagination, reload } = useContentObjects(
    '_media',
    params,
  );

  useEffect(() => {
    if (pagination?.total_pages < page) setPage(pagination.total_pages);
  }, [page, pagination?.total_pages]);

  useApiErrorsToast(errors);

  useEffect(() => {
    if (isLoading) {
      setNewMedia([]);
    }
  }, [isLoading, setNewMedia]);

  const handleNoData = useMemo(
    () => (
      <div
        className={twMerge(
          'md:text-[40px] md:leading-[52px] font-bold text-2xl',
          'flex flex-col items-center py-2 lg:py-8 text-center',
        )}
      >
        <p className="text-blue-600">{t('Media.DropFiles')}</p>
        <p className="text-indigo-950 dark:text-white m-0 mb-6">
          {t('Media.AddToLibrary')}
        </p>
        <FileButton
          onUpload={onUpload}
          multiple={multipleUpload}
          additionalClasses="lg:h-14 lg:text-lg 2xl:text-xl"
          {...getTestProps(testId, 'file', 'testId')}
        />
      </div>
    ),
    [t, onUpload, multipleUpload, testId],
  );

  const selectAllMediaInPage = useCallback(
    (checked) => {
      if (checked) {
        data.forEach((mediaData) => {
          selectMedia(mediaData);
        });
      } else {
        clearSelection();
      }
    },
    [data, clearSelection, selectMedia],
  );

  const applyTagFilter = useCallback(
    (tagId, tagName) => {
      const tagUrl = getTagUrl(tagId);
      const selectedTagFilter = {
        value: tagUrl,
        label: tagName,
      };
      if (
        tagsData.filter(
          (element) =>
            element.dataUrl === selectedTagFilter.dataUrl &&
            element.label === selectedTagFilter.label,
        ).length === 0
      ) {
        setTagsData((prev) => prev.concat([selectedTagFilter]));
      }
      handleFilters('tags[*].dataUrl', tagUrl, '_contains');
      handleActiveFilters('tag', selectedTagFilter.label);
    },
    [handleActiveFilters, handleFilters, tagsData],
  );

  const handleTagUpdate = useCallback(
    async (media, tags) => {
      try {
        let updateResult;
        if (media.length === 1) {
          updateResult = await updateMedia(jwt, {
            mediaId: media[0].id,
            tags: tags,
          });
        } else {
          updateResult = await batchUpdateMedia(
            jwt,
            media.map((element) => {
              const elementTags = (element.tags || []).filter(
                (tag) =>
                  tags.findIndex(
                    (newTag) => newTag.dataUrl === getTagUrl(tag.id),
                  ) < 0,
              );
              return {
                id: element.id,
                tags: tags.concat(
                  elementTags.map((element) => ({
                    dataUrl: getTagUrl(element.id),
                    type: 'internal',
                  })),
                ),
              };
            }),
          );
        }
        checkResponseStatus(updateResult.body, updateResult.status);
        toast.success(t('TagsManagement.TagEditSuccess'));
        reload();
        clearSelection();
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(error.message);
        }
      }
    },
    [jwt, reload, clearSelection, t],
  );

  const handleTagManagementModal = useCallback(
    async (media) => {
      const mediaData = Array.isArray(media) ? media : [media];
      setManagementErrors({});
      await modal({
        title: (
          <Heading level={4} additionalClasses="truncate dark:text-white">
            {mediaData.length === 1 
              ? t('TagsManagement.AddTagsToAsset', {
                  name: mediaData[0]?.fileName,
                })
              : t('TagsManagement.AddTagsToAssets', {
                  counter: mediaData.length,
                })}
          </Heading>
        ),
        content: (
          <TagsManagementModal
            media={mediaData}
            handleTagUpdate={handleTagUpdate}
            {...getTestProps(testId, 'tags-management-modal', 'testId')}
          />
        ),
        dialogAdditionalClasses: '!overflow-visible',
      });
    },
    [modal, t, testId, handleTagUpdate],
  );

  const handleDeleteMedia = useCallback(
    async (selectedMediaToDelete) => {
      setManagementErrors({});

      try {
        const { body, status } = await batchDeleteMedia(
          jwt,
          Object.values(selectedMediaToDelete).map((element) => element.id),
        );
        checkResponseStatus(body, status);
        toast.success(t('Media.MediaManagement.DeleteSuccess'));
        onRemove?.();
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(
            error.message
              ? error.message
              : t('Media.MediaManagement.CouldntDelete'),
          );
          if (error.errors) {
            setManagementErrors((prev) => {
              const errors = { ...prev };
              error.errors.forEach((error) => {
                const objectIdRegex = /_media\/(?<id>[^\s]*)/;
                const regexData = error.match(objectIdRegex);
                if (regexData?.groups) {
                  const { id } = regexData.groups;
                  errors[id] = error;
                }
              });
              return errors;
            });
          }
        }
      }

      clearSelection();
      reload();
    },
    [jwt, reload, onRemove, clearSelection, t],
  );

  const showDeletingModal = useCallback(
    async (selectedMediaToDelete) => {
      modal.deleting('delete-media-modal', {
        testId: `${testId}-delete-confirmation-modal`,
      });
      await handleDeleteMedia(selectedMediaToDelete);
    },
    [handleDeleteMedia, modal, testId],
  );

  const deleteMedia = useCallback(
    async (selectedMediaToDelete) => {
      const count = Object.keys(selectedMediaToDelete).length;
      await modal.delete(
        <div className="dark:text-white">
          <Heading additionalClasses="dark:text-white" level={6}>
            {t('Media.MediaManagement.DeleteConfirmation', { count })}
          </Heading>
          <p>{t('Media.MediaManagement.DeleteWarning')}</p>
        </div>,
        'delete-media-modal',
        () => showDeletingModal(selectedMediaToDelete),
        () => null,
        {
          testId: `${testId}-delete-confirmation-modal`,
        },
      );
    },
    [modal, showDeletingModal, t, testId],
  );

  const selectedMediaActionBar = useMemo(() => {
    if (!isEditble) return;

    const selectedMediaCount = selectedMedia
      ? Object.values(selectedMedia).length
      : 0;
    return (
      <div className="flex flex-col lg:flex-row gap-1 lg:gap-2 w-full">
        <div className="flex flex-row items-center h-10 justify-start w-full md:w-fit">
          <Checkbox
            name="select"
            additionalCheckboxClasses="w-6 h-6"
            onChange={(event) => selectAllMediaInPage(event.target.checked)}
            circular
            checked={selectedMedia && Object.values(selectedMedia).length !== 0}
            label={
              selectedMediaCount > 0
                ? t('Media.MediaManagement.AssetSelectedCounter', {
                    counter: selectedMediaCount,
                  })
                : t('Media.MediaManagement.SelectAll')
            }
            additionalLabelClasses="text-sm md:text-base mr-2"
            {...getTestProps(testId, 'select-all-media', 'testId')}
          />
        </div>
        {selectedMediaCount > 0 && (
          <div className="flex flex-wrap gap-1 lg:gap-2 justify-start w-full md:w-fit">
            <Button
              buttonColor="blueBordered"
              buttonSize="sm"
              onClick={() =>
                handleTagManagementModal(Object.values(selectedMedia))
              }
            >
              {t('Media.MediaManagement.AddTags')}
            </Button>
            <Button
              buttonColor="redBordered"
              buttonSize="sm"
              onClick={() => deleteMedia(selectedMedia)}
            >
              {t('Media.MediaManagement.DeleteSelected')}
            </Button>
            <Button
              buttonColor="blueBordered"
              buttonSize="sm"
              onClick={() => clearSelection()}
            >
              {t('Media.MediaManagement.CancelSelect')}
            </Button>
          </div>
        )}
      </div>
    );
  }, [
    isEditble,
    selectedMedia,
    t,
    testId,
    selectAllMediaInPage,
    handleTagManagementModal,
    deleteMedia,
    clearSelection,
  ]);

  const onMediaClick = useCallback(
    (mediaData) => {
      selectMedia(mediaData, '_media');
    },
    [selectMedia],
  );

  const openFullSizeImage = useCallback((mediaData) => {
    window.open(getMediaUrl(mediaData), '_blank');
  }, []);

  const onCardDelete = useCallback(
    (media) => deleteMedia([media]),
    [deleteMedia],
  );

  const renderMedia = useCallback(
    (media, isNew = false) => {
      const {
        card,
        errors,
        isLoaded = true,
      } = isNew
        ? getMediaData(media)
        : { card: media, errors: managementErrors?.[media.id] };

      return (
        <MediaCard
          key={card.id}
          mediaData={card}
          thumbnail={findThumbnail(errors, isLoaded, false, testId)}
          hideOverlay={errors || !isLoaded ? true : false}
          onClick={!isLoaded || errors ? null : onMediaClick}
          isSelected={selectedMedia?.[card.id] ? true : false}
          onDelete={onCardDelete}
          onShow={openFullSizeImage}
          onEdit={isEditble ? handleTagManagementModal : null}
          onTagEdit={isEditble ? handleTagManagementModal : null}
          onTagClick={applyTagFilter}
          additionalContainerClasses={findBorderColor(errors, isLoaded, isNew)}
          additionalThumbnailClasses={
            managementErrors?.[card.id] &&
            'absolute inset-0 bg-white opacity-70'
          }
          activeSortOrder={order?.order_by}
          {...getTestProps(testId, `media-card-${card.id}`, 'testId')}
        />
      );
    },
    [
      managementErrors,
      testId,
      onMediaClick,
      selectedMedia,
      onCardDelete,
      openFullSizeImage,
      isEditble,
      handleTagManagementModal,
      applyTagFilter,
      order,
    ],
  );

  return (
    <>
      <MediaGrid
        page={page}
        data={data}
        renderMedia={renderMedia}
        isLoading={isLoading}
        errors={errors}
        pagination={pagination}
        handlePageChange={handlePageChange}
        areFiltersApplied={Object.keys(filters).length !== 0}
        filtersNode={filtersNode}
        handleNoData={handleNoData}
        selectedMediaActionBarNode={selectedMediaActionBar}
        newMedia={newMedia}
        selectedMedia={selectedMedia}
        hasPanel={hasPanel}
        emptyFilterText={t('Media.FiltersEmptyResult')}
        activeFilters={activeFilters}
        resultsPerPage={mediaOnPageLimit}
        setResultsPerPage={setMediaOnPageLimit}
        gridView={
          hasGridView ? (
            <MediaDataGrid
              media={data}
              isLoading={isLoading}
              pagination={pagination}
              limit={mediaOnPageLimit}
              reloadMedia={reload}
              order={order}
              handleOrder={handleOrder}
              selectedMedia={selectedMedia}
              selectMedia={selectMedia}
              openFullSizeImage={openFullSizeImage}
              onTagEdit={handleTagManagementModal}
              onTagClick={applyTagFilter}
              testId={testId}
            />
          ) : null
        }
        additionalGridContainerClasses={additionalGridContainerClasses}
        testId={testId}
      />
    </>
  );
};

export default UserMedia;

UserMedia.propTypes = {
  /**
   * Limit of an element on page
   */
  mediaOnPageLimit: PropTypes.number,
  /**
   * Set the limit of an element on page
   */
  setMediaOnPageLimit: PropTypes.func,
  /**
   * On upload callback
   */
  onUpload: PropTypes.func,
  /**
   * If file selection is multiple
   */
  multipleUpload: PropTypes.bool,
  /**
   * If media card is editable
   */
  isEditble: PropTypes.bool,
  /**
   * Callback for selecting media
   */
  selectMedia: PropTypes.func,
  /**
   * Selected media
   */
  selectedMedia: PropTypes.object,
  /**
   * Callback for clearing all media selections
   */
  clearSelection: PropTypes.func,
  /**
   * If media filters are in panel
   */
  hasPanel: PropTypes.bool,
  /**
   * If grid view is allowed to change
   */
  hasGridView: PropTypes.bool,
  /**
   *   CSS classes for Media grid container
   */
  additionalGridContainerClasses: PropTypes.string,
  /**
   *  Callback on remove media
   */
  onRemove: PropTypes.func,
  /**
   *   Test id for user media subpage
   */
  testId: PropTypes.string,
};

UserMedia.defaultProps = {
  mediaOnPageLimit: 8,
  onUpload: /* istanbul ignore next */ () => null,
  isEditble: true,
  selectMedia: /* istanbul ignore next */ () => null,
  selectedMedia: {},
  clearSelection: /* istanbul ignore next */ () => null,
  onRemove: /* istanbul ignore next */ () => null,
  hasPanel: true,
  multipleUpload: true,
  hasGridView: false,
  additionalGridContainerClasses: '',
  testId: '',
};
