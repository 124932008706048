import { Fragment } from 'react';
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useParams,
} from 'react-router-dom';

// :: Layout
import Layout from '../layout/Layout';

// :: Utils
import { getLocalStorage } from '../utils/localStorage';

// :: Lib helpers
import { isModuleEnabled } from '../lib/helpers';

// :: Context
import { ModalProvider } from '../contexts/ModalContext';

// :: Pages
import AccountActivation from '../pages/AccountActivation/AccountActivation';
import AddContentObject from '../pages/AddContentObject/AddContentObject';
import AddContentTypeDefinition from '../pages/AddContentTypeDefinition/AddContentTypeDefinition';
import APIKeys from '../pages/APIKeys/APIKeys';
import ChangePasswordPage from '../pages/ChangePasswordPage/ChangePasswordPage';
import ChangePasswordRequest from '../pages/ChangePasswordRequest/ChangePasswordRequest';
import ContentTypeDefinitions from '../pages/ContentTypeDefinitions/ContentTypeDefinitions';
import ContentTypeObjects from '../pages/ContentTypeObjects/ContentTypeObjects';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import MediaLibrary from '../pages/MediaLibrary/MediaLibrary';
import Page404 from '../pages/Page404/Page404';
import RegisterAppSumo from '../pages/RegisterAppSumo/RegisterAppSumo';
import Register from '../pages/Register/Register';
import Users from '../pages/Users/Users';
import AccountSettings from '../pages/AccountSettings/AccountSettings';
import Starters from '../pages/Starters/Starters';
import AccountDeletion from '../pages/AccountDeletion/AccountDeletion';
import Profile from '../pages/AccountSettings/subpages/Profile';
import AddWebhooks from '../pages/AddWebhooks/AddWebhooks';
import ContentEmpty from '../pages/ContentEmpty/ContentEmpty';
import Plugins from '../pages/Plugins/Plugins';
import HeadlessRoles from '../pages/HeadlessRoles/HeadlessRoles';
import AddHeadlessRole from '../pages/AddHeadlessRole/AddHeadlessRole';
import Logout from '../pages/Logout/Logout';
import Plans from '../pages/Plans/Plans';
import AddPlan from '../pages/AddPlan/AddPlan';

export const ParamKeyPage = ({ paramName, children }) => {
  const params = useParams();
  const key = params[paramName];
  return <Fragment key={key} children={children} />;
};

export const ProtectedRoute = ({ children, redirect }) => {
  const user = getLocalStorage('cms.user', true);

  if (user?.token) {
    return children;
  } else if (redirect) {
    return redirect;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export const webhooksDefinedColumns = () =>
  isModuleEnabled('WEBHOOKS_TYPE')
    ? ['name', 'type', 'url', 'enabled', 'actions']
    : ['name', 'url', 'enabled', 'actions'];

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        element={
          <ModalProvider>
            <Layout />
          </ModalProvider>
        }
      >
        <Route path="/" element={<ProtectedRoute children={<Home />} />} />
        <Route
          path="/content-type-objects/:contentTypeName"
          element={
            <ProtectedRoute
              children={
                <ParamKeyPage paramName="contentTypeName">
                  <ContentTypeObjects />
                </ParamKeyPage>
              }
            />
          }
        />
        <Route
          path="/content-type-objects/add/:contentTypeName"
          element={<ProtectedRoute children={<AddContentObject />} />}
        />
        <Route
          path="/content-type-objects/edit/:contentTypeName/:id"
          element={<ProtectedRoute children={<AddContentObject />} />}
        />
        <Route
          path="/content-type-objects/duplicate/:contentTypeName/:id"
          element={<ProtectedRoute children={<AddContentObject duplicate />} />}
        />
        <Route
          path="/media"
          element={<ProtectedRoute children={<MediaLibrary />} />}
        />
        <Route
          path="/content-type-definitions"
          element={
            <ProtectedRoute
              children={
                <ContentTypeDefinitions limit={10000} pagination={false} />
              }
            />
          }
        />
        <Route
          path="/content-type-definitions/add"
          element={<ProtectedRoute children={<AddContentTypeDefinition />} />}
        />
        <Route
          path="/content-type-definitions/edit/:contentTypeName"
          element={<ProtectedRoute children={<AddContentTypeDefinition />} />}
        />
        <Route
          path="/content-type-definitions/duplicate/:contentTypeName"
          element={
            <ProtectedRoute children={<AddContentTypeDefinition duplicate />} />
          }
        />
        <Route
          path="/users"
          element={<ProtectedRoute children={<Users />} />}
        />
        <Route
          path="/users/add"
          element={
            <ProtectedRoute children={<Profile include={true} mode="add" />} />
          }
        />
        <Route
          path="/users/edit/:id"
          element={
            <ProtectedRoute children={<Profile include={true} mode="edit" />} />
          }
        />
        <Route
          path="/users-data-preview"
          element={<ProtectedRoute children={<Users isAllUsers />} />}
        />
        <Route
          path="/plans"
          element={<ProtectedRoute children={<Plans />} />}
        />
        <Route
          path="/plans/add/"
          element={<ProtectedRoute children={<AddPlan />} />}
        />
        <Route
          path="/plans/edit/:id"
          element={<ProtectedRoute children={<AddPlan />} />}
        />
        <Route
          path="/usage"
          element={
            <ProtectedRoute children={<AccountSettings selectedTabIdx={1} />} />
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute children={<AccountSettings selectedTabIdx={0} />} />
          }
        />
        <Route
          path="/api-keys"
          element={<ProtectedRoute children={<APIKeys />} />}
        />
        <Route
          path="/starters"
          element={<ProtectedRoute children={<Starters />} />}
        />
        <Route
          path="/plugins"
          element={<ProtectedRoute children={<Plugins />} />}
        />
        <Route
          path="/webhooks"
          element={
            <ProtectedRoute
              children={
                <ContentTypeObjects
                  allowInternals
                  definiedTypeName="_webhooks"
                  definiedColumns={webhooksDefinedColumns()}
                />
              }
            />
          }
        />
        <Route
          path="/webhooks/add"
          element={<ProtectedRoute children={<AddWebhooks mode={'add'} />} />}
        />
        <Route
          path="/webhooks/edit/:id"
          element={<ProtectedRoute children={<AddWebhooks mode={'edit'} />} />}
        />
        <Route
          path="/webhooks/duplicate/:id"
          element={
            <ProtectedRoute
              children={<AddWebhooks mode={'edit'} duplicate />}
            />
          }
        />
        <Route
          path="/headless-roles"
          element={<ProtectedRoute children={<HeadlessRoles />} />}
        />
        <Route
          path="/headless-roles/add"
          element={<ProtectedRoute children={<AddHeadlessRole />} />}
        />
        <Route
          path="/headless-roles/edit/:id"
          element={<ProtectedRoute children={<AddHeadlessRole />} />}
        />
        <Route
          path="/headless-roles/duplicate/:id"
          element={<ProtectedRoute children={<AddHeadlessRole duplicate />} />}
        />
        <Route
          path="/content"
          element={<ProtectedRoute children={<ContentEmpty />} />}
        />
      </Route>
      <Route
        path="/login"
        element={
          <ProtectedRoute
            children={<Navigate to="/" replace />}
            redirect={<Login />}
          />
        }
      />
      <Route path="/logout" element={<Logout />} />
      <Route path="/register" element={<Register />} />
      <Route path="/register/activate" element={<AccountActivation />} />
      <Route path="/delete-account-confirm" element={<AccountDeletion />} />
      <Route path="/change-password" element={<ChangePasswordPage />} />
      <Route
        path="/change-password-request"
        element={<ChangePasswordRequest />}
      />
      <Route path="/register-appsumo" element={<RegisterAppSumo />} />
      <Route path="*" element={<Page404 />} />
    </>,
  ),
);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
