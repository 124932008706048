import { useRef, useCallback, useState, useEffect } from 'react';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';
import useToken from '../useToken';
import { getContentType } from '../../lib/flotiq-client';
import useDebounceCallback from '../useDebounceCallback';

const DEFAULT_STATE = {
  data: null,
  isLoading: true,
  errors: null,
};

const DEFAULT_PARAMS = {
  defaultParams: {},
  defaultOptions: {},
};

/**
 *
 * @param {string[]} contentTypeNames
 * @param {object | null} params
 * @param {object | null} options
 * @returns {{
 *  data: object|null,
 *  isLoading: bool,
 *  errors: object|null ,
 *  reload: function,
 * }|[
 *  entity: object|null,
 *  isLoading: bool,
 *  errors: object|null ,
 *  reload: function,
 * ]}
 */
export const useContentTypesByName = (
  contentTypeNames,
  params = DEFAULT_PARAMS.defaultParams,
  options = DEFAULT_PARAMS.defaultOptions,
) => {
  const jwt = useToken();
  const abortCtrl = useRef(null);
  const { debounce = 0 } = options;

  const [{ data, isLoading, errors }, setResponseInfo] =
    useState(DEFAULT_STATE);

  const fetchData = useCallback(
    async (jwt, handleErrors, contentTypeNames, params) => {
      if (!contentTypeNames?.length) {
        setResponseInfo({
          ...DEFAULT_STATE,
          isLoading: false,
        });
        return;
      }
      let newDict = {};
      const newErrors = [];
      const abortCtrlInstance = abortCtrl.current;
      const abortSignal = abortCtrlInstance
        ? { signal: abortCtrlInstance.signal }
        : {};

      let apiNames;
      if (typeof contentTypeNames === 'string') {
        apiNames = [contentTypeNames];
      } else {
        apiNames = [...new Set(contentTypeNames)];
      }

      await Promise.all(
        apiNames
          .filter((apiName) => !!apiName)
          .map(async (apiName) => {
            try {
              const ctdResult = await getContentType(
                jwt,
                { contentTypeName: apiName, ...params },
                abortSignal,
              );

              if (handleErrors) {
                checkResponseStatus(ctdResult.body, ctdResult.status);
              }

              if (!ctdResult.body.id) return;

              newDict[ctdResult.body.name] = ctdResult.body;
            } catch (e) {
              newErrors.push(e);
            }
          }),
      );
      if (abortCtrlInstance?.signal.aborted) return;
      abortCtrl.current = null;

      if (newErrors.length > 0) {
        setResponseInfo({
          ...DEFAULT_STATE,
          isLoading: false,
          errors: newErrors,
        });
      } else {
        setResponseInfo({
          data: newDict,
          isLoading: false,
          errors: null,
        });
      }
    },
    [],
  );

  const reload = useCallback(async () => {
    const { handleErrors = true, cancelUnfinishedRequests = true } = options;

    if (!jwt) return;
    if (!contentTypeNames?.length) {
      setResponseInfo({
        ...DEFAULT_STATE,
        isLoading: false,
      });
      return;
    }
    if (abortCtrl.current) {
      abortCtrl.current.abort();
      abortCtrl.current = null;
    }
    if (cancelUnfinishedRequests) {
      abortCtrl.current = new AbortController();
    }

    setResponseInfo({
      isLoading: true,
      data: null,
      errors: null,
    });

    fetchData(jwt, handleErrors, contentTypeNames, params);
  }, [options, jwt, fetchData, contentTypeNames, params]);

  const debouncedReload = useDebounceCallback(reload, debounce);
  useEffect(debouncedReload, [debouncedReload]);

  const result = [data, isLoading, errors, debouncedReload];
  Object.assign(result, {
    data,
    isLoading,
    errors,
    reload: debouncedReload,
  });
  return result;
};
