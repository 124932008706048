import { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RequiredTemplate from '../RequiredTemplate/RequiredTemplate';
import HelpErrorTextsTemplate from '../HelpErrorTextsTemplate/HelpErrorTextsTemplate';
import Button from '../Button/Button';
import { useModals } from '../../contexts/ModalContext';
import LinkObjectContentModal from './LinkObjectContentModal/LinkObjectContentModal';
import { twMerge } from 'tailwind-merge';
import { getTestProps } from '../../lib/helpers';
import { getMediaUrl } from '../../lib/flotiq-client/api-helpers';
import CreateObjectContentModal from './CreateObjectContentModal/CreateObjectContentModal';
import NewMediaContext from '../../contexts/NewMediaContext';
import EditObjectContentModal from './EditObjectContentModal/EditObjectContentModal';
import { getIn } from 'formik';
import { useRelations } from '../../hooks/api/useRelations';
import RelationCard from '../RelationCard/RelationCard';
import FileButton from '../FileButton/FileButton';

const getObjectData = (dataUrl) => {
  const splited = dataUrl.split('/');
  return [splited[splited.length - 2], splited[splited.length - 1]];
};

const RelationField = ({
  arrayHelpers,
  label,
  required,
  validation,
  error,
  helpText,
  disabled,
  onMediaUpload,
  additionalClasses,
  testId,
}) => {
  const { t } = useTranslation();
  const modal = useModals();
  const [isUploading, setIsUploading] = useState(false);
  const { setNewMedia } = useContext(NewMediaContext);

  const value = useMemo(() => {
    return getIn(arrayHelpers.form.values, arrayHelpers.name) || [];
  }, [arrayHelpers.name, arrayHelpers.form]);

  const onBlur = useCallback(
    () =>
      arrayHelpers.form.handleBlur({
        target: { name: arrayHelpers.name },
      }),
    [arrayHelpers.form, arrayHelpers.name],
  );

  const isMultiple = useMemo(() => validation?.relationMultiple, [validation]);
  const hasOnlyMedia = useMemo(
    () => validation?.relationContenttype === '_media',
    [validation],
  );

  const handleUpload = useCallback(
    async (files) => {
      setIsUploading(true);
      const newMedia = await onMediaUpload(files);
      const newRelations = [];
      newMedia.forEach((media) => {
        if (!isMultiple && newRelations.length > 0) return;
        if (!media[1]) {
          newRelations.push({
            type: 'internal',
            dataUrl: `/api/v1/content/_media/${media[0].id}`,
          });
        }
      });
      if (newRelations.length > 0) {
        arrayHelpers.form.setFieldValue(
          arrayHelpers.name,
          Object.values([...value, ...newRelations]),
        );
      }
      setNewMedia([]);
      setIsUploading(false);
    },
    [
      arrayHelpers.form,
      arrayHelpers.name,
      isMultiple,
      onMediaUpload,
      setNewMedia,
      value,
    ],
  );

  const openRelationModal = useCallback(async () => {
    const selected = value.reduce((selected, relation) => {
      const splited = relation.dataUrl.split('/');
      const id = splited[splited.length - 1];
      selected[id] = relation;
      return selected;
    }, {});

    const newRelations = await modal({
      title: (
        <div className="text-xl md:text-3xl">
          {t('ContentForm.Relation.PickObjects', { label })}
        </div>
      ),
      content: (
        <LinkObjectContentModal
          relationType={validation?.relationContenttype}
          selected={selected}
          isMultiple={isMultiple}
          onMediaUpload={onMediaUpload}
          {...getTestProps(testId, 'link-modal', 'testId')}
        />
      ),
      size: '2xl',
      dialogAdditionalClasses: 'max-h-[calc(100vh-32px)]',
    });

    if (newRelations)
      arrayHelpers.form.setFieldValue(
        arrayHelpers.name,
        Object.values(newRelations),
      );
    setTimeout(onBlur);
  }, [
    value,
    modal,
    isMultiple,
    t,
    validation?.relationContenttype,
    onMediaUpload,
    testId,
    arrayHelpers.form,
    arrayHelpers.name,
    onBlur,
    label,
  ]);

  const openCreateObjectModal = useCallback(async () => {
    const newObject = await modal({
      title: (
        <div className="text-xl md:text-3xl">
          {t('ContentForm.Relation.AddObject', { label })}
        </div>
      ),
      content: (
        <CreateObjectContentModal
          relationType={validation?.relationContenttype}
          onMediaUpload={onMediaUpload}
          isMultiple={isMultiple}
          testId={testId}
        />
      ),
      size: '2xl',
      dialogAdditionalClasses: 'max-h-[calc(100vh-32px)]',
    });

    if (newObject) {
      if (Array.isArray(newObject)) {
        arrayHelpers.form.setFieldValue(
          arrayHelpers.name,
          Object.values([...value, ...newObject]),
        );
      } else {
        arrayHelpers.push({
          type: 'internal',
          dataUrl: `/api/v1/content/${newObject.internal.contentType}/${newObject.id}`,
        });
      }
    }
    setTimeout(onBlur);
    setNewMedia([]);
  }, [
    modal,
    t,
    validation?.relationContenttype,
    onMediaUpload,
    isMultiple,
    testId,
    onBlur,
    setNewMedia,
    arrayHelpers,
    value,
    label,
  ]);

  const openEditModal = useCallback(
    async (ctdName, id) => {
      await modal({
        title: (
          <div className="text-xl md:text-3xl">
            {t('ContentForm.Edit', { contentTypeName: ctdName })}
          </div>
        ),
        content: (
          <EditObjectContentModal
            contentTypeName={ctdName}
            id={id}
            {...getTestProps(testId, 'edit', 'testId')}
          />
        ),
        size: '2xl',
        dialogAdditionalClasses: 'max-h-[calc(100vh-32px)]',
      });
    },
    [modal, t, testId],
  );

  const limitReached = useMemo(
    () => !isMultiple && value.length > 0,
    [isMultiple, value],
  );

  const deleteRelation = useCallback(
    async (idx) => {
      const result = await modal.delete(t('ContentForm.RemoveItem'));
      if (!result) return;
      arrayHelpers.remove(idx);
    },
    [arrayHelpers, modal, t],
  );

  const handleOrderUp = useCallback(
    (idx) => {
      arrayHelpers.swap(idx - 1, idx);
    },
    [arrayHelpers],
  );

  const handleOrderDown = useCallback(
    (idx) => {
      arrayHelpers.swap(idx + 1, idx);
    },
    [arrayHelpers],
  );

  const globalError = useMemo(() => {
    if (!error) return;
    const parsedError = Array.isArray(error) ? error[0] : error;
    if (typeof parsedError === 'string') return error;
  }, [error]);

  const defaultParams = useMemo(
    () => ({ page: 1, limit: value.length }),
    [value.length],
  );

  const { data: relationsDict, isLoading: relationsAreLoading } = useRelations(
    value,
    defaultParams,
  );

  const openFullSizeImage = useCallback((media) => {
    window.open(getMediaUrl(media), '_blank');
  }, []);

  return (
    <div className={twMerge('relative', additionalClasses)}>
      <label
        className="text-sm text-slate-400 mb-1"
        {...getTestProps(testId, 'label')}
      >
        {label}
        {required && <RequiredTemplate />}
      </label>
      {!limitReached && (
        <div
          className="flex flex-wrap gap-2 mb-2"
          {...getTestProps(testId, 'buttons-container')}
        >
          {hasOnlyMedia ? (
            <>
              <FileButton
                onUpload={handleUpload}
                multiple={isMultiple}
                disabled={disabled}
                label={t('ContentForm.Relation.AddMedia')}
                onBlur={onBlur}
                additionalClasses="w-fit"
                testId={testId}
              />
              <Button
                onClick={openRelationModal}
                buttonColor="blueBordered"
                buttonSize="sm"
                onBlur={onBlur}
                disabled={isUploading || disabled}
                {...getTestProps(testId, 'link-media', 'testId')}
              >
                {t('ContentForm.Relation.LinkMedia')}
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={openCreateObjectModal}
                buttonSize="xs"
                disabled={disabled}
                {...getTestProps(testId, 'add-object', 'testId')}
              >
                {t('ContentForm.Relation.AddObject', {
                  label,
                })}
              </Button>
              <Button
                onClick={openRelationModal}
                buttonColor="blueBordered"
                buttonSize="xs"
                onBlur={onBlur}
                disabled={disabled}
                {...getTestProps(testId, 'link-object', 'testId')}
              >
                {t('ContentForm.Relation.PickObjects', {
                  label,
                })}
              </Button>
            </>
          )}
        </div>
      )}
      {value.length > 0 && (
        <div
          className="flex flex-col gap-2 mb-2"
          {...getTestProps(testId, 'relations-container')}
        >
          <div className={twMerge('flex flex-col space-y-2')}>
            {relationsDict &&
              value.map((val, idx, currentValue) => {
                const [ctdName, id] = getObjectData(val.dataUrl);
                const relations = relationsAreLoading
                  ? relationsDict[ctdName]
                  : relationsDict[ctdName]?.data;
                const relation = (relations || []).find((element) =>
                  relationsAreLoading ? element === id : element.id === id,
                );
                const titleField = relationsDict[ctdName]?.titleField;
                const itemError = error?.[idx]?.dataUrl;

                const relationToShow = relation ? relation : { id };
                return (
                  <div
                    key={
                      typeof relationToShow === 'string'
                        ? relationToShow
                        : relationToShow.id
                    }
                  >
                    <RelationCard
                      relation={relationToShow}
                      relationType={ctdName}
                      titleField={titleField}
                      relationsAreLoading={relationsAreLoading}
                      testId={testId}
                      relationsLength={currentValue.length}
                      onUp={() => handleOrderUp(idx)}
                      onDown={() => handleOrderDown(idx)}
                      idx={idx}
                      onDelete={() => deleteRelation(idx)}
                      onEdit={() => openEditModal(ctdName, id)}
                      onShow={openFullSizeImage}
                      disabled={disabled}
                      additionalContainerClasses={
                        itemError || !relation ? 'border border-red' : ''
                      }
                    />
                    <HelpErrorTextsTemplate
                      error={itemError}
                      {...getTestProps(testId, `${ctdName}-${id}`, 'testId')}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <HelpErrorTextsTemplate
        helpText={helpText}
        error={globalError}
        testId={testId}
      />
    </div>
  );
};

export default RelationField;

RelationField.propTypes = {
  /**
   * Array helpers from formik array field
   */
  arrayHelpers: PropTypes.object.isRequired,
  /**
   * Relation field label above relations and buttons
   */
  label: PropTypes.node,
  /**
   * If relation field is required
   */
  required: PropTypes.bool,
  /**
   * Validation relations object
   */
  validation: PropTypes.object,
  /**
   * Error text under relations and buttons
   */
  error: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ),
    PropTypes.string,
  ]),
  /**
   * Help text under relations and buttons
   */
  helpText: PropTypes.string,
  /**
   * If field is disabled
   */
  disabled: PropTypes.bool,
  /**
   * On media upload callback
   */
  onMediaUpload: PropTypes.func,
  /**
   * Additional container classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Test id for field
   */
  testId: PropTypes.string,
};

RelationField.defaultProps = {
  label: '',
  required: false,
  validation: {},
  error: null,
  helpText: '',
  disabled: false,
  onMediaUpload: /* istanbul ignore next */ () => null,
  additionalClasses: '',
  testId: '',
};
