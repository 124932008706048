import { useRef, useCallback } from 'react';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';

/**
 * Create authenticated callbackwhich will handle basic errors or cancel previous instance of a request
 *
 * @param {funciton} getData
 * @param {object} params
 * @param {object} options
 * @returns
 */
const useBaseDataLoad = (jwt, getData, params, options) => {
  const abortCtrl = useRef(null);

  /**
   * Reload function;
   * @throws {DOMException|ResponseError}
   */
  const reload = useCallback(async () => {
    const { handleErrors = true, cancelUnfinishedRequests = true } = options;
    if (abortCtrl.current) {
      abortCtrl.current.abort();
      abortCtrl.current = null;
    }
    if (cancelUnfinishedRequests) {
      abortCtrl.current = new AbortController();
    }

    const result = await getData(
      jwt,
      params,
      abortCtrl.current ? { signal: abortCtrl.current.signal } : {},
    );
    abortCtrl.current = null;
    if (handleErrors) {
      checkResponseStatus(result.body, result.status);
    }
    return result;
  }, [options, jwt, getData, params]);

  return reload;
};

export default useBaseDataLoad;
