import { Field, FieldArray, getIn, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { getTestProps } from '../../lib/helpers';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import ListField from '../ListField/ListField';
import Input from '../Input/Input';
import GeoInput from '../GeoInput/GeoInput';
import BlockInput from '../BlockInput/BlockInput';
import Dropdown from '../Dropdown/Dropdown';
import Switch from '../Switch/Switch';
import RelationField from '../RelationField/RelationField';
import RadioGroup from '../RadioGroup/RadioGroup';
import Textarea from '../Textarea/Textarea';
import RichText from '../RichText/RichText';
import Markdown from '../Markdown/Markdown';
import ContentObjectFormContext from '../../contexts/ContentObjectFormContext';
import FlotiqPlugins from '../../lib/flotiq-plugins/flotiqPluginsRegistry';
import NewMediaContext from '../../contexts/NewMediaContext';
import ElementFromPlugin from '../ElementFromPlugin/ElementFromPlugin';
import { FormRenderFieldEvent } from '../../lib/flotiq-plugins/plugin-events/FormRenderFieldEvent';

const findInputType = (props) => {
  switch (props.inputType) {
    case 'dateTime':
      return props.showTime ? 'datetime-local' : 'date';
    case 'number':
      return 'number';
    case 'email':
      return 'email';
    default:
      return 'text';
  }
};

/**
 * @emits FlotiqPlugins."flotiq.form.field::render"
 * @emits FlotiqPlugins."flotiq.form.field::config"
 */
const CtoCustomField = ({
  name,
  properties,
  schema,
  isRequired,
  disabled,
  label,
  ignoreHidden,
  additionalClasses,
  isFullSize,
  testId,
  ...props
}) => {
  const { contentType, initialData, isEditing } = useContext(
    ContentObjectFormContext,
  );
  const { onUpload: onMediaUpload } = useContext(NewMediaContext);

  const formik = useFormikContext();
  const fieldLabel = label || properties.label || name;
  const isDisabled = (isEditing && properties.readonly) || disabled;

  const error = useMemo(() => {
    if (!getIn(formik.touched, name)) return null;
    const fieldError =
      getIn(formik.errors, name) || formik.status?.errors?.[name];
    if (properties.inputType === 'geo') {
      return !fieldError ||
        Array.isArray(fieldError) ||
        typeof fieldError === 'string'
        ? {
            global: fieldError,
            lat: formik.status?.errors?.[`${name}.lat`],
            lon: formik.status?.errors?.[`${name}.lon`],
          }
        : fieldError;
    }
    return getIn(formik.errors, name) || getIn(formik.status?.errors, name);
  }, [
    formik.errors,
    formik.status?.errors,
    formik.touched,
    name,
    properties?.inputType,
  ]);

  useEffect(() => {
    if (
      ['geo', 'block'].indexOf(properties.inputType) > -1 &&
      Array.isArray(getIn(formik.values, name))
    ) {
      setTimeout(() => formik.setFieldValue(name, {}));
    }
  }, [formik, name, properties.inputType]);

  const FieldComponent = useMemo(() => {
    switch (properties.inputType) {
      case 'object':
        return ListField;
      case 'textarea':
        return Textarea;
      case 'geo':
        return GeoInput;
      case 'block':
        return BlockInput;
      case 'select':
        return Dropdown;
      case 'radio':
        return RadioGroup;
      case 'checkbox':
        return Switch;
      case 'datasource':
        return RelationField;
      case 'richtext':
        return RichText;
      case 'textMarkdown':
        return Markdown;
      default:
        return Input;
    }
  }, [properties.inputType]);

  const getFieldProps = useCallback(() => {
    switch (properties.inputType) {
      case 'email':
      case 'text':
      case 'dateTime': {
        return { type: findInputType(properties) };
      }
      case 'number': {
        return {
          type: findInputType(properties),
          onChange: (data) => {
            const value = data.target.value;
            const number = isNaN(Number(value)) ? value : Number(value);
            formik.setFieldValue(name, number);
          },
        };
      }
      case 'geo': {
        return {
          onChange: (value, coordinate) => {
            if (value == null) {
              const newGeo = getIn(formik.values, name);
              delete newGeo[coordinate];
              formik.setFieldValue(name, newGeo);
            } else {
              formik.setFieldValue(coordinate, value);
            }
          },
        };
      }
      case 'block': {
        return {
          toolsList: properties.blockEditorTypes,
          onChange: (data) => formik.setFieldValue(name, data),
          onBlur: () =>
            formik.handleBlur({
              target: { name },
            }),
          onMediaUpload,
          additionalClasses: 'max-w-full ' + (isFullSize ? 'w-full' : ''),
        };
      }
      case 'textMarkdown':
      case 'richtext': {
        return {
          onMediaUpload,
          onChange: (data) => formik.setFieldValue(name, data),
          onBlur: () =>
            formik.handleBlur({
              target: { name },
            }),
          additionalClasses: 'max-w-full ' + (isFullSize ? 'w-full' : ''),
        };
      }
      case 'select': {
        return {
          options: (properties.options || []).map((option) => ({
            value: option,
            label: option,
          })),
          onChange: (_, value) => {
            if (value == null) formik.setFieldValue(name, '');
            else formik.setFieldValue(name, value);
          },
          nullable: !isRequired,
        };
      }
      case 'radio': {
        return {
          options: (properties.options || []).map((option) => ({
            value: option,
            label: option,
          })),
          horizontal: true,
        };
      }
      case 'checkbox': {
        return {
          checked: getIn(formik.values, name),
          theme: 'dark',
          size: 'base',
          side: 'right',
          labelUp: true,
          additionalClasses: 'max-w-2xl !justify-items-start',
        };
      }
      case 'object': {
        return {
          itemsProps: properties.items,
          itemsSchema: schema.items,
          isEditing: isEditing,
          isFormDisabled: isDisabled,
          additionalClasses: 'max-w-full ' + (isFullSize ? 'w-full' : ''),
        };
      }
      case 'datasource': {
        return {
          validation: properties.validation,
          onMediaUpload,
        };
      }
      case 'textarea': {
        return {
          additionalClasses: 'max-w-full' + (isFullSize ? 'w-full' : ''),
        };
      }
      default:
        return null;
    }
  }, [
    formik,
    isDisabled,
    isEditing,
    isFullSize,
    isRequired,
    name,
    onMediaUpload,
    properties,
    schema.items,
  ]);

  const { fieldProps, pluginRenders } = useMemo(() => {
    const params = {
      name,
      value: getIn(formik.values, name),
      formik,
      required: isRequired,
      disabled: isDisabled,
      properties,
      schema,
      error,
      onMediaUpload,
      contentType,
      initialData,
    };

    const fieldProps = getFieldProps();
    FlotiqPlugins.run(
      'flotiq.form.field::config',
      new FormRenderFieldEvent({ ...params, config: fieldProps }),
    );
    const renders = (
      FlotiqPlugins.run(
        'flotiq.form.field::render',
        new FormRenderFieldEvent(params),
      ) || []
    ).filter((r) => !!r);

    return {
      fieldProps,
      pluginRenders: renders,
    };
  }, [
    name,
    formik,
    isRequired,
    isDisabled,
    properties,
    schema,
    error,
    onMediaUpload,
    contentType,
    initialData,
    getFieldProps,
  ]);

  if (pluginRenders?.length > 0) {
    return <ElementFromPlugin results={pluginRenders} />;
  }

  if (!ignoreHidden && properties.hidden) return null;
  if (['object', 'datasource'].indexOf(properties.inputType) > -1)
    return (
      <FieldArray name={name}>
        {(arrayHelpers) => (
          <FieldComponent
            arrayHelpers={arrayHelpers}
            label={fieldLabel}
            required={isRequired}
            error={error}
            helpText={properties.helpText}
            disabled={isDisabled}
            additionalClasses={additionalClasses}
            {...fieldProps}
            {...getTestProps(testId, name, 'testId')}
          />
        )}
      </FieldArray>
    );

  return (
    <Field name={name}>
      {({ field }) => {
        return (
          <FieldComponent
            name={name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={error}
            label={fieldLabel}
            required={isRequired}
            disabled={isDisabled}
            helpText={properties.helpText}
            additionalClasses={additionalClasses}
            {...props}
            {...fieldProps}
            {...getTestProps(testId, name, 'testId')}
          />
        );
      }}
    </Field>
  );
};

export default CtoCustomField;

CtoCustomField.propTypes = {
  /**
   * Field name
   */
  name: PropTypes.string.isRequired,
  /**
   * Cto field properties
   */
  properties: PropTypes.object,
  /**
   * Cto field schema
   */
  schema: PropTypes.object,
  /**
   * If field is required
   */
  isRequired: PropTypes.bool,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Label to override from props
   */
  label: PropTypes.node,
  /**
   * If hidden property should be ignore
   */
  ignoreHidden: PropTypes.bool,
  /**
   * Form field addistional CSS classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Field test id
   */
  testId: PropTypes.string,
};

CtoCustomField.defaultProps = {
  properties: {},
  schema: {},
  isRequired: false,
  disabled: false,
  additionalClasses: '',
  label: '',
  ignoreHidden: false,
  testId: '',
};
