import { useRef, useCallback, useState, useEffect, useMemo } from 'react';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';
import useToken from '../useToken';
import { getContentObjects, getContentType } from '../../lib/flotiq-client';
import useDebounceCallback from '../useDebounceCallback';

const DEFAULT_STATE = {
  data: null,
  pagination: {},
  isLoading: true,
  errors: null,
};

const DEFAULT_PARAMS = {
  defaultParams: { page: 1, limit: 10 },
  defaultOptions: {},
};

export const useRelations = (
  relationsRawData,
  defaultParams = DEFAULT_PARAMS.defaultParams,
  defaultOptions = DEFAULT_PARAMS.defaultOptions,
) => {
  const jwt = useToken();
  const abortCtrl = useRef(null);
  const { debounce = 0 } = defaultOptions;

  const [{ data, pagination, isLoading, errors }, setResponseInfo] =
    useState(DEFAULT_STATE);

  const relationsRawDataString = useMemo(() => {
    if (!relationsRawData || !relationsRawData.length) return '[]';
    const minIdx = (defaultParams.page - 1) * defaultParams.limit;
    const limitedData = relationsRawData.filter(
      (_, idx) => idx >= minIdx && idx < minIdx + defaultParams.limit,
    );

    return JSON.stringify(
      Array.from(
        new Set(limitedData.map((relation) => relation.dataUrl).sort()),
      ),
    );
  }, [defaultParams.limit, defaultParams.page, relationsRawData]);

  const relationDataUris = useMemo(
    () => JSON.parse(relationsRawDataString),
    [relationsRawDataString],
  );

  const fetchData = useCallback(
    async (
      jwt,
      idsDict,
      handleErrors,
      maxPages,
      totalCount,
      relationDataUris,
      defaultParams,
    ) => {
      let newDict = {};
      const newErrors = [];
      const abortCtrlInstance = abortCtrl.current;
      const abortSignal = abortCtrlInstance
        ? { signal: abortCtrlInstance.signal }
        : {};
      await Promise.all(
        Object.keys(idsDict).map(async (key) => {
          try {
            const ctoPromise = getContentObjects(
              jwt,
              {
                contentTypeName: key,
                ids: idsDict[key],
              },
              abortSignal,
            );
            const ctdPromise = getContentType(
              jwt,
              { contentTypeName: key },
              abortSignal,
            );
            const [ctoResult, ctdResult] = await Promise.all([
              ctoPromise,
              ctdPromise,
            ]);

            if (handleErrors) {
              checkResponseStatus(ctdResult.body, ctdResult.status);
              checkResponseStatus(ctoResult.body, ctoResult.status);
            }
            const def = ctdResult.body.metaDefinition;
            const firstTextField = def.order.find(
              (field) => def.propertiesConfig[field].inputType === 'text',
            );
            newDict[key] = {
              data: ctoResult.body.data,
              titleField: firstTextField || '',
            };
          } catch (e) {
            if (e instanceof DOMException && e.name === 'AbortError') return;
            newErrors.push(e);
          }
        }),
      );
      if (abortCtrlInstance?.signal.aborted) return;
      abortCtrl.current = null;

      if (newErrors.length > 0) {
        setResponseInfo({
          ...DEFAULT_STATE,
          data: {},
          isLoading: false,
          errors: newErrors,
        });
      } else {
        setResponseInfo({
          data: newDict,
          pagination: {
            total_pages: maxPages,
            total_results: totalCount,
            count: relationDataUris.length,
            current_page: defaultParams.page,
          },
          isLoading: false,
          errors: null,
        });
      }
    },
    [],
  );

  const reload = useCallback(async () => {
    const { handleErrors = true, cancelUnfinishedRequests = true } =
      defaultOptions;

    if (!jwt) return;
    if (!relationDataUris || !relationDataUris.length) {
      setResponseInfo({
        ...DEFAULT_STATE,
        isLoading: false,
      });
      return;
    }
    if (abortCtrl.current) {
      abortCtrl.current.abort();
      abortCtrl.current = null;
    }
    if (cancelUnfinishedRequests) {
      abortCtrl.current = new AbortController();
    }

    let maxPages = Math.floor(relationsRawData.length / defaultParams.limit);
    if (relationsRawData.length % defaultParams.limit !== 0) maxPages++;

    const idsDict = relationDataUris.reduce((acc, element) => {
      const { contentTypeName, id } = element.match(
        /(?<contentTypeName>[^/]+)\/(?<id>[^/]+)$/,
      ).groups;
      if (!acc[contentTypeName]) acc[contentTypeName] = [];
      acc[contentTypeName].push(id);
      return acc;
    }, {});

    setResponseInfo({
      isLoading: true,
      data: idsDict,
      pagination: {
        total_pages: maxPages,
        total_results: relationsRawData.length,
        count: relationDataUris.length,
        current_page: defaultParams.page,
      },
      errors: null,
    });

    fetchData(
      jwt,
      idsDict,
      handleErrors,
      maxPages,
      relationsRawData.length,
      relationDataUris,
      defaultParams,
    );
  }, [
    defaultOptions,
    jwt,
    fetchData,
    relationDataUris,
    defaultParams,
    relationsRawData?.length,
  ]);

  const debouncedReload = useDebounceCallback(reload, debounce);
  useEffect(debouncedReload, [debouncedReload]);

  const result = [data, pagination, isLoading, errors, debouncedReload];
  Object.assign(result, {
    data,
    pagination,
    isLoading,
    errors,
    reload: debouncedReload,
  });
  return result;
};
