import { useCallback, useState, useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-hot-toast';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';

// :: React dnd
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';

// :: Components
import Sidebar from '../components/Sidebar/Sidebar';
import Topbar from '../components/Topbar/Topbar';
import FloatingHelp from '../components/FloatingHelp/FloatingHelp';
import AnnouncementComponent from '../components/Announcement/AnnouncementComponent';
import GlobalSearch from '../components/GlobalSearch/GlobalSearch';
import ErrorFallback from '../components/ErrorFallback/ErrorFallback';

// :: Context
import AppContext from '../contexts/AppContext';
import NewMediaContext from '../contexts/NewMediaContext';
import SidebarContext from '../contexts/SidebarContext';
import UserContext from '../contexts/UserContext';

// :: Hooks
import {
  useContentTypes,
  usePlugins,
  useUser,
  useConstraints,
} from '../hooks/api';
import useOnce from '../hooks/useOnce';
import useApiErrorsToast from '../hooks/api/useApiErrorsToast';
import useDarkMode from '../hooks/useDarkMode';

// :: Utils
import { getLocalStorage, setLocalStorage } from '../utils/localStorage';

// :: Lib
import {
  generateApiDocs,
  getHash,
  getMaskedOrganizationName,
  getTestProps,
} from '../lib/helpers';
import {
  checkResponseStatus,
  ResponseError,
} from '../lib/flotiq-client/response-errors';
import { RolePermissions } from '../lib/rolePermissions';
import FlotiqPlugins from '../lib/flotiq-plugins/flotiqPluginsRegistry';

// :: Images
import {
  CopyColorIcon,
  KeyColorIcon,
  ClipboardColorIcon,
  FourSquaresColor,
  FlotiqLogo,
  FlotiqBadgeLogo,
  GearIcon,
  LogOutIcon,
  MediaColorIcon,
  PuzzleColorIcon,
  PolandFlagIcon,
  LightningColorIcon,
  UnitedKingdomFlagIcon,
  UsersIcon,
  ConnectedDotsColorIcon,
  ConnectedDotsColorIconWhite,
  PlayCircleIcon,
  DocumentTextIcon,
  InformationSignIcon,
  ComputerDesktopIcon,
  GearColorIcon,
  UnionIcon,
  ReturnOldFront,
  FlotiqLogoWhite,
  SunIcon,
  MoonIcon,
  FourSquaresColorWhite,
  MediaColorIconWhite,
  StarFavIcon,
  UsersKeyIcon,
  UsersKeyIconWhite,
  ClipboardColorIconWhite,
  GearColorIconWhite,
  PuzzleColorIconWhite,
  LightningColorIconWhite,
  KeyColorIconWhite,
  CopyColorIconWhite,
  UsersIconWhite,
  ReturnOldFrontWhite,
} from '../images/shapes';

const ADMIN_ROLES = ['ROLE_ADMIN', 'ROLE_HEADLESS_ADMIN'];
const ADMIN_ORGANIZATIONS =
  process.env.REACT_APP_ADMIN_ORGANIZATIONS.split(',');

const CTD_PARAMS = {
  internal: 0,
  limit: 10000,
  order_by: 'label',
  order_direction: 'asc',
  page: 1,
};

const PLUGINS_PARAMS = {
  limit: 10000,
  page: 1,
  filters: JSON.stringify({
    type: {
      type: 'equals',
      filter: 'ui-extension',
    },
  }),
};

const Layout = ({ testId, additionalClasses, children }) => {
  const { t, i18n } = useTranslation();
  const { appContext, updateAppContext } = useContext(AppContext);
  const userRaw = getLocalStorage('cms.user');
  const user = useMemo(() => JSON.parse(userRaw), [userRaw]);
  const [layoutLoading, setLayoutLoading] = useState(FlotiqPlugins.enabled());
  const [darkMode, setDarkMode] = useDarkMode();

  const { entity: mimeTypes } = useConstraints('mimetypes');

  const updateUserMimeTypes = useCallback(async () => {
    if (mimeTypes?.data && user) {
      const newUserData = { ...user };
      newUserData.data.allowedMimetypes = mimeTypes.data;
      setLocalStorage('cms.user', newUserData);
    }
  }, [mimeTypes, user]);

  useOnce(updateUserMimeTypes);

  const { data: plugins, isLoading: pluginsAreLoading } =
    usePlugins(PLUGINS_PARAMS);

  useEffect(() => {
    if (!user || (!pluginsAreLoading && !plugins.length))
      setLayoutLoading(false);
  }, [plugins.length, pluginsAreLoading, user]);

  useEffect(() => {
    if (plugins.length > 0) {
      Promise.all(
        plugins.map((plugin) => {
          const url = plugin.settings?.find(
            (setting) => setting.key === 'source-url',
          )?.value;
          if (!plugin.id || !url) return null;
          return FlotiqPlugins.loadPlugin(plugin.id, url);
        }),
      ).then(() => {
        setLayoutLoading(false);
      });
    }
  }, [plugins]);

  useEffect(() => {
    moment.locale(i18n.language === 'en' ? 'en-gb' : 'pl');
  }, [i18n.language]);

  const { onUpload } = useContext(NewMediaContext);

  const oldDashboardUrl = useMemo(
    () => process.env.REACT_APP_OLD_FRONTEND_URL,
    [],
  );

  const announcement = process.env.REACT_APP_ANNOUNCEMENT.split(',').join(',');

  const announcementHash = useMemo(() => {
    return getHash(announcement);
  }, [announcement]);

  const announcementHide = useMemo(() => {
    if (!announcement) return true;
    const announcementData =
      getLocalStorage('cms.user_hideAnnouncement', true) || {};
    if (announcementData.id === announcementHash) return announcementData.hide;
    return false;
  }, [announcement, announcementHash]);

  const [hideAnnouncement, setHideAnnouncement] = useState(announcementHide);

  const { entity: userData, updateEntity: updateUser } = useUser(
    user?.data?.id,
  );

  const isAdmin = useMemo(
    () =>
      (user?.data?.roles || []).findIndex(
        (role) => ADMIN_ROLES.indexOf(role) > -1,
      ) > -1,
    [user?.data?.roles],
  );

  const isRoleAdmin = useMemo(
    () =>
      (user?.data?.roles || []).findIndex(
        (role) => 'ROLE_ADMIN'.indexOf(role) > -1,
      ) > -1 && ADMIN_ORGANIZATIONS.includes(user?.data?.organization),
    [user?.data?.organization, user?.data?.roles],
  );

  const permissions = useMemo(
    () => new RolePermissions(user?.data?.headlessRoles, isAdmin),
    [isAdmin, user?.data?.headlessRoles],
  );

  // Sidebar
  const localstoreSideBarOpenStatus = getLocalStorage(
    'cms.user_sideBarOpen',
    true,
  );

  const isSizeSM = useMediaQuery({
    query: '(max-width: 1024px)',
  });

  const [sideBarOpen, setSidebarOpen] = useState(
    localstoreSideBarOpenStatus !== null
      ? localstoreSideBarOpenStatus
      : !isSizeSM,
  );

  const {
    data: contentTypes,
    errors: ctdErrors,
    reload: reloadCtd,
  } = useContentTypes(CTD_PARAMS);

  const [pinedContent, setPinedContent] = useState([]);

  useEffect(() => {
    setPinedContent((prevState) => {
      return userData?.config?.pinedDefinitions || prevState;
    });
  }, [userData?.config]);

  const pinContentCallback = useCallback(
    async (label, pined) => {
      const removeElement = (arr, label) => {
        const index = arr.indexOf(label);
        if (index > -1) {
          arr.splice(index, 1);
        }
        return arr;
      };

      let newPinnedContent = [...pinedContent];

      if (!pined) {
        newPinnedContent.push(label);
      } else {
        newPinnedContent = removeElement(newPinnedContent, label);
      }

      try {
        const { body, status } = await updateUser({
          config: { ...userData?.config, pinedDefinitions: newPinnedContent },
        });

        checkResponseStatus(body, status);
        setPinedContent(newPinnedContent);
      } catch (e) {
        toast.error(t('Form.CommunicationErrorMessage'));
      }
    },
    [pinedContent, t, updateUser, userData?.config],
  );

  const menuContentItems = useMemo(() => {
    if (!contentTypes) return [[], []];

    const pinedElements = [];

    const dict = contentTypes.reduce((folders, el) => {
      if (!permissions.canCo(el.name)) return folders;
      const hasFolder = el.label.includes('/');
      const [splittedFolderName, ...subfolderNameParts] =
        el.label?.split('/') || [];
      const folderName = hasFolder ? splittedFolderName : '';
      const splitedLabel = hasFolder ? subfolderNameParts.join('/') : el.label;

      const isPinned = pinedContent.includes(el.label);

      const menuElement = {
        key: splitedLabel,
        name: el.name,
        title: splitedLabel || el.label,
        link: `/content-type-objects/${el.name}`,
        pined: isPinned,
      };

      if (isPinned) {
        pinedElements.push({
          ...menuElement,
          title: el.label,
          link: `/content-type-objects/${el.name}?favorites=1`,
          key: `${menuElement.key}-pinned`,
        });
      }

      if (folders[folderName]) folders[folderName].push(menuElement);
      else folders[folderName] = [menuElement];
      return folders;
    }, {});
    const items = [];

    Object.keys(dict)
      .sort((a, b) => {
        if (!a) return 1;
        if (!b) return -1;

        return a > b ? 1 : -1;
      })
      .forEach((key) => {
        if (key === '') items.push(...dict[key]);
        else {
          items.push({
            key: key,
            name: key,
            title: key,
            children: dict[key],
          });
        }
      });

    return [items || [], pinedElements || []];
  }, [contentTypes, permissions, pinedContent]);

  useApiErrorsToast(ctdErrors);

  const handleSidebar = useCallback((value) => {
    setSidebarOpen(value);

    // Case: Save in localstorage user preference of open/close sidebar
    setLocalStorage('cms.user_sideBarOpen', value);
  }, []);

  const handleGetUserDetails = useCallback(() => {
    const userDetails = user?.data;
    const token = user?.token;
    if (
      !appContext?.user?.username ||
      appContext?.user?.username !== user?.data?.username
    ) {
      updateAppContext?.((prevState) => ({
        ...prevState,
        ...(token && { token }),
        ...(userDetails && { user: userDetails }),
      }));
    }
  }, [user?.data, user?.token, appContext?.user?.username, updateAppContext]);

  const updateUserProfile = useCallback(
    async (selectedLang) => {
      const newUserData = { ...user };
      newUserData.data.language = selectedLang;
      setLocalStorage('cms.user', newUserData);

      try {
        const { body, status } = await updateUser({
          language: selectedLang,
        });
        checkResponseStatus(body, status);
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(t('Global.LangChangeError'));
        }
      }
    },
    [t, updateUser, user],
  );

  useOnce(handleGetUserDetails);

  const SIDEBAR_MENU_ITEMS = useMemo(() => {
    const [menuItems, pinedItems] = menuContentItems;

    const items = [
      {
        key: 'dashboard',
        icon: appContext?.darkMode ? (
          <FourSquaresColorWhite className="w-6 min-w-6" />
        ) : (
          <FourSquaresColor className="w-6 min-w-6" />
        ),
        title: t('Global.Dashboard'),
        link: '/',
      },
      {
        key: 'favorites',
        icon: <StarFavIcon className="w-6 min-w-6" />,
        title: t('Global.Favorite'),
        children: pinedItems,
      },
      {
        key: 'media',
        icon: appContext?.darkMode ? (
          <MediaColorIconWhite className="w-6 min-w-6" />
        ) : (
          <MediaColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.MediaLibrary'),
        link: '/media',
        className: !(
          permissions.canCo('_media') && permissions.canCtd('_media')
        )
          ? 'hidden'
          : '',
      },
      {
        key: 'content',
        icon: appContext?.darkMode ? (
          <ClipboardColorIconWhite className="w-6 min-w-6" />
        ) : (
          <ClipboardColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.Content'),
        ...(menuItems.length ? { children: menuItems } : { link: '/content' }),
      },
      {
        key: 'definitionBuilder',
        icon: appContext?.darkMode ? (
          <GearColorIconWhite className="w-6 min-w-6" />
        ) : (
          <GearColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.DefinitionBuilder'),
        link: '/content-type-definitions',
      },
      {
        key: 'webhooks',
        icon: appContext?.darkMode ? (
          <ConnectedDotsColorIconWhite className="w-6 min-w-6" />
        ) : (
          <ConnectedDotsColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.Webhooks'),
        link: '/webhooks',
        className: !(
          permissions.canCo('_webhooks') && permissions.canCtd('_webhooks')
        )
          ? 'hidden'
          : '',
      },
      {
        key: 'plugins',
        icon: appContext?.darkMode ? (
          <PuzzleColorIconWhite className="w-6 min-w-6" />
        ) : (
          <PuzzleColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.Plugins'),
        link: '/plugins',
      },
      {
        key: 'starters',
        icon: appContext?.darkMode ? (
          <LightningColorIconWhite className="w-6 min-w-6" />
        ) : (
          <LightningColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.Starters'),
        link: '/starters',
      },
      {
        key: 'apiKeys',
        icon: appContext?.darkMode ? (
          <KeyColorIconWhite className="w-6 min-w-6" />
        ) : (
          <KeyColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.APIKeys'),
        link: '/api-keys',
      },
      {
        key: 'apiDocs',
        icon: appContext?.darkMode ? (
          <CopyColorIconWhite className="w-6 min-w-6" />
        ) : (
          <CopyColorIcon className="w-6 min-w-6" />
        ),
        title: t('Global.APIDocs'),
        link: generateApiDocs(user?.data),
        target: '_blank',
        rel: 'noreferrer',
        onClick: () => {
          TagManager.dataLayer({
            dataLayer: {
              event: 'apidocs_visit',
            },
          });
        },
      },
    ];

    if (isAdmin) {
      items.splice(4, 0, {
        key: 'users',
        icon: appContext?.darkMode ? (
          <UsersIconWhite className="w-6 min-w-6" />
        ) : (
          <UsersIcon className="w-6 min-w-6" />
        ),
        title: t('Global.Users'),
        link: '/users',
      });
      if (
        process.env.REACT_APP_ENABLE_HEADLESS_ROLES.split(',').join(',') ===
        'true'
      ) {
        items.splice(5, 0, {
          key: 'headless-roles',
          icon: appContext?.darkMode ? (
            <UsersKeyIconWhite className="w-6 min-w-6" />
          ) : (
            <UsersKeyIcon className="w-6 min-w-6" />
          ),
          title: t('Global.HeadlessRoles'),
          link: '/headless-roles',
        });
      }
    }

    if (isRoleAdmin) {
      items.push({
        key: 'admin',
        className: 'border-t border-gray dark:border-slate-800 mt-1.5',
        icon: <StarFavIcon className="w-6 min-w-6" />,
        title: 'Admin',
        hidePin: true,
        children: [
          {
            key: 'all-users',
            icon: <UsersIcon className="w-6 min-w-6" />,
            title: t('Global.AllUsers'),
            link: '/users-data-preview',
          },
          {
            key: 'plans',
            icon: <UsersIcon className="w-6 min-w-6" />,
            title: t('Global.Plans'),
            link: '/plans',
          },
        ],
      });
    }

    if (oldDashboardUrl) {
      items.push({
        key: 'oldFront',
        className:
          'border-t border-gray dark:border-slate-800 text-[15px] min-h-[50px]',
        titleClassName: 'leading-4 line-clamp-2',
        icon: appContext?.darkMode ? (
          <ReturnOldFrontWhite className="w-6 min-w-6" />
        ) : (
          <ReturnOldFront className="w-6 min-w-6" />
        ),
        title: t('Global.ReturnToOldFrontMenuLink'),
        link: oldDashboardUrl,
      });
    }

    return items;
  }, [
    appContext?.darkMode,
    t,
    menuContentItems,
    user?.data,
    oldDashboardUrl,
    permissions,
    isRoleAdmin,
    isAdmin,
  ]);

  const MENU_USER_ITEMS = useMemo(
    () => [
      {
        icon: <UnitedKingdomFlagIcon className="w-5 h-5" />,
        text: 'EN',
        onClick: () => {
          updateUserProfile('en');
          i18n.changeLanguage('en');
        },
        selected: i18n.language === 'en',
      },
      {
        icon: <PolandFlagIcon className="w-5 h-5" />,
        text: 'PL',
        onClick: () => {
          updateUserProfile('pl');
          i18n.changeLanguage('pl');
        },
        selected: i18n.language === 'pl',
      },
      {
        icon: appContext?.darkMode ? (
          <SunIcon className="w-4 text-white" />
        ) : (
          <MoonIcon className="w-4 text-indigo-950" />
        ),
        text: appContext?.darkMode ? 'Choose light theme' : 'Choose dark theme',
        onClick: () => setDarkMode(!darkMode),
      },
      {
        icon: <GearIcon className="w-4 h-4 text-indigo-950 dark:text-white" />,
        text: t('Global.AccountSettings'),
        link: '/profile',
      },
      {
        icon: (
          <LogOutIcon className="w-4 h-4 text-indigo-950 dark:text-white" />
        ),
        text: t('Global.LogOut'),
        link: '/logout',
      },
    ],
    [darkMode, appContext?.darkMode, setDarkMode, i18n, t, updateUserProfile],
  );

  const FLOATING_HELP_CONTENT = useMemo(
    () => [
      {
        label: t('FloatingHelp.Tutorials'),
        icon: <PlayCircleIcon className="w-6 text-blue pr-2" />,
        link: process.env.REACT_APP_TUTORIALS,
        key: 'Tutorials',
      },
      {
        label: t('FloatingHelp.Documentation'),
        icon: <DocumentTextIcon className="w-6 text-blue pr-2" />,
        link: process.env.REACT_APP_DOCUMENTATION,
        key: 'Documentation',
      },
      {
        label: t('FloatingHelp.Support'),
        icon: <InformationSignIcon className="w-6 text-blue pr-2" />,
        link: process.env.REACT_APP_DISCORD,
        key: 'Support',
      },
      {
        label: t('FloatingHelp.Demo'),
        icon: <ComputerDesktopIcon className="w-6 text-blue pr-2" />,
        link: process.env.REACT_APP_DEMO,
        key: 'Demo',
      },
    ],
    [t],
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        onUpload(item.files);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onUpload],
  );

  const sidebarContextValue = useMemo(() => ({ reloadCtd }), [reloadCtd]);

  const handleCloseAnnouncement = useCallback(() => {
    setHideAnnouncement(true);
    setLocalStorage(`cms.user_hideAnnouncement`, {
      id: announcementHash,
      hide: true,
    });
  }, [announcementHash]);

  const userContextValue = useMemo(
    () => ({
      permissions,
      isAdmin,
      isRoleAdmin,
      userStorage: user,
      baseUserEventData: {
        user_id: user?.data?.id,
        organization_id: user?.data?.organization,
        organization_name: getMaskedOrganizationName(
          user?.data?.organization_name,
        ),
        plan_id: user?.data?.limits_plan?.id,
        plan_name: user?.data?.limits_plan?.name,
      },
    }),
    [isAdmin, isRoleAdmin, permissions, user],
  );

  const getErrorFallbackPage = (event) => <ErrorFallback {...event} />;

  return layoutLoading ? null : (
    <Sentry.ErrorBoundary fallback={getErrorFallbackPage}>
      <GlobalSearch />
      <UserContext.Provider value={userContextValue}>
        <SidebarContext.Provider value={sidebarContextValue}>
          <AnnouncementComponent
            additionalClasses="h-16"
            announcement={announcement}
            handleClose={handleCloseAnnouncement}
            hasCloseButton={true}
            hidden={hideAnnouncement}
            {...getTestProps(testId, 'announcement', 'testId')}
          />
          <main
            ref={() => drop(document.body)}
            className={twMerge(
              'flex items-start justify-between brand-bg-gradient w-full',
              'dark:bg-gray-900 from-blue-700 to-blue-650',
              'min-h-screen py-24 md:pb-0 font-inter',
              'transition-all',
              'ease-in-out',
              'duration-normal relative ',
              sideBarOpen
                ? 'pl-0 sm:pl-[68px] lg:pl-[240px]'
                : 'pl-0 sm:pl-[68px]',
              hideAnnouncement ? 'py-14' : 'py-32',
              additionalClasses,
            )}
            data-testid={testId}
          >
            <Transition
              className="z-50"
              show={canDrop && isOver}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              enter="transition ease-in duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
            >
              <div className="fixed top-0 right-0 h-screen w-full bg-white opacity-75" />
              <div
                className={twMerge(
                  'fixed top-1/2 left-1/2 bg-blue aspect-square rounded-full',
                  'h-60 md:h-80 lg:h-96 2xl:h-[500px]',
                  'text-white font-bold text-center',
                  'text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl -translate-x-1/2 -translate-y-1/2',
                )}
                {...getTestProps(testId, 'add-media')}
              >
                <div className="absolute h-full w-full animate-slowPing rounded-full bg-blue opacity-20" />
                <div
                  className={
                    'h-full w-full p-5 md:p-8 lg:py-10 2xl:py-20 grid grid-rows-3 justify-center items-center'
                  }
                >
                  <UnionIcon className="animate-slowSpin justify-self-center h-2 md:h-4 lg:h-6 opacity-80" />
                  <p>{t('Media.DropFiles')}</p>
                  <p className="px-5 2xl:px-20 self-end opacity-80 text-base md:text-2xl 2xl:text-3xl">
                    {t('Media.AddToLibrary')}
                  </p>
                </div>
              </div>
            </Transition>
            <Topbar
              isSidebarOpen={sideBarOpen}
              topbarLogo={<FlotiqBadgeLogo className="h-10" />}
              topbarHeading={appContext?.topBar?.heading || ''}
              topbarButtons={appContext?.topBar?.buttons || []}
              additionalClasses={twMerge(
                'fixed right-0 z-20 w-full',
                'transition-all',
                'ease-in-out',
                'duration-normal',
                hideAnnouncement ? 'top-0' : 'top-16',
                sideBarOpen
                  ? 'pl-3 sm:pl-[68px] lg:pl-[240px]'
                  : 'pl-3 sm:pl-[68px]',
              )}
              breadcrumbs={appContext?.breadcrumbs || []}
              {...getTestProps(appContext?.topBar?.testId, 'topbar', 'testId')}
            />
            <Sidebar
              additionalContainerClasses={twMerge(
                'fixed top-14 left-0 z-20',
                hideAnnouncement ? 'top-0' : 'top-16',
              )}
              logo={
                appContext?.darkMode ? (
                  <FlotiqLogoWhite className="h-10" />
                ) : (
                  <FlotiqLogo className="h-10" />
                )
              }
              logoLink={'/'}
              menuItems={SIDEBAR_MENU_ITEMS}
              logoConfig={{
                height: 64,
                marginBottom: 36,
                marginTop: hideAnnouncement ? 0 : 64,
              }}
              menuConfig={{
                selected: appContext?.page,
                openItem: appContext?.menuItemOpen,
              }}
              userConfig={{
                name: `${appContext?.user?.firstName || ''} ${
                  appContext?.user?.lastName || ''
                }`,
                email: appContext?.user?.email || '',
                height: 64,
                isModalOnClose: true,
                isModalOnCloseOutside: true,
                hasEventOnCloseOutside: true,
                menuItem: MENU_USER_ITEMS,
              }}
              isOpen={sideBarOpen}
              handleSidebar={handleSidebar}
              pinContentCallback={pinContentCallback}
              additionalUserEmailClasses={
                'break-words leading-normal whitespace-normal mr-2 pb-0.5 line-clamp-2'
              }
              {...getTestProps(testId, 'sidebar', 'testId')}
            />
            {children ? children : <Outlet />}
            <FloatingHelp
              content={FLOATING_HELP_CONTENT}
              additionalClasses="hidden xs:block fixed right-1.5 md:right-3 bottom-2 mb-safe md:mb-0 md:bottom-3"
            />
          </main>
        </SidebarContext.Provider>
      </UserContext.Provider>
    </Sentry.ErrorBoundary>
  );
};

export default Layout;

Layout.propTypes = {
  /*
   ** Checking if the sidebar is open
   */
  isSidebarOpen: PropTypes.bool,
  /*
   ** Additional classes for layout
   */
  additionalClasses: PropTypes.string,
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

Layout.defaultProps = {
  additionalClasses: '',
  isSidebarOpen: true,
  testId: '',
};
