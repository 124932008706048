import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';

// :: Hooks
import { useContentTypes, usePluginsLibrary } from '../../hooks/api';
import useOnce from '../../hooks/useOnce';
import useToken from '../../hooks/useToken';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';

// :: Components
import Loader from '../../components/Loader/Loader';
import Heading from '../../components/Heading/Heading';
import Button from '../../components/Button/Button';
import PluginForm from '../../form/PluginForm/PluginForm';
import Panel from '../../components/Panel/Panel';

// :: Components Inner
import PluginItem from './PluginItem/PluginItem';

// :: Context
import AppContext from '../../contexts/AppContext';
import { useModals } from '../../contexts/ModalContext';

// :: Lib
import { getCtdsFromQuery } from '../../lib/flotiq-client/api-helpers';
import {
  deleteContentObject,
  getPlugins,
  postContentObject,
  putContentObject,
} from '../../lib/flotiq-client';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import { getTestProps } from '../../lib/helpers';
import FlotiqPlugins from '../../lib/flotiq-plugins/flotiqPluginsRegistry';
import { PluginAddLibraryEvent } from '../../lib/flotiq-plugins/plugin-events/PluginAddLibraryEvent';

// :: Icons
import { HouseIcon, ArrowUpRightIcon } from '../../images/shapes';

const USER_PLUGINS_PARAMS = {
  order_by: 'internal.createdAt',
  order_direction: 'desc',
};

const CTD_PARAMS = {
  limit: 1000,
  order_by: 'label',
  order_direction: 'asc',
  page: 1,
};

const PluginInfo = ({ plugin }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-xl font-bold mb-2">
        <span className="break-words">{plugin.displayName || plugin.id} </span>
        <span className="float-right text-base font-normal font-mono">
          {plugin.version || (
            <span className="italic">{t('Plugins.UI.UnknownVersion')}</span>
          )}
        </span>
      </div>
      <p className="break-words">
        {plugin.description || (
          <span className="italic break-words">
            {t('Plugins.UI.UnknownDescription')}
          </span>
        )}
      </p>
      <div className="overflow-hidden clear-both">
        {plugin.repository && (
          <Button
            buttonSize="sm"
            buttonColor="borderless"
            iconImage={<ArrowUpRightIcon className="w-3 h-3" />}
            as="a"
            target="_blank"
            rel="noreferrer"
            href={plugin.repository}
            additionalClasses="xl:float-left whitespace-nowrap mt-4 w-fit px-0 underline hover:no-underline"
          >
            {t('Plugins.UI.GoToRepository')}
          </Button>
        )}
        {plugin.permissions?.length > 0 && (
          <details>
            <summary className="xl:float-right leading-none cursor-pointer mt-4 mb-2 text-sm">
              {t('Plugins.UI.DataAccess')}
            </summary>

            <div className="mt-2 clear-both block float-none align-middle">
              {plugin.permissions.map((permission) => (
                <div
                  key={permission.ctdName + '|' + permission.type}
                  className="grid grid-cols-2 border-t border-gray py-2 animate-popoverfadein"
                >
                  <div>{t(`Plugins.UI.Type.${permission.type}`)}</div>
                  <div>{permission.ctdName || t('Plugins.UI.AllCTD')}</div>
                  <div className="col-span-2 italic">
                    {['Read', 'Create', 'Update', 'Delete']
                      .filter((perm) => permission[`can${perm}`])
                      .map((perm) => t(`Global.Permission.${perm}`))
                      .join(', ')}
                  </div>
                </div>
              ))}
            </div>
          </details>
        )}
      </div>
    </>
  );
};

const LoadedUIPluginsPanel = () => {
  const { t } = useTranslation();
  return (
    <Panel
      title={t('Plugins.UI.Title')}
      additionalChildrenClasses="space-y-6 dark:text-gray-200"
      isCollapsable={false}
      open
    >
      {!Object.keys(FlotiqPlugins.getLoadedPlugins()).length
        ? t('Plugins.UI.EmptyData')
        : Object.entries(FlotiqPlugins.getLoadedPlugins()).map(
            ([id, handler]) => (
              <div key={id}>
                <PluginInfo plugin={handler.plugin} />
              </div>
            ),
          )}
    </Panel>
  );
};

/**
 * @emits FlotiqPlugins."flotiq.plugin.library::add"
 */

const Plugins = ({ testId }) => {
  const { t } = useTranslation();
  const jwt = useToken();
  const { updateAppContext } = useContext(AppContext);
  const modal = useModals();
  const [newPlugin, setNewPlugin] = useState(null);
  const [userPlugins, setUserPlugins] = useState([]);
  const [userPluginsAreLoading, setUserPluginsAreLoading] = useState(true);

  const {
    data: pluginsLibrary,
    isLoading: pluginsLibraryIsLoading,
    errors: pluginsLibraryErrors,
  } = usePluginsLibrary();

  const finalPluginsLibrary = useMemo(() => {
    if (!FlotiqPlugins.enabled()) return pluginsLibrary;

    return [
      ...pluginsLibrary,
      {
        type: 'ui-extension',
        name: 'UI Extension',
        description:
          'Integration with the Flotiq Frontend. It changes the way that some fields/forms are rendered.',
        settings: [{ key: 'source-url', label: 'Source code URL' }],
        version: '0.1',
      },
    ];
  }, [pluginsLibrary]);

  const getUserPluginsData = useCallback(async () => {
    try {
      const { body, status } = await getPlugins(jwt, USER_PLUGINS_PARAMS);
      checkResponseStatus(body, status);
      setUserPlugins(body.data);
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      }
      toast.error(error.message);
    }
    setUserPluginsAreLoading(false);
  }, [jwt, t]);

  useEffect(() => {
    getUserPluginsData();
  }, [getUserPluginsData]);

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'plugins',
      topBar: {
        heading: t('Global.Plugins'),
        buttons: [
          {
            label: t('Global.Documentation'),
            color: 'blue',
            key: 'Documentation',
            link: process.env.REACT_APP_PLUGINS,
            target: '_blank',
            rel: 'noreferrer',
          },
        ],
      },
    }));
  }, [t, updateAppContext]);

  useOnce(handlePageUpdate);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: '/',
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Dashboard',
        },
        {
          label: t('Global.Plugins'),
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
          key: 'plugins',
        },
      ],
    }));
  }, [t, updateAppContext]);

  const {
    data: contentTypes,
    isLoading: ctdsAreLoading,
    errors: ctdErrors,
  } = useContentTypes(CTD_PARAMS);

  const finalCtdOptions = useMemo(() => {
    const options = contentTypes
      .filter((ctd) => ctd.name === '_media' || !ctd.internal)
      .map((ctd) => ({
        label: ctd.label,
        value: ctd.name,
      }));
    return options;
  }, [contentTypes]);

  const filterCtds = useCallback(
    async (query, _, setIsLoading) => {
      setIsLoading(true);
      const newCtds = await getCtdsFromQuery(
        jwt,
        { ...CTD_PARAMS, label: query },
        t,
      );
      setIsLoading(false);
      return newCtds
        .filter((ctd) => !ctd.internal || ctd.name === '_media')
        .map((ctd) => ({ label: ctd.label, value: ctd.name }));
    },
    [jwt, t],
  );

  const flotiqPluginLibrary = useMemo(
    () =>
      (
        FlotiqPlugins.run(
          'flotiq.plugin.library::add',
          new PluginAddLibraryEvent(),
        ) || []
      ).filter((r) => !!r),
    [],
  );

  const handleDeletePlugin = useCallback(
    async (id, pluginName, pluginType) => {
      try {
        const { body, status } = await deleteContentObject(jwt, {
          id,
          contentTypeName: '_plugin',
        });
        checkResponseStatus(body, status);
        toast.success(t('Plugins.Deleted', { pluginName }));

        setUserPlugins((prevPlugins) =>
          prevPlugins.filter((plugin) => plugin.id !== id),
        );

        if (pluginType === 'ui-extension') window.location.reload();

        return true;
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return false;
        }
        toast.error(
          error.message || t('Plugins.DeletingError', { pluginName }),
        );
        return false;
      }
    },
    [jwt, t],
  );

  const showDeletingModal = useCallback(
    async (id, pluginName, pluginType) => {
      modal.deleting('delete-modal');
      await handleDeletePlugin(id, pluginName, pluginType);
    },
    [handleDeletePlugin, modal],
  );

  const deletePlugin = useCallback(
    async (id, pluginName, pluginType) => {
      await modal.delete(
        t('Plugins.DeleteConfirmation', { pluginName }),
        'delete-modal',
        () => showDeletingModal(id, pluginName, pluginType),
      );
    },
    [showDeletingModal, modal, t],
  );

  const handleSave = useCallback(
    async (values, isNew = false) => {
      const saveFunc = isNew ? postContentObject : putContentObject;
      try {
        const { status, body } = await saveFunc(jwt, {
          ...values,
          contentTypeName: '_plugin',
          ...(Object.hasOwn(values, 'contentTypeDefinitions')
            ? {
                contentTypeDefinitions: values.contentTypeDefinitions.join(','),
              }
            : {}),
        });
        checkResponseStatus(body, status);
        toast.success(
          t(`Plugins.${isNew ? 'Added' : 'Updated'}`, {
            pluginName: values.name,
          }),
        );

        if (isNew) {
          setNewPlugin(null);
          setUserPlugins((prevPlugins) => {
            const newPlugins = [...prevPlugins];
            newPlugins.unshift(body);
            return newPlugins;
          });
        }

        if (body.type === 'ui-extension') window.location.reload();

        return [[body, {}], false];
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return [[values, {}], true];
        }
        const tranlationKey = isNew ? 'AddingError' : 'UpdatingError';
        toast.error(
          error.message
            ? t('ContentForm.Errors.TryAgain')
            : t(`Plugins.${tranlationKey}`, {
                pluginName: values.name,
              }),
        );
        return [[values, error.errors], true];
      }
    },
    [jwt, t],
  );

  const showReplaceModal = useCallback(
    async (pluginName) => {
      return modal.confirmation(t('Plugins.Replace', { pluginName }));
    },
    [modal, t],
  );

  const handleAddNewPlugin = useCallback(
    async (plugin) => {
      if (plugin.name === newPlugin?.name) return;

      const createNewPlugin = newPlugin
        ? await showReplaceModal(plugin.name)
        : true;
      if (createNewPlugin) setNewPlugin({ ...plugin });
    },
    [newPlugin, showReplaceModal],
  );

  useApiErrorsToast(ctdErrors);
  useApiErrorsToast(pluginsLibraryErrors);

  const userPluginsContent = useMemo(() => {
    const noDataContent = newPlugin ? (
      ''
    ) : (
      <span className="dark:text-white">{t('Plugins.EmptyData')}</span>
    );
    return userPlugins.length
      ? userPlugins.map((plugin, idx) => (
          <PluginForm
            key={plugin.id}
            plugin={JSON.parse(JSON.stringify(plugin))}
            onDelete={deletePlugin}
            onSave={(values) => handleSave(values)}
            ctdsOptions={finalCtdOptions}
            ctdsAreLoading={ctdsAreLoading}
            filterCtds={filterCtds}
            additionalDropdownClasses={
              idx === userPlugins.length - 1 ? '!top-auto bottom-full' : ''
            }
            {...getTestProps(testId, plugin.id, 'testId')}
          />
        ))
      : noDataContent;
  }, [
    ctdsAreLoading,
    deletePlugin,
    filterCtds,
    finalCtdOptions,
    handleSave,
    newPlugin,
    t,
    testId,
    userPlugins,
  ]);

  return (
    <div className="flex items-stretch h-full w-full min-h-[calc(100vh-71px)]">
      <Helmet>
        <title>{t('Global.Plugins')}</title>
      </Helmet>
      <div className="flex flex-col w-full">
        <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 h-full mt-7">
          <div className="md:col-span-3 px-4 xl:pl-7 xl:pr-3.5 pb-7 w-full h-full">
            {pluginsLibraryIsLoading || userPluginsAreLoading ? (
              <div className="h-full overflow-hidden flex justify-center items-center">
                <Loader
                  type="spinner-grid"
                  size="big"
                  {...getTestProps(testId, 'loader', 'testId')}
                />
              </div>
            ) : (
              <>
                {finalPluginsLibrary.length > 0 && (
                  <>
                    <Heading
                      level={3}
                      additionalClasses="pt-0 pb-0 mb-4 text-xl lg:text-3xl leading-none dark:text-white"
                    >
                      {t('Plugins.AvailablePlugins')}
                    </Heading>

                    <div className="flex flex-col">
                      {[...finalPluginsLibrary, ...flotiqPluginLibrary]?.map(
                        (plugin, idx) => {
                          const { order, disabled, ...rest } = plugin;
                          const key = plugin.name + idx;
                          return (
                            <PluginItem
                              key={key}
                              plugin={rest}
                              disabled={disabled}
                              order={order >= 0 || order ? order : idx}
                              onClick={handleAddNewPlugin}
                              {...getTestProps(
                                testId,
                                `plugin-item-${plugin.name}`,
                                'testId',
                              )}
                            />
                          );
                        },
                      )}
                    </div>
                    <div className="my-4 border-t dark:border-slate-800 h-1 w-full" />
                  </>
                )}
                <Heading
                  level={3}
                  additionalClasses="pt-0 pb-0 mb-4 text-xl lg:text-3xl leading-none dark:text-white"
                >
                  {t('Plugins.YoursPlugins')}
                </Heading>
                <div className="space-y-4">
                  {newPlugin && (
                    <PluginForm
                      key={newPlugin.name}
                      plugin={newPlugin}
                      onSave={(values) => handleSave(values, true)}
                      onDelete={() => setNewPlugin(null)}
                      ctdsOptions={finalCtdOptions}
                      ctdsAreLoading={ctdsAreLoading}
                      filterCtds={filterCtds}
                      {...getTestProps(testId, 'new-plugin', 'testId')}
                    />
                  )}
                  {userPluginsContent}
                </div>
              </>
            )}
          </div>
          <div
            className="col-span-3 xl:col-auto px-4 xl:pl-3.5 xl:pr-7 pb-7 border-t md:border-t-0 md:border-l
            w-full xl:mt-10 dark:border-slate-800"
          >
            <LoadedUIPluginsPanel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plugins;

Plugins.propTypes = {
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

Plugins.defaultProps = {
  testId: '',
};
