import { useCallback, useContext, useId, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button/Button';
import { useTranslation } from 'react-i18next';
import { useContentObject, useContentType } from '../../../hooks/api';
import useApiErrorsToast from '../../../hooks/api/useApiErrorsToast';
import { ModalInstanceContext } from '../../../contexts/ModalContext';
import ContentObjectForm from '../../../form/ContentObjectForm/ContentObjectForm';
import Loader from '../../Loader/Loader';
import { updateCTO } from '../../../lib/flotiq-client/api-helpers';
import { getTestProps } from '../../../lib/helpers';

const EditObjectContentModal = ({ contentTypeName, id, testId }) => {
  const formId = useId();
  const { t } = useTranslation();
  const modalInstance = useContext(ModalInstanceContext);
  const [isSaving, setIsSaving] = useState(false);

  const onClose = useCallback(
    () => modalInstance.resolve(null),
    [modalInstance],
  );

  const {
    entity: contentObject,
    isLoading: contentObjectIsLoading,
    updateEntity: updateContentObject,
    errors: contentObjectErrors,
  } = useContentObject(contentTypeName, id);

  const {
    entity: contentType,
    errors: contentTypeErrors,
    isLoading: contentTypeLoading,
  } = useContentType(contentTypeName);

  useApiErrorsToast(contentTypeErrors);
  useApiErrorsToast(contentObjectErrors);

  const updateObject = useCallback(
    async (values) => {
      setIsSaving(true);
      const [formikValues, hasErrors] = await updateCTO(
        values,
        contentTypeName,
        updateContentObject,
        t,
      );
      setIsSaving(false);
      if (!hasErrors) onClose();
      return formikValues;
    },
    [t, updateContentObject, contentTypeName, onClose],
  );

  return (
    <>
      {contentObjectIsLoading || contentTypeLoading ? (
        <Loader size="big" type="spinner-grid" />
      ) : (
        <ContentObjectForm
          contentType={contentType}
          contentObject={contentObject}
          onSubmit={updateObject}
          disabled={isSaving}
          formId={formId}
          hasInitialData
          testId={testId}
        />
      )}
      <div
        className="w-full fixed left-0 bottom-0 flex items-center justify-center p-3 space-x-5
        border-t border-gray dark:border-slate-800 bg-white dark:bg-gray-900"
      >
        <Button
          buttonSize="sm"
          buttonColor="grayBordered"
          onClick={onClose}
          disabled={isSaving}
          {...getTestProps(testId, 'cancel', 'testId')}
        >
          {t('Global.Cancel')}
        </Button>
        <Button
          buttonSize="sm"
          type="submit"
          form={formId}
          disabled={isSaving}
          {...getTestProps(testId, 'save', 'testId')}
        >
          {t('Global.SaveChanges')}
        </Button>
      </div>
    </>
  );
};

export default EditObjectContentModal;

EditObjectContentModal.propTypes = {
  /**
   * Content object type name
   */
  contentTypeName: PropTypes.string.isRequired,
  /**
   * Content object id
   */
  id: PropTypes.string.isRequired,
  /**
   * Edit modal test id
   */
  testId: PropTypes.string,
};

EditObjectContentModal.defaultProps = {
  testId: '',
};
