import {
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useState,
  useId,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-hot-toast';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import TagManager from 'react-gtm-module';

// :: Components
import Heading from '../../components/Heading/Heading';
import Panel from '../../components/Panel/Panel';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import Tooltip from '../../components/Tooltip/Tooltip';

// :: Components Inner
import ApiKeyItem from './APIKeyItem/APIKeyItem';

// :: Context
import AppContext from '../../contexts/AppContext';
import { useModals } from '../../contexts/ModalContext';

// :: Lib
import { getTestProps, generateApiDocs } from '../../lib/helpers';
import {
  deleteApiKey,
  regenerateApiKey,
  updateApiKey,
  postApiKey,
  listContentTypes,
} from '../../lib/flotiq-client';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';

// :: Hooks
import useOnce from '../../hooks/useOnce';
import { useApiKeys, useContentTypes } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useToken from '../../hooks/useToken';

// :: Utils
import { getLocalStorage } from '../../utils/localStorage';

// :: Icons
import { HouseIcon, QuestionMarkIcon } from '../../images/shapes';

const APIKeys = ({ testId }) => {
  const { t } = useTranslation();
  const modal = useModals();
  const jwt = useToken();
  const componentID = useId();
  const { updateAppContext } = useContext(AppContext);

  // :: Api Key Data
  const { data: apiKeysData, isLoading } = useApiKeys();
  const [currentApiKeys, setCurrentApiKeys] = useState([]);
  const [newUserApiKeysCounter, setNewUserApiKeysCounter] = useState(1);
  const [loading, setLoading] = useState();

  // :: User Data
  const userRaw = getLocalStorage('cms.user');
  const user = useMemo(() => JSON.parse(userRaw), [userRaw]);

  // :: Limits
  const scopedKeysLimit = user?.data?.limits_plan?.scoped_keys_limit;
  const scopedKeysDocs = user?.data?.limits_plan?.scoped_keys_docs;
  const apiScopedLen = useMemo(() => {
    return currentApiKeys.filter((el) => !el.global).length;
  }, [currentApiKeys]);
  const disabledAddScopedKeys =
    scopedKeysLimit !== -1 && scopedKeysLimit <= apiScopedLen;

  // :: Content Type Data
  const ctdParams = useMemo(
    () => ({
      limit: 1000,
      order_by: 'label',
      order_direction: 'asc',
      page: 1,
    }),
    [],
  );

  const {
    data: contentTypes,
    isLoading: isLoadingCT,
    errors: ctdErrors,
  } = useContentTypes(ctdParams);

  const filterCtd = useCallback(
    async (query, _, setIsLoading) => {
      setIsLoading(true);
      let newOptions = [];
      try {
        const { body, status } = await listContentTypes(jwt, {
          ...ctdParams,
          name: query,
        });
        checkResponseStatus(body, status);
        newOptions = body.data || [];
      } catch (error) {
        toast.error(
          t(
            error instanceof ResponseError
              ? 'ContentForm.CouldntFetch'
              : 'Form.CommunicationErrorMessage',
          ),
        );
      }
      setIsLoading(false);
      return newOptions
        .filter((ctd) => !ctd.internal || ctd.name === '_media')
        .map((ctd) => ({ label: ctd.label, value: ctd.id }));
    },
    [jwt, ctdParams, t],
  );

  useEffect(() => {
    if (apiKeysData) {
      setCurrentApiKeys(apiKeysData);
    }
  }, [apiKeysData]);

  const contentTypesOptions = useMemo(() => {
    if (!contentTypes) return;

    return contentTypes
      .filter(
        (el) => !(el.internal && el.name !== '_tag' && el.name !== '_media'),
      )
      .map((el) => {
        return {
          value: el.id,
          label: el.label,
        };
      });
  }, [contentTypes]);

  useApiErrorsToast(ctdErrors);

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: `apiKeys`,
      topBar: {
        heading: t('Global.APIKeys'),
        buttons: [
          {
            label: t('ApiKeys.AccessDocumentation'),
            link: process.env.REACT_APP_DOCUMENTATION_API,
            target: '_blank',
            rel: 'noreferrer',
            key: 'AccessDoc',
          },
        ],
      },
    }));
  }, [t, updateAppContext]);

  useOnce(handlePageUpdate);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      breadcrumbs: [
        {
          key: 'dashboard',
          label: <HouseIcon className="w-3 text-blue" />,
          link: '/',
          additionalClasses: 'text-slate-400 truncate text-center',
        },
        {
          key: 'apiKeys',
          label: t('Global.APIKeys'),
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
        },
      ],
    }));
  }, [t, updateAppContext]);

  const handleAddApiKeys = () => {
    const newID = `${componentID}-${newUserApiKeysCounter}`;

    const newApi = {
      id: `_newid-${newID}`,
      name: t('ApiKeys.NewKey'),
      permissions: [
        {
          canRead: false,
          canUpdate: false,
          canCreate: false,
          canDelete: false,
          contentTypeDefinition: { id: '' },
        },
      ],
    };

    // Case: increment for unique id for add/delete newApiKey in one sesion
    setNewUserApiKeysCounter((prevState) => prevState + 1);

    setCurrentApiKeys((prevState) => [newApi, ...prevState]);
  };

  const handleGetSchema = useCallback(
    async (name, apiKey, generateSchema) => {
      if (generateSchema) {
        const result = await modal.confirmation(
          t('ApiKeys.GetScopedSchemaDescription', { name: name }),
          t('Global.Warning'),
          t('ApiKeys.GetScopedSchemaConfirm'),
          t('ApiKeys.GetScopedSchemaCancel'),
          'warning',
          { size: 'lg' },
        );
        if (result) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'apidocs_visit',
            },
          });
          window.open(
            generateApiDocs({
              organization: user?.data?.organization,
              apiReadOnlyToken: apiKey,
            }),
            '_blank',
          );
        }
      } else {
        window.open(process.env.REACT_APP_PRICING, '_blank');
      }
    },
    [modal, user, t],
  );

  const handleRegenerate = useCallback(
    async (name, id) => {
      setLoading(id);
      const result = await modal.confirmation(
        t('ApiKeys.RegenerateDescription', { name: name }),
        t('Global.Warning'),
        t('ApiKeys.Regenerate'),
        t('Global.Cancel'),
        'warning',
        { size: 'lg' },
      );

      if (result) {
        try {
          const { body, status } = await regenerateApiKey(jwt, {
            id: id,
          });

          checkResponseStatus(body, status);

          setCurrentApiKeys((prevState) => {
            const apiIndex = prevState.findIndex((el) => el.id === id);

            prevState[apiIndex] = {
              ...prevState[apiIndex],
              apiKey: body.apiKey,
            };

            return prevState;
          });

          toast.success(t('ApiKeys.RegenerateSuccess', { name: name }));
        } catch (error) {
          if (!(error instanceof ResponseError)) {
            toast.error(t('ApiKeys.DeleteModalError'));
          } else {
            toast.error(
              error.message
                ? `${t('ApiKeys.DeleteModalError')}. ${error.message}`
                : t('ApiKeys.DeleteModalError'),
            );
          }
        }
      }
      setLoading();
    },
    [modal, jwt, t],
  );

  const handleDelete = useCallback(
    async (id, name, mode) => {
      if (mode === 'unsaved') {
        setCurrentApiKeys((prevState) =>
          prevState.filter((el) => el.id !== id),
        );
        return;
      }

      const result = await modal.confirmation(
        t('ApiKeys.DeleteDescription', { name: name }),
        t('Global.Warning'),
        t('Global.Delete'),
        t('Global.Cancel'),
      );

      if (result) {
        try {
          setLoading(id);

          const { body, status } = await deleteApiKey(jwt, {
            id: id,
          });
          checkResponseStatus(body, status);

          setCurrentApiKeys((prevState) =>
            prevState.filter((el) => el.id !== id),
          );

          toast.success(t('ApiKeys.DeleteModalSuccess'));
        } catch (error) {
          if (!(error instanceof ResponseError)) {
            toast.error(t('ApiKeys.DeleteModalError'));
          } else {
            toast.error(
              error.message
                ? `${t('ApiKeys.DeleteModalError')}. ${error.message}`
                : t('ApiKeys.DeleteModalError'),
            );
          }
        }
      }

      setLoading();
    },
    [modal, jwt, t],
  );

  const handleShowQRCode = useCallback(
    (name, apiKey) => {
      modal.info(
        t('ApiKeys.YourApiKey', { name: name }),
        <div className="flex justify-center items-center pb-5">
          <QRCode
            size={300}
            value={`{"apiKey":"${apiKey}","apiUrl":"${process.env.REACT_APP_FLOTIQ_API_URL}/api"}`}
            viewBox={`0 0 300 300`}
          />
        </div>,
        'qr-modal-api-key',
        { size: 'lg' },
      );
    },
    [modal, t],
  );

  const handleSave = useCallback(
    async (id, name, apiKey, permissions) => {
      setLoading(id);

      const updateShape = {
        name: name,
        canCreate: false,
        canRead: false,
        canUpdate: false,
        canDelete: false,
        global: false,
        permissions: permissions,
      };

      if (apiKey) {
        try {
          const { body, status } = await updateApiKey(jwt, {
            id: id,
            ...updateShape,
          });
          checkResponseStatus(body, status);
          toast.success(t('ApiKeys.SaveNewApiKeySuccess', { name: name }));
          setLoading();
        } catch (error) {
          toast.error(t('ApiKeys.UpdateError'));
          setLoading();
        }
      } else {
        try {
          const { body, status } = await postApiKey(jwt, updateShape);
          checkResponseStatus(body, status);
          setCurrentApiKeys((prevState) => {
            const apiIndex = prevState.findIndex((el) => el.id === id);
            prevState[apiIndex] = {
              ...prevState[apiIndex],
              ...body,
            };
            return prevState;
          });
          toast.success(t('ApiKeys.SaveNewApiKeySuccess', { name: name }));
          setLoading();
        } catch (error) {
          toast.error(t('ApiKeys.UpdateError'));
          setLoading();
        }
      }
    },
    [jwt, t],
  );

  return (
    <div
      className="flex items-stretch h-full w-full min-h-[calc(100vh-71px)]"
      {...getTestProps(testId, 'container')}
    >
      <Helmet>
        <title>{t('Global.APIKeys')}</title>
      </Helmet>
      <div className="flex flex-col w-full">
        <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 h-full mt-5">
          <div className="md:col-span-3 rounded-lg mx-4 xl:ml-7 xl:mr-3.5 mb-7">
            {isLoading || isLoadingCT ? (
              <div className="h-full overflow-hidden flex justify-center items-center">
                <Loader type="spinner-grid" size="big" />
              </div>
            ) : (
              <>
                <Heading
                  level={3}
                  additionalClasses="pt-0 pb-0 mb-4 text-xl lg:text-3xl leading-none dark:text-white"
                >
                  {t('ApiKeys.AplicationApiKeys')}
                </Heading>
                {currentApiKeys?.map(
                  (el) =>
                    el.global && (
                      <ApiKeyItem
                        key={el.id}
                        data={el}
                        rules={false}
                        generateSchema={true}
                        onGetSchema={handleGetSchema}
                        onRegenerate={handleRegenerate}
                        onDelete={handleDelete}
                        onShowQRCode={handleShowQRCode}
                        onSave={handleSave}
                        isLoading={el.id === loading}
                        {...getTestProps(testId, 'api-key-item', 'testId')}
                      />
                    ),
                )}
                <div className="my-4 border-t dark:border-slate-800 h-1 w-full" />
                <Heading
                  level={3}
                  additionalClasses="pt-0 pb-0 mb-4 text-xl lg:text-3xl leading-none dark:text-white"
                >
                  {t('ApiKeys.UserDefinedApiKeys')}
                </Heading>
                <div className="flex flex-row items-center">
                  <Button
                    buttonSize="sm"
                    onClick={handleAddApiKeys}
                    additionalClasses={'mb-2'}
                    disabled={disabledAddScopedKeys}
                    {...getTestProps(testId, `button-add-apikey`)}
                  >
                    {t('ApiKeys.AddNewApiKey')}
                  </Button>

                  {disabledAddScopedKeys && (
                    <Link
                      to={process.env.REACT_APP_PRICING}
                      target="_blank"
                      rel="noreferrer"
                      className={'ml-3'}
                      {...getTestProps(testId, `disabled-scoped`)}
                    >
                      <Tooltip
                        tooltip={t('ApiKeys.Limits')}
                        tooltipPlacement={'topCenter'}
                      >
                        <div
                          className={twMerge(
                            'w-4 h-4 flex justify-center',
                            'items-center rounded-full bg-red',
                          )}
                        >
                          <QuestionMarkIcon className="w-2 h-2 text-white" />
                        </div>
                      </Tooltip>
                    </Link>
                  )}
                </div>

                {currentApiKeys?.map(
                  (el) =>
                    !el.global && (
                      <ApiKeyItem
                        key={el.id}
                        data={el}
                        options={contentTypesOptions}
                        optionsFilterCallback={filterCtd}
                        editableName={true}
                        removable={true}
                        generateSchema={scopedKeysDocs}
                        onGetSchema={handleGetSchema}
                        onRegenerate={handleRegenerate}
                        onDelete={handleDelete}
                        onShowQRCode={handleShowQRCode}
                        onSave={handleSave}
                        isLoading={el.id === loading}
                        {...getTestProps(testId, 'api-key-item', 'testId')}
                      />
                    ),
                )}
              </>
            )}
          </div>

          <div
            className="px-4 xl:pl-3.5 xl:pr-7 pb-7 border-t md:border-t-0 md:border-l dark:border-slate-800
            space-y-5 w-full mt-10"
          >
            <Panel
              title={t('ApiKeys.AplicationApiKeys')}
              isCollapsable={false}
              additionalContainerClasses="py-5 px-4"
              additionalTitleClasses="text-lg -mb-1"
              additionalChildrenClasses="dark:text-gray-200"
              open
            >
              <div className="flex flex-col space-y-3">
                {t('ApiKeys.AplicationApiKeysDescription')}
              </div>
            </Panel>

            <Panel
              title={t('ApiKeys.UserDefinedApiKeys')}
              isCollapsable={false}
              additionalContainerClasses="py-5 px-4"
              additionalTitleClasses="text-lg -mb-1"
              additionalChildrenClasses="dark:text-gray-200"
              open
            >
              <div className="flex flex-col space-y-3">
                {t('ApiKeys.UserDefinedApiKeysDescription')}
              </div>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default APIKeys;

APIKeys.propTypes = {
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

APIKeys.defaultProps = {
  testId: '',
};
