import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import RequiredTemplate from '../RequiredTemplate/RequiredTemplate';
import { getTestProps } from '../../lib/helpers';
import HelpErrorTextsTemplate from '../HelpErrorTextsTemplate/HelpErrorTextsTemplate';

const Textarea = ({
  value,
  name,
  onChange,
  onBlur,
  label,
  required,
  disabled,
  placeholderText,
  helpText,
  error,
  additionalClasses,
  additionalTextareaClasses,
  additionalTextareaLabelClasses,
  additionalTextareaErrorClasses,
  testId,
}) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = useCallback(
    (e) => {
      setCurrentValue(e.target.value);
      if (onChange) onChange(e);
    },
    [onChange],
  );

  const handleBlur = useCallback(
    (e) => {
      if (onBlur) onBlur(e);
    },
    [onBlur],
  );

  return (
    <div
      className={twMerge('relative w-full flex flex-col', additionalClasses)}
      {...getTestProps(testId, 'container')}
    >
      {label && (
        <label
          className={twMerge(
            'text-sm text-slate-400 dark:text-gray-200 mb-1',
            additionalTextareaLabelClasses,
          )}
          {...getTestProps(testId, 'label')}
        >
          {label}
          {required && <RequiredTemplate />}
        </label>
      )}
      {!label && required && <RequiredTemplate absolute={true} />}
      <div className="w-full">
        <textarea
          className={twMerge(
            'resize text-base px-4 py-2.5 h-32 w-full',
            'focus:outline-none focus:border-blue',
            'border-slate-200 border rounded-lg',
            'placeholder:text-gray placeholder:font-light',
            'text-ellipsis text-indigo-950',
            'dark:bg-transparent dark:border-slate-700 dark:text-white',
            disabled && 'bg-gray dark:bg-gray-900 cursor-not-allowed',
            required && !label && 'pr-5',
            error ? 'border-red' : '',
            additionalTextareaClasses,
          )}
          name={name}
          value={currentValue}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholderText}
          required={required}
          disabled={disabled}
          {...getTestProps(testId, 'textarea')}
        />
      </div>
      <HelpErrorTextsTemplate
        helpText={helpText}
        error={error}
        additionalErrorClasses={additionalTextareaErrorClasses}
        testId={testId}
      />
    </div>
  );
};

export default Textarea;

Textarea.propTypes = {
  /**
   * Textarea value
   */
  value: PropTypes.string,
  /**
   * Textarea name
   */
  name: PropTypes.string,
  /**
   * On change callback
   */
  onChange: PropTypes.func,
  /**
   * On blur callback
   */
  onBlur: PropTypes.func,
  /**
   * Textarea label
   */
  label: PropTypes.string,
  /**
   * If textarea is required we add "*" near label or inside input if there is no label.
   */
  required: PropTypes.bool,
  /**
   * If textarea is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Textarea placeholder text
   */
  placeholderText: PropTypes.string,
  /**
   * Help text under textarea
   */
  helpText: PropTypes.string,
  /**
   * Error text under textarea
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /**
   * Textarea additional classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Textarea additional input classes
   */
  additionalTextareaClasses: PropTypes.string,
  /**
   * Textarea additional label classes
   */
  additionalTextareaLabelClasses: PropTypes.string,
  /**
   * Textarea additional error classes
   */
  additionalTextareaErrorClasses: PropTypes.string,
  /**
   * Checkbox test id
   */
  testId: PropTypes.string,
};

Textarea.defaultProps = {
  value: '',
  name: '',
  label: '',
  required: false,
  error: '',
  disabled: false,
  placeholderText: '',
  helpText: '',
  additionalClasses: '',
  additionalTextareaClasses: '',
  additionalTextareaLabelClasses: '',
  additionalTextareaErrorClasses: '',
  testId: '',
};
