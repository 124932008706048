import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { getTestProps } from '../../lib/helpers';
import { CheckmarkIcon } from '../../images/shapes';

const Checkbox = ({
  id,
  name,
  checked,
  label,
  onChange,
  disabled,
  error,
  circular,
  additionalContainerClasses,
  additionalLabelClasses,
  additionalCheckboxContainerClasses,
  additionalCheckboxClasses,
  additionalErrorTextClasses,
  testId,
}) => {
  const [currentChecked, setCurrentChecked] = useState(checked);

  useEffect(() => setCurrentChecked(checked), [checked]);

  const onChangeLocal = useCallback(
    (event) => {
      const newCheckedValue = !currentChecked;
      setCurrentChecked(newCheckedValue);
      if (onChange) onChange(event);
    },
    [currentChecked, onChange],
  );

  const checkboxColor = disabled ? 'slate-100' : 'blue';

  return (
    <div
      className={twMerge('flex w-fit items-center', additionalContainerClasses)}
      {...getTestProps(testId, 'container')}
    >
      <div
        className={twMerge(
          'w-fit h-fit self-start relative',
          additionalCheckboxContainerClasses,
        )}
      >
        <CheckmarkIcon
          className={twMerge(
            'pointer-events-none absolute left-1/4 top-1/4 w-1/2 h-1/2',
            checked ? 'text-white' : 'text-transparent',
          )}
        />
        <input
          name={name}
          id={id || name}
          className={twMerge(
            `flex items-center justify-center hover:cursor-pointer text-white bg-${
              disabled
                ? 'slate-100 cursor-not-allowed hover:cursor-not-allowed'
                : 'gray-50'
            }`,
            `dark:bg-${disabled ? 'gray-700' : 'transparent'}`,
            `accent-${checkboxColor} appearance-none w-6 h-6 border border-${
              currentChecked && !disabled
                ? 'blue'
                : 'slate-100 dark:border-slate-800'
            }`,
            `bg-transparent checked:!bg-${checkboxColor} checked:dark:bg-${checkboxColor}`,
            circular ? 'rounded-full ' : 'rounded',
            additionalCheckboxClasses,
          )}
          type="checkbox"
          checked={currentChecked}
          onChange={onChangeLocal}
          disabled={disabled}
          {...getTestProps(testId, 'checkbox')}
        />
      </div>

      <div className="flex flex-col">
        <label
          htmlFor={name}
          className={twMerge(
            'cursor-pointer text-left text-lg ml-1.5 dark:text-white',
            disabled && 'cursor-not-allowed hover:cursor-not-allowed',
            additionalLabelClasses,
          )}
          {...getTestProps(testId, 'label')}
        >
          {label}
        </label>

        {error && (
          <span
            className={twMerge(
              'text-red mt-1 mb-2 leading-none',
              additionalErrorTextClasses,
            )}
            {...getTestProps(testId, 'error')}
          >
            {error}
          </span>
        )}
      </div>
    </div>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  /**
   * Checkbox id
   */
  id: PropTypes.string,
  /**
   * Checkbox name
   */
  name: PropTypes.string,
  /**
   * On checked value change handler
   */
  onChange: PropTypes.func,
  /**
   * Checkbox value
   */
  checked: PropTypes.bool,
  /**
   * Checkbox label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * If checkbox is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Checkbox text that will inform about error
   */
  error: PropTypes.string,
  /**
   * If checkbox is circular
   */
  circular: PropTypes.bool,
  /**
   * Checkbox additional container classes
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Checkbox additional label classes
   */
  additionalLabelClasses: PropTypes.string,
  /**
   * Checkbox container additional label classes
   */
  additionalCheckboxContainerClasses: PropTypes.string,
  /**
   * Checkbox additional classes
   */
  additionalCheckboxClasses: PropTypes.string,
  /**
   * Checkbox additional text error classes
   */
  additionalErrorTextClasses: PropTypes.string,
  /**
   * Checkbox test id
   */
  testId: PropTypes.string,
};

Checkbox.defaultProps = {
  id: '',
  name: '',
  checked: false,
  label: '',
  disabled: false,
  error: '',
  onChange: undefined,
  circular: false,
  additionalContainerClasses: '',
  additionalLabelClasses: '',
  additionalCheckboxContainerClasses: '',
  additionalCheckboxClasses: '',
  additionalErrorTextClasses: '',
  testId: '',
};
