import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Component
import { SectionLogo } from './SectionLogo';
import { SectionMenuItem } from './SectionMenuItem';
import { SectionUserProfile } from './SectionUserProfile';

// :: Lib
import { getTestProps } from '../../lib/helpers';

const Sidebar = ({
  // :: Sidebar
  sizeOpen,
  sizeClose,
  height,
  isOpen,
  additionalContainerClasses,
  handleSidebar,

  // :: Logo
  logo,
  logoLink,
  logoConfig,
  additionalLogoClasses,
  additionalLogoContainerClasses,

  // :: Menu
  menuItems,
  menuConfig,
  additionalMenuItemClasses,
  additionalMenuItemChildClasses,
  additionalMenuItemIconClasses,

  // :: User
  userConfig,
  additionalUserContainerClasses,
  additionalUserNameClasses,
  additionalUserEmailClasses,
  additionalUserMenuContainerClasses,
  additionalUserMenuClasses,

  // :: Other
  pinContentCallback,
  testId,
  ...props
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(isOpen);
  const validHeight =
    (logo
      ? logoConfig.height + logoConfig.marginBottom + logoConfig.marginTop
      : 0) + userConfig.height;

  useEffect(() => {
    setIsSidebarOpen(isOpen);
  }, [isOpen]);

  const handleSidebarOpen = () => {
    setIsSidebarOpen(!isSidebarOpen);
    handleSidebar?.(!isSidebarOpen);
  };

  return (
    <>
      <div
        className={twMerge(
          'lg:hidden absolute top-0 left-0 bg-zinc-600 w-full h-full z-20 transition',
          'duration-500 ease-in-out cursor-pointer',
          isOpen ? 'opacity-50' : 'opacity-0 pointer-events-none',
        )}
        onClick={handleSidebarOpen}
      />
      <div
        className={twMerge(
          'transition-all bg-white dark:bg-slate-950',
          'ease-in-out',
          'duration-normal',
          'border-r',
          'border-gray dark:border-slate-800',
          !isSidebarOpen
            ? '-translate-x-full sm:translate-x-0'
            : 'translate-x-0',
          additionalContainerClasses,
        )}
        style={{
          width: isSidebarOpen ? sizeOpen : sizeClose,
          bottom: 0,
          top: 0,
        }}
        {...props}
        {...getTestProps(testId, 'container')}
      >
        {/* Section: Logo */}
        {logo && (
          <SectionLogo
            logo={logo}
            logoLink={logoLink}
            small={!isSidebarOpen}
            height={logoConfig.height}
            marginBottom={logoConfig.marginBottom}
            marginTop={logoConfig.marginTop}
            additionalLogoClasses={additionalLogoClasses}
            additionalLogoContainerClasses={additionalLogoContainerClasses}
            {...getTestProps(testId, 'logo', 'testId')}
          />
        )}

        {/* Section: Menu Item */}
        <div
          style={{
            height: `calc(100% - ${validHeight}px)`,
          }}
          className={twMerge(
            'overflow-x-hidden overflow-y-overlay scrollbar-sm',
          )}
        >
          <SectionMenuItem
            menuItems={menuItems}
            pinContentCallback={pinContentCallback}
            selected={menuConfig.selected}
            selectedSeparator={menuConfig.selectedSeparator}
            isListOpenSingle={menuConfig.isListOpenSingle}
            isOpen={isSidebarOpen}
            openItem={menuConfig.openItem}
            handleParentOpen={handleSidebarOpen}
            additionalMenuItemClasses={additionalMenuItemClasses}
            additionalMenuItemIconClasses={additionalMenuItemIconClasses}
            additionalMenuItemChildClasses={additionalMenuItemChildClasses}
            {...getTestProps(testId, 'menu-item', 'testId')}
          />
        </div>

        <SectionUserProfile
          menuItem={userConfig.menuItem}
          avatar={userConfig.avatar}
          name={userConfig.name}
          email={userConfig.email}
          height={userConfig.height}
          isModalOnClose={userConfig.isModalOnClose}
          isModalOnCloseOutside={userConfig.isModalOnCloseOutside}
          onClick={handleSidebarOpen}
          isOpen={isSidebarOpen}
          handleSidebarOpen={handleSidebarOpen}
          sizeClose={sizeClose}
          additionalUserContainerClasses={additionalUserContainerClasses}
          additionalUserNameClasses={additionalUserNameClasses}
          additionalUserEmailClasses={additionalUserEmailClasses}
          additionalUserMenuContainerClasses={
            additionalUserMenuContainerClasses
          }
          additionalUserMenuClasses={additionalUserMenuClasses}
          hasEventOnCloseOutside={userConfig.hasEventOnCloseOutside}
          {...getTestProps(testId, 'user-profile', 'testId')}
        />
      </div>
    </>
  );
};

Sidebar.propTypes = {
  /**
   * Sidebar width on open
   */
  sizeOpen: PropTypes.any,
  /**
   * Sidebar width on close
   */
  sizeClose: PropTypes.any,
  /**
   * Sidebar height
   */
  height: PropTypes.any,
  /**
   * If sidebar should open/close
   */
  isOpen: PropTypes.bool,
  /**
   * Additional Container Classes for sidebar
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Logo source path
   */
  logo: PropTypes.any,
  /**
   * Logo link
   */
  logoLink: PropTypes.string,
  /**
   * Logo configuration from sidebar
   */
  logoConfig: PropTypes.shape({
    height: PropTypes.number,
    marginBottom: PropTypes.number,
    marginTop: PropTypes.number,
  }),
  /**
   * Logo additional image Classes
   */
  additionalLogoClasses: PropTypes.string,
  /**
   * Logo additional Container Classes
   */
  additionalLogoContainerClasses: PropTypes.string,

  /**
   * Menu item list
   */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      icon: PropTypes.node,
      title: PropTypes.string,
      link: PropTypes.string,
      hidePin: PropTypes.bool,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.any,
          title: PropTypes.string,
          link: PropTypes.string,
        }),
      ),
    }),
  ),
  /**
   *  Menu config from sidebar
   */
  menuConfig: PropTypes.shape({
    selected: PropTypes.any,
    openItem: PropTypes.string,
    isListOpenSingle: PropTypes.bool,
    selectedSeparator: PropTypes.string,
  }),
  /**
   * Menu additional Menu Item Classes
   */
  additionalMenuItemClasses: PropTypes.string,
  /**
   * Menu additional Menu Item Child Classes
   */
  additionalMenuItemChildClasses: PropTypes.string,
  /**
   * Menu additional Menu Item Icon Classes
   */
  additionalMenuItemIconClasses: PropTypes.string,

  /**
   * User config from sidebar
   */
  userConfig: PropTypes.shape({
    avatar: PropTypes.node,
    name: PropTypes.string,
    email: PropTypes.string,
    height: PropTypes.any,
    isModalOnClose: PropTypes.bool,
    isModalOnCloseOutside: PropTypes.bool,
    menuItem: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.node,
        text: PropTypes.string,
        onClick: PropTypes.func,
      }),
    ),
  }),
  /**
   * User additional User Container Classes
   */
  additionalUserContainerClasses: PropTypes.string,
  /**
   * User additional Username Classes
   */
  additionalUserNameClasses: PropTypes.string,
  /**
   * User additional User Email Classes
   */
  additionalUserEmailClasses: PropTypes.string,
  /**
   * User additional User Menu Container Classes
   */
  additionalUserMenuContainerClasses: PropTypes.string,
  /**
   * User additional User Menu Classes
   */
  additionalUserMenuClasses: PropTypes.string,
};

export default Sidebar;

Sidebar.defaultProps = {
  // :: Sidebar
  sizeOpen: 240,
  sizeClose: 68,
  height: '100vh',
  isOpen: true,
  additionalContainerClasses: '',

  // :: Logo
  logo: undefined,
  logoLink: undefined,
  logoConfig: {
    height: 64,
    marginBottom: 46,
    marginTop: 0,
  },
  additionalLogoClasses: '',
  additionalLogoContainerClasses: '',

  // :: MenuItem
  menuItems: [],
  menuConfig: {
    selected: undefined,
    isListOpenSingle: true,
    selectedSeparator: '/',
  },
  additionalMenuItemClasses: '',
  additionalMenuItemChildClasses: '',
  additionalMenuItemIconClasses: '',

  // :: UserProfile
  userConfig: {
    avatar: undefined,
    name: '',
    email: '',
    isModalOnClose: false,
    isModalOnCloseOutside: false,
    hasEventOnCloseOutside: false,
    menuItem: [],
    height: 64,
  },
  additionalUserContainerClasses: '',
  additionalUserNameClasses: '',
  additionalUserEmailClasses: '',
  additionalUserMenuContainerClasses: '',
  additionalUserMenuClasses: '',
};
