import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

// :: Components
import Input from '../../components/Input/Input';
import Switch from '../../components/Switch/Switch';
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';
import Heading from '../../components/Heading/Heading';
import ValidationToastHandler from '../../components/ValidationToastHandler/ValidationToastHandler';

// :: Lib
import { getTestProps } from '../../lib/helpers';

const getInitialValues = (plan) => {
  return {
    name: plan?.name || '',
    visibleName: plan?.visibleName || '',
    displayOrder: plan?.displayOrder || 0,
    stripePriceApiIdMonthly: plan?.stripePriceApiIdMonthly || '',
    stripePriceApiIdYearly: plan?.stripePriceApiIdYearly || '',
    support: plan?.support || '',
    price: plan?.price || 0,
    fileQuota: plan?.fileQuota || 0,
    maxFileQuota: plan?.maxFileQuota || 0,
    ctdLimit: plan?.ctdLimit || 0,
    ctoLimit: plan?.ctoLimit || 0,
    scopedKeysLimit: plan?.scopedKeysLimit || 0,
    teamMembersLimit: plan?.teamMembersLimit || 0,
    webhooksLimit: plan?.webhooksLimit || 0,
    apiCallsLimit: plan?.apiCallsLimit || 0,
    enabled: plan?.enabled || false,
    visible: plan?.visible || false,
    defaultPlan: plan?.defaultPlan || false,
    defaultFreePlan: plan?.defaultFreePlan || false,
    scopedKeysDocs: plan?.scopedKeysDocs || false,
  };
};

yup.addMethod(yup.number, 'planProperty', function (message) {
  return this.test('planProperty', message, function (value) {
    return value >= -1 && value !== Infinity;
  });
});

const PlanFormForm = ({ plan, onSubmit, disabled, navigateOnSave, testId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validationSchema = yup.object({
    name: yup.string().required(t('Form.FormErrorNotBlank')),
    visibleName: yup.string(),
    displayOrder: yup.number(),
    stripePriceApiIdMonthly: yup.string(),
    stripePriceApiIdYearly: yup.string(),
    price: yup.number().min(0),
    fileQuota: yup.number().planProperty(t('Plans.Form.PlanPropertyNumber')),
    maxFileQuota: yup.number().planProperty(t('Plans.Form.PlanPropertyNumber')),
    ctdLimit: yup.number().planProperty(t('Plans.Form.PlanPropertyNumber')),
    ctoLimit: yup.number().planProperty(t('Plans.Form.PlanPropertyNumber')),
    scopedKeysLimit: yup
      .number()
      .planProperty(t('Plans.Form.PlanPropertyNumber')),
    teamMembersLimit: yup
      .number()
      .planProperty(t('Plans.Form.PlanPropertyNumber')),
    webhooksLimit: yup
      .number()
      .planProperty(t('Plans.Form.PlanPropertyNumber')),
    apiCallsLimit: yup
      .number()
      .planProperty(t('Plans.Form.PlanPropertyNumber')),
  });

  const handleSubmit = useCallback(
    async (values, formik) => {
      const [[newValues, errors], hasErrors] = await onSubmit(values);
      await formik.setStatus({ ...formik.status, errors });

      if (!hasErrors) {
        if (navigateOnSave.current === true) {
          navigate('/plans');
        } else {
          formik.resetForm({
            values: newValues,
          });
        }
      }
    },
    [navigate, navigateOnSave, onSubmit],
  );

  return (
    <Formik
      initialValues={getInitialValues(plan)}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        return (
          <form
            id="plan-form"
            className="space-y-2 md:space-y-4 p-5 md:py-10 md:px-12"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className="flex flex-col items-left gap-3 w-full">
              <Input
                name="name"
                type="text"
                label={t('Plans.Name')}
                disabled={disabled}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.status?.errors?.name || formik.errors.name}
                {...getTestProps(testId, 'plan-name', 'testId')}
              />

              <Input
                name="visibleName"
                type="text"
                label={t('Plans.VisibleName')}
                disabled={disabled}
                value={formik.values.visibleName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.visibleName ||
                  formik.errors.visibleName
                }
              />

              <Input
                name="support"
                type="text"
                label={t('Plans.Support')}
                disabled={disabled}
                value={formik.values.support}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.status?.errors?.support || formik.errors.support}
              />

              <Switch
                name={'enabled'}
                label={t('Plans.Enabled')}
                helpText={t('Plans.HelpText.Enabled')}
                disabled={disabled}
                size={'large'}
                checked={formik.values.enabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.status?.errors?.enabled || formik.errors.enabled}
              />

              <Switch
                name={'visible'}
                label={t('Plans.Visible')}
                disabled={disabled}
                size={'large'}
                helpText={t('Plans.HelpText.Visible')}
                checked={formik.values.visible}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.status?.errors?.visible || formik.errors.visible}
              />

              <Switch
                name={'defaultPlan'}
                label={t('Plans.DefaultPlan')}
                disabled={disabled}
                size={'large'}
                checked={formik.values.defaultPlan}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.defaultPlan ||
                  formik.errors.defaultPlan
                }
              />

              <Switch
                name={'defaultFreePlan'}
                label={t('Plans.DefaultFreePlan')}
                disabled={disabled}
                size={'large'}
                checked={formik.values.defaultFreePlan}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.defaultFreePlan ||
                  formik.errors.defaultFreePlan
                }
              />

              <Input
                name="displayOrder"
                type="number"
                label={t('Plans.DisplayOrder')}
                helpText={t('Plans.HelpText.DisplayOrder')}
                disabled={disabled}
                value={formik.values.displayOrder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.displayOrder ||
                  formik.errors.displayOrder
                }
              />

              <Heading
                level={3}
                children={t('Plans.PaymentDetails')}
                additionalClasses="dark:text-white"
              />

              <Input
                name="stripePriceApiIdMonthly"
                type="text"
                label={t('Plans.StripePriceApiIdMonthly')}
                disabled={disabled}
                value={formik.values.stripePriceApiIdMonthly}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.stripePriceApiIdMonthly ||
                  formik.errors.stripePriceApiIdMonthly
                }
              />

              <Input
                name="stripePriceApiIdYearly"
                type="text"
                label={t('Plans.stripePriceApiIdYearly')}
                disabled={disabled}
                value={formik.values.stripePriceApiIdYearly}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.stripePriceApiIdYearly ||
                  formik.errors.stripePriceApiIdYearly
                }
              />

              <Input
                name="price"
                type="number"
                label={t('Plans.Price')}
                disabled={disabled}
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.status?.errors?.price || formik.errors.price}
                {...getTestProps(testId, 'plan-price', 'testId')}
              />

              <Heading
                level={3}
                children={t('Plans.SystemLimits')}
                additionalClasses="dark:text-white"
              />

              <Input
                name="fileQuota"
                type="number"
                label={t('Plans.Form.FileQuota')}
                disabled={disabled}
                value={formik.values.fileQuota}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.fileQuota || formik.errors.fileQuota
                }
                {...getTestProps(testId, 'plan-file-quota', 'testId')}
              />

              <Input
                name="maxFileQuota"
                type="number"
                label={t('Plans.Form.MaxFileQuota')}
                disabled={disabled}
                value={formik.values.maxFileQuota}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.maxFileQuota ||
                  formik.errors.maxFileQuota
                }
                {...getTestProps(testId, 'plan-max-file-quota', 'testId')}
              />

              <Input
                name="ctdLimit"
                type="number"
                label={t('Plans.Form.ObjectTypesLimit')}
                disabled={disabled}
                value={formik.values.ctdLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.ctdLimit || formik.errors.ctdLimit
                }
                {...getTestProps(testId, 'plan-ctd-limit', 'testId')}
              />

              <Input
                name="ctoLimit"
                type="number"
                label={t('Plans.Form.ObjectsLimit')}
                disabled={disabled}
                value={formik.values.ctoLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.ctoLimit || formik.errors.ctoLimit
                }
                {...getTestProps(testId, 'plan-cto-limit', 'testId')}
              />

              <Input
                name="scopedKeysLimit"
                type="number"
                label={t('Plans.Form.ScopedKeysLimit')}
                disabled={disabled}
                value={formik.values.scopedKeysLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.scopedKeysLimit ||
                  formik.errors.scopedKeysLimit
                }
                {...getTestProps(testId, 'plan-scoped-keys-limit', 'testId')}
              />

              <Input
                name="teamMembersLimit"
                type="number"
                label={t('Plans.Form.TeamMembersLimit')}
                disabled={disabled}
                value={formik.values.teamMembersLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.teamMembersLimit ||
                  formik.errors.teamMembersLimit
                }
                {...getTestProps(testId, 'plan-team-members-limit', 'testId')}
              />

              <Input
                name="webhooksLimit"
                type="number"
                label={t('Plans.Form.WebhookLimit')}
                disabled={disabled}
                value={formik.values.webhooksLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.webhooksLimit ||
                  formik.errors.webhooksLimit
                }
                {...getTestProps(testId, 'plan-webhooks-limit', 'testId')}
              />

              <Input
                name="apiCallsLimit"
                type="number"
                label={t('Plans.Form.ApiCallsLimit')}
                disabled={disabled}
                value={formik.values.apiCallsLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.apiCallsLimit ||
                  formik.errors.apiCallsLimit
                }
                {...getTestProps(testId, 'plan-api-calls-limit', 'testId')}
              />

              <Switch
                name={'scopedKeysDocs'}
                label={t('Plans.Form.ScopedKeysDocs')}
                checked={formik.values.scopedKeysDocs}
                disabled={disabled}
                size={'large'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.scopedKeysDocs ||
                  formik.errors.scopedKeysDocs
                }
              />
            </div>
            <DirtyHandler />
            <ValidationToastHandler />
          </form>
        );
      }}
    </Formik>
  );
};

export default PlanFormForm;

PlanFormForm.propTypes = {
  /**
   * On sumbit callback
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Plan to edit
   */
  plan: PropTypes.object,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * If navigates after submit represented by react ref
   */
  navigateOnSave: PropTypes.object,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

PlanFormForm.defaultProps = {
  plan: {},
  disabled: false,
  navigateOnSave: {},
  testId: '',
};
