import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { formatErrorToString, getTestProps } from '../../lib/helpers';

const HelpErrorTextsTemplate = ({
  helpText,
  error,
  additionalErrorClasses,
  testId,
}) => {
  return (
    <>
      {helpText && (
        <div
          className={twMerge(
            'text-sm mt-1 mb-1 leading-none text-left break-all dark:text-gray-200',
            error && 'text-red',
          )}
          {...getTestProps(testId, 'help-text')}
        >
          {helpText}
        </div>
      )}
      {error && (
        <div
          className={twMerge(
            'text-red text-sm',
            'mt-1 mb-2 leading-none text-left',
            additionalErrorClasses,
          )}
          {...getTestProps(testId, 'error-text')}
        >
          {formatErrorToString(error)}
        </div>
      )}
    </>
  );
};

export default HelpErrorTextsTemplate;

HelpErrorTextsTemplate.propTypes = {
  /**
   * Field text that will inform about error
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  /**
   * Help text under field
   */
  helpText: PropTypes.any,
  /**
   * Field additional error classes
   */
  additionalErrorClasses: PropTypes.string,
  /**
   * Field test id
   */
  testId: PropTypes.string,
};

HelpErrorTextsTemplate.defaultProps = {
  error: '',
  helpText: '',
  additionalErrorClasses: '',
  testId: '',
};
