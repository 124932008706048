import { IFlotiqPluginEvent } from './IFlotiqPluginEvent';

/**
 * An event that is rendering addintional element at start in the content object form.
 *
 * @memberof FlotiqPlugins
 * @event "flotiq.form::add"
 * @type {FormAddElementEvent}
 * @returns {null|string|array|number|boolean|HTMLElement|ReactElement}
 *          Null if rendering should be passed to either Flotiq or other plugins.
 *          Renderable result if plugin wants to add additional field to content object form.
 */

/**
 * @class
 * @memberof FlotiqPlugins
 * @name FormAddElementEvent
 *
 * @property {object} contentType Content type that includes the field
 *
 * @property {FormikContextType} formik An instance of Formik
 *
 * @property {function} onMediaUpload Function to upload media files (mostly for media relations)
 *
 * @property {object} initialData Initial data of the content object.
 *    This will be either a new object or the object being edited.
 *
 */
export class FormAddElementEvent extends IFlotiqPluginEvent {
  contentType;

  formik;

  onMediaUpload;

  initialData;
}
