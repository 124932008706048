import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';

// :: Component
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Checkbox from '../../components/Checkbox/Checkbox';

// :: Lib
import { emailSchema, passwordSchema } from '../../lib/yupHelpers';
import { getTestProps } from '../../lib/helpers';

// :: Images
import { WarningIcon } from '../../images/shapes';

const perrmisionLabel = () => (
  <div id={`permission-label-div`}>
    <Trans i18nKey="Global.DataProcessingPermission">
      I grant permission to CodeWave Sp. z o. o. to store and process my
      personal information for the purposes of create and manage the account in
      Flotiq platform and I have read the
      <Link
        className="text-blue text-underline"
        to="https://flotiq.com/privacy-policy/"
        target="_blank"
        rel="noreferrer"
      >
        Privacy policy
      </Link>
      and accept it. I also have read the
      <Link
        className="text-blue text-underline"
        to="https://flotiq.com/terms-of-service/"
        target="_blank"
        rel="noreferrer"
      >
        Terms of service
      </Link>
      and accept them.
    </Trans>
  </div>
);

const RegisterForm = ({ onSubmit, prefilledEmail, testId }) => {
  const { t } = useTranslation();
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = yup.object({
    firstName: yup.string().required(t('Form.FormErrorNotBlank')),
    lastName: yup.string().required(t('Form.FormErrorNotBlank')),
    email: emailSchema(t),
    plainPassword: passwordSchema(t),
    permission: yup.bool().isTrue(t('Form.FormPermissionRequired')),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: prefilledEmail ?? '',
      plainPassword: '',
      permission: false,
    },
    validateOnChange: validateAfterSubmit,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!onSubmit) return;
      setIsLoading(true);
      const errors = await onSubmit(values);
      setIsLoading(false);
      formik.setStatus({ ...formik.status, errors });
    },
  });

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setValidateAfterSubmit(true);
      formik.handleSubmit();
    },
    [formik],
  );

  return (
    <form
      id="register-form"
      className="w-full max-w-lg m-auto"
      onSubmit={handleSubmit}
      noValidate={true}
      {...getTestProps(testId)}
    >
      <div className="grid grid-cols-2 gap-6 pb-6">
        <Input
          name="firstName"
          placeholder={t('Global.FirstName')}
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.errors.firstName}
          {...getTestProps(testId, 'firstName', 'testId')}
        />
        <Input
          name="lastName"
          placeholder={t('Global.LastName')}
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.errors.lastName}
          {...getTestProps(testId, 'lastName', 'testId')}
        />
      </div>
      <div className="flex flex-wrap space-y-6 mb-6">
        <Input
          name="email"
          placeholder={t('Global.Email')}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.status?.errors?.email ?? formik.errors.email}
          additionalInputClasses={prefilledEmail ? 'cursor-not-allowed' : ''}
          disabled={!!prefilledEmail}
          {...getTestProps(testId, 'email', 'testId')}
        />
        <Input
          name="plainPassword"
          type="password"
          placeholder={t('Global.Password')}
          value={formik.values.plainPassword}
          onChange={formik.handleChange}
          error={
            formik.status?.errors?.plainPassword ?? formik.errors.plainPassword
          }
          showPasswordInfo
          {...getTestProps(testId, 'password', 'testId')}
        />
        <Checkbox
          name="permission"
          label={perrmisionLabel()}
          checked={formik.values.permission}
          onChange={formik.handleChange}
          error={formik.errors.permission}
          additionalLabelClasses="text-sm text-gray-500"
          additionalCheckboxClasses="h-4 w-4"
          additionalErrorTextClasses="text-sm"
          {...getTestProps(testId, 'permission', 'testId')}
        />
      </div>
      <div className="w-full flex flex-col justify-center text-center">
        <Button
          buttonSize="lg"
          type="submit"
          additionalClasses="min-w-[300px] justify-center"
          disabled={isLoading}
          {...getTestProps(testId, 'submit', 'testId')}
        >
          {t('Global.CreateAnAccount')}
          <TailSpin
            height="15"
            width="20"
            color="white"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperClass="ml-4"
            visible={isLoading}
          />
        </Button>
        {formik?.status?.errors && (
          <div className="text-red py-2 inline-flex items-center">
            <WarningIcon className="h-5 w-5 mr-2" />
            <div className="block text-left">
              {formik.status.errors?.general}
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default RegisterForm;

RegisterForm.propTypes = {
  /**
   * On submit handler
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Default email form value
   */
  prefilledEmail: PropTypes.string,
  /**
   * Form test id
   */
  testId: PropTypes.string,
};

RegisterForm.defaultProps = {
  prefilledEmail: '',
  testId: '',
};
