import { useContext, useEffect, useState } from 'react';

// :: Context
import { AppContext } from '../contexts/AppContext';

// :: Utils
import { getLocalStorage, setLocalStorage } from '../utils/localStorage';

export default function useDarkMode() {
  const [darkMode, setDarkMode] = useState(
    getLocalStorage('flotiq-dark-theme', true) || false,
  );
  const { updateAppContext } = useContext(AppContext);

  useEffect(() => {
    const root = window.document.documentElement;

    if (darkMode) {
      root.classList.add('mode-dark');
    } else {
      root.classList.remove('mode-dark');
    }

    updateAppContext?.((prevState) => ({
      ...prevState,
      darkMode: darkMode,
    }));

    setLocalStorage('flotiq-dark-theme', darkMode);
  }, [darkMode, updateAppContext]);

  return [darkMode, setDarkMode];
}
