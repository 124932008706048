import { IFlotiqPluginEvent } from './IFlotiqPluginEvent';

/**
 * An event that is fired inside plugin page.
 * Returning object will be used to add more to plugin library.
 *
 * @memberof FlotiqPlugins
 * @event "flotiq.plugin.library::add"
 * @type {PluginAddLibraryEvent}
 * @returns {null|object}
 */

/**
 * @class
 * @memberof FlotiqPlugins
 * @name PluginAddLibraryEvent
 */
export class PluginAddLibraryEvent extends IFlotiqPluginEvent {}
