import Avatar from '../../components/Avatar/Avatar';
import {
  checkboxRenderer,
  dateRenderer,
  defaultRenderer,
  pillRenderer,
} from '../../components/DataGrid/DataGridCell/cellRenderer';

export const getDefinedColumns = (
  gridOptionsByID,
  hiddenColumnsById,
  editGrid,
  t,
  isAllUsers,
  testId,
) => ({
  email: {
    accessor: 'email',
    label: t('Global.Email'),
    width: gridOptionsByID?.['email']?.width,
    render: (data, rowData) => (
      <div className="flex items-center h-full font-normal">
        <span className="w-fit h-fit mr-2">
          <Avatar
            userInitials={rowData.firstName[0] + rowData.lastName[0]}
            testId={testId}
          />
        </span>
        <span className="truncate">{data}</span>
      </div>
    ),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.email,
  },
  firstName: {
    accessor: 'firstName',
    label: t('Global.FirstName'),
    width: gridOptionsByID?.['firstName']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.firstName,
  },
  lastName: {
    accessor: 'lastName',
    label: t('Global.LastName'),
    width: gridOptionsByID?.['lastName']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.lastName,
  },
  ...(isAllUsers
    ? {
        'organization.name': {
          accessor: 'organization.name',
          label: t('Users.Organization'),
          width: gridOptionsByID?.['organization.name']?.width,
          render: (data) => defaultRenderer(data),
          sortable: !editGrid,
          filterInputType: 'text',
          resizable: true,
          removable: true,
          hide: hiddenColumnsById['organization.name'],
        },
        roles: {
          accessor: 'roles',
          label: t('Users.Roles'),
          width: gridOptionsByID?.['roles']?.width,
          render: (data) =>
            defaultRenderer(
              data?.map((role) => role.replace('ROLE_', '')).join(', '),
            ),
          sortable: true,
          filterInputType: 'select',
          filterInputOptions: [
            {
              value: 'role_admin',
              label: 'ADMIN',
            },
            {
              value: 'role_headless_admin',
              label: 'HEADLESS_ADMIN',
            },
            {
              value: 'role_user',
              label: 'USER',
            },
            {
              value: 'role_headless_user',
              label: 'HEADLESS_USER',
            },
          ],
          multiple: true,
          resizable: true,
          removable: true,
          hide: hiddenColumnsById['roles'],
        },
        enabled: {
          accessor: 'enabled',
          label: t('Users.Enabled'),
          width: gridOptionsByID?.['enabled']?.width,
          render: (data) => checkboxRenderer(data),
          sortable: !editGrid,
          filterInputType: 'checkbox',
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.enabled,
        },
        source: {
          accessor: 'source',
          label: t('Users.Source'),
          width: gridOptionsByID?.['source']?.width,
          render: (data) => defaultRenderer(data),
          sortable: !editGrid,
          filterInputType: 'text',
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.source,
        },
        planName: {
          accessor: 'planName',
          label: t('Users.PlanName'),
          width: gridOptionsByID?.['planName']?.width,
          render: (data) => defaultRenderer(data),
          sortable: !editGrid,
          filterInputType: 'text',
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.planName,
        },
        'organization.paymentStatus': {
          accessor: 'organization.paymentStatus',
          label: t('Users.PaymentStatus'),
          width: gridOptionsByID?.['organization.paymentStatus']?.width,
          render: (data) => pillRenderer(data),
          sortable: !editGrid,
          filterInputType: 'text',
          resizable: true,
          removable: true,
          hide: hiddenColumnsById['organization.paymentStatus'],
        },
        'organization.paymentDueDate': {
          accessor: 'organization.paymentDueDate',
          label: t('Users.PaymentDueDate'),
          width: gridOptionsByID?.['organization.paymentDueDate']?.width,
          render: (data) => dateRenderer(data),
          sortable: !editGrid,
          filterInputType: 'text',
          resizable: true,
          removable: true,
          hide: hiddenColumnsById['organization.paymentDueDate'],
        },
        lastLogin: {
          accessor: 'lastLogin',
          label: t('Users.LastLogin'),
          width: gridOptionsByID?.['lastLogin']?.width,
          render: (data) => dateRenderer(data),
          sortable: !editGrid,
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.lastLogin,
        },
        createdAt: {
          accessor: 'createdAt',
          label: t('Users.Registered'),
          width: gridOptionsByID?.['createdAt']?.width,
          render: (data) => dateRenderer(data),
          sortable: !editGrid,
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.createdAt,
        },
      }
    : {}),
});
