import {
  useContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-hot-toast';

// :: Component

import Heading from '../../components/Heading/Heading';
import LinkButton from '../../components/LinkButton/LinkButton';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';
import RelationModalContent from '../../components/RelationModalContent/RelationModalContent';
import StatusBar from '../../components/StatusBar/StatusBar';
import CustomizableDataGrid from '../../components/CustomizableDataGrid/CustomizableDataGrid';

// :: Hooks
import useToken from '../../hooks/useToken';
import useOnce from '../../hooks/useOnce';
import {
  useContentObjects,
  useContentType,
  useWorkflowDefinition,
} from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import { useRelations } from '../../hooks/api/useRelations';

// :: Context
import { useModals } from '../../contexts/ModalContext';
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';

// :: Lib
import {
  capitalizeText,
  getObjectTitle,
  getTestProps,
  updateLocalStorageGridOptions,
  setInitialGridOptions,
  validateErrorMessage,
} from '../../lib/helpers';
import {
  batchDeleteContentObjects,
  getSearchObject,
  listContentTypes,
} from '../../lib/flotiq-client';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import { RolePermissions } from '../../lib/rolePermissions';

// :: Utils
import {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
} from '../../utils/localStorage';
import DataGridCell, {
  INPUT_TYPES_TO_RENDER,
} from '../../components/DataGrid/DataGridCell/DataGridCell';

// :: Images
import {
  ZoomMaleWorkingImage,
  WarningIcon,
  HouseIcon,
} from '../../images/shapes';
import DataGridControl from '../../components/DataGridControl/DataGridControl';

const DISPLAY_STATE = {
  LOADING_CTD: 0,
  EMPTY_CTD: 1,
  LOADING_DATA: 2,
  DATA: 3,
  EMPTY_FILTER: 4,
};

const ContentTypeObjects = ({
  allowInternals,
  testId,
  definiedTypeName,
  definiedColumns,
}) => {
  const { t } = useTranslation();
  const modal = useModals();
  const { permissions } = useContext(UserContext);
  const [searchParams] = useSearchParams();

  let { contentTypeName } = useParams();
  if (definiedTypeName) {
    contentTypeName = definiedTypeName;
  }
  const { updateAppContext } = useContext(AppContext);
  const sortingLocalStorageKey = `cms._sort_content-type-objects-${contentTypeName}`;
  const gridLocalStorageKey = `cms.content-type-objects-grid-state-v3-${contentTypeName}`;
  const dataGridContainer = useRef();

  const [firstLoading, setFirstLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [isDeleting, setDeleting] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);

  const jwt = useToken();
  const [sort, setSort] = useState(
    getLocalStorage(sortingLocalStorageKey, true),
  );
  const [filters, setFilters] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [editGrid, setEditGrid] = useState(false);
  const [filterGrid, setFilterGrid] = useState(false);
  const [initDataHasContent, setInitDataHasContent] = useState(false);

  // :: Grid Options
  const [gridOptions, setGridOptions] = useState(
    getLocalStorage(gridLocalStorageKey, true),
  );

  const hasFilters = useMemo(
    () =>
      filters
        ? Object.keys(filters).filter((key) => filters[key])?.length > 0
        : false,
    [filters],
  );

  const [relationsRawData, setRelationsRawData] = useState(null);
  const [relationsCurrentPage, setRelationsCurrentPage] = useState(1);

  const relationsParams = useMemo(
    () => ({
      page: relationsCurrentPage,
      limit: 10,
    }),
    [relationsCurrentPage],
  );

  const {
    data: relationsDict,
    errors: relationsErrors,
    isLoading: relationsAreLoading,
    pagination: relationsPagination,
  } = useRelations(relationsRawData, relationsParams);

  const handleRelationsPageChange = useCallback((page) => {
    setRelationsCurrentPage(page);
  }, []);

  const showRelationsModal = useCallback(async () => {
    await modal({
      id: 'relation-modal',
      content: (
        <>
          <RelationModalContent
            isLoading={relationsAreLoading}
            relationsData={relationsDict}
            testId={testId}
          />
          {relationsPagination?.total_pages > 1 && (
            <Pagination
              page={relationsCurrentPage}
              numOfPages={relationsPagination?.total_pages}
              onPageChange={handleRelationsPageChange}
              additionalClasses="!p-0 mt-4"
              {...getTestProps(testId, 'relation-pagination', 'testId')}
            />
          )}
        </>
      ),
      buttons: [
        {
          label: t('Global.Close'),
          key: 'Close',
        },
      ],
      testId: testId ? `${testId}-relations-modal` : '',
      size: 'lg',
      modalDialogAdditionalClasses: 'max-h-[calc(100vh-50px)]',
    });
    setRelationsRawData(null);
  }, [
    modal,
    relationsDict,
    t,
    testId,
    relationsAreLoading,
    relationsPagination?.total_pages,
    relationsCurrentPage,
    handleRelationsPageChange,
  ]);

  useEffect(() => {
    if (relationsDict) {
      showRelationsModal();
    } else {
      setRelationsCurrentPage(1);
    }
  }, [relationsDict, showRelationsModal]);

  useEffect(() => {
    setSort(getLocalStorage(sortingLocalStorageKey, true));
  }, [sortingLocalStorageKey]);

  useEffect(() => {
    setGridOptions(getLocalStorage(gridLocalStorageKey, true));
  }, [gridLocalStorageKey]);

  const clearPage = useCallback(() => {
    setCurrentPage(1);
    setLimit(20);
    setCheckedRows([]);
    setFilters();
    setEditGrid(false);
    setFilterGrid(false);
    setInitDataHasContent(false);
  }, []);

  const handleFilters = useCallback(
    (filters) => {
      if (filters && Object.keys(filters).length) {
        let res = {};

        for (let key in filters) {
          const { type, value } = filters[key];

          let currentFilterType = type;

          if (!currentFilterType) {
            currentFilterType =
              definiedTypeName === '_webhooks' && key === 'type'
                ? 'equals'
                : 'contains';
          }

          res[key] = { type: currentFilterType, filter: value };
        }

        return JSON.stringify(res);
      } else {
        return;
      }
    },
    [definiedTypeName],
  );

  const objectParams = useMemo(() => {
    const validateFilters = handleFilters(filters);

    return {
      page: currentPage,
      limit: limit,

      ...(sort?.sortOrder ? { order_direction: sort?.sortOrder } : {}),
      ...(sort?.sortField ? { order_by: sort?.sortField } : {}),

      ...(validateFilters ? { filters: validateFilters } : {}),
    };
  }, [
    currentPage,
    filters,
    limit,
    sort?.sortField,
    sort?.sortOrder,
    handleFilters,
  ]);

  const {
    entity: contentType,
    errors: contentTypeErrors,
    status: contentTypeStatus,
  } = useContentType(contentTypeName);

  const { data, errors, isLoading, pagination, reload } = useContentObjects(
    contentTypeName,
    objectParams,
  );

  const notAllowed = useMemo(() => {
    if (definiedTypeName)
      return allowInternals && !permissions.canCo(definiedTypeName);
    return contentType?.internal || !permissions.canCo(contentTypeName);
  }, [
    allowInternals,
    contentType?.internal,
    contentTypeName,
    definiedTypeName,
    permissions,
  ]);

  const label = useMemo(() => contentType?.label || '', [contentType]);

  useApiErrorsToast(errors);
  useApiErrorsToast(contentTypeErrors);
  useApiErrorsToast(relationsErrors);

  useEffect(() => {
    if (pagination?.total_pages < currentPage)
      setCurrentPage(pagination.total_pages);
  }, [currentPage, pagination?.total_pages]);

  const handleGetRelations = useCallback(
    (data) => setRelationsRawData(data),
    [],
  );

  const handleInitialGridOptions = useCallback(
    (cols, minWidth, contentType, gridLocalStorageKey) => {
      setInitialGridOptions(
        cols,
        minWidth,
        dataGridContainer,
        setGridOptions,
        setColumns,
        gridLocalStorageKey,
        true,
        contentType,
      );
    },
    [],
  );

  const handlePageUpdate = useCallback(() => {
    if (!label) return;

    let buttonAddLabel = t('ContentForm.Add', { contentTypeName: label });
    let buttonAddPath = `/content-type-objects/add/${contentTypeName}`;
    let currentHeading = t('ObjectsOfType.Title', { contentTypeName: label });
    let currentPage = `content/${label || contentTypeName}`;
    let currentMenuItemOpen = 'content';

    const favoritesParam = searchParams.has('favorites');
    if (favoritesParam) {
      currentMenuItemOpen = 'favorites';
    }

    if (definiedTypeName === '_webhooks') {
      currentHeading = t('Global.Webhooks');
      buttonAddLabel = t('Global.AddNew', { name: 'Webhook' });
      buttonAddPath = '/webhooks/add';
      currentPage = 'webhooks';
      currentMenuItemOpen = '';
    }

    let currentButtons = [];

    !definiedTypeName &&
      permissions.canCtd(
        contentTypeName,
        RolePermissions.PERMISSIONS_TYPES.UPDATE,
      ) &&
      currentButtons.push({
        label: t('ContentDefinition.Edit'),
        link: `/content-type-definitions/edit/${contentTypeName}`,
        color: 'blueBordered',
        key: 'Edit',
      });

    permissions.canCo(
      contentTypeName,
      RolePermissions.PERMISSIONS_TYPES.CREATE,
    ) &&
      currentButtons.push({
        label: buttonAddLabel,
        link: buttonAddPath,
        key: 'Add',
      });

    updateAppContext?.((prevState) => ({
      ...prevState,
      page: currentPage,
      menuItemOpen: currentMenuItemOpen,
      topBar: {
        heading: currentHeading,
        buttons: currentButtons,
        testId: testId,
      },
    }));

    clearPage();
  }, [
    label,
    t,
    contentTypeName,
    definiedTypeName,
    permissions,
    updateAppContext,
    clearPage,
    testId,
    searchParams,
  ]);

  useOnce(handlePageUpdate);

  const handleFetchDatasource = useCallback(
    async (query, { relationContenttype }) => {
      try {
        const { body: Objects, status: ObjectsStatus } = await getSearchObject(
          jwt,
          {
            q: query ? `*${query}*` : '*',
            limit: 10,
            order_by: 'internal.updatedAt',
            order_direction: 'asc',
            page: 1,
            'fields[]': 'object_data',
            ...(relationContenttype && {
              'content_type[]': relationContenttype,
            }),
          },
        );

        checkResponseStatus(Objects, ObjectsStatus);

        const ctdsName = [
          ...new Set(Objects.data.map((el) => el.item.internal.contentType)),
        ];

        const ctdDict = {};

        ctdsName.forEach(async (el) => {
          const { body: ctd, status } = await listContentTypes(jwt, {
            name: el,
          });
          checkResponseStatus(ctd, status);
          ctdDict[el] = ctd;
        });

        return Objects.data.map((el) => ({
          value: el?.item?.internal?.contentType
            ? `/api/v1/content/${el.item.internal.contentType}/${el.item.id}`
            : el.item.id,
          label: getObjectTitle(el.item, ctdDict[el.item.internal.contentType]),
        }));
      } catch (error) {
        toast.error(error.message);
      }
    },
    [jwt],
  );

  useEffect(() => {
    // Remove toasts on change content type
    toast.dismiss();

    // Remove toasts on exit
    return () => {
      toast.dismiss();
    };
  }, [contentType, contentTypeName]);

  const handleDeleteObjetcs = useCallback(
    async (checkedRows) => {
      setDeleting(true);

      // Remove previously added infinity toasts on errors
      toast.dismiss();

      if (checkedRows.length > 10) {
        modal.info(
          t('Global.Deleting'),
          t('Global.DeletingMany'),
          'delete-modal',
        );
      } else {
        modal.deleting('delete-modal');
      }

      try {
        const { body, status } = await batchDeleteContentObjects(
          jwt,
          Object.assign(checkedRows, {
            contentTypeName,
          }),
        );
        checkResponseStatus(body, status);
        toast.success(
          t('ObjectsOfType.DeletedCount', {
            deletedCount: body.deletedCount,
          }),
        );
        setCheckedRows([]);
        reload?.();
        setDeleting(false);
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(
            error.message
              ? validateErrorMessage(error.message)
              : t('ContentForm.CouldntDelete'),
            {
              duration: Infinity,
            },
          );
        }
        setCheckedRows([]);
        reload?.();
        setDeleting(false);
      }
    },
    [contentTypeName, modal, jwt, reload, t],
  );

  const showDeleteModal = useCallback(() => {
    let currentConfirmDeleteText = t('ObjectsOfType.ConfirmDelete', {
      count: checkedRows.length,
    });

    if (definiedTypeName === '_webhooks') {
      currentConfirmDeleteText = t('ObjectsOfType.ConfirmDeleteWebhooks', {
        count: checkedRows.length,
      });
    }

    modal.delete(currentConfirmDeleteText, 'delete-modal', () =>
      handleDeleteObjetcs(checkedRows),
    );
  }, [checkedRows, modal, definiedTypeName, handleDeleteObjetcs, t]);

  const showDeleteOneModal = useCallback(
    async (rowData) => {
      let currentConfirmDeleteText = t('ObjectsOfType.ConfirmDelete', {
        count: 1,
      });

      if (definiedTypeName === '_webhooks') {
        currentConfirmDeleteText = t('ObjectsOfType.ConfirmDeleteWebhooks', {
          count: 1,
        });
      }

      modal.delete(currentConfirmDeleteText, 'delete-modal', () =>
        handleDeleteObjetcs([rowData.id]),
      );
    },
    [handleDeleteObjetcs, modal, definiedTypeName, t],
  );

  const { entity: workflowDefinition } = useWorkflowDefinition(
    contentType?.workflowId,
  );

  useEffect(() => {
    if (!contentType) return;
    if (contentTypeName !== contentType.name) return;

    const cols = [];

    const dataColumns = contentType.metaDefinition?.propertiesConfig || {};
    const currentLocalGridOption = getLocalStorage(gridLocalStorageKey, true);
    const gridOptionsByID = gridOptions?.reduce((acc, elem, idx) => {
      acc[elem.colId] = { ...elem, idx };
      return acc;
    }, {});

    // Case: generate state of hide based on currentLocalGridOption
    const hiddenColumnsById = {};
    const orderFromLocalStorage = [];

    for (let key in currentLocalGridOption) {
      hiddenColumnsById[currentLocalGridOption[key].colId] =
        currentLocalGridOption[key].hide;

      orderFromLocalStorage.push(currentLocalGridOption[key].colId);
    }

    let order = definiedColumns || contentType.metaDefinition?.order || [];
    order = [
      ...new Set([
        ...(orderFromLocalStorage || []),
        'id',
        ...order,
        ...(contentType.workflowId !== 'generic'
          ? ['internal.workflowState', 'internal.workflowPublishedAt']
          : []),
        ...(typeof contentType.schemaDefinition?.allOf !== 'undefined' &&
        contentType.schemaDefinition.allOf[0]['$ref'] ===
          '#/components/schemas/AbstractContentTypeSchemaDefinition'
          ? ['internal.createdAt', 'internal.updatedAt']
          : []),
      ]),
    ];

    if (orderFromLocalStorage.length) {
      updateLocalStorageGridOptions(order, gridLocalStorageKey, setGridOptions);
    }

    const idColumnOption = {
      accessor: 'id',
      label: 'ID',
      filterInputType: 'text',
      sortable: !editGrid,
      resizable: true,
      removable: true,
      showByDefault: true,
      width: gridOptionsByID?.['id']?.width,
      hide: hiddenColumnsById['id'],
      render: (data, rowData) => (
        <DataGridCell
          inputType="text"
          data={data}
          contentTypeName={contentTypeName}
          accessor="id"
          contentObject={rowData}
          contentType={contentType}
        />
      ),
    };

    const states =
      Object.values(workflowDefinition?.places || {}).map((place) => {
        return {
          value: place,
          label: t(
            `ObjectStatus.Option${capitalizeText(place)}`,
            capitalizeText(place),
          ),
        };
      }) || [];

    const workflowStateColumn = {
      accessor: 'internal.workflowState',
      label: 'Status',
      filterInputType: 'select',
      filterInputOptions: states,
      sortable: !editGrid,
      resizable: true,
      removable: true,
      showByDefault: true,
      width: gridOptionsByID?.['internal.workflowState']?.width,
      hide: hiddenColumnsById['internal.workflowState'],
      render: (data, rowData) => (
        <DataGridCell
          inputType="select"
          data={t(
            `ObjectStatus.Option${capitalizeText(data)}`,
            capitalizeText(data),
          )}
          onClickCallback={handleGetRelations}
          color={data}
          contentTypeName={contentTypeName}
          accessor="internal.workflowState"
          contentObject={rowData}
          contentType={contentType}
        />
      ),
    };

    const workflowPublishedAtColumn = {
      accessor: 'internal.workflowPublishedAt',
      label: t('ObjectStatus.Published'),
      filterInputType: 'select',
      filterInputOptions: [
        {
          value: '-',
          label: t('Global.True'),
        },
      ],
      sortable: !editGrid,
      resizable: true,
      removable: true,
      width: gridOptionsByID?.['internal.workflowPublishedAt']?.width,
      hide: hiddenColumnsById['internal.workflowPublishedAt'],
      render: (data, rowData) => (
        <DataGridCell
          inputType="datePublished"
          data={data}
          onClickCallback={handleGetRelations}
          contentTypeName={contentTypeName}
          accessor="internal.workflowPublishedAt"
          contentObject={rowData}
          contentType={contentType}
        />
      ),
    };

    const createdAtColumn = {
      accessor: 'internal.createdAt',
      label: t('ObjectsOfType.Created'),
      filterInputType: 'text',
      sortable: !editGrid,
      resizable: true,
      removable: true,
      width: gridOptionsByID?.['internal.createdAt']?.width,
      hide: hiddenColumnsById['internal.createdAt'],
      render: (data, rowData) => (
        <DataGridCell
          inputType="text"
          data={data}
          contentTypeName={contentTypeName}
          accessor="internal.createdAt"
          contentObject={rowData}
          contentType={contentType}
        />
      ),
    };

    const updatedAtColumn = {
      accessor: 'internal.updatedAt',
      label: t('ObjectsOfType.Modified'),
      filterInputType: 'text',
      sortable: !editGrid,
      resizable: true,
      removable: true,
      width: gridOptionsByID?.['internal.updatedAt']?.width,
      hide: hiddenColumnsById['internal.updatedAt'],
      render: (data, rowData) => (
        <DataGridCell
          inputType="text"
          data={data}
          contentTypeName={contentTypeName}
          accessor="internal.updatedAt"
          contentObject={rowData}
          contentType={contentType}
        />
      ),
    };

    order.forEach((accessor) => {
      if (accessor === 'id') {
        cols.push(idColumnOption);
      } else if (accessor === 'internal.workflowState')
        cols.push(workflowStateColumn);
      else if (accessor === 'internal.workflowPublishedAt')
        cols.push(workflowPublishedAtColumn);
      else if (accessor === 'internal.createdAt') cols.push(createdAtColumn);
      else if (accessor === 'internal.updatedAt') cols.push(updatedAtColumn);
      else if (
        INPUT_TYPES_TO_RENDER.includes(dataColumns[accessor]?.inputType)
      ) {
        const label = dataColumns[accessor]?.label || accessor;
        let currentFilterInputType = dataColumns[accessor]?.inputType;

        if (definiedTypeName === '_webhooks' && accessor === 'actions') {
          currentFilterInputType = 'webhookActions';
        }

        cols.push({
          accessor: accessor,
          label,
          filterInputType: currentFilterInputType,
          filterInputOptions: dataColumns[accessor]?.options,
          filterValidation: dataColumns[accessor]?.validation,
          filterDatasourceFetch: (query) =>
            handleFetchDatasource(query, dataColumns[accessor]?.validation),
          sortable: !editGrid,
          resizable: true,
          removable: true,
          width: gridOptionsByID?.[accessor]?.width,
          hide: hiddenColumnsById[accessor],
          render: (data, rowData) => (
            <DataGridCell
              inputType={currentFilterInputType}
              data={data}
              onClickCallback={handleGetRelations}
              items={dataColumns[accessor]?.items}
              contentTypeName={contentTypeName}
              accessor={accessor}
              contentObject={rowData}
              contentType={contentType}
              testId={testId}
            />
          ),
        });
      }
    });

    cols.push({
      accessor: '_actions',
      label: '',
      sortable: false,
      minWidth: 50,
      notDraggable: true,
      flexGrow: 0,
      fixed: 'right',
      justify: 'center',
      width: gridOptionsByID?.['_actions']?.width,
      hide: hiddenColumnsById['_actions'],
      render: (_, rowData) => (
        <DataGridCell
          inputType="_actions"
          data={rowData}
          onClickCallback={showDeleteOneModal}
          contentTypeName={contentTypeName}
          accessor="_actions"
          contentObject={rowData}
          contentType={contentType}
        />
      ),
    });

    if (!gridOptions) {
      handleInitialGridOptions(cols, 100, contentType, gridLocalStorageKey);
    } else {
      setColumns(cols);
    }
  }, [
    contentType,
    handleGetRelations,
    handleFetchDatasource,
    contentTypeName,
    editGrid,
    t,
    handleInitialGridOptions,
    gridOptions,
    gridLocalStorageKey,
    definiedColumns,
    showDeleteModal,
    showDeleteOneModal,
    workflowDefinition?.places,
    definiedTypeName,
    testId,
    permissions,
  ]);

  const handleResetGrid = useCallback(() => {
    setSort();
    setCurrentPage(1);
    setLimit(20);
    setCheckedRows([]);
    removeLocalStorage(`cms._sort_content-type-objects-${contentTypeName}`);
    setFilters({});
    removeLocalStorage(gridLocalStorageKey);
    setGridOptions();
  }, [contentTypeName, gridLocalStorageKey]);

  useEffect(() => {
    setFirstLoading(true);
  }, [contentTypeName]);

  useEffect(() => {
    if (firstLoading) {
      setInitDataHasContent(false);
    }
    if (!isLoading) setFirstLoading(false);
  }, [isLoading, firstLoading]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleLimitChange = useCallback((resultsLimit) => {
    setLimit(resultsLimit);
    setCurrentPage(1);
  }, []);

  const handleCheckRows = useCallback((checked) => setCheckedRows(checked), []);

  useEffect(() => {
    if (data.length && !initDataHasContent) {
      setInitDataHasContent(true);
    }
  }, [data.length, initDataHasContent]);

  const displayLevel = useMemo(() => {
    if (firstLoading) return DISPLAY_STATE.LOADING_CTD;
    if (
      (!data.length && !hasFilters && !initDataHasContent) ||
      contentTypeStatus === 404 ||
      notAllowed
    ) {
      return DISPLAY_STATE.EMPTY_CTD;
    }
    if (isLoading || isDeleting) return DISPLAY_STATE.LOADING_DATA;
    if (!data.length && hasFilters) return DISPLAY_STATE.EMPTY_FILTER;
    return DISPLAY_STATE.DATA;
  }, [
    firstLoading,
    data.length,
    hasFilters,
    initDataHasContent,
    contentTypeStatus,
    notAllowed,
    isLoading,
    isDeleting,
  ]);

  const emptyData = useMemo(() => {
    if (contentTypeStatus === 404 || notAllowed)
      return (
        <Heading
          level={2}
          additionalClasses={twMerge(
            'text-3xl md:text-4xl leading-8 dark:text-white',
          )}
        >
          <div
            className="flex flex-col items-center justify-center text-center"
            {...getTestProps(testId, 'not-existing')}
          >
            <WarningIcon className="text-red w-14 md:w-20 mb-3" />
            {t('ObjectsOfType.NotExisting', { contentTypeName })}
          </div>
        </Heading>
      );
    if (displayLevel === DISPLAY_STATE.LOADING_CTD)
      return (
        <Loader
          size={'small'}
          type={'spinner-grid'}
          testId={testId ? `${testId}-loading` : ''}
        />
      );

    let buttonAddPath = `/content-type-objects/add/${contentTypeName}`;
    let buttonAddLabel = t('ObjectsOfType.Add');

    if (definiedTypeName === '_webhooks') {
      buttonAddPath = '/webhooks/add';
      buttonAddLabel = t('Global.AddNew', { name: 'Webhook' });
    }

    return (
      <>
        <ZoomMaleWorkingImage className="w-52" />
        <div
          className="text-center font-bold p-5"
          {...getTestProps(testId, 'empty-data')}
        >
          <p className="text-blue-600 text-2xl sm:text-3xl md:text-4xl">
            {t('ContentDefinition.CreateFirst')}
          </p>
          <p className="text-indigo-950 dark:text-white 0 text-3xl sm:text-4xl md:text-5xl">
            {t('ObjectsOfType.ObjectOfType', {
              contentTypeName: label,
            })}
          </p>
        </div>
        <LinkButton
          buttonSize="base"
          additionalClasses="mt-1 md:mt-2"
          link={buttonAddPath}
          {...getTestProps(testId, 'add', 'testId')}
        >
          {buttonAddLabel}
        </LinkButton>
      </>
    );
  }, [
    contentTypeStatus,
    notAllowed,
    testId,
    t,
    contentTypeName,
    displayLevel,
    definiedTypeName,
    label,
  ]);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: '/',
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Dashboard',
        },
        {
          label:
            definiedTypeName === '_webhooks'
              ? t('Global.Webhooks')
              : t('ObjectsOfType.Title', { contentTypeName: label }),
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
          key: 'Title',
        },
      ],
    }));
  }, [updateAppContext, label, contentTypeName, t, definiedTypeName]);

  const handleVisibleColumnsChange = useCallback(
    (options) => {
      setLocalStorage(gridLocalStorageKey, options);
      setGridOptions(options);
    },
    [gridLocalStorageKey],
  );

  return (
    <div className="w-full h-[calc(100vh-120px)] lg:h-[calc(100vh-100px)]">
      <Helmet>
        <title>{t('ObjectsOfType.Title', { contentTypeName: label })}</title>
      </Helmet>
      <div className="flex flex-col h-full w-full">
        <div
          className={twMerge(
            'flex flex-wrap sm:flex-nowrap items-center justify-between relative',
            'px-3 lg:px-7 py-2 sm:py-0 h-auto sm:h-11 w-full z-10 bg-slate-50 border-b gap-3 sm:gap-0',
            'dark:bg-gray-900 dark:border-b dark:border-slate-800',
          )}
        >
          <DataGridControl
            displayGridCheckboxes={
              columns.length > 0 && displayLevel >= DISPLAY_STATE.LOADING_DATA
            }
            displayGridControl={
              displayLevel >= DISPLAY_STATE.EMPTY_CTD &&
              displayLevel >= DISPLAY_STATE.LOADING_DATA
            }
            editGrid={editGrid}
            filterGrid={filterGrid}
            setEditGrid={setEditGrid}
            setFilterGrid={setFilterGrid}
            handleResetGrid={handleResetGrid}
            limit={limit}
            handleLimitChange={handleLimitChange}
            columns={columns}
            gridOptions={gridOptions}
            handleVisibleColumnsChange={handleVisibleColumnsChange}
            columnsVisibilityAdditionalClasses="bg-slate-50 w-fit"
            testId={testId}
          />
        </div>
        <div className="relative px-5 lg:px-7 pt-7 w-full h-full">
          <div
            className="bg-white dark:bg-slate-950 w-full h-full rounded-lg"
            ref={dataGridContainer}
          >
            {displayLevel >= DISPLAY_STATE.LOADING_DATA ? (
              <div className="h-[calc(100%-40px)] md:h-[calc(100%-72px)]">
                <CustomizableDataGrid
                  columns={columns}
                  setColumns={setColumns}
                  data={data}
                  isLoading={displayLevel === DISPLAY_STATE.LOADING_DATA}
                  setSort={setSort}
                  sort={sort?.sortField || undefined}
                  sortOrder={sort?.sortOrder || undefined}
                  sortingLocalStorageKey={sortingLocalStorageKey}
                  setCurrentPage={setCurrentPage}
                  optionsLocalStorageKey={gridLocalStorageKey}
                  editGrid={editGrid}
                  gridOptions={gridOptions}
                  setGridOptions={setGridOptions}
                  checkedRows={checkedRows}
                  onCheckRows={handleCheckRows}
                  statusBar={
                    <StatusBar
                      rows={pagination.count}
                      checkedRows={checkedRows}
                      handleDeleteClick={showDeleteModal}
                      deleteDisabled={checkedRows.length > 100}
                      currentPage={currentPage}
                      pagesCount={pagination.total_pages}
                      handlePageChange={handlePageChange}
                      handleDataUpdate={reload}
                      compactForSmall
                      resultsFrom={(pagination.current_page - 1) * limit + 1}
                      resultsTo={
                        (pagination.current_page - 1) * limit + pagination.count
                      }
                      resultsTotalCount={pagination.total_count}
                      disabled={isDeleting}
                      {...getTestProps(testId, 'statusbar', 'testId')}
                    />
                  }
                  noDataInfoText={t('ObjectsOfType.NoMatchingData')}
                  loadingDataInfoText={
                    isDeleting
                      ? t('ObjectsOfType.DeletingCount', {
                          deletingCount: checkedRows.length,
                        })
                      : ''
                  }
                  hasFilters={filterGrid}
                  disableFilters={editGrid || isDeleting}
                  filters={filters}
                  onFilter={(currentFilters) => {
                    setFilters(currentFilters);
                    setCurrentPage(1);
                  }}
                  additionalClasses={twMerge(
                    isDeleting && 'opacity-50 pointer-events-none',
                  )}
                  hasSelectColumn
                  contentType={contentType}
                  {...getTestProps(testId, 'grid', 'testId')}
                />
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-full">
                {emptyData}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTypeObjects;

ContentTypeObjects.propTypes = {
  /**
   * Information if we want to allow internals
   */
  allowInternals: PropTypes.bool,
  /**
   * Definied columns when we want to specify them
   */
  definiedColumns: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.string,
  ]),
  /**
   * Definied content type name
   */
  definiedTypeName: PropTypes.string,
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

ContentTypeObjects.defaultProps = {
  allowInternals: false,
  definiedColumns: '',
  definiedTypeName: '',
  testId: '',
};
