import {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik, getIn } from 'formik';
import * as yup from 'yup';
import { twMerge } from 'tailwind-merge';
import moment from 'moment';

// :: Components
import Button from '../../components/Button/Button';
import ContentObjectInformations from '../../components/ContentObjectInformations/ContentObjectInformations';
import ContentObjectVersions from '../../components/ContentObjectVersions/ContentObjectVersions';
import ContentObjectLogs from '../../components/ContentObjectLogs/ContentObjectLogs';
import Dropdown from '../../components/Dropdown/Dropdown';
import Input from '../../components/Input/Input';
import Loader from '../../components/Loader/Loader';
import RequiredTemplate from '../../components/RequiredTemplate/RequiredTemplate';
import Switch from '../../components/Switch/Switch';

// :: Components Sections
import SectionActions from './Sections/Actions';
import SectionHeaders from './Sections/Headers';

// :: Context
import AppContext from '../../contexts/AppContext';
import { useModals } from '../../contexts/ModalContext';

// :: Lib client
import {
  getWebhooks,
  postWebhooks,
  putWebhooks,
  deleteWebhooks,
  versionWebhooks,
  getContentObjectVersion,
  logsWebhooks,
  listContentTypes,
} from '../../lib/flotiq-client';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';

// :: Lib helpers
import { getTestProps, isModuleEnabled } from '../../lib/helpers';

// :: Hooks
import useToken from '../../hooks/useToken';
import useOnce from '../../hooks/useOnce';
import { useContentTypes } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import UserContext from '../../contexts/UserContext';
import { HouseIcon } from '../../images/shapes';

const AddWebhooks = ({ mode, duplicate, testId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateAppContext } = useContext(AppContext);
  const { permissions } = useContext(UserContext);

  const { canCreate, canDelete, canUpdate } = useMemo(
    () => permissions.getCoPermissions('_webhooks') || {},
    [permissions],
  );

  const jwt = useToken();
  let { id } = useParams();
  const modal = useModals();
  const refSubmitButton = useRef();

  const [webhookVersions, setWebhookVersions] = useState([]);
  const [webhookLogs, setWebhookLogs] = useState([]);
  const [webhookLogsLoading, setWebhookLogsLoading] = useState(false);
  const [webhookLoading, setWebhookLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const validationSchema = yup.object({
    name: yup.string().min(1).required('Must be at least 1 characters long'),
    url: yup.string().min(1).required('Must be at least 1 characters long'),
    type: yup.string().required(t('Form.FormErrorNotBlank')),
    contentTypeDefinitions: yup.array().when('type', {
      is: 'ctd',
      then: yup.array().min(0),
      otherwise: yup
        .array()
        .min(1, t('PropertyForm.Errors.OptionsLength'))
        .required(t('PropertyForm.Errors.OptionsLength')),
    }),
    actions: yup.array().when('type', {
      is: 'ctd',
      then: yup.array().min(0),
      otherwise: yup
        .array()
        .min(1, t('PropertyForm.Errors.OptionsLength'))
        .required(t('PropertyForm.Errors.OptionsLength')),
    }),
    headers: yup.array().of(
      yup.object().shape({
        header_name: yup
          .string()
          .min(1)
          .required('Must be at least 1 characters long'),
        header_value: yup
          .string()
          .min(1)
          .required('Must be at least 1 characters long'),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      url: '',
      contentTypeDefinitions: [],
      actions: [],
      headers: [],
      enabled: false,
      type: 'async',
    },
    validateOnChange: validateAfterSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsLoading(true);
      const errors = await handleSave(values);
      formik.setStatus({ ...formik.status, errors });
      setIsLoading(false);
    },
  });

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setValidateAfterSubmit(true);
      formik.handleSubmit();
      setValidateAfterSubmit(false);
    },
    [formik],
  );

  const handleDeleteWebhook = useCallback(async () => {
    const result = await modal.confirmation(
      t('Webhooks.DeleteModalText'),
      t('Global.Warning'),
      t('Global.Delete'),
      t('Global.Cancel'),
      'warning',
      { size: 'lg' },
    );

    if (result) {
      try {
        const { body, status } = await deleteWebhooks(jwt, { id });
        checkResponseStatus(body, status);

        navigate('/webhooks');
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Webhooks.Error'));
        } else {
          toast.error(error.message);
        }
      }
    }
  }, [navigate, modal, jwt, t, id]);

  const topBarButtons = useMemo(() => {
    const buttons = [
      {
        key: 'cancel',
        label: t('Global.Cancel'),
        onClick: () => navigate('/webhooks'),
        color: 'gray',
        ...getTestProps(testId, 'topbar-button-cancel', 'testId'),
      },
    ];

    if (mode === 'edit' && !duplicate && canDelete) {
      buttons.unshift({
        key: 'delete',
        label: t('Global.Delete'),
        color: 'redBordered',
        onClick: () => handleDeleteWebhook(),
        ...getTestProps(testId, 'topbar-button-delete', 'testId'),
      });
    }

    if ((canUpdate && id) || (canCreate && !id)) {
      buttons.unshift({
        key: 'save',
        label: t('Global.Save'),
        onClick: () => refSubmitButton.current.click(),
        iconImage: isLoading && <Loader size="small" type="spinner-grid" />,
        ...getTestProps(testId, 'topbar-button-save', 'testId'),
      });
    }

    return buttons;
  }, [
    t,
    testId,
    mode,
    duplicate,
    canDelete,
    canUpdate,
    id,
    canCreate,
    navigate,
    handleDeleteWebhook,
    isLoading,
  ]);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: `webhooks`,
      topBar: {
        heading: `${
          mode === 'add' ? t('Global.Add') : t('Global.Edit')
        } Webhook`,
        buttons: topBarButtons,
      },
    }));
  }, [updateAppContext, mode, topBarButtons, t]);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: '/',
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Dashboard',
        },
        {
          label: t('Global.Webhooks'),
          link: `/webhooks`,
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Title',
        },
        {
          label: `${
            mode === 'add' ? t('Global.Add') : t('Global.Edit')
          } Webhook`,
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
          key: 'Add',
        },
      ],
    }));
  }, [topBarButtons, t, updateAppContext, mode]);

  const handleUpdateFormik = useCallback(
    (body, id) => {
      formik.setValues({
        ...body,
        actions: body?.actions?.map((el) => el.action),
        contentTypeDefinitions: body?.content_type_definitions?.map(
          (el) => el.content_type_definition_name,
        ),
        headers: body?.headers
          ? body?.headers?.map((el, index) => ({
              ...el,
              id: `#hid-${id}-${index}`,
            }))
          : [],
      });
    },
    // eslint-disable-next-line
    [],
  );

  const handleFetchWebhook = useCallback(async () => {
    if (mode === 'edit') {
      setWebhookLoading(true);
      try {
        const { body, status } = await getWebhooks(jwt, { id });

        checkResponseStatus(body, status);
        handleUpdateFormik(body, id);
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Webhooks.FetchError'));
        } else {
          toast.error(error.message);
        }
        navigate('/webhooks');
      }
      setWebhookLoading(false);
    }
  }, [mode, handleUpdateFormik, navigate, jwt, t, id]);

  useOnce(handleFetchWebhook);

  const handleFetchVersion = useCallback(async () => {
    if (mode === 'edit' && !duplicate) {
      try {
        const { body, status } = await versionWebhooks(jwt, {
          id,
          limit: 1000,
        });
        checkResponseStatus(body, status);

        const sortedByVersion = body.data.sort((a, b) => {
          if (a.version > b.version) {
            return -1;
          }
          if (a.version < b.version) {
            return 1;
          }
          return 0;
        });

        setWebhookVersions(sortedByVersion);
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Webhooks.Error'));
        } else {
          toast.error(error.message);
        }
      }
    }
  }, [mode, duplicate, jwt, t, id]);

  useOnce(handleFetchVersion);

  const handleFetchLogs = useCallback(async () => {
    if (mode === 'edit' && !duplicate) {
      setWebhookLogsLoading(true);

      try {
        const { body, status } = await logsWebhooks(jwt, { id });

        checkResponseStatus(body, status);

        setWebhookLogs(body.data);
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Webhooks.Error'));
        } else {
          toast.error(error.message);
        }
      }

      setWebhookLogsLoading(false);
    }
  }, [mode, duplicate, jwt, t, id]);

  useOnce(handleFetchLogs);
  const isWebhookTypeCtd = useMemo(
    () => formik?.values?.type === 'ctd',
    [formik],
  );

  const handleSave = useCallback(
    async (values) => {
      let updateShape = {
        name: values?.name,
        url: values?.url,
        enabled: values?.enabled,
        type: values?.type,
        headers: values?.headers?.map((el) => {
          const { id, ...rest } = el;
          return {
            ...rest,
          };
        }),
        ...(!isWebhookTypeCtd
          ? {
              actions: values?.actions?.map((action) => {
                return { action };
              }),
              content_type_definitions: values?.contentTypeDefinitions?.map(
                (content_type_definition_name) => {
                  return { content_type_definition_name };
                },
              ),
            }
          : { actions: [], content_type_definitions: {} }),
      };
      if (mode === 'edit' && !duplicate) {
        try {
          const { body, status } = await putWebhooks(jwt, {
            ...updateShape,
            id,
          });
          checkResponseStatus(body, status);

          toast.success(`${t('Webhooks.SavedOnSucces')}`);
          navigate('/webhooks');
        } catch (error) {
          if (!(error instanceof ResponseError)) {
            toast.error(t('Webhooks.SavedOnError'));
          } else {
            toast.error(error.message);
          }
        }
      } else {
        try {
          const { body, status } = await postWebhooks(jwt, updateShape);
          checkResponseStatus(body, status);

          toast.success(`${t('Webhooks.AddedOnSucces')}`);
          navigate('/webhooks');
        } catch (error) {
          if (!(error instanceof ResponseError)) {
            toast.error(t('Webhooks.SavedOnError'));
          } else {
            toast.error(error.message);
          }
        }
      }
    },
    [isWebhookTypeCtd, mode, duplicate, jwt, id, t, navigate],
  );

  const handleChangeActions = useCallback(
    (e) => {
      if (e.target.value) {
        formik.setFieldValue('actions', [
          ...formik.values.actions,
          e.target.name,
        ]);
      } else {
        formik.setFieldValue(
          'actions',
          formik.values.actions.filter((el) => el !== e.target.name),
        );
      }
    },
    [formik],
  );

  // :: Content Type Data
  const ctdParams = useMemo(
    () => ({
      limit: 1000,
      order_by: 'label',
      order_direction: 'asc',
      page: 1,
    }),
    [],
  );

  const {
    data: contentTypes,
    isLoading: isLoadingCT,
    errors: ctdErrors,
  } = useContentTypes(ctdParams);

  useApiErrorsToast(ctdErrors);

  const contentTypesOptions = useMemo(() => {
    if (!contentTypes) return;

    return contentTypes
      .filter(
        (el) => !(el.internal && el.name !== '_tag' && el.name !== '_media'),
      )
      .map((el) => {
        return {
          value: el.name,
          label: el.label,
        };
      });
  }, [contentTypes]);

  const typeOptions = useMemo(() => {
    return [
      {
        value: 'async',
        label: t('Webhooks.TypeOptions.Asynchronous'),
      },
      isModuleEnabled('WEBHOOKS_TYPE') && {
        value: 'sync',
        label: t('Webhooks.TypeOptions.Synchronous'),
      },
      {
        value: 'ctd',
        label: t('Webhooks.TypeOptions.Ctd'),
      },
    ].filter(Boolean);
  }, [t]);

  const filterCtdName = useCallback(
    async (query, _, setIsLoading) => {
      setIsLoading(true);
      let newOptions = [];
      try {
        const { body, status } = await listContentTypes(jwt, {
          ...ctdParams,
          name: query,
        });
        checkResponseStatus(body, status);
        newOptions = body.data || [];
      } catch (error) {
        toast.error(
          t(
            error instanceof ResponseError
              ? 'ContentForm.CouldntFetch'
              : 'Form.CommunicationErrorMessage',
          ),
        );
      }

      setIsLoading(false);
      return newOptions
        .filter((ctd) => !ctd.internal || ctd.name === '_media')
        .map((ctd) => ({ label: ctd.label, value: ctd.name }));
    },
    [jwt, ctdParams, t],
  );

  const handleAddHeaders = useCallback(() => {
    formik.setFieldValue('headers', [
      ...formik.values.headers,
      {
        header_name: '',
        header_value: '',
        id: `#hid-${new Date().getTime()}`,
      },
    ]);
  }, [formik]);

  const handleRemoveHeaders = useCallback(
    (id) => {
      formik.setFieldValue('headers', [
        ...formik.values.headers.filter((el) => el.id !== id),
      ]);
    },
    [formik],
  );

  const handleVersionSelectCallback = useCallback(
    async (versionNumber) => {
      setWebhookLoading(true);

      try {
        const { body, status } = await getContentObjectVersion(jwt, {
          contentTypeName: '_webhooks',
          id: id,
          versionNumber: versionNumber,
        });

        checkResponseStatus(body, status);

        handleUpdateFormik(body, id);

        toast.success(
          t('Webhooks.VersionPreview', {
            date: moment(body?.internal?.updatedAt).format('L LT'),
          }),
        );
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Webhooks.GetVersionError'));
        } else {
          toast.error(error.message);
        }
      }

      setWebhookLoading(false);
    },
    [id, handleUpdateFormik, jwt, t],
  );

  const WebhookActionsFields = useMemo(() => {
    if (!isWebhookTypeCtd) {
      return (
        <>
          <SectionActions
            actions={formik?.values?.actions}
            onChange={handleChangeActions}
            errors={formik?.errors?.actions}
            {...getTestProps(testId, 'actions', 'testId')}
          />

          <label className="block text-sm text-slate-400 mb-2">
            {t('Webhooks.ContentTypeDefinitions')} <RequiredTemplate />
          </label>

          {isLoadingCT ? (
            <div className="h-12 flex justify-center items-center">
              <Loader type="spinner-grid" size="small" />
            </div>
          ) : (
            <Dropdown
              name="contentTypeDefinitions"
              options={contentTypesOptions}
              value={formik.values.contentTypeDefinitions}
              multiple
              onChange={formik.handleChange}
              filterCallback={filterCtdName}
              debounceTime={150}
              nullable
              placeholder={t('Webhooks.ContentTypeDefinitions')}
              placeholderMultiple={t('Webhooks.ContentTypeDefinitions')}
              truncate={true}
              truncateLength={25}
              multiline={true}
              error={
                formik.errors.contentTypeDefinitions ||
                formik.status?.errors?.contentTypeDefinitions
              }
              {...getTestProps(testId, `dropdown`, 'testId')}
            />
          )}
        </>
      );
    }
  }, [
    contentTypesOptions,
    filterCtdName,
    formik,
    handleChangeActions,
    isLoadingCT,
    isWebhookTypeCtd,
    t,
    testId,
  ]);

  return (
    <div className="flex items-stretch w-full min-h-[calc(100vh-71px)]">
      <Helmet>
        <title>{t(`Global.Webhooks`)}</title>
      </Helmet>
      <div className="flex flex-col w-full">
        <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 h-full mt-7">
          <div
            className="md:col-span-3 bg-white dark:bg-slate-950 rounded-lg mx-4 xl:ml-7 xl:mr-3.5 mb-7 relative"
            {...getTestProps(testId, 'container')}
          >
            {webhookLoading && (
              <div
                className={twMerge(
                  'absolute top-0 left-0 w-full h-full flex bg-white dark:bg-gray-800',
                  'justify-center items-center z-10 rounded-md opacity-90',
                )}
                {...getTestProps(testId, 'container-loader')}
              >
                <Loader type="spinner-grid" size="small" />
              </div>
            )}

            <form
              className="w-full max-w-3xl px-5 m-auto pt-10"
              onSubmit={handleSubmit}
              noValidate={true}
            >
              <Input
                name="name"
                type="text"
                label={`${t('Global.Name')}`}
                value={formik?.values?.name}
                onChange={formik.handleChange}
                error={formik?.errors?.name || formik.status?.errors?.name}
                additionalClasses={'mb-4'}
                required
                {...getTestProps(testId, 'input-name', 'testId')}
              />

              <Dropdown
                name="type"
                label={t('Webhooks.Type')}
                options={typeOptions}
                value={formik?.values?.type || 'async'}
                onChange={formik.handleChange}
                debounceTime={150}
                placeholder={t('Webhooks.Type')}
                additionalClasses={'mb-4'}
                required
                error={
                  formik.errors.type ||
                  formik.status?.errors?.type ||
                  (formik?.values?.type &&
                  !typeOptions.find((el) => el.value === formik?.values?.type)
                    ? t('Webhooks.Errors.SelectedNoLongerValid')
                    : null)
                }
                {...getTestProps(testId, `dropdown-type`, 'testId')}
              />

              <Input
                name="url"
                type="text"
                label={'URL'}
                value={formik?.values?.url}
                onChange={formik.handleChange}
                error={formik?.errors?.url || formik.status?.errors?.url}
                additionalClasses={'mb-4'}
                required
                {...getTestProps(testId, 'input-url', 'testId')}
              />

              <Switch
                name={'enabled'}
                checked={getIn(formik?.values, 'enabled')}
                label={t('Webhooks.Enabled')}
                onChange={formik.handleChange}
                additionalClasses={'mb-4'}
                {...getTestProps(testId, 'switch-enabled', 'testId')}
              />

              {WebhookActionsFields}

              <label className="flex text-sm text-slate-400 mt-4 mb-4">
                {t('Webhooks.Headers')}
              </label>

              <SectionHeaders
                headers={formik.values?.headers}
                onRemove={handleRemoveHeaders}
                onAdd={handleAddHeaders}
                onChange={formik.handleChange}
                errors={formik.errors.headers}
                {...getTestProps(testId, 'headers', 'testId')}
              />

              <Button
                type="submit"
                buttonSize="sm"
                additionalClasses="m-auto w-max justify-center mt-4 hidden"
                additionalChildrenClasses="flex flex-row items-center"
                ref={refSubmitButton}
                iconImage={
                  isLoading && <Loader size="small" type="spinner-grid" />
                }
                iconPosition="start"
                {...getTestProps(testId, `button-submit`, 'testId')}
              >
                {t('Global.Save')}
              </Button>
            </form>
          </div>

          <div
            className="px-4 xl:pl-3.5 xl:pr-7 pb-7 border-t md:border-t-0 md:border-l dark:border-slate-800
            flex flex-col gap-5 w-full"
          >
            <ContentObjectInformations
              createdAt={duplicate ? null : formik?.values?.internal?.createdAt}
              updatedAt={duplicate ? null : formik?.values?.internal?.updatedAt}
              testId={testId}
            />

            {mode === 'edit' && !duplicate && (
              <ContentObjectLogs
                logs={webhookLogs}
                loading={webhookLogsLoading}
                testId={testId}
                onRefresh={handleFetchLogs}
              />
            )}

            {webhookVersions?.length > 0 && !duplicate && (
              <ContentObjectVersions
                versions={webhookVersions}
                testId={testId}
                selectVersionCallback={handleVersionSelectCallback}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddWebhooks;

AddWebhooks.propTypes = {
  /**
   * If is on duplicate
   */
  duplicate: PropTypes.bool,
  /**
   * Current mode "edit" or "add"
   */
  mode: PropTypes.string,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

AddWebhooks.defaultProps = {
  mode: 'edit',
  duplicate: false,
  testId: '',
};
