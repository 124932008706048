import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Lib
import { getTestProps } from '../../../lib/helpers';

// :: Components
import Button from '../../../components/Button/Button';
import Heading from '../../../components/Heading/Heading';

// :: Components Inner
import PlanItemRow from './PlanItemRow';
import Loader from '../../../components/Loader/Loader';

const PlanItem = ({
  testId,
  details,
  title,
  subtitle,
  onClickText,
  onClick,
  loading,
  disabledButton,
  disabledText,
}) => {
  if (!title && !subtitle && !details) return null;

  return (
    <div
      className={twMerge(
        'flex flex-col my-3 pb-4 px-2',
        'bg-slate-50 dark:bg-gray-900 rounded-lg p-5 dark:text-white',
      )}
      {...getTestProps(testId, 'container')}
    >
      <div className="flex items-center justify-between border-b dark:border-slate-800 pb-3">
        <div>
          {title && (
            <Heading
              level={4}
              additionalClasses="pt-0 pb-0 dark:text-white"
              {...getTestProps(testId, 'title', 'testId')}
            >
              {title}
            </Heading>
          )}

          {subtitle && (
            <Heading
              level={6}
              additionalClasses="pt-0 pb-0 mt-1 dark:text-white italic font-normal"
              {...getTestProps(testId, 'subtitle', 'testId')}
            >
              {subtitle}
            </Heading>
          )}
        </div>

        {onClickText && onClick && (
          <Button
            additionalClasses="max-w-fit"
            buttonSize={'xs'}
            onClick={onClick}
            iconPosition="start"
            iconImage={
              loading && (
                <Loader
                  type="spinner-white"
                  {...getTestProps(testId, 'loader', 'testId')}
                />
              )
            }
            disabled={disabledButton}
            title={disabledButton ? disabledText : null}
            {...getTestProps(testId, 'button', 'testId')}
          >
            {onClickText}
          </Button>
        )}
      </div>

      <ul className="mt-3">
        {details?.map(
          (row) =>
            !row.hidden && (
              <li key={row.id}>
                <PlanItemRow
                  value={row.value}
                  title={row.title}
                  {...getTestProps(testId, `plan-item-row-${row.id}`, 'testId')}
                />
              </li>
            ),
        )}
      </ul>
    </div>
  );
};

export default PlanItem;

PlanItem.propTypes = {
  /**
   * Test Id
   */
  testId: PropTypes.string,
  /**
   * Details
   */
  details: PropTypes.any,
  /**
   * Title plan
   */
  title: PropTypes.string,
  /**
   * Disabled button
   */
  disabledButton: PropTypes.bool,
  /**
   * Disabled title text
   */
  disabledText: PropTypes.string,
  /**
   * Subtitle plan
   */
  subtitle: PropTypes.string,
  /**
   * On checkout handler
   */
  onClickText: PropTypes.string,
  /**
   * On contact handler
   */
  onClick: PropTypes.any,
  /**
   * Loading for button on/off
   */
  loading: PropTypes.bool,
};

PlanItem.defaultProps = {
  testId: '',
  details: '',
  title: '',
  subtitle: '',
  loading: false,
  disabledButton: false,
  disabledText: '',
};
