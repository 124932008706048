import {
  checkboxRenderer,
  defaultRenderer,
} from '../../components/DataGrid/DataGridCell/cellRenderer';

export const getDefinedColumns = (
  gridOptionsByID,
  hiddenColumnsById,
  editGrid,
  t,
) => ({
  name: {
    accessor: 'name',
    label: t('Plans.Name'),
    width: gridOptionsByID?.['name']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.name,
  },
  visibleName: {
    accessor: 'visibleName',
    label: t('Plans.VisibleName'),
    width: gridOptionsByID?.['visibleName']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.visibleName,
  },
  displayOrder: {
    accessor: 'displayOrder',
    label: t('Plans.DisplayOrder'),
    width: gridOptionsByID?.['displayOrder']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.displayOrder,
  },
  stripePriceApiIdMonthly: {
    accessor: 'stripePriceApiIdMonthly',
    label: t('Plans.StripePriceApiIdMonthly'),
    width: gridOptionsByID?.['stripePriceApiIdMonthly']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.stripePriceApiIdMonthly,
  },
  stripePriceApiIdYearly: {
    accessor: 'stripePriceApiIdYearly',
    label: t('Plans.stripePriceApiIdYearly'),
    width: gridOptionsByID?.['stripePriceApiIdYearly']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.stripePriceApiIdYearly,
  },
  price: {
    accessor: 'price',
    label: t('Plans.Price'),
    width: gridOptionsByID?.['price']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.price,
  },
  enabled: {
    accessor: 'enabled',
    label: t('Plans.Enabled'),
    width: gridOptionsByID?.['enabled']?.width,
    render: (data) => checkboxRenderer(data),
    sortable: !editGrid,
    filterInputType: 'checkbox',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.enabled,
  },
  visible: {
    accessor: 'visible',
    label: t('Plans.Visible'),
    width: gridOptionsByID?.['visible']?.width,
    render: (data) => checkboxRenderer(data),
    sortable: !editGrid,
    filterInputType: 'checkbox',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.visible,
  },
  defaultPlan: {
    accessor: 'defaultPlan',
    label: t('Plans.DefaultPlan'),
    width: gridOptionsByID?.['defaultPlan']?.width,
    render: (data) => checkboxRenderer(data),
    sortable: !editGrid,
    filterInputType: 'checkbox',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.defaultPlan,
  },
  defaultFreePlan: {
    accessor: 'defaultFreePlan',
    label: t('Plans.DefaultFreePlan'),
    width: gridOptionsByID?.['defaultFreePlan']?.width,
    render: (data) => checkboxRenderer(data),
    sortable: !editGrid,
    filterInputType: 'checkbox',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.defaultFreePlan,
  },
});
