import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

// :: Lib
import { getTestProps } from '../../lib/helpers';
import { AvatarIcon } from '../../images/shapes';

const useOutsideListener = (ref, handler, hasEventOnCloseOutside) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handler?.();
      }
    };

    hasEventOnCloseOutside &&
      document.addEventListener('mousedown', handleClickOutside);

    return () => {
      hasEventOnCloseOutside &&
        document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler, hasEventOnCloseOutside]);
};

export const SectionUserMenu = ({
  items,
  avatar,
  name,
  email,
  handleClose,
  isModalOnCloseOutside,
  leftOnOutside,
  isOpen,
  minWidth,
  additionalUserMenuContainerClasses,
  additionalUserMenuClasses,
  hasEventOnCloseOutside,
  testId,
}) => {
  const wrapperRef = useRef(null);

  useOutsideListener(wrapperRef, handleClose, hasEventOnCloseOutside);

  const handleClick = (onclick) => {
    // Case: On click close modal
    handleClose?.();

    onclick?.();
  };

  return (
    <div
      ref={wrapperRef}
      className={twMerge(
        'absolute bottom-2 rounded bg-white dark:bg-gray-900 py-3 z-10 shadow-modal overflow-hidden',
        additionalUserMenuContainerClasses,
      )}
      style={{
        minWidth: isModalOnCloseOutside ? minWidth : 'calc(100% - 32px)',
        left: isModalOnCloseOutside && !isOpen ? leftOnOutside + 10 : 24,
      }}
      {...getTestProps(testId, 'container')}
    >
      <div className="flex items-center justify-center pb-3 mb-3 border-b dark:border-slate-800">
        {avatar && (
          <AvatarIcon
            className={twMerge('w-12 pl-3 flex select-none text-blue')}
          />
        )}

        <div
          className={twMerge(
            'relative flex flex-col ml-3 overflow-hidden transition-all ease-linear duration-fast dark:text-white',
            isOpen ? 'w-full' : 'w-0',
          )}
        >
          <div
            title={name?.length > 13 ? name : undefined}
            className={twMerge(
              'text-base font-medium whitespace-nowrap relative overflow-hidden text-ellipsis pr-2',
            )}
            {...getTestProps(testId, 'name')}
          >
            {name}
          </div>

          <div
            title={email?.length > 19 ? email : undefined}
            className={twMerge('text-[13px] whitespace-nowrap pr-2')}
            {...getTestProps(testId, 'email')}
          >
            {email}
          </div>
        </div>
      </div>
      <ul>
        {items?.map((el) => {
          const elID = el.id || el.text;
          return (
            <li
              key={elID}
              className={twMerge(
                'flex flex-nowrap cursor-pointer text-sm hover:bg-gradient-menu-hover',
                el.selected && 'bg-gradient-menu-hover',
                !el.link && 'py-2 pl-4',
                additionalUserMenuClasses,
              )}
              onClick={() => handleClick(el.onClick)}
              {...getTestProps(testId, elID)}
            >
              {el.link ? (
                <Link
                  className="flex items-center leading-none dark:text-white w-full py-2 pl-4"
                  to={el.link}
                  {...getTestProps(testId, `link-${elID}`)}
                >
                  {el.icon}
                  <div className="ml-2 whitespace-nowrap pr-2">{el.text}</div>
                </Link>
              ) : (
                <div
                  className="flex items-center leading-none"
                  {...getTestProps(testId, `text-${elID}`)}
                >
                  {el.icon}
                  <div className="ml-2 whitespace-nowrap dark:text-white pr-2">
                    {el.text}
                  </div>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

SectionUserMenu.propTypes = {
  /**
   Items
   */
  items: PropTypes.any,
  /**
   User avatar
   */
  avatar: PropTypes.any,
  /**
   handle Close is used to triggered option on close
   */
  handleClose: PropTypes.func,
  /**
   If we want to show modal On Close Outside mode
   */
  isModalOnCloseOutside: PropTypes.bool,
  /**
   component min Width only on mode when is outside `isModalOnCloseOutside`: *true*
   */
  minWidth: PropTypes.number,
  /**
   Modal position on left used on closed sidebar
   */

  leftOnOutside: PropTypes.number,
  /**
   Used for defined if we want to move left on closed sidebar
   */
  isOpen: PropTypes.bool,
  /**
   additional User Menu Container Classes
   */
  additionalUserMenuContainerClasses: PropTypes.string,
  /**
   additional User Menu Classes
   */
  additionalUserMenuClasses: PropTypes.string,
  /**
   Add event to close modal after click outside
   */
  hasEventOnCloseOutside: PropTypes.bool,
  /**
   Component test id
   */
  testId: PropTypes.string,
};

SectionUserMenu.defaultProps = {
  items: [],
  handleClose: undefined,
  isModalOnCloseOutside: false,
  hasEventOnCloseOutside: false,
  leftOnOutside: 0,
  isOpen: false,
  minWidth: 250,
  additionalUserMenuContainerClasses: '',
  additionalUserMenuClasses: '',
  testId: '',
};
