import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Card from '../../../Card/Card';
import { getTestProps, strToChecksum } from '../../../../lib/helpers';
import MediaGrid from '../../../MediaGrid/MediaGrid';
import Heading from '../../../Heading/Heading';
import { twMerge } from 'tailwind-merge';
import Input from '../../../Input/Input';
import Dropdown from '../../../Dropdown/Dropdown';
// eslint-disable-next-line max-len
import image1 from '../../../../images/graphics/type-definition-card-backgrounds-placeholders/card_bg-1-placeholder.jpg';
// eslint-disable-next-line max-len
import image2 from '../../../../images/graphics/type-definition-card-backgrounds-placeholders/card_bg-2-placeholder.jpg';
// eslint-disable-next-line max-len
import image3 from '../../../../images/graphics/type-definition-card-backgrounds-placeholders/card_bg-3-placeholder.jpg';
// eslint-disable-next-line max-len
import image4 from '../../../../images/graphics/type-definition-card-backgrounds-placeholders/card_bg-4-placeholder.jpg';

const backgrounds = [image1, image2, image3, image4];

const TypeCard = ({ onClick, label, count, name, testId }) => {
  const { t } = useTranslation();
  return (
    <Card
      onClick={onClick}
      body={
        <div className="relative space-y-2">
          <div className="truncate" title={label}>
            {label}
          </div>
          <div className="truncate text-slate-400 text-sm">
            {t('TypeDefinitionCard.ObjectNumber', {
              count: count || 0,
            })}
          </div>
        </div>
      }
      imageUrl={backgrounds[Math.abs(strToChecksum(name) % 4)]}
      additionalContainerClasses="bg-slate-50 dark:bg-gray-900 w-full h-24 border border-slate-200 dark:border-none"
      additionalImageClasses="w-full"
      additionalImageContainerClasses="w-1/3 max-w-[150px]"
      additionalBodyClasses="px-4 py-2 w-2/3 grow"
      horizontal
      {...getTestProps(testId, `${name}`, 'testId')}
    />
  );
};

const PickType = ({
  data,
  page,
  pagination,
  setPage,
  setType,
  ctdCounts,
  isLoading,
  errors,
  query,
  handleQueryChange,
  queryBy,
  setSearchBy,
  showFilters,
  testId,
}) => {
  const { t } = useTranslation();

  const renderCard = useCallback(
    (ctdData) => (
      <TypeCard
        key={ctdData.id}
        onClick={() => setType(ctdData.name)}
        label={ctdData.label}
        count={ctdCounts?.[ctdData.name]}
        name={ctdData.name}
        testId={testId}
      />
    ),
    [ctdCounts, setType, testId],
  );

  const searchByOptions = useMemo(
    () => [
      { value: 'label', label: t('ContentDefinition.Label') },
      { value: 'name', label: t('ContentDefinition.Name') },
    ],
    [t],
  );

  const filtersNode = useMemo(() => {
    if (!showFilters) return null;
    return (
      <div className="flex flex-col xs:flex-row gap-2">
        <Input
          placeholder={t('ContentDefinition.Search')}
          type="search"
          onChange={handleQueryChange}
          value={query}
        />
        <Dropdown
          options={searchByOptions}
          onChange={setSearchBy}
          value={queryBy}
          additionalOptionsClasses="!py-1.5"
          additionalClasses="w-full xs:w-1/3"
          {...getTestProps(testId, 'search-by', 'testId')}
        />
      </div>
    );
  }, [
    showFilters,
    t,
    handleQueryChange,
    query,
    testId,
    searchByOptions,
    setSearchBy,
    queryBy,
  ]);

  return (
    <MediaGrid
      data={data}
      page={page}
      renderMedia={renderCard}
      isLoading={isLoading}
      errors={errors}
      pagination={pagination}
      handlePageChange={setPage}
      areFiltersApplied={query ? true : false}
      filtersNode={filtersNode}
      handleNoData={
        <div className="flex justify-center">
          <Heading level={2} color="blue">
            {t('ObjectsOfType.NotFound')}
          </Heading>
        </div>
      }
      hasPanel={false}
      emptyFilterText={t('ContentDefinition.FiltersEmptyResult')}
      additionalGridClasses={twMerge(
        '!gap-2 sm:!gap-4 !grid-cols-[repeat(auto-fill,_minmax(10rem,_1fr))]',
        'sm:!grid-cols-[repeat(auto-fill,_minmax(13rem,_1fr))]',
      )}
    />
  );
};

export default PickType;

PickType.propTypes = {
  /**
   * Array of object data
   */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Current page number
   */
  page: PropTypes.number.isRequired,
  /**
   * Object with pagination data
   */
  pagination: PropTypes.object.isRequired,
  /**
   * Callback handling changing page
   */
  setPage: PropTypes.func.isRequired,
  /**
   * Callback for setting new chosen type
   */
  setType: PropTypes.func,
  /**
   * Dictionary with ctd objects count
   */
  ctdCounts: PropTypes.object,
  /**
   * If data are loading
   */
  isLoading: PropTypes.bool,
  /**
   * Objects loading errors
   */
  errors: PropTypes.any,
  /**
   * Filters query
   */
  query: PropTypes.string,
  /**
   * Callback for setting new query filter
   */
  handleQueryChange: PropTypes.func,
  /**
   * Filters query by
   */
  queryBy: PropTypes.string,
  /**
   * Callback for setting new query by filter
   */
  setSearchBy: PropTypes.func,
  /**
   * If filters should be shown
   */
  showFilters: PropTypes.bool,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

PickType.defaultProps = {
  setType: /* istanbul ignore next */ () => null,
  ctdCounts: {},
  isLoading: false,
  errors: null,
  query: '',
  handleQueryChange: /* istanbul ignore next */ () => null,
  queryBy: '',
  setSearchBy: /* istanbul ignore next */ () => null,
  showFilters: true,
  testId: '',
};
