import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../../../Button/Button';
import Input from '../../../../Input/Input';
import { twMerge } from 'tailwind-merge';
import { getMediaUrl } from '../../../../../lib/flotiq-client/api-helpers';
import PropTypes from 'prop-types';
import { getTestProps } from '../../../../../lib/helpers';

const formatMediaModalResult = (result) => {
  const resultObject = Object.values(result)[0];
  return {
    url: getMediaUrl(resultObject),
    extension: resultObject.extension,
    fileName: resultObject.fileName,
    width: resultObject.width,
    height: resultObject.height,
    type: resultObject.type,
  };
};

const isImage = (extension) => {
  if (extension) {
    return ['jpg', 'png', 'bmp', 'svg', 'gif', 'webp', 'ico', 'jpeg'].includes(
      extension.toLowerCase(),
    );
  }
};

const FlotiqImagePluginBody = ({
  openModal,
  saveSelected,
  data,
  block,
  api,
  testId,
}) => {
  const { t } = useTranslation();
  const imageRef = useRef();
  const [selectedImage, setSelectedImage] = useState({
    url: data.url,
    extension: data.extension,
    width: data.width,
    height: data.height,
    fileName: data.fileName,
  });

  const [caption, setCaption] = useState(data.caption || '');

  const tunes = useMemo(
    () => ({
      withBorder: data.withBorder || false,
      withBackground: data.withBackground || false,
      stretched: data.stretched || false,
    }),
    [data],
  );

  const handleModal = useCallback(async () => {
    let result = await openModal();
    if (!result) {
      return;
    }
    setSelectedImage(formatMediaModalResult(result));
  }, [openModal]);

  useEffect(() => {
    if (selectedImage.url) {
      saveSelected({
        ...selectedImage,
        caption: caption,
        ...tunes,
        ...(!isImage(selectedImage.extension) && { width: null, height: null }),
      });

      block.dispatchChange();
    }
  }, [data, selectedImage, caption, saveSelected, tunes, block]);

  const imageFileContainer = useMemo(() => {
    if (selectedImage && !isImage(selectedImage.extension)) {
      return (
        <div
          className={twMerge(
            'flex items-center flex-row w-full justify-start p-2',
          )}
          {...getTestProps(testId, 'selected-file')}
        >
          <div
            className="flex items-center justify-center rounded-full
            h-[32px] sm:h-12 min-w-[32px] w-[32px] sm:w-12 bg-blue-300 text-sm sm:text-base"
          >
            {selectedImage.extension || ''}
          </div>
          <a
            href={selectedImage.url}
            target={'_blank'}
            rel="noreferrer"
            className="text-sm sm:text-base break-all ml-3"
          >
            {selectedImage.fileName}
          </a>
        </div>
      );
    }
    return null;
  }, [selectedImage, testId]);

  const handleDeleteBLock = useCallback(
    (event) => {
      if (event.code === 'Backspace') {
        event.stopPropagation();
        event.preventDefault();
        api.blocks.delete(api.blocks.getCurrentBlockIndex());
      }
    },
    [api],
  );

  const handleFocusSwap = useCallback(
    (event) => {
      if (event.code === 'Backspace' && caption === '') {
        event.preventDefault();
        event.stopPropagation();
        imageRef.current?.focus();
      }
    },
    [caption],
  );

  const selectedImageCard = useMemo(() => {
    return (
      selectedImage.url && (
        <div
          {...getTestProps(testId, 'image-container')}
          ref={imageRef}
          tabIndex={-1}
          onKeyDown={handleDeleteBLock}
          className={twMerge(
            'border border-transparent focus:border-blue',
            tunes.withBorder && 'border border-solid border-slate-400/80',
            tunes.withBackground && 'bg-slate-50',
            tunes.stretched && 'w-full h-full',
          )}
        >
          {!isImage(selectedImage.extension) ? (
            imageFileContainer
          ) : (
            <img
              {...getTestProps(testId, 'selected-image')}
              className={twMerge(
                'w-64 h-full',
                tunes.stretched && 'w-full h-full',
              )}
              src={selectedImage.url}
              alt={selectedImage.fileName}
            />
          )}
        </div>
      )
    );
  }, [
    selectedImage.url,
    selectedImage.extension,
    selectedImage.fileName,
    testId,
    handleDeleteBLock,
    tunes.withBackground,
    tunes.stretched,
    tunes.withBorder,
    imageFileContainer,
  ]);

  if (!selectedImage.url) {
    return (
      <Button
        onClick={() => handleModal()}
        additionalClasses={'mt-2'}
        {...getTestProps(testId, 'open-modal', 'testId')}
      >
        {t('Global.MediaLibrary')}
      </Button>
    );
  }
  return (
    <div className={'flex flex-col py-3'}>
      {selectedImageCard}
      <Input
        type={'text'}
        additionalClasses={'mt-2'}
        onChange={(event) => {
          setCaption(event.target.value);
        }}
        onKeyDown={handleFocusSwap}
        placeholder={'Content'}
        value={caption}
        {...getTestProps(testId, 'caption', 'testId')}
      />
    </div>
  );
};

export default FlotiqImagePluginBody;

FlotiqImagePluginBody.propTypes = {
  /**
   * Modal opening callback
   */
  openModal: PropTypes.func.isRequired,
  /**
   * Saving selected image into callback
   */
  saveSelected: PropTypes.func.isRequired,
  /**
   * Editor js block object
   */
  block: PropTypes.object.isRequired,
  /**
   * Editor js api object
   */
  api: PropTypes.object.isRequired,
  /**
   * Selected image data
   */
  data: PropTypes.shape({
    url: PropTypes.string,
    extension: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    fileName: PropTypes.string,
    type: PropTypes.string,
    caption: PropTypes.string,
    withBorder: PropTypes.bool,
    withBackground: PropTypes.bool,
    stretched: PropTypes.bool,
  }),
  /**
   * Test id for image plugin body
   */
  testId: PropTypes.string,
};

FlotiqImagePluginBody.defaultProps = {
  data: {},
  testId: '',
};
