import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import Input from '../../../../Input/Input';
import PropTypes from 'prop-types';

const YouTubeEmdedBody = ({ saveData, data, api }) => {
  const { t } = useTranslation();
  const [dataUrl, setDataUrl] = useState(data.url);
  const [currentUrl, setCurrentUrl] = useState(data.url);

  const videoId = useMemo(() => {
    const matches = dataUrl.match(
      /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/,
    );
    if (matches) return matches[1];
    return null;
  }, [dataUrl]);

  const handleBlur = useCallback((event) => {
    setDataUrl(event.target.value);
  }, []);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.code === 'Enter') {
        handleBlur(event);
      }
      if (event.code === 'Backspace' && currentUrl === '') {
        event.stopPropagation();
        event.preventDefault();
        api.blocks.delete(api.blocks.getCurrentBlockIndex());
      }
    },
    [api.blocks, handleBlur, currentUrl],
  );

  const handleChange = useCallback(
    (event) => {
      const newUrl = event.target.value;
      setCurrentUrl(newUrl);
      saveData(newUrl);
    },
    [saveData],
  );

  return (
    <div className="py-3">
      {videoId && (
        <div className="relative pb-[56.25%] mb-2">
          <iframe
            className="absolute w-full h-full"
            title={videoId}
            src={`https://www.youtube.com/embed/${videoId}`}
            allowFullScreen
          />
        </div>
      )}
      <Input
        value={currentUrl}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        placeholder={t('ContentForm.YouTubePlaceholder')}
        error={!!dataUrl && !videoId ? t('ContentForm.Errors.YouTubeUrl') : ''}
        autoFocus
      />
    </div>
  );
};

export default YouTubeEmdedBody;

YouTubeEmdedBody.propTypes = {
  /**
   * Save url callback
   */
  saveData: PropTypes.func.isRequired,
  /**
   * Editor js api object
   */
  api: PropTypes.object.isRequired,
  /**
   * Data with yt url
   */
  data: PropTypes.shape({
    url: PropTypes.string,
  }),
};

YouTubeEmdedBody.defaultProps = {
  data: {},
};
