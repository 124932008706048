import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Lib
import { getTestProps } from '../../lib/helpers';

const AccountTypePill = ({ children, additionalClasses, testId }) => {
  return (
    children && (
      <div
        className={twMerge(
          'px-5 py-0.5 h-7 rounded-full bg-gradient-blue',
          'font-semibold text-white text-base uppercase tracking-[1px]',
          additionalClasses,
        )}
        {...getTestProps(testId)}
      >
        {children}
      </div>
    )
  );
};

export default AccountTypePill;

AccountTypePill.propTypes = {
  /**
   * Pill contents
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /**
   * Additional CSS classes for component
   */
  additionalClasses: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

AccountTypePill.defaultProps = {
  additionalClasses: '',
  children: '',
  testId: '',
};
