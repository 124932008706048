import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

import Panel from '../../../components/Panel/Panel';
import Pagination from '../../../components/Pagination/Pagination';
import { getObjectTitle, getTestProps } from '../../../lib/helpers';

const ContentObjectBacklinks = ({
  backlinks,
  contentTypes,
  pagination,
  onPageChange,
  additionalClasses,
  testId,
}) => {
  const { t } = useTranslation();

  return (
    <Panel
      title={t('ContentForm.Backlinks')}
      isCollapsable={false}
      additionalContainerClasses={twMerge(
        'py-5 px-4 md:px-2 xl:px-4',
        additionalClasses,
      )}
      additionalTitleClasses="text-lg"
      additionalChildrenClasses={'pt-2'}
      open
    >
      <div className="flex flex-col space-y-2">
        {backlinks?.map(({ item }) => (
          <Link
            key={`${item.internal.contentType}-${item.id}`}
            className={twMerge(
              'px-3 md:px-2 xl:px-3 py-2',
              'hover:bg-slate-50 dark:hover:bg-gray-900 hover:rounded-md hover:no-underline hover:text-stone-700',
              'dark:hover:text-white',
            )}
            to={`/content-type-objects/edit/${item.internal.contentType}/${item.id}`}
          >
            <span className="text-sm text-stone-700 dark:text-gray-200 font-semibold block">
              {contentTypes?.[item.internal.contentType]?.label ||
                item.internal.contentType}
            </span>
            {getObjectTitle(item, contentTypes?.[item.internal.contentType])}
          </Link>
        ))}
        {pagination?.total_pages > 1 && (
          <Pagination
            numOfPages={pagination.total_pages}
            onPageChange={onPageChange}
            page={pagination.current_page}
            {...getTestProps(testId, 'pagination', 'testId')}
          />
        )}
      </div>
    </Panel>
  );
};

ContentObjectBacklinks.propTypes = {
  /**
   * An array of backlink search results
   */
  backlinks: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        id: PropTypes.string,
        internal: PropTypes.shape({
          contentType: PropTypes.string,
        }),
      }),
    }),
  ),
  /**
   * content types from backlinks indexed by name
   */
  contentTypes: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ),
  /**
   * A pagination object
   */
  pagination: PropTypes.shape({
    current_page: PropTypes.number,
    total_pages: PropTypes.number,
  }),
  /**
   * Callback for pagination page change
   */
  onPageChange: PropTypes.func,
  /**
   * Additional css classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Test id
   */
  testId: PropTypes.string,
};

ContentObjectBacklinks.defaultProps = {
  testId: '',
};

export default ContentObjectBacklinks;
