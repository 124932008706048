import { refreshToken } from './auth-requests';
import { getModal } from './../../contexts/ModalContext';
import i18next from '../../i18n';
import LoginModalForm from '../../form/LoginModalForm/LoginModalForm';
import { getLocalStorage } from '../../utils/localStorage';
import { WarningTriangleRedIcon } from '../../images/shapes';
import { fetchLogin } from './fetch-login';

class RefreshStatus {
  constructor() {
    this.isRefreshing = false;
    this.isRefreshed = false;
    this.isInvalid = false;
    this.newJwt = '';
    this.fetchRequest = null;
    this.loginRequest = null;
  }
}

const refreshStatus = new RefreshStatus();

export const resetRefreshStatus = () =>
  Object.assign(refreshStatus, new RefreshStatus());

export class MissingParamError extends Error {
  constructor(url) {
    super(`Not all params are provided to url: ${url}`);
  }
}

const showLoginModal = async () => {
  const email = getLocalStorage('cms.user', true)?.data?.email;
  const modal = getModal();
  const token = await modal({
    title: (
      <div className="inline-flex text-red font-bold text-3xl items-center">
        <WarningTriangleRedIcon className="h-5 mr-2.5" />
        {i18next.t('Global.SessionExpired')}
      </div>
    ),
    content: (
      <LoginModalForm
        email={email}
        onSubmit={(values) =>
          fetchLogin({ password: values.password, email }, i18next.t)
        }
      />
    ),
    hideClose: true,
  });
  refreshStatus.newJwt = token;
};

/**
 * Creates fetch method
 * @param {string} url server url
 * @param {string} method valid http method (GET, POST, PUT, PATCH, DELETE)
 * @param {string} jwt A token used for communication
 * @param {Record<strng, any>} options additional options used for query
 * @returns {Promise<Response>}
 */
export const fetchMethod = (url, method, jwt, options = null) =>
  fetch(url, {
    method,
    ...options,
    headers: {
      Accept: 'application/json',
      ...(jwt ? { Authorization: `JWT ${jwt}` } : {}),
      ...(options?.skipContentType
        ? {}
        : { 'Content-Type': 'application/json; charset=UTF-8' }),
      ...(options?.headers ?? {}),
    },
  });

/**
 *
 * @param {string} method valid http method (GET, POST, PUT, PATCH, DELETE)
 * @param {string} path A path relative to server url
 * @param {string} jwt A token used for communication
 * @param {Record<strng, any>} options additional options used for query
 * @returns {Promise<Response>}
 */
export const apiRequest = async (method, path, jwt, options = null) => {
  const baseUrl = process.env.REACT_APP_FLOTIQ_API_URL;
  const url = `${baseUrl.replace(/\/+$/, '')}/${path.replace(/^\/*/, '')}`;

  const response = await fetchMethod(url, method, jwt, options);

  if (response?.status === 401 && jwt) {
    if (!refreshStatus.isRefreshing) {
      refreshStatus.isRefreshing = true;

      refreshStatus.fetchRequest = refreshToken();
      const refreshReponse = await refreshStatus.fetchRequest;

      if (refreshReponse.ok) {
        localStorage.setItem('cms.user', JSON.stringify(refreshReponse.body));
        refreshStatus.newJwt = refreshReponse.body.token;
      } else {
        refreshStatus.isInvalid = true;
        refreshStatus.loginRequest = showLoginModal();
      }
      refreshStatus.isRefreshed = true;
    } else {
      await refreshStatus.fetchRequest;
    }
    await refreshStatus.loginRequest;

    return fetchMethod(url, method, refreshStatus.newJwt, options);
  }

  return response;
};

/**
 *
 *
 * @param {string} url an url with varaible keys in it, e.g. api/data/{{id}}
 * @param {Record<string, any>} params parameters to take url variables from, e.g. {id: 123}
 * @returns {string} url with substituted values, e.g. api/data/123
 */
const applyUrlParams = (url, params) => {
  const newUrl = Object.keys(params).reduce((url, key) => {
    const newUrl = url.replaceAll(
      `{{${key}}}`,
      encodeURIComponent(params[key]),
    );
    if (newUrl !== url) delete params[key];

    return newUrl;
  }, url);

  if (/{{[a-z0-9]+}}/i.test(newUrl)) throw new MissingParamError(url);

  return newUrl;
};

/**
 * Appends url params to the url
 * @param {string} url Base url that params are appended to
 * @param {Record<string,string>} params a key/value object of params
 * @returns {string} Url combined with query params
 */
export const appendQueryParams = (url, params) => {
  const keyValues = [];
  Object.keys(params).forEach((key) => {
    if (params[key] != null) {
      const encodedKey = encodeURIComponent(key);
      if (Array.isArray(params[key])) {
        const arrayString = params[key]
          .map((el) => `${encodedKey}[]=${encodeURIComponent(el)}`)
          .join('&');
        keyValues.push(arrayString);
      } else {
        keyValues.push(`${encodedKey}=${encodeURIComponent(params[key])}`);
      }
    }
  });
  const queryString = keyValues.join('&');
  if (!queryString) return url;
  if (url.includes('?')) {
    return `${url}&${queryString}`;
  } else {
    return `${url}?${queryString}`;
  }
};

/**
 * Parses response into a valid object depending on response content-type
 * @param {Response} res response acquired from server
 * @returns {*}
 */
export const defaultResponseParser = async (res) => {
  const headers = Object.fromEntries(res.headers.entries());
  if (headers['content-type'] === 'application/json')
    return { status: res.status, body: await res.json(), ok: res.ok };
  return res;
};

/**
 * Creates a method for querying a listing url
 *
 * @param {string} name endpoint used for this URL
 * @param {string} method REST method, default = GET
 * @param {Record<string, any>} defaultParams default parameters added to query url
 */
export function makeBodilessQuery(name, method = 'GET', defaultParams = {}) {
  /**
   * Bodiless query function. Used for GET, DELETE and HEAD queries
   * @param {String} jwt Token used for query
   * @param {object} params values used with query and url params
   * @param {object} options fetch options
   * @returns {Promise<Response|{body: any, status: number, ok: boolean}>}
   */
  return (jwt, params = defaultParams, options = {}) => {
    let url = `api/${name}`;
    const paramsLocalCopy = JSON.parse(JSON.stringify(params || {}));
    url = applyUrlParams(url, paramsLocalCopy);
    url = appendQueryParams(url, { ...defaultParams, ...paramsLocalCopy });

    return apiRequest(method, url, jwt, options).then(defaultResponseParser);
  };
}

/**
 *
 * @param {string} name endpoint used for this url
 */
export function makeJSONQuery(name, method = 'POST') {
  /**
   * JSON query function. Used for POST, PUT, and PATCH queries
   * @param {String} jwt Token used for query
   * @param {object} params values used with body and url params
   * @param {object} options fetch options
   * @returns {Promise<Response|{body: any, status: number, ok: boolean}>}
   */
  return (jwt, body = {}, options = {}) => {
    let url = `api/${name}`;
    const bodyLocalCopy = Array.isArray(body)
      ? Object.assign({}, body)
      : JSON.parse(JSON.stringify(body));
    url = applyUrlParams(url, bodyLocalCopy);
    return apiRequest(method, url, jwt, {
      body: JSON.stringify(bodyLocalCopy),
      ...options,
    }).then(defaultResponseParser);
  };
}
