import { IFlotiqPluginEvent } from './IFlotiqPluginEvent';

/**
 * An event that is fired when a filter is being rendered on a top of a column.
 * Returning anything will embed that result in the column header for the field.
 *
 * @memberof FlotiqPlugins
 * @event "flotiq.grid.filter::render"
 * @type {GridRenderFilterEvent}
 * @returns {null|string|array|number|boolean|HTMLElement|ReactElement}
 *          Null if rendering should be passed to either Flotiq or other plugins.
 *          Renderable result if plugin wants to replace the default renderer.
 */

/**
 * @class
 * @memberof FlotiqPlugins
 * @name GridRenderFilterEvent
 *
 * @property {string} accessor Full path to the field within the object
 * @property {string} inputType Field input type
 * @property {function} updateFilters Function to update filters
 * @property {object} allFilters All current filters
 * @property {boolean} disabled If the fifilter is disabled
 * @property {object} contentType Content type that includes the field
 */
export class GridRenderFilterEvent extends IFlotiqPluginEvent {
  accessor;
  inputType;
  updateFilters;
  allFilters;
  disabled;
  contentType;
}
