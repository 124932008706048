import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { twMerge } from 'tailwind-merge';

// :: Hoc
import Authentication from '../../hoc/Authentication/Authentication';

// :: Components
import AnnouncementComponent from '../../components/Announcement/AnnouncementComponent';

// :: Form
import RegisterForm from '../../form/RegisterForm/RegisterForm';

// :: Lib
import { postRegister } from '../../lib/flotiq-client';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import {
  getTestProps,
  generateRedirectUrl,
  getMaskedOrganizationName,
} from '../../lib/helpers';

// :: Hooks
import useDarkMode from '../../hooks/useDarkMode';

const Register = ({ testId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const announcement = process.env.REACT_APP_ANNOUNCEMENT.split(',').join(',');
  const [darkMode] = useDarkMode();

  const handlePost = useCallback(
    async (values) => {
      try {
        const { body, status } = await postRegister({
          ...values,
          redirectUri: generateRedirectUrl('/register/activate'),
          plan: searchParams.has('plan') ? searchParams.get('plan') : '',
        });
        checkResponseStatus(body, status);
        toast.success(t('Global.CreatedAccount'), { duration: 10000 });

        TagManager.dataLayer({
          dataLayer: {
            event: 'sign_up',
            user_id: body.user_id,
            organization_id: body.organization_id,
            organization_name: getMaskedOrganizationName(
              body.organization_name,
            ),
            plan_id: body.plan_id,
            plan_name: body.plan_name,
          },
        });

        window.dataLayer.push(function () {
          this.reset();
        });

        navigate('/');
        return null;
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return {
            general: t('Form.CommunicationErrorMessage'),
            ...error.errors,
          };
        }
        toast.error(error.message ? error.message : t('Form.ValidationError'));
        return {
          general: t('Form.ValidationError'),
          ...error.errors,
        };
      }
    },
    [navigate, searchParams, t],
  );

  return (
    <>
      <Helmet>
        <title>{t('Global.RegisterToFlotiq')}</title>
      </Helmet>
      <AnnouncementComponent
        additionalClasses="h-16"
        announcement={announcement}
      />
      <Authentication
        loginLayout={true}
        headerText={t('Global.CreateAccount')}
        paragraphText={
          <>
            {t('Global.HaveAccount')}
            <Link to="/login" className="text-blue">
              <span> {t('Global.LogIn')}</span>
            </Link>
          </>
        }
        logoLink={process.env.REACT_APP_FLOTIQ_PAGE_URL}
        additionalClasses={twMerge(
          announcement && 'pt-16',
          darkMode && 'dark:bg-gray-900',
        )}
        {...getTestProps(testId, 'authentication', 'testId')}
      >
        <RegisterForm
          onSubmit={handlePost}
          {...getTestProps(testId, 'form', 'testId')}
        />
      </Authentication>
    </>
  );
};

export default Register;

Register.propTypes = {
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

Register.defaultProps = {
  testId: '',
};
