import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getTestProps } from '../../lib/helpers';
import RelationCard from '../RelationCard/RelationCard';
import { useCallback } from 'react';
import { getMediaUrl } from '../../lib/flotiq-client/api-helpers';

const RelationModalContent = ({ isLoading, relationsData, testId }) => {
  const { t } = useTranslation();

  const openFullSizeImage = useCallback((media) => {
    window.open(getMediaUrl(media), '_blank');
  }, []);

  return (
    <>
      {!relationsData || Object.keys(relationsData).length <= 0 ? (
        <div
          className={twMerge(
            'flex flex-col items-center space-y-2 font-bold text-lg',
            { ...getTestProps(testId, 'no-relations') },
          )}
        >
          {t('ObjectsOfType.NoRelationsData')}
        </div>
      ) : (
        <div
          className={twMerge(
            'flex flex-col items-center space-y-2 px-2 pt-7 pb-5',
          )}
        >
          {relationsData &&
            Object.keys(relationsData).map((contentObjectType) => {
              const relationType = contentObjectType;
              const objects = relationsData[contentObjectType].data
                ? relationsData[contentObjectType].data
                : relationsData[contentObjectType];
              const titleField = relationsData[contentObjectType].titleField;
              return objects.map((relation) => (
                <RelationCard
                  key={typeof relation === 'string' ? relation : relation.id}
                  relation={relation}
                  relationType={relationType}
                  titleField={titleField}
                  relationsAreLoading={isLoading}
                  onShow={openFullSizeImage}
                  testId={testId}
                />
              ));
            })}
        </div>
      )}
    </>
  );
};

export default RelationModalContent;

RelationModalContent.propTypes = {
  /**
   * Show loaders for cards
   */
  isLoading: PropTypes.bool,
  /**
   * Data to display in the modal
   */
  relationsData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.shape({
        data: PropTypes.array,
        titleField: PropTypes.string,
      }),
    ]),
  ),
  /**
   * Total number of pages
   */
  totalPages: PropTypes.number,
  /**
   * Currently selected page
   */
  page: PropTypes.number,
  /**
   * Callback to call when the modal is closed
   */
  onClose: PropTypes.func,
  /**
   * Callback to call when page is changed
   */
  onPageChange: PropTypes.func,
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

RelationModalContent.defaultProps = {
  isLoading: false,
  relationsData: {},
  totalPages: 1,
  page: 1,
  onClose: /* istanbul ignore next */ () => true,
  onPageChange: /* istanbul ignore next */ () => true,
  testId: '',
};
