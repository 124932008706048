import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { Trans, useTranslation } from 'react-i18next';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Component
import Button from '../Button/Button';
import Tooltip from '../Tooltip/Tooltip';

// :: Images
import { InformationCircleIcon } from '../../images/shapes';

const existValueFromConfigOrAlternative = (
  config,
  status,
  field,
  alternative,
) => {
  if (config?.[status]?.[field] !== undefined) {
    return config?.[status]?.[field];
  } else {
    return alternative;
  }
};

const InformationBlock = ({
  title,
  description,
  version,
  status,
  hidden,

  onClick,
  buttonText,
  hasButton,
  hasInformation,
  informationText,
  config,

  additionalClasses,
  additionalTitleClasses,
  additionalDescriptionClasses,
  additionalButtonClasses,
  additionalInformationClasses,
  testId,
}) => {
  const { t } = useTranslation();

  const currentStatus = {
    published: 'bg-orange text-white',
    public: 'bg-lime text-white',
    draft: 'bg-slate-400 text-white',
    archive: 'bg-red text-white',
  };

  const statusText = useCallback(
    (status) => {
      const statusText = {
        draft: t('ObjectStatus.Draft'),
        archive: t('ObjectStatus.Archived'),
        public: t('ObjectStatus.Public'),
      };

      switch (status) {
        case 'published':
          return (
            <Trans
              i18nKey="ObjectStatus.ViewVersionPublishedText"
              default={
                '<strong className="text-lg">Version {{version}} has been published.</strong>' +
                '<p>Current changes will not affect the public version until the workflow is completed.</p>'
              }
              values={{
                version: version,
              }}
              components={{
                strong: <strong className="text-lg" />,
                p: <p className="font-light mt-2" />,
              }}
            />
          );
        case 'draft':
          return (
            <Trans
              i18nKey="ObjectStatus.ViewVersionDraftText"
              default={
                'You are viewing {{ version }} which is now in' +
                '<strong>{{status}}</strong>'
              }
              values={{
                status: statusText[status],
                version: `${t('ObjectStatus.Ver')} ${version}`,
              }}
            />
          );
        case 'archive':
        case 'public':
        default:
          return (
            <Trans
              i18nKey="ObjectStatus.ViewVersionStatusText"
              default={
                'You are viewing {{ version }} which is now' +
                '<strong>{{status}}</strong>'
              }
              values={{
                status: statusText[status] || status,
                version: `${t('ObjectStatus.Ver')} ${version}`,
              }}
            />
          );
      }
    },
    [t, version],
  );

  const validateButton = existValueFromConfigOrAlternative(
    config,
    status,
    'button',
    hasButton,
  );

  const validateInformation = existValueFromConfigOrAlternative(
    config,
    status,
    'information',
    hasInformation,
  );

  const validateTitle = existValueFromConfigOrAlternative(
    config,
    status,
    'title',
    title,
  );

  const validateDescription = existValueFromConfigOrAlternative(
    config,
    status,
    'description',
    description,
  );

  const validateHidden = existValueFromConfigOrAlternative(
    config,
    status,
    'hidden',
    hidden,
  );

  return (
    <div
      className={twMerge(
        'rounded-lg p-4 w-full',
        'bg-white text-black',
        validateHidden && 'hidden',
        validateInformation && 'pr-10 relative',
        currentStatus[status],
        additionalClasses,
      )}
      {...getTestProps(testId, `container`)}
    >
      {validateTitle && (
        <p
          className={twMerge('font-bold', additionalTitleClasses)}
          {...getTestProps(testId, `title`)}
        >
          {title}
        </p>
      )}

      {validateDescription && (
        <p
          className={twMerge(additionalDescriptionClasses)}
          {...getTestProps(testId, `description`)}
        >
          {description}
        </p>
      )}

      {!(validateTitle || validateDescription) && (
        <div {...getTestProps(testId, `status-text`)}>{statusText(status)}</div>
      )}

      {validateButton && (
        <Button
          buttonColor="borderless"
          additionalClasses={twMerge(
            'underline hover:opacity-50 text-white mt-3',
            additionalButtonClasses,
          )}
          onClick={onClick}
          buttonSize="sm"
          type="button"
          noPaddings={true}
          {...getTestProps(testId, `button`, 'testId')}
        >
          {buttonText || t('ObjectStatus.Unpublish')}
        </Button>
      )}

      {validateInformation && (
        <div className="h-6 w-6 absolute top-4 right-4">
          <Tooltip
            tooltip={informationText || t('ObjectStatus.InformationOnReview')}
            tooltipPlacement={'leftCenter'}
            phoneTooltipPlacement={'bottomCenter'}
            {...getTestProps(testId, `tootltip`, 'testId')}
          >
            <InformationCircleIcon
              className={twMerge(
                'h-6 w-6 text-white',
                additionalInformationClasses,
              )}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default InformationBlock;

InformationBlock.propTypes = {
  /**
   * Component test id
   */
  testId: PropTypes.string,
  /**
   * Component title
   */
  title: PropTypes.string,
  /**
   * Component version text included in predefined status text
   */
  version: PropTypes.any,
  /**
   * Component description
   */
  description: PropTypes.string,
  /**
   * If component has button
   */
  hasButton: PropTypes.bool,
  /**
   * Button text
   */
  buttonText: PropTypes.string,
  /**
   * If component has infomration icon with predefined text if not provided in 'informationText' prop
   */
  hasInformation: PropTypes.bool,
  /**
   * Information text
   */
  informationText: PropTypes.string,
  /**
   * Config if path esist overide any props settings
   */
  config: PropTypes.any,
  /**
   * Component Status
   */
  status: PropTypes.string,
  /**
   * Additional CSS classes for component
   */
  additionalClasses: PropTypes.string,
  /**
   * Additional CSS classes for component title
   */
  additionalTitleClasses: PropTypes.string,
  /**
   * Additional CSS classes for component description
   */
  additionalDescriptionClasses: PropTypes.string,
  /**
   * Additional CSS classes for component button
   */
  additionalButtonClasses: PropTypes.string,
};

InformationBlock.defaultProps = {
  testId: '',
  version: '',
  title: '',
  status: '',
  description: '',
  hasButton: false,
  buttonText: '',
  hasInformation: false,
  hidden: false,
  informationText: '',
  config: undefined,
  additionalClasses: '',
  additionalTitleClasses: '',
  additionalDescriptionClasses: '',
  additionalButtonClasses: '',
};
