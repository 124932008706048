import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Images
import {
  SpinnerGridIcon,
  SpinnerIcon,
  SpinnerWhiteIcon,
} from '../../images/shapes';

const Loader = ({ testId, src, size, animate, type, additionalClasses }) => {
  const sizeClass = {
    tiny: 'h-6 w-6',
    small: 'h-8 w-8',
    normal: 'h-12 w-12',
    big: 'h-14 w-14',
    veryBig: 'h-16 w-16',
  };

  const srcType = {
    image: src ? (
      <img src={src} alt="" className="w-full h-full" />
    ) : (
      <SpinnerIcon className="w-full h-full" />
    ),
    spinner: <SpinnerIcon className="w-full h-full dark:text-gray-200" />,
    'spinner-white': <SpinnerWhiteIcon className="w-full h-full" />,
    'spinner-grid': <SpinnerGridIcon className="w-full h-full" />,
  };

  const animateClass = {
    none: '',
    spin: 'animate-spin mx-autoanimate-[pulse_1.5s_ease-in-out_infinite]',
    pulse: 'animate-pulse mx-autoanimate-[pulse_1.5s_ease-in-out_infinite]',
  };

  return (
    <div
      className={twMerge(
        'mx-autoanimate-[pulse_1.5s_ease-in-out_infinite]',
        sizeClass[size] || sizeClass.normal,
        animateClass[animate] || animateClass.none,
        additionalClasses,
      )}
      {...getTestProps(testId)}
    >
      {srcType[type] || srcType.spinner}
    </div>
  );
};

export default Loader;

Loader.propTypes = {
  /**
   * Testing prop type
   */
  testId: PropTypes.string,
  /**
   * Loader src
   */
  src: PropTypes.string,
  /**
   * Loader type image or spinner
   */
  type: PropTypes.oneOf(['image', 'spinner', 'spinner-white', 'spinner-grid']),
  /**
   * Loader animation loaders
   */
  animate: PropTypes.oneOf(['none', 'spin', 'pulse']),
  /**
   * Loader image alt
   */
  alt: PropTypes.string,
  /**
   * Loader size
   */
  size: PropTypes.oneOf(['tiny', 'small', 'normal', 'big', 'veryBig']),
  /**
   * Loader additional CSS classes
   */
  additionalClasses: PropTypes.string,
};

Loader.defaultProps = {
  testId: 'loader',
  type: 'spinner',
  size: 'normal',
  animate: 'spin',
  additionalClasses: '',
};
