import { useCallback, useContext, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// :: Hooks
import useApiErrorsToast from '../../../hooks/api/useApiErrorsToast';
import { useUnsplashMedia } from '../../../hooks/api';
import useToken from '../../../hooks/useToken';

// :: Lib
import { saveUnsplashMedia } from '../../../lib/flotiq-client';
import {
  ResponseError,
  checkResponseStatus,
} from '../../../lib/flotiq-client/response-errors';

// :: Components
import Heading from '../../../components/Heading/Heading';
import Input from '../../../components/Input/Input';
import MediaGrid from '../../../components/MediaGrid/MediaGrid';
import UnsplashCard from '../../../components/UnsplashCard/UnsplashCard';

// :: Contexts
import NewMediaContext from '../../../contexts/NewMediaContext';

const UnsplashMedia = ({
  mediaOnPageLimit,
  setMediaOnPageLimit,
  showAsNewMedia,
  additionalGridContainerClasses,
  testId,
}) => {
  const [page, setPage] = useState(1);
  const [tag, setTag] = useState('');
  const jwt = useToken();
  const { t } = useTranslation();
  const { setNewMedia } = useContext(NewMediaContext);

  const handleFilters = useCallback((event) => {
    setTag(event.target.value);
    setPage(1);
  }, []);

  const UnsplashPlusImageError = useMemo(() => 'unsplash-plus-image', []);

  const params = useMemo(() => {
    return {
      limit: mediaOnPageLimit,
      clientOriginalName: tag,
      page: page,
    };
  }, [page, mediaOnPageLimit, tag]);

  const { data, errors, isLoading, pagination } = useUnsplashMedia(params);

  useApiErrorsToast(errors);

  const handlePageChange = useCallback((pageNo) => {
    setPage(pageNo);
  }, []);

  const saveMedia = useCallback(
    async (unsplashMediaId) => {
      try {
        const { body, status } = await saveUnsplashMedia(jwt, {
          mediaId: unsplashMediaId,
        });
        checkResponseStatus(body, status);
        toast.success(t('Media.OnSaveSuccess'));
        if (showAsNewMedia) {
          setNewMedia((prevNewMedia) => {
            if (prevNewMedia.findIndex((media) => media[0].id === body.id) < 0)
              return [[body, ''], ...prevNewMedia];
            return prevNewMedia;
          });
        }
        return true;
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          if (error.message === UnsplashPlusImageError) {
            toast.error(t('Media.UnsplashPlusMediaError'));
            return false;
          }

          toast.error(
            error.message ? error.message : t('Media.OnErrorMessage'),
          );
        }
        return false;
      }
    },
    [jwt, t, showAsNewMedia, setNewMedia, UnsplashPlusImageError],
  );

  const filtersNode = useMemo(() => {
    return (
      <div className={'md:grid md:gap-6 md:grid-cols-3 lg:grid-cols-4'}>
        <Input
          testId={testId}
          label={t('Media.Filters.SearchByTag')}
          placeholder={t('Media.Filters.SearchByTag')}
          onChange={handleFilters}
          value={tag}
          type="search"
        />
      </div>
    );
  }, [handleFilters, tag, t, testId]);

  const renderMedia = useCallback(
    (media) => (
      <UnsplashCard
        key={media.id}
        mediaData={media}
        saveMediaCallback={saveMedia}
        testId={testId}
      />
    ),
    [saveMedia, testId],
  );

  return (
    <>
      <MediaGrid
        page={page}
        data={data}
        renderMedia={renderMedia}
        isLoading={isLoading}
        errors={errors}
        pagination={pagination}
        handlePageChange={handlePageChange}
        areFiltersApplied={Object.keys(tag).length !== 0}
        filtersNode={filtersNode}
        handleNoData={
          <div className="flex justify-center">
            <Heading level={2} color="blue">
              {t('Media.UnsplashNotFound')}
            </Heading>
          </div>
        }
        hasPanel={false}
        emptyFilterText={t('Media.FiltersEmptyResult')}
        testId={testId}
        resultsPerPage={mediaOnPageLimit}
        setResultsPerPage={setMediaOnPageLimit}
        additionalGridContainerClasses={twMerge(
          'px-5',
          additionalGridContainerClasses,
        )}
      />
    </>
  );
};

export default UnsplashMedia;

UnsplashMedia.propTypes = {
  /**
   * Limit of an element on page
   */
  mediaOnPageLimit: PropTypes.number,
  /**
   * Set the limit of an element on page
   */
  setMediaOnPageLimit: PropTypes.func,
  /**
   * If saved, unsplash should be shown in a new media array
   */
  showAsNewMedia: PropTypes.bool,
  /**
   *   Test id for unsplash media subpage
   */
  testId: PropTypes.string,
  /**
   *   CSS classes for Media grid container
   */
  additionalGridContainerClasses: PropTypes.string,
};

UnsplashMedia.defaultProps = {
  mediaOnPageLimit: 8,
  showAsNewMedia: false,
  testId: '',
};
