import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

// :: Components
import Dropdown from '../Dropdown/Dropdown';
import Checkbox from '../Checkbox/Checkbox';
import Button from '../Button/Button';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Images
import { DeleteIcon } from '../../images/shapes';

const RulePermissions = ({ name, ruleColumn, index }) => {
  return (
    <div className="grid grid-cols-4 w-full xl:w-1/2 my-3 justify-items-center">
      {ruleColumn?.map((el) => (
        <Field key={el.id} name={`${name}.${index}.${el.name}`}>
          {({ field }) => (
            <Checkbox
              checked={field.value}
              {...field}
              additionalCheckboxClasses="mr-0"
              additionalContainerClasses="flex-col-reverse items-center"
              additionalLabelClasses={twMerge(
                'uppercase text-sm mb-4 ml-0',
                index > 0 && 'xl:hidden',
              )}
              additionalCheckboxContainerClasses="self-auto"
              label={el.label}
            />
          )}
        </Field>
      ))}
    </div>
  );
};

const CTDDropdown = ({
  name,
  index,
  options,
  optionsFilterCallback,
  testId,
}) => {
  const { t } = useTranslation();
  return (
    <Field name={`${name}.${index}.contentTypeDefinition.id`}>
      {({ field, meta }) => {
        const formErrors = meta.touched ? meta.error : '';
        const noOptionError =
          field.value && !options.find((el) => el.value === field.value)
            ? t('ApiKeys.ErrorSelectedNoLongerValid')
            : null;

        return (
          <Dropdown
            options={options}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
            filterCallback={optionsFilterCallback}
            debounceTime={150}
            nullable
            placeholder={t('Global.SelectContentType')}
            error={formErrors || noOptionError}
            additionalClasses="min-w-0 grow"
            additionalDropdownErrorClasses="absolute"
            {...getTestProps(testId, 'content-type-definition', 'testId')}
          />
        );
      }}
    </Field>
  );
};

const PermissionsField = ({
  permissions,
  ruleColumn,
  options,
  optionsFilterCallback,
  renderBeforeDropdown,
  renderButtons,
  testId,
}) => (
  <FieldArray name={'permissions'}>
    {({ remove, push, name }) => (
      <div className="w-full">
        {permissions?.length > 0 &&
          permissions?.map((item, index) => (
            <div
              key={item.id || index}
              className={twMerge(
                'flex flex-col xl:flex-row w-full items-end px-5 mb-6 last:mb-0',
                'border-t xl:border-none',
              )}
              {...getTestProps(testId, `container-${index}`)}
            >
              <div
                className={twMerge(
                  'flex flex-row w-full xl:w-1/2 items-center',
                  'relative my-2 xl:mb-0 xl:mt-3 justify-stretch',
                )}
              >
                {renderBeforeDropdown && renderBeforeDropdown(name, index)}
                <CTDDropdown
                  name={name}
                  index={index}
                  options={options}
                  optionsFilterCallback={optionsFilterCallback}
                  testId={testId}
                />
                <Button
                  onClick={() => remove(index)}
                  iconImage={
                    <DeleteIcon className={'w-5 h-5 dark:text-gray-200'} />
                  }
                  buttonColor="borderless"
                  iconPosition="top"
                  additionalClasses={
                    'px-2 hover:opacity-50 justify-center w-fit'
                  }
                  {...getTestProps(testId, 'delete-icon')}
                />
              </div>

              <RulePermissions
                name={name}
                ruleColumn={ruleColumn}
                index={index}
              />
            </div>
          ))}

        {renderButtons && renderButtons(push)}
      </div>
    )}
  </FieldArray>
);

export default PermissionsField;

PermissionsField.propTypes = {
  /**
   * Headless role item
   */
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      contentTypeDefinition: PropTypes.shape({ id: PropTypes.string }),
    }),
  ),
  /**
   * On save callback
   */
  ruleColumn: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ),
  /**
   * Content types data
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.any,
        PropTypes.arrayOf(PropTypes.any),
      ]).isRequired,
    }),
  ),
  /**
   * Callback for filtering content types
   */
  optionsFilterCallback: PropTypes.func,
  /**
   * Callback for rendering components before CTDs dropdown
   */
  renderBeforeDropdown: PropTypes.func,
  /**
   * Callback for rendering buttons on bottom of permissions
   */
  renderButtons: PropTypes.func,
  /**
   * Test id for field
   */
  testId: PropTypes.string,
};

PermissionsField.defaultProps = {
  permissions: [],
  ruleColumn: [],
  options: [],
  optionsFilterCallback: /* istanbul ignore next */ () => null,
  renderBeforeDropdown: /* istanbul ignore next */ () => null,
  renderButtons: /* istanbul ignore next */ () => null,
  testId: '',
};
