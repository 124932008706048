import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

// :: Components
import Avatar from '../Avatar/Avatar';
import DateContainer from '../DateContainer/DateContainer';
import Panel from '../Panel/Panel';
import Loader from '../Loader/Loader';

// :: Lib
import { capitalizeText, getTestProps } from '../../lib/helpers';

const ContentObjectVersions = ({
  versions,
  loading,
  testId,
  selectVersionCallback,
  additionalClasses,
}) => {
  const { t } = useTranslation();

  const statusPillColor = {
    draft: 'bg-slate-400 text-white',
    review: 'bg-orange text-white',
    public: 'bg-lime text-white',
    archive: 'bg-red text-white',
  };

  return (
    <Panel
      title={t('ContentForm.Versions')}
      isCollapsable={false}
      additionalContainerClasses={twMerge('py-5 px-4', additionalClasses)}
      additionalTitleClasses="text-lg mb-1"
      additionalChildrenClasses={'pt-2'}
      open
      {...getTestProps(testId, `co-versions-container`, 'testId')}
    >
      {loading && (
        <Loader
          size="tiny"
          type="spinner-grid"
          additionalClasses="absolute top-[20px] left-1/2"
          {...getTestProps(testId, 'loader', 'testId')}
        />
      )}
      <div
        className={twMerge(
          'flex flex-col space-y-1.5 max-h-[340px] overflow-y-overlay scrollbar-sm',
          'before:absolute before:inset-0 before:border-l before:border-slate-200 dark:before:border-slate-800',
          'before:pointer-events-none before:mx-[2.7rem] before:md:mx-[2.2rem] before:mt-16 before:mb-5',
          loading && 'opacity-20',
        )}
      >
        {versions?.map((version) => (
          <div
            key={version.id}
            onClick={() => selectVersionCallback(version.version)}
            {...getTestProps(testId, `version-select-option-${version.id}`)}
            className={twMerge(
              'inline-flex items-center justify-between px-3 md:pl-1 md:pr-3 py-2',
              'cursor-pointer group',
            )}
          >
            <div className="inline-flex items-center space-x-1">
              <div
                className="flex items-center justify-center relative h-[32px] w-[32px] bg-gray rounded-full
                outline outline-4 outline-white dark:outline-gray-700 group-hover:outline-none"
              >
                <Avatar
                  userInitials={
                    version.editor
                      ? version.editor?.firstName[0] +
                        version.editor?.lastName[0]
                      : ''
                  }
                  additionalClasses="!cursor-pointer h-[26px] w-[26px] text-sm"
                  testId={testId}
                />
              </div>
              <div
                className="flex flex-col justify-start group-hover:bg-slate-50 dark:group-hover:bg-gray-900
                group-hover:rounded-md p-2"
              >
                <span className="text-sm text-stone-700 dark:text-white font-semibold relative">
                  {t('ObjectStatus.Ver')} {version.version}
                  <span
                    className={twMerge(
                      'h-[20px] py-0 px-[10px]',
                      'rounded-md',
                      'relative top-[-1px] right-[-10px]',
                      'inline-flex items-center justify-center w-max',
                      'text-xs font-normal',
                      'bg-gray-50 text-black',
                      statusPillColor[version.internal.workflowState] ||
                        'bg-gray text-black dark:!text-slate-950',
                    )}
                  >
                    {capitalizeText(
                      t(
                        `ObjectStatus.Option${version.internal.workflowState}`,
                        version.internal.workflowState,
                      ),
                    )}
                  </span>
                </span>

                <DateContainer
                  date={version.internal.updatedAt}
                  dateFormat={'L, LTS'}
                  isSemibold={false}
                  additionalClasses={'text-slate-400 text-sm'}
                  testId={testId}
                />
                <p
                  className="text-sm font-semibold dark:text-white"
                  {...getTestProps(testId, 'editor')}
                >
                  {version.editor
                    ? `${version.editor?.firstName} ${version.editor?.lastName}`
                    : `(${t('Global.DeletedUser')})`}
                </p>
                {version.apiKeyName && (
                  <p
                    className="text-sm dark:text-white"
                    {...getTestProps(testId, 'api-key-name')}
                  >
                    {version.apiKeyName}
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Panel>
  );
};

export default ContentObjectVersions;

ContentObjectVersions.propTypes = {
  /**
   * Content object versions array
   */
  versions: PropTypes.array.isRequired,
  /**
   * Test id for information container
   */
  testId: PropTypes.string,
  /**
   *  Callback for version onclick
   */
  selectVersionCallback: PropTypes.func,
  /**
   *  Loader
   */
  loading: PropTypes.bool,
  /**
   * Additional css classes
   */
  additionalClasses: PropTypes.string,
};

ContentObjectVersions.defaultProps = {
  loading: false,
  versions: [],
  testId: '',
  selectVersionCallback: /* istanbul ignore next */ () => null,
};
