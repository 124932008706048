import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';

// :: Contexts
import { ModalInstanceContext } from '../../contexts/ModalContext';

// :: Lib
import {
  authButtonsData,
  generateRedirectUrl,
  getMaskedOrganizationName,
  getLoginErrorText,
} from '../../lib/helpers';
import { simplePasswordSchema } from '../../lib/yupHelpers';

// :: Components
import Loader from '../../components/Loader/Loader';
import LinkButton from '../../components/LinkButton/LinkButton';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

// :: Images
import { WarningIcon } from '../../images/shapes';

const LoginModalForm = ({ onSubmit, email }) => {
  const modalInstance = useContext(ModalInstanceContext);
  const { t } = useTranslation();
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [isLogging, setIsLogging] = useState(false);

  const validationSchema = yup.object({
    password: simplePasswordSchema(t),
  });

  const formik = useFormik({
    initialValues: { password: '' },
    validateOnChange: validateAfterSubmit,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLogging(true);
      const [token, errors] = await onSubmit(values);
      if (!errors) modalInstance.resolve(token);
      formik.setStatus({ ...formik.status, errors });
      if (errors?.global) setError(errors.global);
      setIsLogging(false);
    },
  });

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setValidateAfterSubmit(true);
      formik.handleSubmit(event);
    },
    [formik],
  );

  const handleWindowMessage = useCallback(
    (event) => {
      if (event.data.type !== 'login') return;
      const params = new URLSearchParams(event.data.params);
      const status = params.get('status');
      const token = params.get('token');
      const registered = params.get('registered');

      let errorMessage = '';
      if (status) {
        errorMessage = getLoginErrorText(status, t);
      }
      if (token) {
        try {
          const userData = window.atob(token);
          const user = JSON.parse(userData);
          localStorage.setItem('cms.user', userData);

          TagManager.dataLayer({
            dataLayer: {
              event: 'login',
              user_id: user.data?.id,
              organization_id: user.data?.organization,
              organization_name: getMaskedOrganizationName(
                user.data?.organization_name,
              ),
              plan_id: user.data?.limits_plan?.id,
              plan_name: user.data?.limits_plan?.name,
            },
          });

          if (registered)
            TagManager.dataLayer({
              dataLayer: {
                event: 'sign_up',
              },
            });

          modalInstance.resolve(user.token);
        } catch (e) {
          errorMessage = t('AppSumo.TokenNotValid');
        }
      }
      if (errorMessage) {
        toast.error(errorMessage);
        setError(errorMessage);
      }
    },
    [t, modalInstance],
  );

  const openNewAuthWindow = useCallback(
    (url) => {
      const newWindow = window.open(
        `${url}?redirectUri=${generateRedirectUrl('/loginBlank.html', true)}`,
        '',
        'width=600,height=400,left=200,top=200',
      );
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed == 'undefined'
      ) {
        toast.error(t('Login.EnablePopups'));
        return;
      }
      window.addEventListener('message', handleWindowMessage);
    },
    [handleWindowMessage, t],
  );

  useEffect(() => {
    return () => {
      window.removeEventListener('message', handleWindowMessage);
    };
  }, [handleWindowMessage]);

  const onLogOut = useCallback(() => {
    modalInstance.resolve();
  }, [modalInstance]);

  return (
    <div className="-mb-8">
      <form
        id="login-modal-form"
        className="relative w-full"
        onSubmit={handleSubmit}
        noValidate={true}
      >
        <div className="mb-2">
          <Trans i18nKey="Global.SessionExpiredInfo">
            It seems that your session expired. To continue, please log in again
            using credentials for <span className="text-blue">{{ email }}</span>
            .
          </Trans>
        </div>
        <div className="flex flex-row items-center gap-2 w-full">
          <Input
            name="password"
            type="password"
            placeholder={t('Global.Password')}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.errors.password}
          />
          <Button
            buttonSize="sm"
            type="submit"
            disabled={isLogging}
            iconImage={
              isLogging ? <Loader size="small" type="spinner-grid" /> : null
            }
            additionalClasses={twMerge(
              'w-fit whitespace-nowrap',
              formik.errors.password && 'mb-6',
            )}
          >
            {t('Global.LogIn')}
          </Button>
        </div>
        {error && (
          <div className="text-red pt-2 inline-flex">
            <WarningIcon className="h-5 w-5 mr-2" />
            <div className="block text-left">{error}</div>
          </div>
        )}
        <div className="flex flex-wrap items-center justify-center gap-2 mt-5">
          {authButtonsData.map((auth) => (
            <Button
              key={auth.name}
              iconImage={auth.logo}
              buttonSize="sm"
              iconPosition="start"
              buttonColor="grayBordered"
              onClick={() => openNewAuthWindow(auth.url)}
              additionalClasses="w-fit !text-sm"
            >
              {t('Global.ContinueWith')} {auth.name}
            </Button>
          ))}
        </div>
        <div className="flex justify-center items-center">
          <LinkButton
            buttonSize="sm"
            onClick={onLogOut}
            link={'/logout'}
            buttonColor="borderless"
            noPaddings
            additionalClasses="w-fit h-fit pt-5 !text-sm"
          >
            {t('Global.LogOut')}
          </LinkButton>
        </div>
      </form>
    </div>
  );
};
export default LoginModalForm;

LoginModalForm.propTypes = {
  /**
   * Login form on submit handler
   */
  onSubmit: PropTypes.func,

  /**
   * User email
   */
  email: PropTypes.string.isRequired,
};

LoginModalForm.defaultProps = {
  onSubmit: /* istanbul ignore next */ () => null,
};
