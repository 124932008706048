import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getMediaUrl } from '../../lib/flotiq-client/api-helpers';
import {
  getTestProps,
  isImagePreviewSupported,
  strToChecksum,
} from '../../lib/helpers';
import Card from '../Card/Card';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import Tooltip from '../Tooltip/Tooltip';
import { twMerge } from 'tailwind-merge';
import Button from '../Button/Button';

// :: Images
import image1 from '../../images/graphics/type-definition-card-backgrounds-placeholders/card_bg-1-placeholder.jpg';
import image2 from '../../images/graphics/type-definition-card-backgrounds-placeholders/card_bg-2-placeholder.jpg';
import image3 from '../../images/graphics/type-definition-card-backgrounds-placeholders/card_bg-3-placeholder.jpg';
import image4 from '../../images/graphics/type-definition-card-backgrounds-placeholders/card_bg-4-placeholder.jpg';
import {
  DeleteIcon,
  PencilIcon,
  LinkIcon,
  ArrowUpRightIcon,
  ArrowUpThinIcon,
} from '../../images/shapes';

const backgrounds = [image1, image2, image3, image4];

const RelationCard = ({
  relation,
  relationType,
  titleField,
  relationsAreLoading,
  onClick,
  imageOverlay,
  thumbnail,
  relationsLength,
  onDelete,
  onUp,
  onDown,
  onEdit,
  onShow,
  disabled,
  idx,
  additionalContainerClasses,
  testId,
}) => {
  const { t } = useTranslation();
  const isImage = relation.type === 'image';
  const isMedia = relationType === '_media';

  const title =
    relation.internal?.objectTitle || relation[titleField] || relation.id;

  const imagePreviewSupported = isImagePreviewSupported(relation.extension);
  const getMediaUrlOnSuported = imagePreviewSupported
    ? getMediaUrl(relation, 280)
    : '';

  const mediaUrl = isImage ? getMediaUrlOnSuported : '';
  const relationUrl = isMedia
    ? mediaUrl
    : backgrounds[Math.abs(strToChecksum(relationType) % 4)];

  const relationTypeToShow = isMedia ? 'Media' : relationType;

  const onClickCallback = useCallback(() => {
    onClick?.(relation, relationType);
  }, [onClick, relationType, relation]);

  const cardThumbnail = useMemo(() => {
    if (thumbnail) return thumbnail;

    return isMedia &&
      (relationsAreLoading ||
        (isImage && !imagePreviewSupported) ||
        !isImage) ? (
      <div className="flex items-center justify-center h-full w-full">
        <div
          className={twMerge(
            'flex items-center justify-center rounded-full bg-slate-700 h-12 w-12',
            relationsAreLoading ? 'animate-pulse' : 'bg-blue-300',
          )}
        >
          {relationsAreLoading ? '' : relation.extension || t('Global.NA')}
        </div>
      </div>
    ) : (
      ''
    );
  }, [
    thumbnail,
    isMedia,
    relationsAreLoading,
    isImage,
    relation.extension,
    imagePreviewSupported,
    t,
  ]);

  const fullSizeImage = useMemo(() => {
    return (
      isMedia &&
      !relationsAreLoading &&
      onShow &&
      relation.id &&
      relation.extension && (
        <div className="hidden group-hover:flex absolute top-2 right-2">
          <Button
            buttonColor="gray"
            buttonSize="xs"
            iconColor="blue"
            testId={'open-full-size-media'}
            iconImage={<ArrowUpRightIcon className="w-3" />}
            additionalClasses="shrink-0"
            onClick={(event) => {
              event.stopPropagation();
              onShow(relation);
            }}
          />
        </div>
      )
    );
  }, [isMedia, onShow, relation, relationsAreLoading]);

  return (
    <Card
      onClick={onClick ? onClickCallback : null}
      body={
        relationsAreLoading ? (
          <div
            className="animate-pulse"
            {...getTestProps(testId, 'card-loading')}
          >
            <div className="grid grid-rows-2 gap-2 w-full">
              <div className="h-3.5 my-[3.5px] w-3/4 bg-slate-400 rounded" />
              <div className="h-3.5 my-[3.5px] w-1/2 bg-slate-500 rounded" />
            </div>
          </div>
        ) : (
          <div className="relative space-y-2">
            <div className="truncate dark:text-white" title={title}>
              <CopyToClipboard
                className="cursor-copy"
                text={title}
                onCopy={() => {
                  toast.success(t('ObjectsOfType.Copied', { text: title }));
                }}
                {...getTestProps(testId, `copy-title-${relation.id}`)}
              >
                <span onClick={(event) => event.stopPropagation()}>
                  {title}
                </span>
              </CopyToClipboard>
            </div>
            <div className="truncate text-sm text-slate-400 pr-5">
              {relationTypeToShow}
            </div>
            <div
              className="absolute right-0 bottom-0.5"
              onClick={(event) => event.stopPropagation()}
            >
              <div className="flex gap-2 absolute bottom-12 right-0">
                {onEdit && !isMedia && (
                  <Button
                    iconImage={
                      <PencilIcon className="text-gray-700 dark:text-gray-50 opacity-50 hover:opacity-100 w-3.5" />
                    }
                    buttonColor="borderless"
                    onClick={() => onEdit()}
                    additionalClasses="w-fit"
                    disabled={disabled}
                    {...getTestProps(
                      testId,
                      `edit-${relationType}-${relation.id}`,
                      'testId',
                    )}
                    noPaddings
                  />
                )}
                {onUp && relationsLength > 1 && (
                  <Button
                    onClick={onUp}
                    iconImage={
                      <ArrowUpThinIcon
                        className="h-3.5 text-gray-700 dark:text-gray-50 opacity-50
                        hover:opacity-100"
                      />
                    }
                    buttonColor="borderless"
                    additionalClasses="w-fit"
                    disabled={disabled || idx === 0}
                    {...getTestProps(
                      testId,
                      `up-${relationType}-${relation.id}`,
                      'testId',
                    )}
                    noPaddings
                  />
                )}
                {onDown && relationsLength > 1 && (
                  <Button
                    iconImage={
                      <ArrowUpThinIcon
                        className="rotate-180 h-3.5 text-gray-700 dark:text-gray-50 opacity-50
                        hover:opacity-100"
                      />
                    }
                    buttonColor="borderless"
                    additionalClasses="w-fit"
                    onClick={onDown}
                    disabled={disabled || idx === relationsLength - 1}
                    {...getTestProps(
                      testId,
                      `down-${relationType}-${relation.id}`,
                      'testId',
                    )}
                    noPaddings
                  />
                )}
                {onDelete && (
                  <Button
                    iconImage={
                      <DeleteIcon className="h-3.5 text-gray-700 dark:text-red-300 opacity-50 hover:opacity-100" />
                    }
                    buttonColor="borderless"
                    additionalClasses="w-fit"
                    onClick={onDelete}
                    disabled={disabled}
                    {...getTestProps(
                      testId,
                      `delete-${relationType}-${relation.id}`,
                      'testId',
                    )}
                    noPaddings
                  />
                )}
              </div>
              <Tooltip
                tooltip={t('ObjectsOfType.CopyIdTooltip')}
                tooltipPlacement="leftCenter"
                phoneTooltipPlacement="leftCenter"
              >
                <CopyToClipboard
                  text={relation.id}
                  onCopy={() =>
                    toast.success(
                      t('ObjectsOfType.Copied', { text: relation.id }),
                    )
                  }
                  {...getTestProps(testId, 'copy-id')}
                >
                  <LinkIcon className="w-4 h-4 cursor-copy ml-1 text-sm text-gray-700 dark:text-blue" />
                </CopyToClipboard>
              </Tooltip>
            </div>
          </div>
        )
      }
      imageUrl={relationsAreLoading ? '' : relationUrl}
      thumbnail={cardThumbnail}
      imageOverlay={imageOverlay ? imageOverlay : fullSizeImage}
      additionalContainerClasses={twMerge(
        'bg-gray dark:bg-slate-800 w-full h-24',
        additionalContainerClasses,
      )}
      additionalImageClasses={twMerge(isMedia ? 'w-fit h-fit' : 'w-full')}
      additionalImageContainerClasses="w-1/3 max-w-[150px] dark:bg-gray-700"
      additionalBodyClasses="px-4 py-2 w-2/3 grow"
      horizontal
      {...getTestProps(testId, '', 'testId')}
    />
  );
};

export default RelationCard;

RelationCard.propTypes = {
  /**
   * Relation object
   */
  relation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Relation object type
   */
  relationType: PropTypes.string,
  /**
   * Relation type field used to get object title
   */
  titleField: PropTypes.string,
  /**
   * If relations are loading
   */
  relationsAreLoading: PropTypes.bool,
  /**
   * On relation card click callback
   */
  onClick: PropTypes.func,
  /**
   * Relation card image overlay
   */
  imageOverlay: PropTypes.node,
  /**
   * Relation card image container
   */
  thumbnail: PropTypes.node,
  /**
   * All relations length
   */
  relationsLength: PropTypes.number,
  /**
   * If relation actions should be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Index of relation in relations array
   */
  idx: PropTypes.number,
  /**
   * Additional grid classes
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Relations card test id
   */
  testId: PropTypes.string,
};

RelationCard.defaultProps = {
  relation: {},
  relationType: '',
  titleField: '',
  relationsAreLoading: false,
  imageOverlay: '',
  thumbnail: '',
  relationsLength: 1,
  disabled: false,
  idx: 0,
  additionalContainerClasses: '',
  testId: '',
};
