import { useMemo } from 'react';
import { getLocalStorage } from '../utils/localStorage';

const useToken = () => {
  const userRaw = getLocalStorage('cms.user');
  const userDetails = useMemo(() => JSON.parse(userRaw), [userRaw]);
  return userDetails?.token;
};

export const getToken = () => {
  const userRaw = getLocalStorage('cms.user');
  const userDetails = JSON.parse(userRaw);
  return userDetails?.token;
};

export default useToken;
