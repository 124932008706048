import { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ModalInstanceContext, useModals } from '../../contexts/ModalContext';
import useToken from '../../hooks/useToken';
import { useContentTypes } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import { getCtdsFromQuery } from '../../lib/flotiq-client/api-helpers';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import { getContentType } from '../../lib/flotiq-client';
import PropertyModalFormContent from './PropertyModalFormContent/PropertyModalFormContent';
import PropertyModalCtdsContext from '../../contexts/PropertyModalCtdsContext';

const CTD_PARAMS = {
  limit: 20,
  order_by: 'label',
  order_direction: 'asc',
};

const PropertyModalForm = ({
  property,
  order,
  isNew,
  isDuplicate,
  testId,
  parentInputType,
}) => {
  const jwt = useToken();
  const { t } = useTranslation();
  const modal = useModals();
  const modalInstance = useContext(ModalInstanceContext);

  const {
    data: ctds,
    errors: ctdsErrors,
    isLoading: ctdsAreLoading,
  } = useContentTypes(CTD_PARAMS);

  const finalCtdOptions = useMemo(() => {
    const options = ctds
      .filter((ctd) => ctd.name === '_media' || !ctd.internal)
      .map((ctd) => ({
        label: ctd.label,
        value: ctd.name,
      }));
    options.unshift({
      label: t('ContentTypeForm.Descriptions.AnyType'),
      value: '',
    });
    return options;
  }, [ctds, t]);

  useApiErrorsToast(ctdsErrors);

  const getValidationType = useCallback(
    async (contentTypeName) => {
      try {
        const { body, status } = await getContentType(jwt, {
          contentTypeName,
        });
        checkResponseStatus(body, status);
        return body;
      } catch (error) {
        toast.error(
          t(
            error instanceof ResponseError
              ? 'ContentForm.CouldntFetch'
              : 'Form.CommunicationErrorMessage',
          ),
        );
        return null;
      }
    },
    [jwt, t],
  );

  const filterCtds = useCallback(
    async (query, _, setIsLoading) => {
      setIsLoading(true);

      const newCtds = (
        await getCtdsFromQuery(jwt, { ...CTD_PARAMS, label: query }, t)
      )
        .filter((ctd) => !ctd.internal || ctd.name === '_media')
        .map((ctd) => ({ label: ctd.label, value: ctd.name }));

      setIsLoading(false);

      if (
        t('ContentTypeForm.Descriptions.AnyType')
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      ) {
        newCtds.unshift({
          label: t('ContentTypeForm.Descriptions.AnyType'),
          value: '',
        });
      }
      return newCtds;
    },
    [jwt, t],
  );

  const propertyCtdsContextValue = useMemo(
    () => ({
      ctds: finalCtdOptions,
      getValidationType,
      ctdsAreLoading,
      filterCtds,
    }),
    [finalCtdOptions, ctdsAreLoading, filterCtds, getValidationType],
  );

  const validationSchema = yup.object({
    config: yup.object({
      label: yup.string().required(t('Form.FormErrorNotBlank')),
      options: yup.lazy((_, context) => {
        if (['select', 'radio'].indexOf(context.parent.inputType) < 0)
          return yup.array();
        return yup
          .array()
          .of(yup.string())
          .min(1, t('PropertyForm.Errors.OptionsLength'))
          .required(t('PropertyForm.Errors.OptionsLength'))
          .test({
            name: 'config.options',
            message: t('PropertyForm.Errors.OptionsDuplications'),
            test: (value) => {
              return value.length === new Set(value).size;
            },
          });
      }),
      items: yup.object().test({
        name: 'items',
        message: t('ContentTypeForm.Errors.OneProperty'),
        test: (value, context) => {
          if (context.parent.inputType !== 'object') return true;

          return value.order && value.order.length > 0;
        },
      }),
    }),
    key: yup
      .string()
      .matches(
        '^[a-zA-Z0-9_]*[a-zA-Z_]*[a-zA-Z0-9_]+$',
        t('PropertyForm.Errors.Key'),
      )
      .required(t('Form.FormErrorNotBlank'))
      .test({
        name: 'key',
        message: t('PropertyForm.Errors.UniqueKey'),
        test: (value) => {
          if (isDuplicate && property?.key === value) return false;
          return (
            order.filter((keys) => keys !== property?.key).indexOf(value) < 0
          );
        },
      }),
    schema: yup.object({
      type: yup.string().required(t('PropertyForm.Errors.Type')),
      pattern: yup.string().test({
        name: 'schema.pattern',
        message: t('PropertyForm.Errors.Pattern'),
        test: (value) => {
          try {
            new RegExp(value);
            return true;
          } catch (e) {
            return false;
          }
        },
      }),
      default: yup.lazy((_, context) => {
        const config = context.from?.[1]?.value?.config;
        const schemaType = context.parent?.type;
        if (!schemaType) return yup.mixed();

        let validator = yup[schemaType]();
        if (config?.inputType === 'email') {
          validator = validator.email(t('ContentForm.Errors.Email'));
        }
        if (['select', 'radio'].indexOf(config?.inputType) > -1) {
          validator = validator.oneOf(
            config?.options || [],
            t('ContentForm.Errors.OptionNotFound'),
          );
        }
        if (config?.inputType === 'geo') {
          const latError = t('ContentForm.Errors.Coordinates', {
            coordinate: t('ContentForm.Latitude'),
            max: 90,
            min: -90,
          });
          const lonError = t('ContentForm.Errors.Coordinates', {
            coordinate: t('ContentForm.Longitude'),
            max: 180,
            min: -180,
          });
          validator = validator.shape({
            lat: yup.number().min(-90, latError).max(90, latError),
            lon: yup.number().min(-180, lonError).max(180, lonError),
          });
        }
        if (config?.validation) {
          if (config.validation.relationContenttype)
            validator = validator.of(
              yup.object().shape({
                internal: yup.bool(),
                dataUrl: yup
                  .string()
                  .matches(
                    new RegExp(
                      `^.*${config?.validation.relationContenttype}.*$`,
                    ),
                    t('ContentForm.Errors.DataUrl'),
                  ),
              }),
            );
          if (!config.validation.relationMultiple)
            validator = validator.max(1, t('ContentForm.Errors.MaxLength'));
        }

        const pattern = context.parent?.pattern;
        if (pattern) {
          try {
            new RegExp(pattern);
            const errorMessage =
              config?.inputType === 'dateTime'
                ? t('ContentForm.Errors.Pattern', { pattern })
                : t('PropertyForm.Errors.Default');

            validator = validator.matches(pattern, errorMessage);
          } catch {
            return validator;
          }
        }
        return validator;
      }),
    }),
  });

  const handleSumbit = useCallback(
    async (values) => {
      if (property && property.key !== values.key && !isNew) {
        const result = await modal.confirmation(
          t('PropertyForm.ChangeKeyConfirm'),
        );
        if (!result) return;
      }

      modalInstance.resolve(values);
    },
    [property, isNew, modalInstance, modal, t],
  );

  return (
    <Formik
      initialValues={
        property
          ? JSON.parse(JSON.stringify(property))
          : {
              key: '',
              config: { label: '', helpText: '', unique: false },
              schema: { type: '' },
              required: false,
            }
      }
      onSubmit={handleSumbit}
      validationSchema={validationSchema}
    >
      <PropertyModalCtdsContext.Provider value={propertyCtdsContextValue}>
        <PropertyModalFormContent
          property={property}
          testId={testId}
          parentInputType={parentInputType}
        />
      </PropertyModalCtdsContext.Provider>
    </Formik>
  );
};

export default PropertyModalForm;

PropertyModalForm.propTypes = {
  /**
   * Property object to edit
   */
  property: PropTypes.object,
  /**
   * Order of current content type
   */
  order: PropTypes.array,
  /**
   * If property is new
   */
  isNew: PropTypes.bool,
  /**
   * If property is duplication
   */
  isDuplicate: PropTypes.bool,
  /**
   * Test id for property modal form
   */
  testId: PropTypes.string,
};

PropertyModalForm.defaultProps = {
  order: [],
  isNew: false,
  isDuplicate: false,
  testId: '',
};
