import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import TagManager from 'react-gtm-module';

// :: Lib
import {
  getFormatedSize,
  getTestProps,
  getPriceIdByLocalisation,
  getPlanSubtitle,
} from '../../../lib/helpers';
import {
  checkoutPlanProperty,
  checkoutPlan,
  getPublicPlans,
} from '../../../lib/flotiq-client';
import {
  ResponseError,
  checkResponseStatus,
} from '../../../lib/flotiq-client/response-errors';
import { fetchMethod } from '../../../lib/flotiq-client/base-request';

// :: Hooks
import { useConstraints } from '../../../hooks/api';
import useApiErrorsToast from '../../../hooks/api/useApiErrorsToast';
import useToken from '../../../hooks/useToken';
import useOnce from '../../../hooks/useOnce';

// :: Components
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Heading from '../../../components/Heading/Heading';
import LinkButton from '../../../components/LinkButton/LinkButton';
import Loader from '../../../components/Loader/Loader';

// :: Components Inner
import PlanItem from '../PlanItem/PlanItem';

// :: Contexts
import { useModals } from '../../../contexts/ModalContext';
import UserContext from '../../../contexts/UserContext';

// :: Images
import { CrownIcon } from '../../../images/shapes';

const Usage = ({ testId }) => {
  const { t } = useTranslation();
  const { userStorage, baseUserEventData } = useContext(UserContext);

  const token = useToken();
  const modal = useModals();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingBuyExtra, setLoadingBuyExtra] = useState('');
  const [loadingBuyPlan, setLoadingBuyPlan] = useState('');
  const userPlanName = userStorage?.data?.limits_plan?.name;

  // :: Plans
  const [plansData, setPlansData] = useState();
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [countryRegion, setCountryRegion] = useState();

  const { entity: ctoCount, errors: ctoErrors } = useConstraints('cto-count');
  const { entity: ctdCount, errors: ctdErrors } = useConstraints('ctd-count');
  const { entity: mediaCount, errors: mediaErrors } =
    useConstraints('media-sum-size');
  const { entity: apiKeysCount, errors: apiKeysErrors } =
    useConstraints('scoped-keys-count');
  const { entity: teamMembersCount, errors: teamMembersErrors } =
    useConstraints('team-members-count');
  const { entity: webhooksCount, errors: webhooksErrors } =
    useConstraints('webhooks-count');

  useApiErrorsToast(ctoErrors);
  useApiErrorsToast(ctdErrors);
  useApiErrorsToast(mediaErrors);
  useApiErrorsToast(apiKeysErrors);
  useApiErrorsToast(teamMembersErrors);
  useApiErrorsToast(webhooksErrors);

  const handleFetchLocalisation = useCallback(async () => {
    const baseUrl = process.env.REACT_APP_CHECK_LOCALISATION_URL;
    if (!baseUrl) return;

    await fetchMethod(baseUrl, 'GET', undefined, {
      skipContentType: true,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result?.countryRegion?.isoCode) {
          setCountryRegion(result?.countryRegion?.isoCode);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, []);
  useOnce(handleFetchLocalisation);

  const getPublicPlansData = useCallback(async () => {
    setLoadingPlans(true);

    try {
      const { body, status } = await getPublicPlans(token);
      checkResponseStatus(body, status);
      if (body.data) {
        setPlansData(body.data);
      }
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('AccountSettings.ErrorCouldntFetchPlans'));
      }
      toast.error(error.message);
    }

    setLoadingPlans(false);
  }, [token, t]);

  useEffect(() => {
    getPublicPlansData();
  }, [getPublicPlansData]);

  const handlePaymentResultModal = useCallback(async () => {
    const paymentSuccessToken = searchParams.has('success') && 'success';
    const paymentCancelToken = searchParams.has('cancel') && 'cancel';
    const paymentTypeToken = searchParams.get('type') || null;
    let modalConfig;

    switch (
      `${paymentSuccessToken || paymentCancelToken}_${paymentTypeToken}`
    ) {
      case 'success_null':
      case 'success_plan_parameter':
        modalConfig = {
          title: t('AccountSettings.PurchaseModal.SuccessTitle'),
          titleIcon: <CrownIcon className="h-7 mr-1" />,
          id: 'payment-modal-success',
          contentTransKey: 'AccountSettings.PurchaseModal.SuccessMessage',
        };

        TagManager.dataLayer({
          dataLayer: {
            event: 'limit_increase',
            type: searchParams.get('propertyName') || 'plan_parameter',
          },
        });

        TagManager.dataLayer({
          dataLayer: {
            type: undefined,
          },
        });

        break;
      case 'success_plan':
        modalConfig = {
          title: t('AccountSettings.PurchaseModal.SuccessPlanTitle'),
          titleIcon: <CrownIcon className="h-7 mr-1" />,
          id: 'payment-modal-success-plan',
          contentTransKey: 'AccountSettings.PurchaseModal.SuccessPlanMessage',
        };

        TagManager.dataLayer({
          dataLayer: {
            event: 'purchase',
            type: 'plan',
            plan_id: searchParams.get('planId') || '',
            plan_name: searchParams.get('planName') || '',
          },
        });

        TagManager.dataLayer({
          dataLayer: {
            type: undefined,
            ...baseUserEventData,
          },
        });

        break;
      case 'cancel_plan':
        modalConfig = {
          title: t('AccountSettings.PurchaseModal.CancelPlanTitle'),
          id: 'payment-modal-cancel-plan',
          contentTransKey: 'AccountSettings.PurchaseModal.CancelPlanMessage',
        };
        break;
      default:
        break;
    }

    if (modalConfig) {
      await modal({
        id: modalConfig.id,
        title: (
          <div className="flex justify-center items-center !py-2">
            {modalConfig.titleIcon}
            <Heading level={3} additionalClasses={'!p-0 dark:text-white'}>
              {modalConfig.title}
            </Heading>
          </div>
        ),
        content: (
          <Trans
            i18nKey={modalConfig.contentTransKey}
            components={{
              strong: <strong className={'my-4 text-lg'} />,
              p: <p className={'my-4 text-lg'} />,
            }}
          />
        ),
        dialogAdditionalClasses: '!max-w-[650px] !pb-15',
        buttons: [],
      });

      searchParams.delete('success');
      searchParams.delete('cancel');
      searchParams.delete('type');
      setSearchParams(searchParams);
    }
  }, [searchParams, t, baseUserEventData, modal, setSearchParams]);

  useEffect(() => {
    handlePaymentResultModal();
  }, [handlePaymentResultModal]);

  const accountDetails = useMemo(
    () => [
      {
        key: 'title',
        label: t('Global.AccountDetails'),
        isTitle: true,
      },
      {
        key: 'price',
        label: t('AccountSettings.Price'),
        value:
          userStorage?.data?.limits_plan?.price === -1
            ? '-'
            : `$${userStorage?.data?.limits_plan?.price} / ${t(
                'AccountSettings.Month',
              )}`,
      },
      {
        key: 'file',
        label: t('AccountSettings.FileSize'),
        value:
          userStorage?.data?.limits_plan?.max_file_quota === -1
            ? t('Global.Unlimited')
            : userStorage?.data?.limits_plan?.max_file_quota,
      },
      {
        key: 'api',
        label: t('AccountSettings.EnabledApiDocs'),
        value: (
          <Checkbox
            checked={userStorage?.data?.limits_plan?.scoped_keys_docs}
            additionalCheckboxClasses={twMerge(
              '!bg-gray !cursor-default checked:!bg-blue',
              userStorage?.data?.limits_plan?.scoped_keys_docs
                ? '!border-blue'
                : '!border-gray',
            )}
            disabled
            circular
            {...getTestProps(testId, 'enabled-docs', 'testId')}
          />
        ),
      },
    ],
    [t, userStorage?.data?.limits_plan, testId],
  );

  const findApiCallsSuffix = useCallback((limit) => {
    if (limit < 1000) return limit;
    else if (limit < 1000000) return limit / 1000 + 'K';
    return limit / 1000000 + 'M';
  }, []);

  const resourcesDetails = useMemo(
    () => [
      {
        key: 'title',
        label: t('AccountSettings.Resources'),
        isTitle: true,
      },
      {
        key: 'cto',
        property: 'cto_limit',
        label: t('Global.ContentTypeObjects'),
        value: ctoCount?.data,
        limit: userStorage?.data?.limits_plan?.cto_limit,
      },
      {
        key: 'ctd',
        label: t('Global.ContentTypeDefinitions'),
        value: ctdCount?.data,
        limit: userStorage?.data?.limits_plan?.ctd_limit,
        property: 'ctd_limit',
      },
      {
        key: 'media',
        label: t('Global.FileQuota'),
        value: mediaCount?.data + ' MB',
        limit: userStorage?.data?.limits_plan?.file_quota,
        property: 'file_quota',
      },
      {
        key: 'apiKeys',
        label: t('Global.ScopedApiKeysCount'),
        value: apiKeysCount?.data,
        limit: userStorage?.data?.limits_plan?.scoped_keys_limit,
      },
      {
        key: 'team',
        label: t('Global.TeamMembers'),
        value: teamMembersCount?.data,
        limit: userStorage?.data?.limits_plan?.team_members_limit,
        property: 'team_members_limit',
      },
      {
        key: 'webhooks',
        label: t('Global.Webhooks'),
        value: webhooksCount?.data,
        limit: userStorage?.data?.limits_plan?.webhooks_limit,
      },
      {
        key: 'apiCalls',
        label: t('Global.APICalls'),
        value: null,
        limit: findApiCallsSuffix(
          userStorage?.data?.limits_plan?.api_calls_limit,
        ),
        property: 'api_calls_limit',
      },
    ],
    [
      t,
      ctoCount?.data,
      userStorage?.data?.limits_plan,
      ctdCount?.data,
      mediaCount?.data,
      apiKeysCount?.data,
      teamMembersCount?.data,
      webhooksCount?.data,
      findApiCallsSuffix,
    ],
  );

  const handleBuyExtra = useCallback(
    async (propertyName) => {
      try {
        setLoadingBuyExtra(propertyName);

        const { body, status } = await checkoutPlanProperty(token, {
          propertyName: propertyName,
          uri: `${
            process.env.PUBLIC_URL || window.origin
          }/usage?propertyName=${propertyName}`,
        });
        checkResponseStatus(body, status);
        setLoadingBuyExtra('');
        window.location.assign(body.url);
      } catch (e) {
        toast.error(t('Form.CommunicationErrorMessage'));
        setLoadingBuyExtra('');
      }
    },
    [t, token],
  );

  const handleBuyPlan = useCallback(
    async (priceId, planVisibleName, name, id) => {
      setLoadingBuyPlan(priceId);

      try {
        const { body, status } = await checkoutPlan(token, {
          priceId: getPriceIdByLocalisation(
            priceId,
            planVisibleName,
            countryRegion,
          ),
          uri: `${
            process.env.REACT_APP_BUY_PLAN_REDIRECT_URL ||
            process.env.PUBLIC_URL ||
            window.origin
          }/usage?planName=${name}&planId=${id}`,
        });
        checkResponseStatus(body, status);
        setLoadingBuyPlan('');
        window.location.assign(body.url);
      } catch (e) {
        toast.error(t('Form.CommunicationErrorMessage'));
        setLoadingBuyPlan('');
      }
    },
    [t, countryRegion, token],
  );

  const BuyExtraBtn = useCallback(
    ({ detail }) => {
      const availableToExtend = ['cto', 'ctd', 'media', 'team', 'apiCalls'];
      if (!availableToExtend.includes(detail.key) || !detail?.property) {
        return;
      }

      return (
        <Button
          buttonSize={'sm'}
          as={'button'}
          disabled={detail.limit === -1}
          additionalClasses={'w-fit'}
          additionalChildrenClasses={'flex flex-row-reverse'}
          {...getTestProps(testId, `buy-extra-${detail.key}`)}
          onClick={() => handleBuyExtra(detail.property)}
        >
          {t('AccountSettings.BuyExtra')}
          <TailSpin
            height="15"
            width="20"
            color="white"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperClass="mr-2"
            visible={loadingBuyExtra === detail.property}
          />
        </Button>
      );
    },
    [testId, t, loadingBuyExtra, handleBuyExtra],
  );

  const CurrentPlanData = useCallback(() => {
    const userPlanBaseName = userPlanName?.split(' ')?.[0];
    const getCurrentSelectedPlanDisplayOrder = plansData?.filter(
      (el) => el.visible && el.visibleName === userPlanBaseName,
    )?.[0]?.displayOrder;

    return plansData?.map((plan) => {
      // Hide Plan Rules (#24121):
      // * show only with visible param, hide "Free" plan,
      // * hide lower plan and hide current selected
      if (
        !plan.visible ||
        plan.price === 0 ||
        plan.displayOrder <= getCurrentSelectedPlanDisplayOrder
      ) {
        return null;
      }

      const planDetails = [
        {
          id: `monthly-calls-${plan.name}`,
          value: getFormatedSize(
            plan.apiCallsLimit,
            'compact-number',
            t('AccountSettings.PlanDetails.Unlimited', {
              context: 'it',
            }),
          ),
          title: t('AccountSettings.PlanDetails.MonthlyCalls'),
        },
        {
          id: `objects-limits-${plan.name}`,
          value: getFormatedSize(
            plan.ctoLimit,
            'compact-number',
            t('AccountSettings.PlanDetails.Unlimited'),
          ),
          title: t('AccountSettings.PlanDetails.ObjectsLimits'),
        },
        {
          id: `object-types-${plan.name}`,
          value: getFormatedSize(
            plan.ctdLimit,
            '',
            t('AccountSettings.PlanDetails.Unlimited', {
              context: 'it',
            }),
          ),
          title: t('AccountSettings.PlanDetails.ObjectTypes'),
        },
        {
          id: `file-quota-${plan.name}`,
          value: getFormatedSize(
            plan.fileQuota,
            'MB',
            t('AccountSettings.PlanDetails.Unlimited'),
          ),
          title: t('AccountSettings.PlanDetails.FileQuota'),
        },
        {
          id: `scoped-api-keys-${plan.name}`,
          value: getFormatedSize(
            plan.scopedKeysLimit,
            '',
            t('AccountSettings.PlanDetails.Unlimited', {
              context: 'it',
            }),
          ),
          title: t('AccountSettings.PlanDetails.ScopedAPIKeys'),
        },
        {
          id: `scoped-api-docs-${plan.name}`,
          title: t('AccountSettings.PlanDetails.ScopedAPIDocs'),
          hidden: !plan.scopedKeysDocs,
        },
      ];

      if (plan.support) {
        planDetails.push({
          id: `support-${plan.name}`,
          value: plan.support,
          title: t('AccountSettings.PlanDetails.Support'),
        });
      }

      let planValidate;
      if (plan.price > 0) {
        // Settings for payable plans
        planValidate = {
          text: t('AccountSettings.GoToCheckout'),
          subtitle: getPlanSubtitle(
            plan.visibleName,
            plan.price,
            'USD/mo',
            countryRegion,
          ),
          onClick: () =>
            handleBuyPlan(
              plan.stripePriceApiIdMonthly,
              plan.visibleName,
              plan.name,
              plan.id,
            ),
          disabled: !plan.stripePriceApiIdMonthly,
          disabledText: t('AccountSettings.PlanDetails.DisabledCheckoutButton'),
          loading: loadingBuyPlan === plan.stripePriceApiIdMonthly,
        };
      } else if (plan.price === -1) {
        // For Enterprise contact form link
        planValidate = {
          onClick: () =>
            window.open(
              process.env.REACT_APP_FLOTIQ_PAGE_CONTACT,
              '_blank',
              'noopener',
            ),
          text: t('AccountSettings.ContactUs'),
          disabled: !process.env.REACT_APP_FLOTIQ_PAGE_CONTACT,
          disabledText: !process.env.REACT_APP_FLOTIQ_PAGE_CONTACT
            ? t('AccountSettings.PlanDetails.DisabledContactUs')
            : null,
          loading: false,
        };
      }

      return (
        <PlanItem
          key={plan.id}
          details={planDetails}
          title={t('AccountSettings.UpgradeTo', { name: plan.visibleName })}
          subtitle={planValidate?.subtitle}
          onClick={planValidate?.onClick}
          onClickText={planValidate?.text}
          disabledButton={planValidate?.disabled}
          disabledText={planValidate?.disabledText}
          loading={planValidate?.loading}
          {...getTestProps(testId, `plan-item-${plan.name}`, 'testId')}
        />
      );
    });
  }, [
    plansData,
    userPlanName,
    testId,
    handleBuyPlan,
    loadingBuyPlan,
    countryRegion,
    t,
  ]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 h-full">
      <div className="md:col-span-3 bg-white dark:bg-slate-950 rounded-lg mx-5 xl:ml-0 xl:mr-3.5 mb-7">
        <div
          className="h-full flex flex-col gap-10 text-xl md:text-2xl xl:text-3xl font-semibold p-4 md:p-8 xl:p-12
          dark:text-white"
        >
          <div
            className="mt-2 md:mt-5 w-full h-fit rounded-lg bg-gray-150 dark:bg-gray-900
             inline-flex items-center p-4 xl:px-8 xl:py-10 gap-4 font-normal"
          >
            {t('AccountSettings.Plan')}:
            <span className="font-bold" {...getTestProps(testId, 'plan-name')}>
              {userPlanName}
            </span>
          </div>
          <div>
            {accountDetails.map((detail) => (
              <div key={detail.key}>
                {detail.isTitle ? (
                  <div className="pb-2 md:pb-5">{detail.label}</div>
                ) : (
                  <div
                    className="py-2 md:py-5 grid grid-cols-2 gap-2 text-base md:text-lg items-center"
                    {...getTestProps(testId, detail.key)}
                  >
                    <div className="font-normal">{detail.label}</div>
                    {detail.value}
                  </div>
                )}
                <div className="h-0 border border-slate-200 dark:border-slate-800 w-full" />
              </div>
            ))}
          </div>
          <div>
            {resourcesDetails.map((detail) => (
              <div key={detail.key}>
                {detail.isTitle ? (
                  <div className="pb-2 md:pb-5">{detail.label}</div>
                ) : (
                  <div
                    className="py-2 md:py-5 grid grid-cols-3 gap-2 text-base md:text-lg items-center"
                    {...getTestProps(testId, detail.key)}
                  >
                    <div className="font-normal">{detail.label}</div>
                    {detail.value}
                    {detail.value !== null && ' / '}
                    {detail.limit === -1 ? t('Global.Unlimited') : detail.limit}
                    <BuyExtraBtn detail={detail} />
                  </div>
                )}
                <div className="h-0 border border-slate-200 dark:border-slate-800 w-full" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="px-4 xl:pl-3.5 xl:pr-0 pb-7 border-t md:border-t-0 md:border-l dark:border-slate-800">
        <div
          className="h-full bg-white dark:bg-slate-950 rounded-lg py-4 md:py-4 px-4 xl:px-6 flex flex-col
          items-left"
          {...getTestProps(testId, 'right-sidebar-container')}
        >
          {userPlanName !== 'Enterprise' && (
            <LinkButton
              link={process.env.REACT_APP_PRICING}
              target="_blank"
              rel="noreferrer"
              additionalClasses="h-9 md:h-9 text-sm md:text-base px-2 2xl:text-2xl mb-2 md:mb-1 underline"
              buttonColor="borderless"
              noPaddings={true}
              {...getTestProps(testId, 'pricing', 'testId')}
            >
              {t('AccountSettings.ComparePlans')}
            </LinkButton>
          )}

          {loadingPlans ? (
            <div
              className="flex justify-center items-center h-14"
              {...getTestProps(testId, 'plans-loader')}
            >
              <Loader size={'small'} type={'spinner-grid'} />
            </div>
          ) : (
            <CurrentPlanData testId={testId} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Usage;

Usage.propTypes = {
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

Usage.defaultProps = {
  testId: '',
};
