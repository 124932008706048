import { useState, useCallback, useEffect, useMemo } from 'react';
import useDebounceCallback from '../../useDebounceCallback';
import useToken from '../../useToken';
import useBaseDataLoad from '../useBaseDataLoad';
import { handleErrors } from './default-error-handler';

const DEFAULT_STATE = {
  entity: null,
  isLoading: true,
  status: 0,
  errors: null,
};

export const createEntityHook = (
  getEntity,
  putEntity,
  removeEntity,
  idFieldName = 'id',
  defaultParams = {},
  defaultOptions = {},
) => {
  /**
   * @param { string } id
   * @param { { handleErrors: boolean, debounce: number } } hookOptions
   * @returns {{
   *  entity: object|null,
   *  isLoading: bool,
   *  status: number,
   *  errors: object|null ,
   *  reload: function,
   *  updateEntity: function,
   *  deleteEntity: function
   * }|[
   *  entity: object|null,
   *  isLoading: bool,
   *  status: number,
   *  errors: object|null ,
   *  reload: function,
   *  updateEntity: function,
   *  deleteEntity: function
   * ]}
   */
  return (id, hookParams = defaultParams, hookOptions = defaultOptions) => {
    const { debounce = 0 } = hookOptions;
    const [{ entity, isLoading, status, errors }, setResponseInfo] =
      useState(DEFAULT_STATE);
    const jwt = useToken();
    const params = useMemo(
      () => ({ [idFieldName]: id, ...hookParams }),
      [id, hookParams],
    );
    const loadData = useBaseDataLoad(jwt, getEntity, params, hookOptions);

    const reload = useCallback(async () => {
      if (!jwt || !id || hookOptions?.pause) return;
      setResponseInfo((responseInfo) =>
        responseInfo.isLoading
          ? responseInfo
          : {
              ...responseInfo,
              isLoading: true,
            },
      );
      try {
        const { body, status } = await loadData();
        setResponseInfo({
          entity: body,
          isLoading: false,
          status,
          errors: null,
        });
      } catch (error) {
        handleErrors(error, setResponseInfo, DEFAULT_STATE);
      }
    }, [loadData, jwt, id, hookOptions?.pause]);

    const debouncedReload = useDebounceCallback(reload, debounce);
    useEffect(debouncedReload, [debouncedReload]);

    const updateEntity = useCallback(
      async (values, options = {}) => {
        const { updateLocal = true } = options;
        const response = await putEntity(jwt, {
          [idFieldName]: entity[idFieldName],
          ...values,
        });
        if (updateLocal && response.ok && response.body) {
          setResponseInfo({
            status,
            isLoading,
            entity: { ...entity, ...response.body },
          });
        }
        return response;
      },
      [jwt, entity, status, isLoading],
    );

    const deleteEntity = useCallback(
      async (params = {}, options = {}) => {
        const { updateLocal = true } = options;
        const response = await removeEntity(jwt, {
          [idFieldName]: entity[idFieldName],
          ...params,
        });
        if (updateLocal && response.ok) {
          setResponseInfo({
            status,
            isLoading,
            entity: null,
          });
        }
        return response;
      },
      [jwt, entity, status, isLoading],
    );

    const result = [
      entity,
      isLoading,
      status,
      errors,
      debouncedReload,
      updateEntity,
      deleteEntity,
    ];
    Object.assign(result, {
      entity,
      isLoading,
      status,
      errors,
      reload: debouncedReload,
      updateEntity,
      deleteEntity,
    });
    return result;
  };
};
