import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import slug from 'slug';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// :: Components
import Button from '../../components/Button/Button';
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';
import Input from '../../components/Input/Input';
import Tooltip from '../../components/Tooltip/Tooltip';
import DraggableCard from './DraggableCard/DraggableCard';

// :: Lib
import { formatErrorToString, getTestProps } from '../../lib/helpers';

// :: Helpers
import {
  changeOrderDown,
  changeOrderUp,
  deleteProperty,
  updateOrder,
  updateProperties,
} from './helpers/parser';

// :: Contexts
import { useModals } from '../../contexts/ModalContext';
import ContentTypeFormContext from '../../contexts/ContentTypeFormContext';

// :: Hooks
import useDebounceCallback from '../../hooks/useDebounceCallback';

// :: Form
import PropertyModalForm from '../PropertyModalForm/PropertyModalForm';
import { EDITABLE } from '../PropertyModalForm/PropertiesSettings/propertiesFields';

// :: Images
import { QuestionMarkCircleIcon } from '../../images/shapes';

// :: Yup Add Methods
yup.addMethod(yup.string, 'notStartWithSlash', function (message) {
  return this.test('notStartWithSlash', message, function (string) {
    return string?.[0] !== '/';
  });
});
yup.addMethod(yup.string, 'noMoreThanOneSlash', function (message) {
  return this.test('noMoreThanOneSlash', message, function (string) {
    return (string?.match(/\//g) || []).length <= 1;
  });
});

const parsePropErrors = (
  errors,
  newPropertiesErrors,
  key,
  findRegexp,
  group,
) => {
  const propsErrors = Object.keys(errors).filter((errorKey) =>
    errorKey.includes(key),
  );
  if (propsErrors.length) {
    propsErrors.forEach((errorKey) => {
      let { propName, prop } = errorKey.match(findRegexp).groups;
      if (!prop) prop = 'global';
      if (prop === 'required') {
        newPropertiesErrors[propName] = {
          ...newPropertiesErrors[propName],
          required: errors[errorKey],
        };
      } else {
        newPropertiesErrors[propName] = {
          ...newPropertiesErrors[propName],
          [group]: {
            ...newPropertiesErrors[propName]?.[group],
            [prop]: errors[errorKey],
          },
        };
      }
    });
  }
};

const parsePropPropertiesErrors = (errors, newPropertiesErrors) => {
  if (Object.keys(errors).length) {
    Object.keys(errors).forEach((errorKey) => {
      const { propName, prop } = errorKey.match(
        /(?<propName>[^.\]]*)]?(?:\.(?<prop>[^.]+))?/,
      ).groups;
      newPropertiesErrors[propName] = {
        ...newPropertiesErrors[propName],
        global: {
          ...newPropertiesErrors[propName]?.global,
          [prop || '']: errors[errorKey],
        },
      };
    });
  }
};

const checkListItems = (value, context, path, t) => {
  const errors = [];
  Object.keys(value).forEach((propName) => {
    const property = value[propName];
    if (property?.items) {
      if (
        !property.items.propertiesConfig ||
        Object.keys(property.items.propertiesConfig).length === 0
      ) {
        errors.push(
          context.createError({
            path: `${path}.${propName}.items`,
            message: t('ContentTypeForm.Errors.OneProperty'),
          }),
        );
      } else {
        errors.push(
          ...checkListItems(
            property.items.propertiesConfig,
            context,
            `${path}.${propName}.items.propertiesConfig`,
            t,
          ),
        );
      }
    }
  });
  return errors;
};

const ContentTypeForm = ({
  contentType,
  isEditing,
  onSubmit,
  disabled,
  navigateOnSave,
  onMediaUpload,
  testId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modal = useModals();

  const metaPath = 'metaDefinition';
  const schemaPath = 'schemaDefinition.allOf[1]';
  const requiredBasePath = 'schemaDefinition';

  const [fieldsToDelete, setFieldsToDelete] = useState([]);
  const oldOrder = useMemo(
    () => contentType?.metaDefinition?.order || [],
    [contentType],
  );
  const [propertiesErrors, setPropertiesErrors] = useState({});
  const [transformError, setTransformError] = useState();
  const [transformCoError, setTransformCoError] = useState();
  const [dirty, setDirty] = useState(false);

  const validationSchema = yup.object({
    label: yup
      .string()
      .required(t('Form.FormErrorNotBlank'))
      .notStartWithSlash(t('Form.FormErrorNotStartWithSlash'))
      .noMoreThanOneSlash(t('Form.FormErrorNoMoreThanOneSlash')),
    name: yup
      .string()
      .matches('^[a-zA-Z_]*$', t('ContentTypeForm.Errors.ApiKey'))
      .required(t('Form.FormErrorNotBlank')),
    metaDefinition: yup.object({
      propertiesConfig: yup.object().test({
        name: 'metaDefinition.propertiesConfig',
        message: t('ContentTypeForm.Errors.OneProperty'),
        test: (value, context) => {
          if (Object.keys(value).length === 0) return false;
          else {
            const errors = checkListItems(value, context, context.path, t);
            return errors.length ? new yup.ValidationError(errors) : true;
          }
        },
      }),
    }),
  });

  const generateSlug = useCallback((formik) => {
    if (!formik.values.label) return '';
    if (formik.values.name) return formik.values.name;
    formik.setFieldValue(
      'name',
      slug(formik.values.label, '_').replace(/\d/g, ''),
    );
  }, []);

  const handleDeleteField = useCallback(
    async (fieldName, formik) => {
      let result = null;
      const showModal = isEditing && oldOrder.indexOf(fieldName) > -1;
      if (!showModal) result = true;
      else result = await modal.delete(t('ContentTypeForm.DeleteProperty'));
      if (result) {
        if (showModal) setFieldsToDelete((prev) => [...prev, fieldName]);

        deleteProperty(
          formik,
          fieldName,
          metaPath,
          schemaPath,
          requiredBasePath,
          setDirty,
        );
      }
    },
    [isEditing, oldOrder, modal, t],
  );

  const handleChangeOrder = useCallback((sourceName, targetName, formik) => {
    updateOrder(sourceName, targetName, formik, metaPath, setDirty);
  }, []);

  const onDropCallback = useDebounceCallback(handleChangeOrder, 10);

  const handleOrderUp = useCallback((idx, formik) => {
    changeOrderUp(formik, metaPath, idx, setDirty);
  }, []);

  const handleOrderDown = useCallback((idx, formik) => {
    changeOrderDown(formik, metaPath, idx, setDirty);
  }, []);

  const handleSubmit = useCallback(
    async (values, formik) => {
      if (fieldsToDelete.length) {
        const result = await modal.confirmation(
          <Trans i18nKey="ContentTypeForm.ConfirmDeleteProperties">
            <div className="font-bold inline">
              {{ properties: fieldsToDelete.join(', ') }}
            </div>
          </Trans>,
          null,
          t('Global.Save'),
        );
        if (!result) return;
      }
      setDirty(false);
      const [newObject, errors, hasErrors] = await onSubmit(values);
      formik.setStatus({ ...formik.status, errors });
      if (!hasErrors) {
        setFieldsToDelete([]);
        setPropertiesErrors({});
        setTransformCoError();
        setTransformError();

        if (navigateOnSave.current) {
          navigate('/content-type-definitions');
        } else {
          formik.resetForm({
            values: newObject,
          });
        }
      } else {
        setDirty(true);
        if (!errors || !Object.keys(errors).length) return;
        const newPropertiesErrors = {};
        parsePropErrors(
          errors,
          newPropertiesErrors,
          'metaDefinition.propertiesConfig',
          /metaDefinition\.propertiesConfig[.[](?<propName>[^.\]]*)]?(?:\.(?<prop>[^.]+))?/,
          'config',
        );
        parsePropErrors(
          errors,
          newPropertiesErrors,
          'schemaDefinition.allOf[1].properties',
          /schemaDefinition\.allOf\[1\]\.properties[.[](?<propName>[^.\]]*)]?(?:\.(?<prop>[^.]+))?/,
          'schema',
        );
        parsePropPropertiesErrors(errors, newPropertiesErrors);
        setPropertiesErrors(newPropertiesErrors);

        setTransformCoError(errors.co);
        setTransformError(errors.ctd);
      }
    },
    [fieldsToDelete, modal, navigate, navigateOnSave, onSubmit, t],
  );

  const updateFormikProperties = useCallback(
    (formik, property, newProperty, copiedPropertyIdx) => {
      updateProperties(
        formik,
        property,
        newProperty,
        copiedPropertyIdx,
        metaPath,
        schemaPath,
        requiredBasePath,
        setDirty,
      );
    },
    [],
  );

  const ctdContextValue = useMemo(
    () => ({
      oldCtd: contentType,
      setDirty,
      disabled,
      isEditing,
      onMediaUpload,
      propertiesErrors,
    }),
    [contentType, isEditing, onMediaUpload, propertiesErrors, disabled],
  );

  const openPropertyModal = useCallback(
    async (
      formik,
      fieldName,
      fieldProps,
      fieldSchema,
      isRequired,
      copiedPropertyIdx,
    ) => {
      let property = null;
      if (fieldName) {
        property = { key: fieldName };
        property.config = fieldProps || { label: '' };
        property.schema = fieldSchema || { type: '' };
        property.required = isRequired ? true : false;
      }
      const newProperty = await modal({
        title: (
          <div className="font-bold text-xl sm:text-3xl">
            {t('ContentTypeForm.ContentProperty')}
          </div>
        ),
        content: (
          <ContentTypeFormContext.Provider value={ctdContextValue}>
            <PropertyModalForm
              property={property ? property : null}
              order={formik.values.metaDefinition.order}
              isNew={
                copiedPropertyIdx > -1 || oldOrder.indexOf(property?.key) < 0
              }
              isDuplicate={copiedPropertyIdx > -1}
              {...getTestProps(testId, 'property-form', 'testId')}
            />
          </ContentTypeFormContext.Provider>
        ),
        size: '2xl',
        dialogAdditionalClasses: 'h-full lg:max-h-[calc(100vh-2rem)]',
      });

      if (!newProperty) return;

      updateFormikProperties(
        formik,
        copiedPropertyIdx > -1 ? null : property,
        newProperty,
        copiedPropertyIdx,
      );
    },
    [modal, t, oldOrder, testId, updateFormikProperties, ctdContextValue],
  );

  const handleEditField = useCallback(
    async (
      fieldName,
      fieldProps,
      fieldSchema,
      isRequired,
      formik,
      copiedPropertyIdx = -1,
    ) => {
      if (EDITABLE.indexOf(fieldProps.inputType) < 0) {
        alert('Not implemented');
        return;
      }
      await openPropertyModal(
        formik,
        fieldName,
        fieldProps,
        fieldSchema,
        isRequired,
        copiedPropertyIdx,
      );
    },
    [openPropertyModal],
  );

  const renderField = useCallback(
    (fieldName, idx, currentOrder, formikValues) => (
      <DraggableCard
        key={fieldName}
        fieldName={fieldName}
        idx={idx}
        currentOrder={currentOrder}
        fieldProps={formikValues.metaDefinition?.propertiesConfig?.[fieldName]}
        fieldSchema={
          formikValues.schemaDefinition?.allOf?.[1]?.properties?.[fieldName]
        }
        isRequired={
          formikValues.schemaDefinition?.required.indexOf(fieldName) > -1
        }
        onDelete={handleDeleteField}
        onEdit={handleEditField}
        onDrop={onDropCallback}
        onUp={handleOrderUp}
        onDown={handleOrderDown}
        onDuplicate={handleEditField}
        testId={testId}
      />
    ),
    [
      handleDeleteField,
      handleEditField,
      onDropCallback,
      handleOrderUp,
      handleOrderDown,
      testId,
    ],
  );

  return (
    <Formik
      initialValues={
        contentType
          ? JSON.parse(JSON.stringify(contentType))
          : {
              label: '',
              name: '',
              metaDefinition: { order: [], propertiesConfig: {} },
              schemaDefinition: {
                additionalProperties: false,
                required: [],
                type: 'object',
                allOf: [
                  {
                    $ref: '#/components/schemas/AbstractContentTypeSchemaDefinition',
                  },
                  { type: 'object', properties: {} },
                ],
              },
            }
      }
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        return (
          <form
            id="ctd-form"
            className="space-y-2 md:space-y-4 p-5 md:py-10 md:px-12"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <ContentTypeFormContext.Provider value={ctdContextValue}>
              <div className="flex flex-col sm:flex-row gap-2 lg:gap-10">
                <Input
                  name="label"
                  value={formik.values.label}
                  onChange={formik.handleChange}
                  onBlur={(event) => {
                    formik.handleBlur(event);
                    generateSlug(formik);
                  }}
                  error={
                    formik.touched.label
                      ? formik.status?.errors?.label || formik.errors.label
                      : null
                  }
                  label={t('ContentDefinition.Label')}
                  additionalClasses="max-w-2xl"
                  disabled={disabled}
                  helpText={
                    <div className="flex">
                      <QuestionMarkCircleIcon className="text-blue w-3 ml-1  mr-1" />
                      {t('Form.FormCreateFolderHelpText')}
                    </div>
                  }
                  visualSeparateSlash={true}
                  title={formik.values.label}
                  required
                  {...getTestProps(testId, 'label', 'testId')}
                />

                <Input
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.name
                      ? formik.status?.errors?.name || formik.errors.name
                      : null
                  }
                  label={
                    <Tooltip
                      tooltip={t('ContentTypeForm.ApiNameTooltip')}
                      additionalClasses="w-fit inline-flex"
                      phoneTooltipPlacement={'rightCenter'}
                    >
                      {t('ContentTypeForm.Name')}
                      <QuestionMarkCircleIcon className="text-blue w-3 ml-1" />
                    </Tooltip>
                  }
                  additionalClasses="max-w-2xl"
                  disabled={isEditing || disabled}
                  required
                  {...getTestProps(testId, 'name', 'testId')}
                />
              </div>
              {transformError && (
                <div
                  className="text-red"
                  {...getTestProps(testId, 'ctd-transform-errors')}
                >
                  {formatErrorToString(transformError)}
                </div>
              )}
              {transformCoError && (
                <div
                  className="flex flex-col text-red"
                  {...getTestProps(testId, 'co-transform-errors')}
                >
                  <span className="font-bold mb-2">
                    {t('ContentDefinition.Transformation.Errors')}
                  </span>
                  <span>
                    {t('ContentDefinition.Transformation.Description')}
                  </span>
                  <span className="whitespace-pre-line">
                    {Object.keys(transformCoError)
                      .filter((key) => key)
                      .map((key) => `-${key}: ${transformCoError[key]} \n`)}
                  </span>
                </div>
              )}
              {(formik.values.metaDefinition?.order || []).map(
                (fieldName, idx, currentOrder) =>
                  renderField(fieldName, idx, currentOrder, formik.values),
              )}
              <div>
                {formik.touched.metaDefinition?.propertiesConfig &&
                  formik.errors?.metaDefinition?.propertiesConfig &&
                  typeof formik.errors?.metaDefinition?.propertiesConfig ===
                    'string' && (
                    <div
                      className="text-sm text-red"
                      {...getTestProps(testId, 'properties-error')}
                    >
                      {formik.errors?.metaDefinition?.propertiesConfig}
                    </div>
                  )}
                <Button
                  onClick={() => openPropertyModal(formik)}
                  buttonSize="sm"
                  disabled={disabled}
                  {...getTestProps(testId, 'add-property', 'testId')}
                >
                  {t('ContentTypeForm.AddProperty')}
                </Button>
              </div>
              {formik.status?.errors?.[''] && (
                <div
                  className="text-red text-sm mb-2"
                  {...getTestProps(testId, 'global-ctd-error')}
                >
                  {formatErrorToString(formik.status?.errors?.[''])}
                </div>
              )}
              <DirtyHandler isDirty={dirty} />
            </ContentTypeFormContext.Provider>
          </form>
        );
      }}
    </Formik>
  );
};

export default ContentTypeForm;

ContentTypeForm.propTypes = {
  /**
   * On sumbit callback
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Content object to edit
   */
  contentType: PropTypes.object,
  /**
   * If form is used for object editing
   */
  isEditing: PropTypes.bool,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * If navigates after submit represented by react ref
   */
  navigateOnSave: PropTypes.object,
  /**
   * Callback for media uploading
   */
  onMediaUpload: PropTypes.func,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

ContentTypeForm.defaultProps = {
  contentType: {},
  isEditing: false,
  disabled: false,
  navigateOnSave: {},
  onMediaUpload: /* istanbul ignore next */ () => null,
  testId: '',
};
