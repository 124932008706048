import { FlotiqScopedApiClient } from './flotiq-scoped-api-client';
import { PluginEventHandler } from './plugin-event-handler';
import { Jodit } from 'jodit-pro/es2015/jodit.min.js';

const callbackRegister = {
  'flotiq.grid.cell::render': {},
  'flotiq.grid.filter::render': {},
  'flotiq.form.field::config': {},
  'flotiq.form.field::render': {},
  'flotiq.form.sidebar::add': {},
  'flotiq.form::add': {},
  'flotiq.plugin.library::add': {},
};

const registeredPluginHandlers = {};
const loadedPluginScripts = {};

/**
 * @memberof FlotiqPlugins
 */
class FlotiqPluginsRegistry {
  enabled = () =>
    process.env.REACT_APP_ENABLE_UI_PLUGINS?.split(',').join(',') === 'true';

  getLoadedPlugins = () => registeredPluginHandlers;

  runPluginCode(code) {
    if (!FlotiqPlugins.enabled()) {
      console.warn('Plugins are disabled, skipping plugin code execution');
      return;
    }
    const pluginScript = `
    with (this) {
      ${code}
    }
    `;

    /**
     * This `new Function` is considered safe-ish as it is executing external plugin code by design.
     */
    // eslint-disable-next-line no-new-func
    new Function(pluginScript).call(window);
  }

  async loadPlugin(id, url) {
    if (!FlotiqPlugins.enabled()) {
      console.warn('Plugins are disabled, skipping plugin loading', id, url);
      return;
    }
    try {
      const scriptText = await fetch(url).then((r) => r.text());
      FlotiqPlugins.runPluginCode(scriptText);
      loadedPluginScripts[id] = url;
    } catch (e) {
      console.error('Error while loading plugin script', e);
    }
  }

  /**
   * Register new plugin. If plugin with given ID already exists, it will be unregistered first.
   *
   * @param {PluginInfo} pluginInfo
   * @param {PluginRegistrationCallback} callback
   * @returns
   */
  add(pluginInfo, callback) {
    if (!FlotiqPlugins.enabled()) {
      console.warn(
        'Plugins are disabled, skipping plugin registration',
        pluginInfo?.id,
      );
      return;
    }
    const { id, permissions } = pluginInfo;
    if (registeredPluginHandlers[id]) {
      registeredPluginHandlers[id].unregister();
    }
    // event handler for the plugin
    const eventHandler = new PluginEventHandler(
      pluginInfo,
      FlotiqPlugins,
      callbackRegister,
      registeredPluginHandlers,
    );

    registeredPluginHandlers[id] = eventHandler;

    const client = new Proxy(new FlotiqScopedApiClient(permissions), {
      get: (target, prop) => {
        if (prop === 'getMediaUrl') return target.getMediaUrl.bind(target);
        if (prop === 'getContentTypes')
          return target.getContentTypes.bind(target);
        return {
          get: (id) => target.getObject(prop, id),
          list: (params) => target.listObjects(prop, params),
          post: (object) => target.postObject(prop, object),
          put: (id, object) => target.putObject(prop, id, object),
          delete: (id) => target.deleteObject(prop, id),
          getContentType: () => target.getContentType(prop),
        };
      },
    });
    callback(eventHandler, client, { Jodit });
    console.info('Plugin registered ⚡', pluginInfo);
  }

  /**
   * Unregister plugin event handlers by id
   * @param {string} pluginId
   */
  unregister(pluginId) {
    if (!pluginId) {
      throw Error('Plugin id was not provided');
    }

    if (registeredPluginHandlers[pluginId]) {
      registeredPluginHandlers[pluginId].unregister();
    }
  }

  /**
   * Execute all handlers for an event. Event name and parameters must be paired correctly.
   *
   * @param {string} event
   * @param  {...any} params
   * @returns
   */
  run(event, ...params) {
    if (!callbackRegister[event]) return [];
    return Object.entries(callbackRegister[event]).reduce(
      (results, [pluginId, pluginCallbacks]) => {
        results.push(
          ...pluginCallbacks.map((c) => {
            try {
              return c(...params);
            } catch (e) {
              console.error(
                'Error while running plugin callback',
                pluginId,
                event,
                e,
              );
              return null;
            }
          }),
        );
        return results;
      },
      [],
    );
  }

  init() {
    if (window.initFlotiqPlugins) {
      window.initFlotiqPlugins.forEach(({ pluginInfo, callback }) => {
        window.FlotiqPlugins.add(pluginInfo, callback);
      });
      window.initFlotiqPlugins.length = 0;
    }
  }

  getLoadedPluginsIds() {
    return Object.keys(registeredPluginHandlers);
  }
}

const FlotiqPlugins = new FlotiqPluginsRegistry();
window.FlotiqPlugins = FlotiqPlugins;

export default FlotiqPlugins;

/**
 * @typedef {Object} PluginPermission
 * @memberof FlotiqPlugins.PluginInfo
 *
 * @property {('CO'|'CTD')} type - permission type. Can be either 'CO' or 'CTD'
 * @property {boolean} canRead - whether plugin can read content of given type
 * @property {boolean} canWrite - whether plugin can write content of given type
 * @property {boolean} canCreate - whether plugin can create content of given type
 * @property {boolean} canDelete - whether plugin can delete content of given type
 * @property {string} ctdName - Name of content type permission applies to. '*' means all content types
 */

/**
 * A set of information required from plugin to register in flotiq UI
 *
 * @typedef {Object} PluginInfo
 * @memberof FlotiqPlugins
 *
 * @property {string} id - unique plugin id
 * @property {string} displayName - plugin display name
 * @property {string} version - plugin version
 * @property {string} description - plugin description
 * @property {string} repository - url to source code repository
 * @property {PluginPermission[]} [permissions] - list of permissions that plugin requires
 */

/**
 * Api client
 *
 * @typedef {Object} FlotiqApiClient
 * @memberof FlotiqPlugins
 *
 * @property {ContentTypeAPIClient<contenttypename>} contenttypename - api client for given content type
 * @property {function} contenttypename.get - get single content object, passing id as a argument.
 *     Will throw an error witout access to the content type.
 * @property {function} contenttypename.list - list content objects, passing query params as a first argument
 *     Will throw an error witout access to the content type.
 * @property {function} contenttypename.post - create content object, passing object as a argument
 *     Will throw an error witout access to the content type.
 * @property {function} contenttypename.put - update content object, passing id and object as arguments
 *     Will throw an error witout access to the content type.
 * @property {function} contenttypename.delete - delete content object, passing id as a argument
 *     Will throw an error witout access to the content type.
 * @property {function} contenttypename.getContentType - get definition for given content type
 *     Will throw an error witout access to the content type.
 */

/**
 * Generate url for media. Use width/height params to generate url for resized image
 *
 * @method
 * @name FlotiqPlugins.FlotiqApiClient#getMediaUrl
 * @param {object} mediaContentObject
 * @param {number} width
 * @param {number} height
 * @returns {string} media url
 */

/**
 * Generate url for media. Use width/height params to generate url for resized image
 *
 * @method
 * @name FlotiqPlugins.FlotiqApiClient#getContentTypes
 * @param {object} params query parameters for content types list (e.g. limit, page etc)
 * @returns {Promise<ContentTypesResponse>} Api response containing list of content types
 */

/**
 * @typedef {import('jodit-pro').Jodit} Jodit
 */

/**
 * @typedef {Object} FlotiqGlobals
 * @memberof FlotiqPlugins
 *
 * @property {Jodit} Jodit - Jodit editor instance
 */

/**
 * @callback PluginRegistrationCallback
 * @memberof FlotiqPlugins.FlotiqPluginsRegistry
 * @param {PluginEventHandler} eventHandler - event handler for the plugin
 * @param {FlotiqPlugins.FlotiqApiClient} client - api client for the plugin
 * @param {FlotiqGlobals} globals - global variables available for the plugin
 */
