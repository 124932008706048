import { useCallback, useEffect, useMemo } from 'react';
import { useBlocker } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useModals } from '../../contexts/ModalContext';
import { useTranslation } from 'react-i18next';

const DirtyHandler = ({ isDirty }) => {
  const { t } = useTranslation();
  const modal = useModals();
  const { dirty, isSubmitting, touched } = useFormikContext();

  const block = useMemo(
    () =>
      isDirty || (dirty && !isSubmitting && Object.keys(touched).length > 0),
    [dirty, isSubmitting, touched, isDirty],
  );

  const blocker = useBlocker(block);

  const handleUnload = useCallback(
    (event) => {
      if (block) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    [block],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [handleUnload]);

  useEffect(() => {
    if (blocker.state === 'blocked' && !block) {
      blocker.reset();
    }
  }, [blocker, block]);

  const showModal = useCallback(async () => {
    const result = await modal.confirmation(
      t('DirtyHandler.ShouldLeaveConfirm'),
      t('DirtyHandler.ShouldLeave'),
      t('DirtyHandler.Leave'),
      t('DirtyHandler.Stay'),
    );
    if (result) {
      blocker.proceed();
    } else {
      blocker.reset();
    }
  }, [modal, blocker, t]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      showModal();
    }
  }, [showModal, dirty, blocker]);
};

export default DirtyHandler;

DirtyHandler.propTypes = {
  /**
   * Dirty state used for overriding formik dirty state
   */
  isDirty: PropTypes.bool,
};

DirtyHandler.defaultProps = {};
