import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Component
import Button from '../Button/Button';
import Heading from '../Heading/Heading';
import Tooltip from '../Tooltip/Tooltip';
import ActionButton from '../ActionButton/ActionButton';
import LinkButton from '../LinkButton/LinkButton';

// :: Lib
import { getTestProps } from '../../lib/helpers';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const UtilityToolbar = ({ children }) => (
  <div
    className={twMerge(
      'flex items-center justify-center md:justify-end',
      'pt-2 pb-safe md:py-0 pl-5 space-x-3',
      'fixed md:relative bottom-0 left-0',
      'w-full md:w-auto border-t border-gray dark:border-zinc-800 md:border-none bg-white dark:bg-slate-950',
    )}
  >
    {children}
  </div>
);

const Topbar = ({
  topbarLogo,
  topbarHeading,
  topbarButtons,
  additionalClasses,
  breadcrumbs,
  isSidebarOpen,
  testId,
}) => (
  <header
    className={twMerge(
      'flex items-center border-b border-gray dark:border-blue-650 sm:border-none bg-white dark:bg-slate-950',
      additionalClasses,
    )}
    {...getTestProps(testId)}
  >
    <div className="h-full inline-flex sm:hidden items-center">
      {topbarLogo}
    </div>
    <div
      className="inline-flex h-14 px-3 lg:px-7 justify-between items-center
      border-b-none sm:border-b sm:border-gray sm:dark:border-slate-950 grow"
    >
      <div>
        <Heading
          level={4}
          additionalClasses={twMerge(
            'text-lg sm:text-2xl w-fit m-0 p-0 leading-6 truncate dark:text-white',
            'max-w-[calc(100vw-94px)] sm:max-w-[calc(100vw-114px)] md:max-w-[calc(100vw-430px)]',
            isSidebarOpen
              ? 'lg:max-w-[calc(100vw-600px)]'
              : 'lg:max-w-[calc(100vw-430px)]',
          )}
          title={topbarHeading.length > 30 ? topbarHeading : ''}
        >
          {topbarHeading}
        </Heading>
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          isSidebarOpen={isSidebarOpen}
          additionalClasses="p-0 mt-0.5"
        />
      </div>
      <UtilityToolbar>
        {topbarButtons?.map((button) => {
          const {
            key,
            tooltip,
            tooltipPlacement,
            phoneTooltipPlacement,
            additionalTooltipClasses,
            label,
            color,
            additionalClasses,
            ...props
          } = button;

          if (button.menuItems?.length > 0) {
            return (
              <ActionButton
                key={key}
                buttonColor={color}
                buttonSize="xs"
                additionalClasses={twMerge(
                  'text-sm md:!text-base mb-2 md:mb-0',
                  additionalClasses,
                )}
                {...props}
              >
                {label}
              </ActionButton>
            );
          }

          const ButtonComponent = button.link ? LinkButton : Button;

          const buttonComponent = (
            <ButtonComponent
              key={key}
              buttonColor={color}
              buttonSize="xs"
              additionalClasses={twMerge(
                'text-base mb-2 md:mb-0',
                additionalClasses,
              )}
              additionalChildrenClasses={twMerge(
                'max-w-[250px] md:max-w-[150px] lg:max-w-[150px] 2xl:max-w-[450px] truncate',
              )}
              title={label.length > 90 ? label : ''}
              {...getTestProps(testId, `${key}`, 'testId')}
              {...props}
            >
              {label}
            </ButtonComponent>
          );

          return button.tooltip ? (
            <Tooltip
              key={key}
              tooltip={tooltip}
              tooltipPlacement={tooltipPlacement}
              phoneTooltipPlacement={phoneTooltipPlacement}
              additionalTooltipClasses={additionalTooltipClasses}
            >
              {buttonComponent}
            </Tooltip>
          ) : (
            buttonComponent
          );
        })}
      </UtilityToolbar>
    </div>
  </header>
);

Topbar.propTypes = {
  /**
   * Topbar logo
   */
  topbarLogo: PropTypes.any,
  /**
   * Header for this section
   */
  topbarHeading: PropTypes.string,
  /**
   * Buttons which you can style by your own
   */
  topbarButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      color: PropTypes.string,
      onClick: PropTypes.func,
      key: PropTypes.string.isRequired,
    }),
  ),
  /**
   * Topbar additional CSS classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Breadcrumbs
   */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      label: PropTypes.node,
      disabled: PropTypes.bool,
      key: PropTypes.string.isRequired,
      additionalClasses: PropTypes.string,
    }),
  ),
  /*
   * Is sidebar open
   */
  isSidebarOpen: PropTypes.bool,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

Topbar.defaultProps = {
  topbarLogo: '',
  topbarHeading: '',
  topbarButtons: [],
  additionalClasses: '',
  breadcrumbs: [],
  testId: '',
};

export default Topbar;
