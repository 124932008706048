import { getLocalStorage } from '../../utils/localStorage';
import { generateRedirectUrl } from '../helpers';
import { makeBodilessQuery, makeJSONQuery } from './base-request';

const _postLogin = makeJSONQuery('login_check');
const _postRegister = makeJSONQuery(
  'register?redirectUri={{redirectUri}}&plan={{plan}}',
);
const _postRegisterAppSumo = makeJSONQuery(
  'integrations/appsumo/activate/{{appSumoToken}}',
);
const _getAccountActivation = makeBodilessQuery('activate/{{activationToken}}');
const _resetPasswordRequest = makeJSONQuery(
  'resetPasswordRequest?redirectUri={{redirectUri}}',
  'POST',
);
const _resetPassword = makeJSONQuery('resetPassword', 'POST');
const _refreshToken = makeJSONQuery('token/refresh', 'POST');

/**
 * Acquire JWT and user data via username & password
 *
 * @param {string} username
 * @param {string} password
 */
export const postLogin = (username, password) =>
  _postLogin(null, {
    _username: username,
    _password: password,
  });

/**
 * Create user account
 *
 * @param {Record<string, string>} registerFormData
 */
export const postRegister = (registerFormData) =>
  _postRegister(null, registerFormData);

/**
 * Create user account via AppSumo integration
 *
 * @param {string} activationToken activation token extracted from base64 token passed from AppSumo
 */
export const postRegisterAppSumo = (activationToken, registerFormData) =>
  _postRegisterAppSumo(null, {
    appSumoToken: activationToken,
    ...registerFormData,
  });

/**
 * Activate user account with a valid token
 *
 * @param {string} activationToken activation token extracted from base64 token passed from AppSumo
 */
export const getAccountActivation = (activationToken) =>
  _getAccountActivation(null, {
    activationToken,
  });

/**
 * Send password reset request
 *
 * @param {string} email for which request should be sent
 */
export const resetPasswordRequest = (email) =>
  _resetPasswordRequest(null, {
    email,
    sendEmail: true,
    redirectUri: generateRedirectUrl('/change-password'),
  });

/**
 * Send password reset request
 *
 * @param {Record<string, string>} resetPasswordFormData that contains plainPassword, plainPasswordRepeat and token
 */
export const resetPassword = (resetPasswordFormData) =>
  _resetPassword(null, resetPasswordFormData);

/**
 * Send refresh token request
 *
 * @param {Record<string, string>} resetPasswordFormData that contains plainPassword, plainPasswordRepeat and token
 */
export const refreshToken = () => {
  const refresh_token = getLocalStorage('cms.user', true)?.refresh_token;
  return _refreshToken(null, { refresh_token });
};
