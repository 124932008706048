import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

// :: Components
import Heading from '../../components/Heading/Heading';
import LinkButton from '../../components/LinkButton/LinkButton';
import Loader from '../../components/Loader/Loader';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import StatusBar from '../../components/StatusBar/StatusBar';
import CustomizableDataGrid from '../../components/CustomizableDataGrid/CustomizableDataGrid';
import DataGridControl from '../../components/DataGridControl/DataGridControl';

// :: Utils
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from '../../utils/localStorage';

// :: Hooks
import { useAllUsers, useConstraints, useUsers } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useOnce from '../../hooks/useOnce';
import useToken from '../../hooks/useToken';

// :: Contexts
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';

// :: Lib
import {
  generateExportUsersCsvUrl,
  getTestProps,
  updateLocalStorageGridOptions,
  setInitialGridOptions,
  getActionsColumn,
} from '../../lib/helpers';
import { getDefinedColumns } from './definedColumns';

// :: Images
import { HouseIcon, PencilIcon, WarningIcon } from '../../images/shapes';

const getApiFilters = (filters) => {
  if (!filters) return {};

  const newFilters = {};

  Object.keys(filters).forEach((key) => {
    if (key === 'organization.name') {
      newFilters.organizationName = filters['organization.name'].value;
    } else if (key === 'organization.paymentStatus') {
      newFilters.organizationPaymentStatus =
        filters['organization.paymentStatus'].value;
    } else if (key === 'organization.paymentDueDate') {
      newFilters.organizationPaymentDueDate =
        filters['organization.paymentDueDate'].value;
    } else if (key === 'roles') {
      newFilters.roles = filters[key].value.join(',');
    } else {
      newFilters[key] = filters[key].value;
    }
  });

  return newFilters;
};

const Users = ({ isAllUsers, testId }) => {
  const jwt = useToken();
  const SORT_KEY = isAllUsers ? 'cms.all-users-sort' : 'cms.user-sort';
  const OPTIONS_KEY = isAllUsers
    ? 'cms.all-users-grid-state'
    : 'cms.user-grid-state';

  const usersGridContainer = useRef();
  const { t } = useTranslation();
  const { updateAppContext } = useContext(AppContext);
  const { isRoleAdmin, isAdmin, userStorage: user } = useContext(UserContext);

  const usersLimit = useMemo(
    () => user?.data?.limits_plan?.team_members_limit,
    [user],
  );
  const [gridOptions, setGridOptions] = useState(
    getLocalStorage(OPTIONS_KEY, true),
  );

  const { entity: membersCount } = useConstraints('team-members-count');

  const disabledAdding = useMemo(
    () =>
      isAllUsers || usersLimit === -1
        ? false
        : membersCount?.data >= usersLimit,
    [isAllUsers, usersLimit, membersCount?.data],
  );

  const [sort, setSort] = useState(getLocalStorage(SORT_KEY, true));
  const [firstLoading, setFirstLoading] = useState(true);
  const [initDataHasContent, setInitDataHasContent] = useState(false);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [columns, setColumns] = useState([]);
  const [editGrid, setEditGrid] = useState(false);
  const [filterGrid, setFilterGrid] = useState(false);

  useEffect(() => {
    setSort(getLocalStorage(SORT_KEY, true));
  }, [SORT_KEY]);

  useEffect(() => {
    setGridOptions(getLocalStorage(OPTIONS_KEY, true));
  }, [OPTIONS_KEY]);

  const topBarButtons = useMemo(() => {
    const buttons = [];

    if (isAdmin || isRoleAdmin) {
      buttons.push({
        label: t('Users.Add'),
        link: '/users/add',
        disabled: disabledAdding,
        tooltip: disabledAdding ? t('Users.LimitReached') : '',
        tooltipPlacement: 'leftBottom',
        additionalTooltipClasses: 'hidden md:block',
        key: 'Add',
      });
    }

    if (isRoleAdmin && isAllUsers) {
      buttons.push({
        label: t('Users.Export'),
        link: generateExportUsersCsvUrl(jwt),
        key: 'Export',
        ...getTestProps(testId, `export-link`, 'testId'),
      });
    }

    return buttons;
  }, [disabledAdding, isAllUsers, isRoleAdmin, isAdmin, jwt, t, testId]);

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: isAllUsers ? 'admin/all-users' : 'users',
      menuItemOpen: isAllUsers ? 'admin' : '',
      topBar: {
        heading: isAllUsers ? t('Global.AllUsers') : t('Global.Users'),
        buttons: topBarButtons,
      },
    }));
  }, [updateAppContext, isAllUsers, t, topBarButtons]);

  useOnce(handlePageUpdate);

  const usersParams = useMemo(
    () => ({
      page,
      limit,
      ...getApiFilters(filters),
      ...(sort?.sortOrder ? { order_direction: sort?.sortOrder } : {}),
      ...(sort?.sortField ? { order_by: sort?.sortField } : {}),
    }),
    [page, limit, filters, sort?.sortOrder, sort?.sortField],
  );

  const usersOptions = useMemo(
    () => ({
      pause: isAllUsers,
    }),
    [isAllUsers],
  );

  const {
    data: users,
    errors: usersError,
    status: usersStatus,
    isLoading: usersAreLoading,
    pagination: usersPagination,
    reload: usersReload,
  } = useUsers(usersParams, usersOptions);

  const allUsersOptions = useMemo(
    () => ({
      pause: !isRoleAdmin || !isAllUsers,
    }),
    [isRoleAdmin, isAllUsers],
  );

  const {
    data: allUsers,
    errors: allUsersError,
    status: allUsersStatus,
    isLoading: allUsersAreLoading,
    pagination: allUsersPagination,
    reload: allUsersReload,
  } = useAllUsers(usersParams, allUsersOptions);

  useApiErrorsToast(usersError);
  useApiErrorsToast(allUsersError);

  const pagination = useMemo(
    () => (isAllUsers ? allUsersPagination : usersPagination),
    [allUsersPagination, isAllUsers, usersPagination],
  );

  useEffect(() => {
    if (firstLoading) {
      setInitDataHasContent(false);
    }
    if (
      (!usersAreLoading && !isAllUsers) ||
      (!allUsersAreLoading && isAllUsers)
    )
      setFirstLoading(false);
  }, [allUsersAreLoading, firstLoading, isAllUsers, usersAreLoading]);

  useEffect(() => {
    if (!initDataHasContent && (users.length || allUsers.length)) {
      setInitDataHasContent(true);
    }
  }, [users.length, initDataHasContent, allUsers.length]);

  const handleResetGrid = useCallback(() => {
    setSort();
    setPage(1);
    setLimit(20);
    setFilters({});
    removeLocalStorage(SORT_KEY);
    removeLocalStorage(OPTIONS_KEY);
    setGridOptions();
  }, [OPTIONS_KEY, SORT_KEY]);

  const handleLimitChange = useCallback((resultsLimit) => {
    setLimit(resultsLimit);
    setPage(1);
  }, []);

  const handlePageChange = useCallback((page) => {
    setPage(page);
  }, []);

  const filtersApplied = useMemo(
    () =>
      filters
        ? Object.keys(filters).filter((key) => filters[key])?.length > 0
        : false,
    [filters],
  );

  const handleFilters = useCallback((currentFilters) => {
    setFilters(currentFilters);
    setPage(1);
  }, []);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: '/',
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Dashboard',
        },
        {
          label: isAllUsers ? t('Global.AllUsers') : t('Global.Users'),
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
          key: 'Users',
        },
      ],
    }));
  }, [isAllUsers, t, updateAppContext]);

  const showGrid = useMemo(() => {
    if (!isAllUsers)
      return (
        users.length > 0 ||
        (usersAreLoading && !firstLoading) ||
        filtersApplied ||
        initDataHasContent
      );
    return (
      isRoleAdmin &&
      (allUsers.length > 0 ||
        (allUsersAreLoading && !firstLoading) ||
        filtersApplied ||
        initDataHasContent)
    );
  }, [
    isAllUsers,
    users.length,
    usersAreLoading,
    firstLoading,
    filtersApplied,
    initDataHasContent,
    isRoleAdmin,
    allUsers.length,
    allUsersAreLoading,
  ]);

  const emptyData = useMemo(() => {
    if (showGrid) return null;
    if (firstLoading && (!isAllUsers || isRoleAdmin)) {
      return (
        <Loader
          size="big"
          type="spinner-grid"
          {...getTestProps(testId, 'loading', 'testId')}
        />
      );
    }
    return (
      <Heading
        level={2}
        additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon className="text-red w-14 md:w-20 mb-3" />
          {(usersStatus || allUsersStatus) === 403 ||
          (!isRoleAdmin && isAllUsers)
            ? t('Global.NoAccess')
            : t('Media.OnErrorMessage')}
        </div>
      </Heading>
    );
  }, [
    showGrid,
    firstLoading,
    isAllUsers,
    isRoleAdmin,
    testId,
    usersStatus,
    allUsersStatus,
    t,
  ]);

  const actions = useCallback(
    (data) => [
      {
        key: 'edit',
        label: t('Global.Edit'),
        icon: <PencilIcon className="w-3.5 mr-2" />,
        link: `/users/edit/${data.id}`,
      },
    ],
    [t],
  );

  const handleInitialGridOptions = useCallback(
    (cols, minWidth) => {
      setInitialGridOptions(
        cols,
        minWidth,
        usersGridContainer,
        setGridOptions,
        setColumns,
        OPTIONS_KEY,
        false,
      );
    },
    [OPTIONS_KEY],
  );

  useEffect(() => {
    const cols = [];

    const gridOptionsByID = gridOptions?.reduce((acc, elem) => {
      acc[elem.colId] = elem;
      return acc;
    }, {});
    const currentLocalGridOption = getLocalStorage(OPTIONS_KEY, true);

    const hiddenColumnsById = {};
    const orderFromLocalStorage = [];

    for (let key in currentLocalGridOption) {
      hiddenColumnsById[currentLocalGridOption[key].colId] =
        currentLocalGridOption[key].hide;
      orderFromLocalStorage.push(currentLocalGridOption[key].colId);
    }

    const definiedColumns = getDefinedColumns(
      gridOptionsByID,
      hiddenColumnsById,
      editGrid,
      t,
      isAllUsers,
      testId,
    );

    let order = Object.keys(definiedColumns);
    order = [...new Set([...(orderFromLocalStorage || []), ...order])];

    if (orderFromLocalStorage.length) {
      updateLocalStorageGridOptions(order, OPTIONS_KEY, setGridOptions);
    }

    order.forEach((accessor) => {
      if (definiedColumns[accessor]) cols.push(definiedColumns[accessor]);
    });

    if (!isAllUsers)
      cols.push(getActionsColumn(gridOptionsByID, actions, testId));

    if (!gridOptions) {
      handleInitialGridOptions(cols, 250);
    } else {
      setColumns(cols);
    }
  }, [
    t,
    actions,
    handleInitialGridOptions,
    gridOptions,
    testId,
    editGrid,
    OPTIONS_KEY,
    isAllUsers,
  ]);

  const handleVisibleColumnsChange = useCallback(
    (options) => {
      setLocalStorage(OPTIONS_KEY, options);
      setGridOptions(options);
    },
    [OPTIONS_KEY],
  );

  return (
    <div className="w-full md:h-[calc(100vh-66px)]">
      <Helmet>
        <title>{t('Global.Users')}</title>
      </Helmet>
      <div className="flex flex-col h-full w-full">
        {!isAllUsers && (
          <div
            className={twMerge(
              'flex flex-wrap sm:flex-nowrap items-center justify-between ',
              'px-3 lg:px-7 py-2 sm:py-0 h-auto sm:h-11 w-full z-10 bg-slate-50 border-b gap-3 sm:gap-0',
              'dark:bg-gray-900 dark:border-b dark:border-slate-800',
            )}
          >
            <div className="flex items-center justify-start w-full sm:w-1/2 max-w-[580px] px-0 sm:px-4">
              <ProgressBar
                barBackgroundColor="green"
                progressBarLabel={t('Users.Users')}
                completionPercentValue={
                  usersLimit === -1
                    ? 100
                    : (membersCount?.data / usersLimit) * 100
                }
                outOfCompletionTotalValue={
                  usersLimit === -1 ? t('Global.Unlimited') : usersLimit
                }
                outOfCompletionValue={membersCount?.data}
                barThickness="thin"
                hidePercentValue
                additionalLabelClasses={twMerge('mb-0')}
                additionalCompletionValuesContainerClasses={twMerge(
                  'mb-0 whitespace-nowrap leading-none ml-2',
                )}
                additionalBarClasses={twMerge(
                  'flex sm:hidden md:flex mb-0 mx-4',
                )}
                additionalContainerClasses={twMerge(
                  'flex flex-row items-center',
                )}
              />
              <LinkButton
                buttonSize="xs"
                additionalClasses={twMerge('whitespace-nowrap text-sm xl:ml-7')}
                link="/usage"
              >
                {t('Global.UpgradePlan')}
              </LinkButton>
            </div>
          </div>
        )}
        <div
          className={twMerge(
            'flex flex-col xs:flex-row items-center justify-between relative w-full px-5 lg:px-7',
            'space-y-3 xs:space-y-0',
            isAllUsers
              ? 'bg-slate-50 mb-7 py-3 xs:py-2 border-b dark:bg-gray-900 dark:border-b dark:border-slate-800'
              : 'bg-transparent py-3',
          )}
        >
          <DataGridControl
            displayGridCheckboxes={showGrid && columns.length > 0}
            displayGridControl={showGrid}
            editGrid={editGrid}
            filterGrid={filterGrid}
            setEditGrid={setEditGrid}
            setFilterGrid={setFilterGrid}
            handleResetGrid={handleResetGrid}
            limit={limit}
            handleLimitChange={handleLimitChange}
            columns={columns}
            gridOptions={gridOptions}
            handleVisibleColumnsChange={handleVisibleColumnsChange}
            columnsVisibilityAdditionalClasses={twMerge(
              isAllUsers && '-top-2 xs:top-[0.2rem]',
            )}
            testId={testId}
          />
        </div>
        <div
          className={twMerge(
            'w-full px-5 lg:px-7 rounded-lg',
            isAllUsers
              ? 'h-[calc(100vh-230px)] md:h-[calc(100%-190px)]'
              : 'h-[calc(100vh-260px)] md:h-[calc(100%-210px)]',
          )}
          ref={usersGridContainer}
        >
          {showGrid ? (
            <CustomizableDataGrid
              columns={columns}
              setColumns={setColumns}
              data={isAllUsers ? allUsers : users}
              isLoading={isAllUsers ? allUsersAreLoading : usersAreLoading}
              setSort={setSort}
              sort={sort?.sortField || undefined}
              sortOrder={sort?.sortOrder || undefined}
              sortingLocalStorageKey={SORT_KEY}
              optionsLocalStorageKey={OPTIONS_KEY}
              setCurrentPage={setPage}
              editGrid={editGrid}
              gridOptions={gridOptions}
              setGridOptions={setGridOptions}
              statusBar={
                <StatusBar
                  rows={pagination.count}
                  currentPage={page}
                  pagesCount={pagination.total_pages}
                  handlePageChange={handlePageChange}
                  handleDataUpdate={isAllUsers ? allUsersReload : usersReload}
                  resultsFrom={(pagination.current_page - 1) * limit + 1}
                  resultsTo={
                    (pagination.current_page - 1) * limit + pagination.count
                  }
                  resultsTotalCount={pagination.total_count}
                  {...getTestProps(testId, 'statusbar', 'testId')}
                />
              }
              noDataInfoText={t('Users.FiltersEmptyResult')}
              hasFilters={filterGrid}
              filters={filters}
              disableFilters={editGrid}
              onFilter={handleFilters}
              additionalClasses="bg-white dark:bg-gray-900"
              {...getTestProps(testId, 'grid', 'testId')}
            />
          ) : (
            <div className="flex flex-col items-center justify-center h-full">
              {emptyData}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;

Users.propTypes = {
  /**
   * If page is for all users data
   */
  isAllUsers: PropTypes.bool,
  /**
   * Test id for users page
   */
  testId: PropTypes.string,
};

Users.defaultProps = {
  isAllUsers: false,
  testId: '',
};
