import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import ValidationToastHandler from '../../components/ValidationToastHandler/ValidationToastHandler';
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';
import { generateYupShapeForCto } from './helpers/generateYupShapeForCto';
import { getDefaultValueForType, getTestProps } from '../../lib/helpers';
import CtoCustomField from '../../components/CtoCustomField/CtoCustomField';
import ContentObjectFormContext from '../../contexts/ContentObjectFormContext';
import CustomFormElement from './CustomFormElement/CustomFormElement';

const getInitialData = (properties, schema, contentObject, hasData) =>
  Object.keys(properties).reduce((initials, key) => {
    const defaultDate = moment(
      schema[key].default,
      moment.ISO_8601,
      true,
    ).isValid()
      ? schema[key].default
      : null;
    const defaultValue =
      properties[key].inputType === 'dateTime'
        ? defaultDate
        : schema[key].default;
    const defaultForType = getDefaultValueForType(schema[key].type);
    const init = hasData ? contentObject[key] : defaultValue;
    initials[key] = init || defaultForType;
    return initials;
  }, {});

const ContentObjectForm = ({
  contentObject,
  contentType,
  isEditing,
  onSubmit,
  disabled,
  navigateOnSave,
  hasInitialData,
  formId,
  isFullSize,
  testId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contentTypeName } = useParams();

  const schema = useMemo(() => {
    if (!contentType?.schemaDefinition?.allOf[1]?.properties) return {};
    return contentType.schemaDefinition.allOf[1].properties;
  }, [contentType]);

  const properties = useMemo(() => {
    if (!contentType?.metaDefinition?.propertiesConfig) return {};
    if (!contentType.metaDefinition?.order)
      return contentType.metaDefinition?.propertiesConfig;
    return contentType.metaDefinition.order.reduce((acc, key) => {
      acc[key] = contentType.metaDefinition?.propertiesConfig[key];
      return acc;
    }, {});
  }, [contentType]);

  const requiredFields = useMemo(() => {
    if (!contentType?.schemaDefinition?.required) return {};
    return contentType.schemaDefinition?.required.reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});
  }, [contentType]);

  const validationObject = useMemo(
    () => generateYupShapeForCto(schema, t, requiredFields, properties),
    [properties, schema, requiredFields, t],
  );

  const renderFormField = useCallback(
    (key, props, schema, isRequired) => (
      <CtoCustomField
        key={key}
        name={key}
        properties={props}
        schema={schema}
        isRequired={isRequired}
        disabled={disabled}
        additionalClasses="max-w-2xl"
        isFullSize={isFullSize}
        testId={testId}
      />
    ),
    [disabled, isFullSize, testId],
  );

  const initialValues = useMemo(
    () => getInitialData(properties, schema, contentObject, hasInitialData),
    [properties, schema, contentObject, hasInitialData],
  );

  const handleSubmit = useCallback(
    async (values, formik) => {
      const [newObject, errors] = await onSubmit(values);
      formik.setStatus({ ...formik.status, errors });
      if (!errors || !Object.keys(errors).length) {
        if (navigateOnSave?.current) {
          navigate(`/content-type-objects/${contentTypeName}`);
        } else {
          formik.resetForm({
            values: getInitialData(properties, schema, newObject, true),
          });
        }
      }
    },
    [navigate, onSubmit, properties, schema, navigateOnSave, contentTypeName],
  );

  const contentObjectFormContextValue = useMemo(
    () => ({
      contentType,
      isEditing,
      initialData: contentObject,
    }),
    [contentObject, contentType, isEditing],
  );

  return (
    <Formik
      initialValues={JSON.parse(JSON.stringify(initialValues))}
      onSubmit={handleSubmit}
      validationSchema={yup.object().shape(validationObject)}
      enableReinitialize
    >
      <ContentObjectFormContext.Provider value={contentObjectFormContextValue}>
        <Form
          id={formId}
          className="flex flex-col space-y-3 md:space-y-6 pb-5"
          noValidate
          {...getTestProps(testId, 'formik')}
        >
          <CustomFormElement />
          {Object.keys(properties).map((key) =>
            renderFormField(
              key,
              properties[key],
              schema[key],
              requiredFields[key],
            ),
          )}
          <ValidationToastHandler />
          <DirtyHandler />
        </Form>
      </ContentObjectFormContext.Provider>
    </Formik>
  );
};

export default ContentObjectForm;

ContentObjectForm.propTypes = {
  /**
   * Content type
   */
  contentType: PropTypes.object.isRequired,
  /**
   * On sumbit callback
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Content object to edit
   */
  contentObject: PropTypes.object,
  /**
   * If form is used for object editing
   */
  isEditing: PropTypes.bool,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Does the object have initial data?
   */
  hasInitialData: PropTypes.bool,
  /**
   * Content object form id
   */
  formId: PropTypes.string,
  /**
   * If form is full size
   */
  isFullSize: PropTypes.bool,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

ContentObjectForm.defaultProps = {
  contentObject: {},
  isEditing: false,
  disabled: false,
  hasInitialData: false,
  formId: 'cto-form',
  isFullSize: false,
  testId: '',
};
