import { useTranslation } from 'react-i18next';
import {
  useCallback,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

// :: Components
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import Dropdown from '../../components/Dropdown/Dropdown';
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';

// :: Helpers
import { getTestProps } from '../../lib/helpers';
import { emailSchema, passwordSchema } from '../../lib/yupHelpers';

const UserForm = forwardRef(
  ({ user, onSubmit, mode, withDirtyHandler, testId }, ref) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const submitButtonRef = useRef();

    useImperativeHandle(ref, () => ({
      submit() {
        submitButtonRef.current.click();
      },
    }));

    const validationSchema = useMemo(
      () =>
        mode === 'add'
          ? yup.object({
              firstName: yup.string().required(t('Form.FormErrorNotBlank')),
              lastName: yup.string().required(t('Form.FormErrorNotBlank')),
              email: emailSchema(t),
              plainPassword: passwordSchema(t),
              language: yup.string().required(t('Form.FormErrorNotBlank')),
            })
          : yup.object({
              firstName: yup.string().required(t('Form.FormErrorNotBlank')),
              lastName: yup.string().required(t('Form.FormErrorNotBlank')),
              username: yup.string().required(t('Form.FormErrorNotBlank')),
              language: yup.string().required(t('Form.FormErrorNotBlank')),
            }),
      [mode, t],
    );

    const initialValues = useMemo(
      () =>
        mode === 'add'
          ? {
              email: '',
              plainPassword: '',
              firstName: '',
              lastName: '',
              language: 'en',
            }
          : {
              firstName: user.firstName,
              lastName: user.lastName,
              username: user.username,
              language: user.language,
            },
      [user, mode],
    );

    let buttonSaveText =
      mode === 'add' ? t('Global.Save') : t('Global.SaveChanges');

    const onCancel = useCallback(
      (formik) => formik.resetForm({ values: initialValues }),
      [initialValues],
    );

    const handleSubmit = useCallback(
      async (values, formik) => {
        setIsLoading(true);
        const errors = await onSubmit(values);
        formik.setStatus({ ...formik.status, errors });
        if (!errors || !Object.keys(errors).length)
          formik.resetForm({ values });
        setIsLoading(false);
      },
      [onSubmit],
    );

    const lngOptions = useMemo(
      () => [
        { value: 'en', label: t('UserForm.English') },
        { value: 'pl', label: t('UserForm.Polish') },
      ],
      [t],
    );

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
      >
        {(formik) => (
          <form
            id="user-form"
            className="max-w-3xl"
            onSubmit={formik.handleSubmit}
            noValidate={true}
          >
            <div className="flex flex-col gap-4 md:gap-6">
              <div className="text-xl md:text-2xl xl:text-3xl font-semibold dark:text-white">
                {t('UserForm.PersonalInformation')}
              </div>
              <div className="grid grid-cols-1 xs:grid-cols-2 items-center gap-4 md:gap-6 w-full">
                {mode === 'edit' && (
                  <Input
                    name={'username'}
                    placeholder={t('UserForm.Username')}
                    label={t('UserForm.Username')}
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.username || formik.status?.errors?.username
                    }
                    additionalInputClasses={
                      isLoading ? '' : '!bg-white dark:!bg-gray-900'
                    }
                    disabled
                    required
                  />
                )}

                {mode === 'add' && (
                  <>
                    <Input
                      name={'email'}
                      placeholder={'Email'}
                      label={'Email'}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.email || formik.status?.errors?.email
                      }
                      required
                      additionalInputClasses={
                        isLoading ? '' : '!bg-white dark:!bg-gray-900'
                      }
                      additionalClasses="min-h-[110px]"
                      {...getTestProps(testId, 'email', 'testId')}
                    />

                    <Input
                      name="plainPassword"
                      type="password"
                      placeholder={t('Global.Password')}
                      label={t('Global.Password')}
                      value={formik.values.plainPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.plainPassword ||
                        formik.status?.errors?.plainPassword
                      }
                      required
                      showPasswordInfo
                      additionalInputClasses={
                        isLoading ? '' : '!bg-white dark:!bg-gray-900'
                      }
                      additionalClasses="min-h-[110px]"
                      {...getTestProps(testId, 'plain-password', 'testId')}
                    />
                  </>
                )}

                <Input
                  name="firstName"
                  placeholder={t('Global.FirstName')}
                  label={t('Global.FirstName')}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.firstName || formik.status?.errors?.firstName
                  }
                  disabled={isLoading}
                  additionalClasses="xs:row-start-2 min-h-[110px]"
                  required
                  {...getTestProps(testId, 'first-name', 'testId')}
                />

                <Input
                  name="lastName"
                  placeholder={t('Global.LastName')}
                  label={t('Global.LastName')}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.lastName || formik.status?.errors?.lastName
                  }
                  disabled={isLoading}
                  additionalClasses="xs:row-start-2 min-h-[110px]"
                  required
                  {...getTestProps(testId, 'last-name', 'testId')}
                />

                <Dropdown
                  name="language"
                  label={t('UserForm.Language')}
                  options={lngOptions}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.language}
                  disabled={isLoading}
                  error={
                    formik.errors.language || formik.status?.errors?.language
                  }
                  additionalClasses="xs:row-start-3"
                  hideSearch
                  required
                />
              </div>
              <div>
                <div className="flex flex-row items-center gap-2 md:gap-6">
                  <Button
                    buttonSize="sm"
                    type="submit"
                    disabled={isLoading}
                    iconImage={
                      isLoading ? (
                        <Loader size="small" type="spinner-grid" />
                      ) : null
                    }
                    additionalClasses="w-fit whitespace-nowrap"
                    ref={submitButtonRef}
                    {...getTestProps(testId, 'submit', 'testId')}
                  >
                    {buttonSaveText}
                  </Button>
                  {formik.dirty && (
                    <Button
                      buttonColor="gray"
                      buttonSize="sm"
                      onClick={() => onCancel(formik)}
                      disabled={isLoading}
                      {...getTestProps(testId, 'cancel', 'testId')}
                    >
                      {t('Global.Cancel')}
                    </Button>
                  )}
                </div>
                {formik.status?.errors?.global && (
                  <div className="text-red mt-2 inline-flex">
                    <div className="block text-left">
                      {formik.status?.errors?.global}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {withDirtyHandler && <DirtyHandler />}
          </form>
        )}
      </Formik>
    );
  },
);

export default UserForm;

UserForm.propTypes = {
  /**
   * On submit handler
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * User data
   */
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    username: PropTypes.string,
    language: PropTypes.string,
  }),
  /**
   * User form test id
   */
  testId: PropTypes.string,
  /**
   * User mode between "add" or "edit"
   */
  mode: PropTypes.string,
};

UserForm.defaultProps = {
  user: {},
  withDirtyHandler: true,
  mode: 'edit',
  testId: '',
};
