import { useCallback, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// :: components
import Tabs from '../../components/Tabs/Tabs';

// :: contexts
import AppContext from '../../contexts/AppContext';

// :: hooks
import useOnce from '../../hooks/useOnce';

// :: subpages
import Usage from './subpages/Usage';
import Profile from './subpages/Profile';
import { HouseIcon } from '../../images/shapes';

const AccountSettings = ({ selectedTabIdx }) => {
  const { t } = useTranslation();
  const { updateAppContext } = useContext(AppContext);
  const navigate = useNavigate();

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'account-settings',
      topBar: {
        heading: t('Global.AccountSettings'),
        buttons: [
          {
            label: t('Global.Documentation'),
            color: 'blue',
            key: 'Documentation',
            link: process.env.REACT_APP_DOCUMENTATION,
            target: '_blank',
            rel: 'noreferrer',
          },
        ],
      },
    }));
  }, [t, updateAppContext]);

  useOnce(handlePageUpdate);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: '/',
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Dashboard',
        },
        {
          label: t('Global.AccountSettings'),
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
          key: 'AccountSettings',
        },
      ],
    }));
  }, [t, updateAppContext]);

  const tabsContent = useMemo(() => {
    return [
      {
        label: t('AccountSettings.Profile'),
        key: 'Profile',
        render: () => <Profile owner={true} mode={'edit'} />,
      },
      {
        label: t('Global.AccountDetails'),
        key: 'Usage',
        render: () => <Usage />,
      },
    ];
  }, [t]);

  const onTabChange = useCallback(
    (tabIdx) => {
      navigate(tabIdx === 0 ? '/profile' : '/usage');
    },
    [navigate],
  );

  return (
    <div className="flex items-stretch h-full w-full min-h-[calc(100vh-71px)]">
      <Helmet>
        <title>{t('Global.AccountSettings')}</title>
      </Helmet>
      <div className="flex flex-col w-full">
        <div className="px-4 xl:px-7 pb-7 w-full h-full relative mt-3">
          <Tabs
            tabs={tabsContent}
            defaultSelectedIndex={selectedTabIdx}
            onChange={onTabChange}
            additionalTabPanelsClasses="md:!mt-6"
            ignoreSelecting
          />
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;

AccountSettings.propTypes = {
  /**
   * Selected tab index
   */
  selectedTabIdx: PropTypes.number,
};

AccountSettings.defaultProps = {
  selectedTabIdx: 0,
};
