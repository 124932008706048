import { createContext } from 'react';
import PropTypes from 'prop-types';

export const ContentObjectFormContext = createContext({});

ContentObjectFormContext.propTypes = {
  value: PropTypes.shape({
    /**
     * Content type before editing
     */
    contentType: PropTypes.shape({}),
    /**
     * If cto isc urrently editing
     */
    isEditing: PropTypes.bool,
    /**
     * Initial data for content object
     */
    initialCto: PropTypes.shape({}),
  }).isRequired,
};

export default ContentObjectFormContext;
