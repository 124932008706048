import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-hot-toast';

// :: Components
import Button from '../../components/Button/Button';
import DataGridActionMenu from '../../components/DataGrid/DataGridActionMenu/DataGridActionMenu';
import Heading from '../../components/Heading/Heading';
import Loader from '../../components/Loader/Loader';
import ResultsPerPageDropdown from '../../components/Pagination/ResultsPerPageDropdown';
import StatusBar from '../../components/StatusBar/StatusBar';
import CustomizableDataGrid from '../../components/CustomizableDataGrid/CustomizableDataGrid';
import LinkButton from '../../components/LinkButton/LinkButton';

// :: Hooks
import { useHeadlessRoles } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useOnce from '../../hooks/useOnce';
import useToken from '../../hooks/useToken';

// :: Contexts
import AppContext from '../../contexts/AppContext';
import { useModals } from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';

// :: Lib
import { getLocalStorage, removeLocalStorage } from '../../utils/localStorage';
import { getTestProps, setInitialGridOptions } from '../../lib/helpers';
import { defaultRenderer } from '../../components/DataGrid/DataGridCell/cellRenderer.js';

// :: Api
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import { deleteHeadlessRole } from '../../lib/flotiq-client';

// :: Images
import {
  HouseIcon,
  RefreshIcon,
  WarningIcon,
  ZoomMaleWorkingImage,
} from '../../images/shapes';

const SORT_KEY = 'cms.headless-roles-sort';
const OPTIONS_KEY = 'cms.headless-roles-grid-state';

const HeadlessRoles = ({ testId }) => {
  const rolesGridContainer = useRef();
  const { t } = useTranslation();
  const jwt = useToken();
  const modal = useModals();
  const { updateAppContext } = useContext(AppContext);
  const { isAdmin } = useContext(UserContext);

  const [gridOptions, setGridOptions] = useState(
    getLocalStorage(OPTIONS_KEY, true),
  );

  const [sort, setSort] = useState(getLocalStorage(SORT_KEY, true));
  const [firstLoading, setFirstLoading] = useState(true);
  const [initDataHasContent, setInitDataHasContent] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [columns, setColumns] = useState([]);
  const [nameQuery, setNameQuery] = useState('');

  useEffect(() => {
    setSort(getLocalStorage(SORT_KEY, true));
  }, []);

  useEffect(() => {
    setGridOptions(getLocalStorage(OPTIONS_KEY, true));
  }, []);

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'headless-roles',
      topBar: {
        heading: t('Global.HeadlessRoles'),
        buttons: !isAdmin
          ? []
          : [
              {
                key: 'add',
                label: t('HeadlessRoles.Add'),
                link: '/headless-roles/add',
                ...getTestProps(testId, 'topbar-add', 'testId'),
              },
            ],
      },
    }));
  }, [updateAppContext, t, isAdmin, testId]);

  useOnce(handlePageUpdate);

  const rolesParams = useMemo(
    () => ({
      page,
      limit,
      ...(nameQuery ? { name: nameQuery } : {}),
      ...(sort?.sortOrder ? { order_direction: sort?.sortOrder } : {}),
      ...(sort?.sortField ? { order_by: sort?.sortField } : {}),
    }),
    [page, limit, nameQuery, sort?.sortOrder, sort?.sortField],
  );

  const rolesOptions = useMemo(
    () => ({
      pause: !isAdmin,
    }),
    [isAdmin],
  );

  const {
    data: roles,
    errors: rolesErrors,
    status: rolesStatus,
    isLoading: rolesAreLoading,
    pagination,
    reload,
  } = useHeadlessRoles(rolesParams, rolesOptions);

  useApiErrorsToast(rolesErrors);

  useEffect(() => {
    if (firstLoading) {
      setInitDataHasContent(false);
    }
    if (!rolesAreLoading) setFirstLoading(false);
  }, [firstLoading, rolesAreLoading]);

  useEffect(() => {
    if (roles.length && !initDataHasContent) {
      setInitDataHasContent(true);
    }
  }, [initDataHasContent, roles.length]);

  const handleResetGrid = useCallback(() => {
    setSort();
    setPage(1);
    setLimit(20);
    setNameQuery('');
    removeLocalStorage(SORT_KEY);
    removeLocalStorage(OPTIONS_KEY);
    setGridOptions();
  }, []);

  const handleLimitChange = useCallback((resultsLimit) => {
    setLimit(resultsLimit);
    setPage(1);
  }, []);

  const handlePageChange = useCallback((page) => {
    setPage(page);
  }, []);

  const handleFilters = useCallback((currentFilters) => {
    setNameQuery(currentFilters.name?.value);
    setPage(1);
  }, []);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: '/',
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Dashboard',
        },
        {
          label: t('Global.HeadlessRoles'),
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
          key: 'headless-roles',
        },
      ],
    }));
  }, [t, updateAppContext]);

  const showGrid = useMemo(
    () =>
      isAdmin &&
      (roles.length > 0 ||
        (rolesAreLoading && !firstLoading) ||
        nameQuery ||
        initDataHasContent),
    [
      isAdmin,
      roles.length,
      rolesAreLoading,
      firstLoading,
      nameQuery,
      initDataHasContent,
    ],
  );

  const emptyData = useMemo(() => {
    if (showGrid) return null;
    if (firstLoading && isAdmin) {
      return (
        <Loader
          size="big"
          type="spinner-grid"
          {...getTestProps(testId, 'loading', 'testId')}
        />
      );
    }
    if (rolesStatus === 403 || !isAdmin || rolesErrors)
      return (
        <Heading
          level={2}
          additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
        >
          <div
            className="flex flex-col items-center justify-center text-center"
            {...getTestProps(testId, 'empty-data')}
          >
            <WarningIcon className="text-red w-14 md:w-20 mb-3" />
            {rolesStatus === 403 || !isAdmin
              ? t('Global.NoAccess')
              : t('Media.OnErrorMessage')}
          </div>
        </Heading>
      );
    return (
      <>
        <ZoomMaleWorkingImage className="w-52" />
        <div
          className="text-center font-bold p-5"
          {...getTestProps(testId, 'empty-data')}
        >
          <p className="text-blue-600 text-2xl sm:text-3xl md:text-4xl">
            {t('ContentDefinition.CreateFirst')}
          </p>
          <p className="text-indigo-950 dark:text-white text-3xl sm:text-4xl md:text-5xl">
            {t('HeadlessRoles.CreateHeadlessRole')}
          </p>
        </div>
        <LinkButton
          buttonSize="base"
          link="/headless-roles/add"
          {...getTestProps(testId, 'add-first', 'testId')}
        >
          {t('HeadlessRoles.Add')}
        </LinkButton>
      </>
    );
  }, [showGrid, firstLoading, isAdmin, rolesStatus, rolesErrors, testId, t]);

  const handleDeleteRole = useCallback(
    async (id) => {
      modal.deleting('delete-modal');
      try {
        const { body, status } = await deleteHeadlessRole(jwt, {
          id,
        });
        checkResponseStatus(body, status);
        toast.success(t('HeadlessRoles.Deleted'));
        reload();
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(
            error.message ? error.message : t('HeadlessRoles.DeletingError'),
          );
        }
      }
    },
    [jwt, modal, reload, t],
  );

  const showDeleteModal = useCallback(
    async (id) => {
      modal.delete(t('HeadlessRoles.ConfirmDelete'), 'delete-modal', () =>
        handleDeleteRole(id),
      );
    },
    [modal, t, handleDeleteRole],
  );

  const actions = useCallback(
    (rowData) => [
      {
        label: t('Global.Duplicate'),
        link: `/headless-roles/duplicate/${rowData.id}`,
      },
      {
        key: 'edit',
        label: t('Global.Edit'),
        link: `/headless-roles/edit/${rowData.id}`,
      },
      {
        key: 'delete',
        label: t('Global.Delete'),
        onClick: () => showDeleteModal(rowData.id),
      },
    ],
    [showDeleteModal, t],
  );

  const handleInitialGridOptions = useCallback((cols, minWidth) => {
    setInitialGridOptions(
      cols,
      minWidth,
      rolesGridContainer,
      setGridOptions,
      setColumns,
      OPTIONS_KEY,
      false,
    );
  }, []);

  useEffect(() => {
    const gridOptionsByID = gridOptions?.reduce((acc, elem) => {
      acc[elem.colId] = elem;
      return acc;
    }, {});

    const cols = [
      {
        accessor: 'name',
        label: t('Global.Name'),
        width: gridOptionsByID?.['name']?.width,
        render: (data) => defaultRenderer(data),
        sortable: true,
        filterInputType: 'text',
        resizable: true,
      },
      {
        accessor: 'description',
        label: t('HeadlessRoles.Description'),
        width: gridOptionsByID?.['description']?.width,
        render: (data) => defaultRenderer(data),
        resizable: true,
      },
      {
        accessor: '_actions',
        label: '',
        width: gridOptionsByID?.['_actions']?.width,
        minWidth: 50,
        flexGrow: 0,
        fixed: 'right',
        align: 'right',
        justify: 'center',
        render: (_, rowData) => (
          <div className="flex items-center justify-start h-full">
            <DataGridActionMenu
              menuItems={actions(rowData)}
              rowData={rowData}
              {...getTestProps(testId, 'action', 'testId')}
            />
          </div>
        ),
        resizable: false,
        sortable: false,
      },
    ];

    if (!gridOptions) {
      handleInitialGridOptions(cols, 250);
    } else {
      setColumns(cols);
    }
  }, [t, actions, handleInitialGridOptions, gridOptions, testId]);

  return (
    <div className="w-full md:h-[calc(100vh-66px)]">
      <Helmet>
        <title>{t('Global.HeadlessRoles')}</title>
      </Helmet>
      <div className="flex flex-col h-full w-full">
        <div
          className={twMerge(
            'flex flex-wrap sm:flex-nowrap items-center justify-end ',
            'px-3 lg:px-7 py-2 sm:py-0 h-auto sm:h-11 w-full z-10 bg-slate-50 border-b gap-3 sm:gap-0',
            'dark:bg-gray-900 dark:border-b dark:border-slate-800',
          )}
        >
          {showGrid && (
            <div className="flex items-center justify-end w-full xs:w-auto">
              <Button
                buttonSize="sm"
                iconImage={<RefreshIcon className="text-blue w-4" />}
                onClick={handleResetGrid}
                buttonColor={'borderless'}
                additionalClasses="text-sm sm:text-base"
                noPaddings
                {...getTestProps(testId, 'reset-grid', 'testId')}
              >
                {t('Global.ResetGrid')}
              </Button>
              <ResultsPerPageDropdown
                value={limit}
                setResultsPerPage={handleLimitChange}
                additionalButtonClasses="!pr-8 2xl:!py-1"
                additionalClasses="!p-0 ml-5 text-sm sm:text-base"
                label={t('Global.ResultsPerPage')}
                {...getTestProps(testId, 'results-per-page', 'testId')}
              />
            </div>
          )}
        </div>
        <div
          className="w-full h-[calc(100vh-230px)] md:h-[calc(100%-190px)] rounded-lg mt-7 px-5 lg:px-7"
          ref={rolesGridContainer}
        >
          {showGrid ? (
            <CustomizableDataGrid
              setSort={setSort}
              sortingLocalStorageKey={SORT_KEY}
              optionsLocalStorageKey={OPTIONS_KEY}
              setCurrentPage={setPage}
              gridOptions={gridOptions}
              setGridOptions={setGridOptions}
              columns={columns}
              data={roles}
              isLoading={rolesAreLoading}
              sort={sort?.sortField || undefined}
              sortOrder={sort?.sortOrder || undefined}
              statusBar={
                <StatusBar
                  rows={pagination.count}
                  currentPage={page}
                  pagesCount={pagination.total_pages}
                  handlePageChange={handlePageChange}
                  handleDataUpdate={reload}
                  resultsFrom={(pagination.current_page - 1) * limit + 1}
                  resultsTo={
                    (pagination.current_page - 1) * limit + pagination.count
                  }
                  resultsTotalCount={pagination.total_count}
                  {...getTestProps(testId, 'statusbar', 'testId')}
                />
              }
              noDataInfoText={t('HeadlessRoles.FiltersEmptyResult')}
              hasFilters
              filters={{ name: { value: nameQuery } }}
              onFilter={handleFilters}
              additionalClasses={twMerge('bg-white dark:bg-slate-950 ')}
              {...getTestProps(testId, 'grid', 'testId')}
            />
          ) : (
            <div className="flex flex-col items-center justify-center h-full">
              {emptyData}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeadlessRoles;

HeadlessRoles.propTypes = {
  /**
   * Test id for headless roles page
   */
  testId: PropTypes.string,
};

HeadlessRoles.defaultProps = {
  testId: '',
};
