import PropTypes from 'prop-types';

// :: Lib
import { getTestProps } from '../../../lib/helpers';

const PlanItemRow = ({ value, title, testId }) => {
  if (!value && !title) {
    return null;
  }

  return (
    <p
      className={'flex flex-row mb-4 last:mb-1 dark:text-slate-400'}
      {...getTestProps(testId, 'container')}
    >
      {value && (
        <span
          className="text-base font-bold mr-1"
          {...getTestProps(testId, 'value')}
        >
          {value}
        </span>
      )}
      {title && (
        <span
          className="text-base font-normal"
          {...getTestProps(testId, 'title')}
        >
          {title}
        </span>
      )}
    </p>
  );
};

export default PlanItemRow;

PlanItemRow.propTypes = {
  /**
   * Componente test Id
   */
  testId: PropTypes.string,
  /**
   * Row value
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Row Title
   */
  title: PropTypes.string,
};

PlanItemRow.defaultProps = {
  testId: '',
  value: '',
  title: '',
};
