import {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';
import TagManager from 'react-gtm-module';

// :: Components
import Loader from '../../../components/Loader/Loader';
import Heading from '../../../components/Heading/Heading';
import UserHeadlessRolesForm from '../../../form/UserHeadlessRolesForm/UserHeadlessRolesForm';

// :: Hooks
import { useHeadlessRoles, useUser } from '../../../hooks/api';
import useOnce from '../../../hooks/useOnce';
import useToken from '../../../hooks/useToken';
import useApiErrorsToast from '../../../hooks/api/useApiErrorsToast';

// :: Contexts
import { useModals } from '../../../contexts/ModalContext';
import AppContext from '../../../contexts/AppContext';
import UserContext from '../../../contexts/UserContext';

// :: Lib
import { changePasswordRequest } from '../../../lib/flotiq-client/api-helpers';
import {
  generateRedirectUrl,
  getMaskedOrganizationName,
  getTestProps,
} from '../../../lib/helpers';
import {
  ResponseError,
  checkResponseStatus,
} from '../../../lib/flotiq-client/response-errors';
import {
  deleteAccountRequest,
  putSubscribe,
  putUnsubscribe,
  postUser,
  getHeadlessRoles,
  assignHeadlessRole,
  unassignHeadlessRole,
} from '../../../lib/flotiq-client';

// :: Form
import UserForm from '../../../form/UserForm/UserForm';
import UserActions from '../../../form/UserForm/UserActions/UserActions';

// :: Utils
import { setLocalStorage } from '../../../utils/localStorage';

// :: Images
import { HouseIcon, WarningIcon } from '../../../images/shapes';

// :: Const
const HEADLESS_ROLES_PARAMS = {
  page: 1,
  limit: 1000,
};

const WithBreadcrumbs = ({
  children,
  include,
  isSaving,
  isDeleting,
  handleDeleteUser,
  userDetails,
  refUserFormSubmitButton,
  testId,
  isAdmin,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { updateAppContext } = useContext(AppContext);

  const topBarButtons = useMemo(() => {
    const validDisabled = isSaving || isDeleting;
    const buttons = [
      {
        key: 'save',
        label: t('Global.Save'),
        onClick: () => refUserFormSubmitButton.current.submit(),
        iconImage: isSaving ? <Loader size="small" type="spinner-grid" /> : '',
        disabled: validDisabled,
        ...getTestProps(testId, 'topbar-save', 'testId'),
      },
    ];

    if (isAdmin && id && userDetails?.data?.id !== id) {
      buttons.push({
        key: 'delete',
        label: t('Global.Delete'),
        onClick: handleDeleteUser,
        color: 'redBordered',
        iconImage: isDeleting ? (
          <Loader size="small" type="spinner-grid" />
        ) : (
          ''
        ),
        disabled: validDisabled,
        ...getTestProps(testId, 'topbar-delete-user', 'testId'),
      });
    }

    buttons.push({
      key: 'cancel',
      label: t('Global.Cancel'),
      onClick: () => navigate(`/users`),
      color: 'gray',
      disabled: validDisabled,
      ...getTestProps(testId, 'topbar-cancel', 'testId'),
    });

    return buttons;
  }, [
    navigate,
    id,
    userDetails?.data?.id,
    isSaving,
    isDeleting,
    handleDeleteUser,
    refUserFormSubmitButton,
    testId,
    isAdmin,
    t,
  ]);

  const handlePageUpdate = useCallback(() => {
    if (include) {
      updateAppContext?.((prevState) => ({
        ...prevState,
        topBar: {
          heading: id ? t(`Users.Edit`) : t('Users.Add'),
          buttons: topBarButtons,
        },
        breadcrumbs: [
          {
            label: <HouseIcon className="w-3 text-blue" />,
            link: '/',
            additionalClasses: 'text-slate-400 truncate text-center',
            key: 'Dashboard',
          },
          {
            label: t('Global.Users'),
            link: '/users',
            additionalClasses: 'text-slate-400 truncate',
            key: 'Users',
          },
          {
            label: id ? t(`Users.Edit`) : t('Users.Add'),
            additionalClasses: 'text-zinc-600 truncate',
            disabled: true,
            key: id ? 'EditUser' : 'AddUser',
          },
        ],
      }));
    }
  }, [t, updateAppContext, include, id, topBarButtons]);

  useOnce(handlePageUpdate);

  return include ? (
    <div className="flex w-full min-h-[calc(100vh-71px)] items-stretch">
      <Helmet>
        <title>{t('Global.Users')}</title>
      </Helmet>
      <div className="flex flex-col h-full w-full">
        <div
          className={twMerge(
            'grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 h-full relative mt-7',
          )}
        >
          {children}
        </div>
      </div>
    </div>
  ) : (
    children
  );
};

const Profile = ({ testId, owner, mode, include }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const jwt = useToken();
  const modal = useModals();
  const { id } = useParams();
  const ref = useRef();
  const {
    isAdmin,
    userStorage: userDetails,
    baseUserEventData,
  } = useContext(UserContext);

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [firstLoading, setFirstLoading] = useState(true);

  const {
    entity: user,
    updateEntity: updateUser,
    deleteEntity: deleteContentObject,
    isLoading,
    errors,
    reload: reloadUser,
  } = useUser(id || userDetails?.data?.id);

  useApiErrorsToast(errors);

  const handleDeleteUser = useCallback(async () => {
    setIsDeleting(true);

    const result = await modal.confirmation(
      t('Users.UserDelete'),
      t('Global.Warning'),
      t('Global.Delete'),
      t('Global.Cancel'),
    );

    if (result) {
      try {
        const { body, status } = await deleteContentObject({ id });

        checkResponseStatus(body, status);
        toast.success(t('Users.UserDeleteSuccess', { name: user.email }));

        TagManager.dataLayer({
          dataLayer: {
            event: 'account_leave_org',
            user_id: user.id,
            organization_id: user.organization?.id,
            organization_name: getMaskedOrganizationName(
              user.organization?.name,
            ),
            plan_id: user.plan?.id,
            plan_name: user.plan?.name,
          },
        });

        TagManager.dataLayer({
          dataLayer: baseUserEventData,
        });

        navigate(`/users`);
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(
            error.message ? error.message : t('ContentForm.CouldntDelete'),
          );
        }
      }
    }

    setIsDeleting(false);
  }, [modal, t, deleteContentObject, id, user, baseUserEventData, navigate]);

  useEffect(() => {
    if (!isLoading) setFirstLoading(false);
  }, [isLoading]);

  const onUserChange = useCallback(
    async (values) => {
      try {
        const { body, status } = await updateUser(values);
        checkResponseStatus(body, status);
        reloadUser();
        toast.success(t('UserForm.UpdatingSuccess'));
        setIsSaving(false);

        if (owner) {
          const newUserData = { ...userDetails };
          newUserData.data.firstName = values.firstName;
          newUserData.data.lastName = values.lastName;
          newUserData.data.username = values.username;
          newUserData.data.language = values.language;
          setLocalStorage('cms.user', newUserData);
        }

        return null;
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return { global: t('Form.CommunicationErrorMessage') };
        }
        toast.error(
          error.message
            ? t('ContentForm.Errors.TryAgain')
            : t('UserForm.UpdatingError'),
        );
        setIsSaving(false);
        return error.errors;
      }
    },
    [reloadUser, t, updateUser, owner, userDetails],
  );

  const onSubscriptionChange = useCallback(
    async (isSubscribed, email, activationToken) => {
      try {
        let result;
        if (isSubscribed)
          result = await putUnsubscribe(jwt, {
            email,
            activationToken,
          });
        else
          result = await putSubscribe(jwt, {
            email,
            activationToken,
          });
        checkResponseStatus(result.body, result.status);
        reloadUser();
        toast.success(
          isSubscribed
            ? t('UserForm.UnsubscribeSuccess')
            : t('UserForm.SubscribeSuccess'),
        );
        return null;
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return t('Form.CommunicationErrorMessage');
        }
        const errorMessage = isSubscribed
          ? t('UserForm.UnsubscribeError')
          : t('UserForm.SubscribeError');
        toast.error(errorMessage);
        return errorMessage;
      }
    },
    [jwt, reloadUser, t],
  );

  const onPasswordRequest = useCallback(
    async (email) => {
      const errors = await changePasswordRequest(email, t);
      return errors?.general;
    },
    [t],
  );

  const onDelete = useCallback(
    async (email) => {
      try {
        let { body, status } = await deleteAccountRequest(jwt, {
          email,
          redirectUri: generateRedirectUrl('/delete-account-confirm'),
        });
        checkResponseStatus(body, status);
        toast.success(t('UserForm.DeleteLinkSuccess'));
        return null;
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return t('Form.CommunicationErrorMessage');
        }
        const errorMessage = error.errors?.form
          ? t('Form.FormErrorEmailSent')
          : t('UserForm.DeletingError');
        toast.error(errorMessage);
        return errorMessage;
      }
    },
    [jwt, t],
  );

  const handleNoData = useMemo(() => {
    if (!(firstLoading || errors)) return null;
    if (firstLoading) return <Loader size="big" type="spinner-grid" />;
    return (
      <Heading
        level={2}
        additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'no-user')}
        >
          <WarningIcon className="text-red w-14 md:w-20 mb-3" />
          <div className="h-full flex items-center justify-center">
            {t('UserForm.FetchError')}
          </div>
        </div>
      </Heading>
    );
  }, [errors, firstLoading, t, testId]);

  const onAddUser = useCallback(
    async (values) => {
      try {
        const { body, status } = await postUser(jwt, {
          ...values,
          username: values.email,
          ...(process.env.REACT_APP_SEND_REGISTRATION_EMAIL.split(',').join(
            ',',
          ) !== 'false'
            ? {}
            : { enabled: true, sendMail: false }),
          redirectUri: generateRedirectUrl('/register/activate'),
        });
        checkResponseStatus(body, status);

        toast.success(t('UserForm.UpdatingSuccess'));

        TagManager.dataLayer({
          dataLayer: {
            event: 'account_join_org',
            user_id: body.user_id,
            organization_id: body.organization_id,
            organization_name: getMaskedOrganizationName(
              body.organization_name,
            ),
            plan_id: undefined,
            plan_name: undefined,
          },
        });

        TagManager.dataLayer({
          dataLayer: baseUserEventData,
        });

        navigate(`/users`);

        return null;
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return { global: t('Form.CommunicationErrorMessage') };
        }
        if (error.status === 403 && error.message) {
          toast.error(t('UserForm.TeamMembersLimitExceeded'));
          return error.errors;
        }

        toast.error(
          error.message
            ? t('ContentForm.Errors.TryAgain')
            : t('UserForm.UpdatingError'),
        );
        return error.errors;
      }
    },
    [jwt, t, baseUserEventData, navigate],
  );

  const validView = owner || isAdmin;

  const handleNoAccess = useMemo(() => {
    if (!owner && !isAdmin) {
      return (
        <Heading
          level={2}
          additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
          {...getTestProps(testId, 'heading', 'testId')}
        >
          <div className="flex flex-col items-center justify-center text-center">
            <WarningIcon className="text-red w-14 md:w-20 mb-3" />
            {t('Global.NoAccess')}
          </div>
        </Heading>
      );
    }
  }, [isAdmin, owner, t, testId]);

  const headlessRolesOptions = useMemo(
    () => ({
      pause: mode !== 'edit' || !include,
    }),
    [include, mode],
  );

  const {
    data: headlessRoles,
    isLoading: headlessRolesAreLoading,
    errors: headlessRolesErrors,
  } = useHeadlessRoles(HEADLESS_ROLES_PARAMS, headlessRolesOptions);

  useApiErrorsToast(headlessRolesErrors);

  const filterHeadlessRoles = useCallback(
    async (query) => {
      try {
        const { body, status } = await getHeadlessRoles(jwt, {
          ...HEADLESS_ROLES_PARAMS,
          name: query,
        });
        checkResponseStatus(body, status);
        return body.data?.map((role) => ({ value: role.id, label: role.name }));
      } catch (error) {
        toast.error(
          t(
            error instanceof ResponseError
              ? 'HeadlessRoles.CouldntFetch'
              : 'Form.CommunicationErrorMessage',
          ),
        );
        return [];
      }
    },
    [jwt, t],
  );

  const handleUpdateRole = useCallback(
    async (userId, roleId, errors, assign = true) => {
      const method = assign ? assignHeadlessRole : unassignHeadlessRole;
      try {
        const { body, status } = await method(jwt, {
          id: roleId,
          users: [userId],
        });
        checkResponseStatus(body, status);
      } catch {
        errors.push(roleId);
      }
    },
    [jwt],
  );

  const handleRolesUpdate = useCallback(
    async (newRoles) => {
      if (!user?.id) return;
      const newRolesSet = new Set(newRoles || []);
      const oldRolesSet = new Set(user.headlessRoles?.map((role) => role.id));

      const rolesToAssign = [...newRolesSet].filter(
        (id) => !oldRolesSet.has(id),
      );
      const rolesToUnassign = [...oldRolesSet].filter(
        (id) => !newRolesSet.has(id),
      );

      let finalErrorMessage = '';
      const assignErrors = [];
      const unassignErrors = [];

      for (const roleId of rolesToAssign) {
        await handleUpdateRole(user.id, roleId, assignErrors);
      }
      for (const roleId of rolesToUnassign) {
        await handleUpdateRole(user.id, roleId, unassignErrors, false);
      }

      if (assignErrors.length) {
        finalErrorMessage += t('HeadlessRoles.CouldntAssign', {
          roleId: assignErrors.join(', '),
        });
      }
      if (unassignErrors.length) {
        finalErrorMessage +=
          (finalErrorMessage ? '\n' : '') +
          t('HeadlessRoles.CouldntUnassign', {
            roleId: unassignErrors.join(', '),
          });
      }

      if (finalErrorMessage)
        toast.error(
          <span className="whitespace-pre-line">{finalErrorMessage}</span>,
        );
      else toast.success(t('UserForm.UpdatingSuccess'));

      return finalErrorMessage;
    },
    [handleUpdateRole, t, user?.headlessRoles, user?.id],
  );

  return (
    <WithBreadcrumbs
      include={include}
      isDeleting={isDeleting}
      isSaving={isSaving}
      handleDeleteUser={handleDeleteUser}
      refUserFormSubmitButton={ref}
      testId={testId}
      userDetails={userDetails}
      isAdmin={isAdmin}
    >
      <div
        className={twMerge(
          'h-full bg-white dark:bg-slate-950 rounded-lg col-span-3',
          'p-5 lg:px-7 mx-4 xl:ml-7 xl:mr-3.5 mb-7',
          !validView && 'flex justify-center items-center',
        )}
      >
        {handleNoAccess}

        {validView && (firstLoading || errors) ? (
          <div className="h-full flex items-center justify-center">
            {handleNoData}
          </div>
        ) : (
          validView && (
            <>
              <UserForm
                ref={ref}
                mode={mode}
                user={mode === 'edit' ? user : {}}
                onSubmit={mode === 'edit' ? onUserChange : onAddUser}
                testId={testId}
              />

              {mode === 'edit' && (
                <>
                  <div
                    className="h-0 border border-slate-200 dark:border-slate-800 w-full mt-6 md:mt-10 mb-3
                    md:mb-8"
                  />
                  <UserActions
                    user={user}
                    onSubscriptionChange={onSubscriptionChange}
                    onDelete={onDelete}
                    onPasswordRequest={onPasswordRequest}
                    testId={testId}
                    deletable={owner || userDetails?.data?.id === id}
                  />
                </>
              )}
            </>
          )
        )}
      </div>
      {include &&
        user?.headlessRoles &&
        !headlessRolesAreLoading &&
        headlessRoles.length > 0 && (
          <div
            className="col-span-3 xl:col-auto px-4 xl:pl-3.5 xl:pr-7 pb-7 xl:border-l dark:border-slate-800
            flex flex-col gap-5 w-full mt-5 xl:mt-0"
          >
            <UserHeadlessRolesForm
              roles={user?.headlessRoles?.map((role) => role.id)}
              onSubmit={(roles) => handleRolesUpdate(roles)}
              rolesOptions={headlessRoles.map((role) => ({
                value: role.id,
                label: role.name,
              }))}
              filterRoles={filterHeadlessRoles}
              additionalClasses="py-5 rounded-lg bg-white dark:bg-slate-950 h-fit px-4"
              {...getTestProps(testId, 'roles-form', 'testId')}
            />
          </div>
        )}
    </WithBreadcrumbs>
  );
};

export default Profile;

Profile.propTypes = {
  /**
   * Test id for page
   */
  testId: PropTypes.string,
  /**
   * Profile inlude HOC with breadcrumbs
   */
  include: PropTypes.bool,
};

Profile.defaultProps = {
  mode: 'edit',
  include: false,
  testId: '',
};
