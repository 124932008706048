import { toast } from 'react-hot-toast';
import {
  getContentObjects,
  listContentTypes,
  postContentObject,
  postMedia,
} from './index';
import { formatErrorToString, isImagePreviewSupported } from '../helpers';
import { ResponseError, checkResponseStatus } from './response-errors';
import { resetPasswordRequest } from './auth-requests';
import TagManager from 'react-gtm-module';

const apiUrl = process.env.REACT_APP_FLOTIQ_API_URL;

export const defaultToDo = {
  publicDoc: {
    title: 'Visit api documentation',
    status: 'todo',
  },
  contentType: {
    title: 'Add content type definition',
    status: 'todo',
  },
  contentObject: {
    title: 'Add content object',
    status: 'todo',
  },
  gatsbyStarters: {
    title: 'Try our starter projects',
    status: 'todo',
  },
};

/**
 * Generate url to media with specified parameters
 * @param {object} mediaData
 * @param {number} [desiredWidth=0]
 * @param {number} [desiredHeight=0]
 * @returns {string} url for the media
 */
export function getMediaUrl(mediaData, desiredWidth = 0, desiredHeight = 0) {
  const url = `${apiUrl.replace(/\/{1,10}$/, '')}/image`;
  const size =
    mediaData?.type !== 'image' ? '0x0' : `${desiredWidth}x${desiredHeight}`;

  const basePath = `${url}/${size}/${mediaData.id}`;

  if (
    process.env.REACT_APP_ADD_FILENAME_TO_MEDIA_URL.split(',').join(',') ===
    'true'
  ) {
    return `${basePath}/${encodeURIComponent(mediaData.fileName).replace(
      /\.(?=.*\.)/g,
      '-',
    )}`;
  }

  return `${basePath}.${mediaData.extension}`;
}

/**
 * Update user tasks list
 * @param {string} taskName
 * @param {object} user
 * @param {(user: object) => Promise<void>} updateUser
 * @param {string} [contentTypeSlug='']
 */
export async function markTaskAsDone(
  taskName,
  user,
  updateUser,
  contentTypeSlug = '',
) {
  try {
    const toDoList = JSON.parse(JSON.stringify(defaultToDo));
    if (!user) return;
    const newConfig =
      user?.config && !Array.isArray(user?.config) ? { ...user.config } : {};
    if (!newConfig.todo) {
      newConfig.todo = toDoList;
    }
    if (!newConfig.todo[taskName]) {
      if (!toDoList[taskName]) {
        throw new Error(`Task doesn't exists ${taskName}`);
      }
      newConfig.todo[taskName] = toDoList[taskName];
    }
    const status = newConfig.todo[taskName].status;
    if (status === 'done' && !contentTypeSlug) return;
    newConfig.todo[taskName].status = 'done';
    if (contentTypeSlug)
      newConfig.todo[taskName].contentTypeSlug = contentTypeSlug;
    await updateUser({ config: newConfig });
  } catch (e) {
    console.warn(e);
  }
}

/**
 * Uploads file and handles upload errors
 * @param {string} jwt User JWT token
 * @param {File} file
 * @param {(key: string, options?: object) => string} t Translation function
 * @returns {Promise.<[result: any, errors?: string]>} Array containing result on first element and errors on the second
 */
export async function uploadFile(jwt, file, t) {
  if (!file) return;
  try {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', file.type.includes('image') ? 'image' : 'file');
    const { body, ok } = await postMedia(
      jwt,
      {},
      {
        skipContentType: true,
        body: formData,
      },
    );
    if (!ok) {
      throw new Error(formatErrorToString(body));
    }
    const showImages = isImagePreviewSupported(body.extension);

    toast(t('Media.OnAddSuccess', { name: body.fileName }), {
      icon: (
        <div className="h-12 w-12 mr-2">
          {body.type === 'image' && showImages ? (
            <img
              src={getMediaUrl(body, 150)}
              alt={t('Media.OnAddSuccess', { name: body.fileName })}
              className="h-full w-full object-center object-cover"
            />
          ) : (
            <div className="flex items-center justify-center rounded-full h-full w-full bg-blue-300">
              {body.extension}
            </div>
          )}
        </div>
      ),
    });
    return [body, ''];
  } catch (e) {
    const error = e.message || t('Media.OnErrorMessage');
    toast.error(error);
    return [file, error];
  }
}

/**
 * Uploads array of files
 * @param {string} jwt User JWT token
 * @param {FileList} files
 * @param {(key: string, options?: object) => string} t Translation function
 * @returns {Promise.<[result: any, errors?: string][]>} array of uploaded media
 */
export async function uploadFiles(jwt, files, t) {
  if (!files || !files.length) return;
  const uploadedMedia = [];
  await Promise.all(
    Array.from(files).map(async (file) => {
      const media = await uploadFile(jwt, file, t);
      uploadedMedia.push(media);
    }),
  );
  return uploadedMedia;
}

/**
 * Filter tags data that contains the query
 * @param {string} jwt User JWT token
 * @param {string} query
 * @param {(key: string, options?: object) => string} t Translation function
 * @param {object} tagsParams
 * @returns {Promise.<object[]>} array of tags data
 */
export async function getTagsFromQuery(jwt, query, t, tagsParams) {
  const tagQueryParams = {
    ...tagsParams,
    contentTypeName: '_tag',
    filters: JSON.stringify({
      name: { filter: query, type: 'contains' },
    }),
  };
  let newOptions = [];
  try {
    const { body, status } = await getContentObjects(jwt, tagQueryParams);
    checkResponseStatus(body, status);
    newOptions = body.data || [];
  } catch (error) {
    toast.error(
      t(
        error instanceof ResponseError
          ? 'TagsManagement.CouldntFetch'
          : 'Form.CommunicationErrorMessage',
      ),
    );
  }
  return newOptions;
}

/**
 * Handle create/update CTO errors
 * @param error
 * @param values
 * @param t
 * @param createCTO
 */
const handleCTOErrors = (error, values, t, createCTO = false) => {
  if (!(error instanceof ResponseError)) {
    toast.error(t('Form.CommunicationErrorMessage'));
    return [[values, {}], true];
  }

  if (error.status === 413) {
    toast.error(t('ContentForm.Errors.ContentToLarge'));
    return [[values, error.errors], true];
  }

  if (createCTO) {
    toast.error(
      error.message
        ? t('ContentForm.Errors.TryAgain')
        : t('ContentForm.CouldntAdd'),
    );
    return [[values, error.errors], true];
  }

  toast.error(
    error.message
      ? t('ContentForm.Errors.TryAgain')
      : t('ContentForm.CouldntSave'),
  );
  return [[values, error.errors], true];
};

/**
 * Update content type
 * @param {object} values
 * @param {string} contentTypeName
 * @param {(contentObject: object) => Promise<void>} updateContentObject
 * @param {(key: string, options?: object) => string} t Translation function
 * @returns {Promise.<[[result: object, erors: object], failed: boolean]>}
 * updating result that contains new ctd values, fetch errors and booleand value if request failed
 */
export async function updateCTO(
  values,
  contentTypeName,
  updateContentObject,
  t,
) {
  try {
    const { status, body } = await updateContentObject({
      ...values,
      contentTypeName,
    });
    checkResponseStatus(body, status);
    toast.success(t('ContentForm.Saved'));

    TagManager.dataLayer({
      dataLayer: {
        event: 'co_change',
      },
    });

    return [[body, {}], false];
  } catch (error) {
    return handleCTOErrors(error, values, t);
  }
}

/**
 * Save new content type
 * @param {string} jwt User JWT token
 * @param {object} values
 * @param {string} contentTypeName
 * @param {object} user
 * @param {(user: object) => Promise<void>} updateUser
 * @param {(key: string, options?: object) => string} t Translation function
 * @returns {Promise.<[[result: object, erors: object], failed: boolean]>}
 * saving result that contains new ctd values, fetch errors and booleand value if request failed
 */
export async function saveNewCTO(
  jwt,
  values,
  contentTypeName,
  user,
  updateUser,
  t,
) {
  try {
    const { body, status } = await postContentObject(jwt, {
      ...values,
      contentTypeName,
    });
    checkResponseStatus(body, status);
    toast.success(t('ContentForm.Added'));

    TagManager.dataLayer({
      dataLayer: {
        event: 'co_change',
      },
    });

    markTaskAsDone('contentObject', user, updateUser);
    return [[body, {}], false];
  } catch (error) {
    return handleCTOErrors(error, values, t, true);
  }
}

/**
 * Send change password request
 * @param {string} email
 * @param {(key: string, options?: object) => string} t Translation function
 * @returns {Promise.<object>} response errors
 */
export async function changePasswordRequest(email, t) {
  try {
    const { body, status } = await resetPasswordRequest(email);
    checkResponseStatus(body, status);
    toast.success(t('Form.FormChangePasswordRequestSuccess'), {
      duration: 10000,
    });
    return null;
  } catch (error) {
    if (!(error instanceof ResponseError)) {
      toast.error(t('Form.CommunicationErrorMessage'));
      return {
        general: t('Form.CommunicationErrorMessage'),
        ...error.errors,
      };
    }
    const errorMessage = error.errors?.form
      ? t('Form.FormErrorEmailSent')
      : error.message;
    const finalError = errorMessage ? errorMessage : t('Form.ValidationError');
    toast.error(finalError);
    return {
      general: finalError,
      ...error.errors,
    };
  }
}

/**
 * Get ctds data from query
 * @param {string} jwt User JWT token
 * @param {string} query
 * @param {(key: string, options?: object) => string} t Translation function
 * @returns {Promise.<Array.<object>>} A list of filtered content types
 */
export async function getCtdsFromQuery(jwt, params, t) {
  try {
    const { body, status } = await listContentTypes(jwt, params);
    checkResponseStatus(body, status);
    return body.data || [];
  } catch (error) {
    toast.error(
      t(
        error instanceof ResponseError
          ? 'ContentForm.CouldntFetch'
          : 'Form.CommunicationErrorMessage',
      ),
    );
    return [];
  }
}
