import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Lib
import { getTestProps } from '../../lib/helpers';

export const ProgressBar = ({
  progressBarLabel,
  outOfCompletionValue,
  outOfCompletionValueUnit,
  outOfCompletionTotalValue,
  outOfCompletionTotalValueUnit,
  completionPercentValue,
  barThickness,
  barBackgroundColor,
  hidePercentValue,
  additionalLabelClasses,
  additionalCompletionValuesContainerClasses,
  additionalCompletionTotalValuesClasses,
  additionalBarClasses,
  additionalContainerClasses,
  testId,
}) => {
  const thicknessClass = {
    thin: 'h-2',
    thick: 'h-3',
  };
  const backgroundColorClass = {
    blue: 'bg-blue',
    orange: 'bg-orange',
    green: 'bg-lime',
    gradientBackground: 'bg-gradient-blueRed',
  };

  return (
    <div
      className={twMerge(
        'flex flex-col items-start text-black w-full leading-none',
        additionalContainerClasses,
      )}
      {...getTestProps(testId, 'wrapper')}
    >
      {progressBarLabel && (
        <p
          className={twMerge(
            'text-slate-400 text-sm font-light mb-2',
            additionalLabelClasses,
          )}
        >
          {progressBarLabel}
        </p>
      )}

      <div className="flex items-end">
        {outOfCompletionValue != null && (
          <div
            className={twMerge(
              'font-medium mb-1 whitespace-nowrap',
              additionalCompletionValuesContainerClasses,
            )}
          >
            <span className="font-bold dark:text-white">
              {outOfCompletionValue}
            </span>
            <span className="font-bold dark:text-white">
              {' '}
              {outOfCompletionValueUnit}
            </span>
            <span className="dark:text-white">
              {' '}
              {outOfCompletionTotalValue ? '/ ' : ''}
            </span>
            <span
              className={twMerge(
                'font-normal text-sm dark:text-white',
                additionalCompletionTotalValuesClasses,
              )}
            >
              {outOfCompletionTotalValue}
            </span>
            <span
              className={twMerge(
                'font-normal dark:text-white',
                additionalCompletionTotalValuesClasses,
              )}
            >
              {' '}
              {outOfCompletionTotalValueUnit}
            </span>
          </div>
        )}
      </div>

      <div
        className={twMerge('flex items-center w-full', additionalBarClasses)}
        {...getTestProps(testId, 'indicator-wrap')}
      >
        <div
          className={twMerge(
            'w-full bg-gray dark:bg-gray-700 rounded-full overflow-hidden',
            thicknessClass[barThickness],
          )}
        >
          <div
            data-testid="progress-bar-indicator"
            className={twMerge(
              'rounded-full dark:text-white',
              thicknessClass[barThickness],
              backgroundColorClass[barBackgroundColor],
            )}
            style={{ width: `${completionPercentValue}%` }}
          />
        </div>

        {!isNaN(completionPercentValue) && !hidePercentValue && (
          <p className="whitespace-nowrap min-w-[50px] !w-[50px] ml-3 text-lg font-semibold font-ibm dark:text-white">
            <span>{completionPercentValue.toFixed(0)}</span>%
          </p>
        )}
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  /**
   * Progress bar label
   */
  progressBarLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Progress completion - Out of completion value
   */
  outOfCompletionValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
  ]),
  /**
   * Progress completion - Out of completion value unit
   */
  outOfCompletionValueUnit: PropTypes.string,
  /**
   * Progress completion - Out of completion value
   */
  outOfCompletionTotalValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
  ]),
  /**
   * Progress completion - Out of completion value unit
   */
  outOfCompletionTotalValueUnit: PropTypes.string,
  /**
   * Progress completion - accepted values from 0 to 100
   */
  completionPercentValue: PropTypes.oneOfType([PropTypes.number]),
  /**
   * Progress bar background color
   */
  barThickness: PropTypes.oneOf(['thin', 'thick']),
  /**
   * Progress bar background color
   */
  barBackgroundColor: PropTypes.oneOf([
    'blue',
    'orange',
    'green',
    'gradientBackground',
  ]),
  /**
   * If percents should be hidden
   */
  hidePercentValue: PropTypes.bool,
  /**
   * If progress bar should display as compact variant
   */
  isCompact: PropTypes.bool,
  /**
   * Progress bar label additional CSS classes
   */
  additionalLabelClasses: PropTypes.string,
  /**
   * Progress bar completion values container additional CSS classes
   */
  additionalCompletionValuesContainerClasses: PropTypes.string,
  /**
   * Progress bar additional CSS classes
   */
  additionalBarClasses: PropTypes.string,
  /**
   * Progress bar container additional CSS classes
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Progress bar total value additional CSS classes
   */
  additionalCompletionTotalValuesClasses: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

ProgressBar.defaultProps = {
  progressBarLabel: '',
  outOfCompletionValue: null,
  outOfCompletionValueUnit: '',
  outOfCompletionTotalValue: '',
  outOfCompletionTotalValueUnit: '',
  completionPercentValue: 0,
  barThickness: 'thin',
  barBackgroundColor: 'blue',
  additionalLabelClasses: '',
  additionalCompletionValuesContainerClasses: '',
  additionalCompletionTotalValuesClasses: '',
  additionalBarClasses: '',
  additionalContainerClasses: '',
  hidePercentValue: false,
  isCompact: false,
  testId: '',
};

export default ProgressBar;
