import { useFormikContext } from 'formik';
import FlotiqPlugins from '../../../lib/flotiq-plugins/flotiqPluginsRegistry';
import ContentObjectFormContext from '../../../contexts/ContentObjectFormContext';
import { useContext, useMemo } from 'react';
import NewMediaContext from '../../../contexts/NewMediaContext';
import ElementFromPlugin from '../../../components/ElementFromPlugin/ElementFromPlugin';
import { FormAddElementEvent } from '../../../lib/flotiq-plugins/plugin-events/FormAddElementEvent';

const CustomFormElement = () => {
  const { contentType, initialData } = useContext(ContentObjectFormContext);
  const { onUpload: onMediaUpload } = useContext(NewMediaContext);
  const formik = useFormikContext();

  /**
   * @emits FlotiqPlugins."flotiq.form::add"
   */
  const pluginRenders = useMemo(
    () =>
      (
        FlotiqPlugins.run(
          'flotiq.form::add',
          new FormAddElementEvent({
            contentType,
            formik,
            onMediaUpload,
            initialData,
          }),
        ) || []
      ).filter((r) => !!r),
    [contentType, formik, initialData, onMediaUpload],
  );

  return pluginRenders?.length ? (
    <ElementFromPlugin results={pluginRenders} />
  ) : null;
};

export default CustomFormElement;
