import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

// :: Component
import Button from '../../components/Button/Button';
import Heading from '../../components/Heading/Heading';

// :: Hooks
import useDarkMode from '../../hooks/useDarkMode';

const Page404 = () => {
  const { t } = useTranslation();
  const [darkMode] = useDarkMode();

  return (
    <div
      className={twMerge(
        'w-full h-screen flex flex-col justify-center items-center',
        darkMode && 'dark:bg-gray-900',
      )}
    >
      <Heading
        level={1}
        alignment={'center'}
        additionalClasses="mb-10 dark:text-white"
      >
        {t('Global.Page404')}
      </Heading>

      <Link to="/">
        <Button>{`${t('Global.BackTo')} ${t('Global.Dashboard')}`}</Button>
      </Link>
    </div>
  );
};

export default Page404;
