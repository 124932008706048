import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

import Button from '../Button/Button';
import StatusPill from '../StatusPill/StatusPill';
import { strToChecksum } from '../../lib/helpers';

const SearchResultTile = ({
  title,
  image,
  properties,
  ctdLabel,
  ctdName,
  selected,
  onClick,
}) => (
  <Button
    buttonColor="borderless"
    additionalClasses={twMerge(
      'rounded w-full border hover:bg-slate-50 h-12 px-2',
      selected ? 'bg-slate-50 border-blue' : 'border-transparent',
    )}
    additionalChildrenClasses={'w-full'}
    onClick={onClick}
  >
    <span className="text-left flex w-full items-center gap-4 whitespace-nowrap">
      {image && <img src={image} alt={title} className="w-12 h-12 rounded" />}
      {title && (
        <span className="min-w-[20%] max-w-[30%] w-full truncate">{title}</span>
      )}{' '}
      <StatusPill
        color={['lime', 'blue', 'red', 'orange'][strToChecksum(ctdName) % 4]}
        status={ctdLabel}
      />
      {!!properties && (
        <span
          className="truncate text-slate-400/80 text-sm space-x-2 max-w-[50%]"
          title={properties}
        >
          {properties}
        </span>
      )}
    </span>
  </Button>
);

SearchResultTile.propTypes = {
  /**
   * A text title for the result
   */
  title: PropTypes.string,
  /**
   * Image url for the results with visual representation
   */
  image: PropTypes.string,
  /**
   * A string with additional object properties to display
   */
  properties: PropTypes.string,
  /**
   * Content type label
   */
  ctdLabel: PropTypes.string,
  /**
   * Content type name to be used for color selection
   */
  ctdName: PropTypes.string,
  /**
   * Set to true for highlighting the tile
   */
  selected: PropTypes.bool,
  /**
   * Click event callback
   */
  onClick: PropTypes.func,
};

SearchResultTile.defaultProps = {
  title: '',
  image: '',
  properties: '',
  ctdLabel: '',
  ctdName: '',
  selected: false,
};

export default SearchResultTile;
