import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { getLocalStorage, removeLocalStorage } from '../../utils/localStorage';
import FlotiqPlugins from '../../lib/flotiq-plugins/flotiqPluginsRegistry';

const Logout = () => {
  const navigate = useNavigate();
  const userRaw = getLocalStorage('cms.user');
  const user = useMemo(() => JSON.parse(userRaw), [userRaw]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'logout',
      },
    });

    removeLocalStorage('cms.user');
    window.dataLayer.push(function () {
      this.reset();
    });
    navigate('/login');

    if (FlotiqPlugins.getLoadedPluginsIds().length) window.location.reload();
  }, [navigate, user]);

  return <></>;
};

export default Logout;
