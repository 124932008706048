import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../Button/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { getTestProps } from '../../lib/helpers';

// :: Icons
import { CloseIcon } from '../../images/shapes';

const Modal = ({
  open,
  onClose,
  buttons,
  title,
  content,
  size,
  hideClose,
  dialogAdditionalClasses,
  testId,
}) => {
  let bodyRef = useRef(null);
  const { t } = useTranslation();

  const sizeClass = {
    sm: 'w-full max-w-[300px]',
    md: 'w-full max-w-[500px]',
    lg: 'w-full max-w-[700px]',
    xl: 'w-full max-w-[900px]',
    '2xl': 'w-full max-w-[1052px] m-1 md:m-4',
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        initialFocus={bodyRef}
        as="div"
        className="relative z-30"
        onClose={() => true}
        {...getTestProps(testId, 'container')}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-250"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-250"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-30 inset-0 overflow-y-auto overflow-y-overlay">
          <div className="flex items-start md:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-250"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-250"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  sizeClass[size || 'md'],
                  'relative bg-white dark:bg-gray-900 rounded-lg text-left ',
                  'max-h-[calc(100vh-5rem)] md:max-h-[calc(100vh-3rem)]',
                  'overflow-hidden shadow-xl transform transition-all text-black',
                  dialogAdditionalClasses,
                )}
              >
                <div className="absolute top-0 right-0">
                  {!hideClose && (
                    <button
                      type="button"
                      className="rounded-md text-zinc-600 hover:text-gray-500 focus:outline-none
                      pt-3 pr-3 sm:pt-7 sm:pr-6"
                      onClick={onClose}
                      {...getTestProps(testId, 'x-icon')}
                    >
                      <span className="sr-only">{t('Global.CloseModal')}</span>
                      <CloseIcon
                        className="w-2.5 sm:w-3.5 h-2.5 sm:h-3.5 text-indigo-950 dark:text-white hover:text-gray-300"
                        aria-hidden="true"
                      />
                    </button>
                  )}
                </div>
                <div
                  ref={bodyRef}
                  className="h-full"
                  {...getTestProps(testId, 'body')}
                >
                  <Dialog.Title
                    className={twMerge(
                      'leading-none flex items-center dark:text-white',
                      title && 'h-[4rem]',
                    )}
                  >
                    {title && (
                      <div className="w-full font-medium px-6 md:px-11 py-2 dark:text-white">
                        {title}
                      </div>
                    )}
                  </Dialog.Title>
                  {content && (
                    <div
                      className={twMerge(
                        'flex flex-col items-center justify-start',
                        'px-6 md:px-11 overflow-y-auto w-full scrollbar-sm pb-20',
                        title
                          ? 'max-h-[calc(100vh-7rem)] pt-3'
                          : 'max-h-[calc(100vh-20rem)] pt-7',
                      )}
                    >
                      <div className="w-full h-full dark:text-gray-200">
                        {content}
                      </div>
                    </div>
                  )}
                </div>
                {buttons && buttons.length > 0 && (
                  <div
                    className="w-full fixed left-0 bottom-0 flex items-center justify-center p-3 space-x-5
                    border-t border-gray dark:border-slate-800 bg-white dark:bg-gray-900"
                    {...getTestProps(testId, 'buttons')}
                  >
                    {buttons.map((button) => (
                      <Button
                        key={button.key}
                        buttonSize="sm"
                        buttonColor={button.color}
                        onClick={button.onClick}
                        additionalClasses={button.additionalClasses}
                        iconImage={button.iconImage}
                        disabled={button.disabled}
                        testId={button.testId}
                        {...button.props}
                      >
                        {button.label}
                      </Button>
                    ))}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

Modal.propTypes = {
  /**
   * Is open state
   */
  open: PropTypes.bool,
  /**
   * On close callback
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Modal dialog title
   */
  title: PropTypes.node,
  /**
   * Modal dialog subtitle
   */
  content: PropTypes.node,
  /**
   * Buttons under the dialog text
   */
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      onClick: PropTypes.func,
      additionalClasses: PropTypes.string,
      testId: PropTypes.string,
      color: PropTypes.string,
      iconImage: PropTypes.node,
      disabled: PropTypes.bool,
      key: PropTypes.string.isRequired,
    }),
  ),
  /**
   * Modal dialog subtitle
   */
  testId: PropTypes.string,
  /**
   * Modal size
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']),
  /**
   * If close button should be hidden
   */
  hideClose: PropTypes.bool,
  /**
   * Modal dialog additional classes
   */
  dialogAdditionalClasses: PropTypes.string,
};

Modal.defaultProps = {
  content: '',
  buttons: [],
  testId: '',
  size: 'md',
  dialogAdditionalClasses: '',
  title: '',
  open: true,
  onClose: /* istanbul ignore next */ () => null,
  hideClose: false,
};

export default Modal;
