import { formatErrorToString } from '../helpers';

export class ResponseError extends Error {
  status = 0;
  constructor(message, errors, global, status) {
    super(message);
    this.errors = errors;
    this.global = global;
    this.status = status;
  }
}

export const checkResponseStatus = (body, status) => {
  if (status >= 200 && status < 300) {
    return true;
  }

  if (!body) throw new ResponseError('', null, [], status);
  let rawErrors = body;
  if (body.errors) {
    rawErrors = body.errors;
  }
  if (body.message && body.code && typeof body.code === 'number') {
    rawErrors = body.message;
  }
  if (body.quota_name) {
    rawErrors = body.quota_name;
  }

  throw new ResponseError(
    formatErrorToString(rawErrors),
    rawErrors,
    Array.isArray(rawErrors) ? rawErrors : [],
    status,
  );
};
