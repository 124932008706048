import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

// :: Components
import Panel from '../Panel/Panel';
import DateContainer from '../DateContainer/DateContainer';

// :: Components Inner
import InformationRow from './InformationRow/InformationRow';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Images
import {
  ClockIcon,
  FileIcon,
  UsersCurrentColorIcon,
  LightningCurrentColorIcon,
} from '../../images/shapes';

const ContentObjectInformations = ({
  createdAt,
  createdAtText,
  updatedAt,
  updatedAtText,
  createdBy,
  createdByText,
  lastPublishedAt,
  lastPublishedText,
  additionalClasses,
  additionalTitleClasses,
  additionalContainerClasses,
  additionalChildrenClasses,
  additionalIconClasses,
  isPinned,
  children,
  testId,
}) => {
  const { t } = useTranslation();

  return (
    <Panel
      title={t('ContentForm.Information')}
      isCollapsable={false}
      additionalContainerClasses={twMerge(
        'pt-5 pb-2 px-4',
        isPinned &&
          'lg:absolute lg:top-0 lg:right-0 lg:z-10 lg:rounded-tr-none lg:rounded-br-none ' +
            'lg:!w-[63px] lg:h-full lg:max-h-[calc(100%-30px)]',
        additionalClasses,
      )}
      additionalTitleClasses={twMerge(
        'text-lg',
        isPinned && 'lg:!mr-0 lg:max-w-[32px] lg:overflow-hidden',
        additionalTitleClasses,
      )}
      additionalChildrenClasses={twMerge(additionalChildrenClasses)}
      open
      {...getTestProps(testId, 'container', 'testId')}
    >
      <div
        className={twMerge(
          'flex flex-col space-y-5',
          isPinned && 'lg:justify-center lg:items-center',
          additionalContainerClasses,
        )}
      >
        <DateContainer
          date={createdAt}
          icon={
            <ClockIcon
              className={twMerge(
                'text-blue w-6 mr-1 xl:mr-2',
                isPinned && 'lg:!mr-0',
                additionalIconClasses,
              )}
              {...getTestProps(testId, 'created-at-icon')}
            />
          }
          text={createdAtText || t('TypeDefinitionCard.Created')}
          isCompact={isPinned}
          {...getTestProps(testId, 'data-created-at', 'testId')}
        />

        {createdBy && (
          <InformationRow
            title={createdBy}
            icon={
              <UsersCurrentColorIcon
                className={twMerge(
                  'text-blue w-6 mr-1 xl:mr-2',
                  isPinned && 'lg:!mr-0',
                  additionalIconClasses,
                )}
                {...getTestProps(testId, 'created-by-icon')}
              />
            }
            label={createdByText || t('TypeDefinitionCard.CreatedBy')}
            value={createdBy}
            isCompact={isPinned}
            {...getTestProps(testId, 'created-by', 'testId')}
          />
        )}

        <DateContainer
          icon={
            <FileIcon
              className={twMerge(
                'text-blue w-6 mr-1 xl:mr-2',
                isPinned && 'lg:!mr-0',
                additionalIconClasses,
              )}
              {...getTestProps(testId, 'updated-at-icon')}
            />
          }
          text={updatedAtText || t('TypeDefinitionCard.Updated')}
          date={updatedAt}
          isCompact={isPinned}
          {...getTestProps(testId, 'data-updated-at', 'testId')}
        />

        {lastPublishedAt && (
          <DateContainer
            icon={
              <LightningCurrentColorIcon
                className={twMerge(
                  'text-blue h-6 w-6 mr-1 xl:mr-2',
                  isPinned && 'lg:!mr-0',
                  additionalIconClasses,
                )}
                {...getTestProps(testId, 'last-published-at-icon')}
              />
            }
            text={lastPublishedText || t('TypeDefinitionCard.LastPublished')}
            date={lastPublishedAt}
            isCompact={isPinned}
            {...getTestProps(testId, 'data-last-published-at', 'testId')}
          />
        )}

        {!isPinned && (
          <div {...getTestProps(testId, 'children')}>{children}</div>
        )}
      </div>
    </Panel>
  );
};

export default ContentObjectInformations;

ContentObjectInformations.propTypes = {
  /**
   * Created date of content object
   */
  createdAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    momentPropTypes.momentObj,
  ]),
  /**
   * Updated date of content object
   */
  updatedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    momentPropTypes.momentObj,
  ]),
  /**
   * Created by person first and last name
   */
  createdBy: PropTypes.string,
  /**
   * Last published date of content object
   */
  lastPublishedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    momentPropTypes.momentObj,
  ]),
  /**
   * Content object information children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Additional CSS classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Container additional CSS classes
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Children additional CSS classes
   */
  additionalChildrenClasses: PropTypes.string,
  /**
   * Icon additional CSS classes
   */
  additionalIconClasses: PropTypes.string,
  /**
   * Created by label
   */
  createdByText: PropTypes.string,
  /**
   * Created at label
   */
  createdAtText: PropTypes.string,
  /**
   * Updated at label
   */
  updatedAtText: PropTypes.any,
  /**
   * Last published label
   */
  lastPublishedText: PropTypes.string,
  /**
   * Test id for informations container
   */
  testId: PropTypes.string,
};

ContentObjectInformations.defaultProps = {
  createdAt: '',
  updatedAt: '',
  createdBy: '',
  lastPublishedAt: '',
  createdByText: '',
  createdAtText: '',
  updatedAtText: '',
  lastPublishedText: '',
  additionalClasses: '',
  additionalTitleClasses: '',
  additionalContainerClasses: '',
  testId: '',
};
