import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

// :: Lib
import {
  getDataSize,
  getTestProps,
  isImagePreviewSupported,
} from '../../lib/helpers';
import { getMediaUrl } from '../../lib/flotiq-client/api-helpers';

// :: Component
import Card from '../Card/Card';
import StatusPill from '../StatusPill/StatusPill';
import DateContainer from '../DateContainer/DateContainer';
import Checkbox from '../Checkbox/Checkbox';
import Button from '../Button/Button';

// :: Images
import {
  ArrowUpRightIcon,
  PencilIcon,
  TrashIcon,
  TagIcon,
} from '../../images/shapes';

const MediaCard = ({
  mediaData,
  hideOverlay,
  thumbnail,
  onClick,
  isSelected,
  onDelete,
  onEdit,
  onShow,
  onTagEdit,
  onTagClick,
  additionalContainerClasses,
  additionalImageClasses,
  additionalThumbnailClasses,
  testId,
  activeSortOrder,
}) => {
  const { t } = useTranslation();
  const showImage = isImagePreviewSupported(mediaData?.extension);

  const imageUrl = useMemo(() => {
    if (mediaData.type === 'image' && showImage) {
      return getMediaUrl(mediaData, 300);
    }
  }, [mediaData, showImage]);

  const imageFileContainer = useMemo(() => {
    if (thumbnail) return thumbnail;
    if (
      mediaData.type !== 'image' ||
      (mediaData.type === 'image' && !showImage)
    ) {
      return (
        <div className="flex items-center justify-center h-full w-full">
          <div className="flex items-center justify-center rounded-full h-12 w-12 bg-blue-300">
            {mediaData.extension || ''}
          </div>
        </div>
      );
    }
    return '';
  }, [thumbnail, mediaData, showImage]);

  const imageUploadedDate = useMemo(() => {
    if (mediaData.internal?.createdAt) {
      return (
        <DateContainer
          additionalClasses="text-white text-sm sm:text-base"
          additionalTextClasses="font-semibold mr-2 text-sm sm:text-base"
          date={mediaData.internal?.createdAt}
          text={t('Media.MediaCard.ImageData.ImageUploadedDate')}
          isSemibold={false}
        />
      );
    }
    return null;
  }, [mediaData, t]);

  const imageOverlay = useMemo(() => {
    if (hideOverlay) return null;
    return (
      <div
        className="flex flex-col h-full p-3 justify-between"
        {...getTestProps(testId, 'media-overlay')}
      >
        <Checkbox
          additionalContainerClasses="group-hover:block"
          additionalCheckboxClasses={twMerge(
            'border-0 group-hover:block group-hover:border dark:block',
            'checked:disabled:bg-blue checked:disabled:border-blue disabled:cursor-pointer dark:border-gray-200',
          )}
          checked={isSelected}
          circular
          {...getTestProps(testId, '', 'testId')}
        />
        <div
          className="hidden group-hover:flex flex-col items-start justify-between transition
          duration-200 ease-in-out grow"
        >
          <div className="text-white flex flex-col items-start justify-start mt-3">
            {mediaData.type === 'image' && (
              <div
                className="flex font-semibold text-sm sm:text-base"
                {...getTestProps(testId, 'image-dimensions')}
              >
                <Trans i18nKey="Media.MediaCard.ImageData.ImageDimensions">
                  Dimensions:
                  <span className="whitespace-nowrap ml-1 font-normal">
                    {{ width: mediaData.width }} x{' '}
                    {{ height: mediaData.height }} px
                  </span>
                </Trans>
              </div>
            )}
            <div
              className="flex font-semibold text-sm sm:text-base"
              {...getTestProps(testId, 'image-size')}
            >
              <Trans i18nKey="Media.MediaCard.ImageData.ImageByteSize">
                Size:
                <span className="whitespace-nowrap ml-1 font-normal">
                  {{ size: getDataSize(mediaData.size) }}
                </span>
              </Trans>
            </div>
            <div className="flex text-white">{imageUploadedDate}</div>
          </div>

          <div className="flex justify-end gap-2 w-full">
            {onEdit && (
              <Button
                buttonColor="grayBordered"
                buttonSize="xs"
                iconColor="white"
                testId={'open-tag-management-modal'}
                iconImage={
                  <PencilIcon className="w-4 group-hover:text-white" />
                }
                additionalClasses="shrink-0 bg-transparent hover:bg-transparent"
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit(mediaData);
                }}
              />
            )}
            {onShow && (
              <Button
                buttonColor="blueBordered"
                buttonSize="xs"
                iconColor="blue"
                testId={'open-full-size-media'}
                iconImage={
                  <ArrowUpRightIcon className="w-[12px] group-hover:text-white" />
                }
                additionalClasses="shrink-0 bg-transparent"
                onClick={(event) => {
                  event.stopPropagation();
                  onShow(mediaData);
                }}
              />
            )}
            {onDelete && (
              <Button
                buttonColor="redBordered"
                buttonSize="xs"
                iconColor="red"
                iconImage={<TrashIcon className="w-4 group-hover:text-white" />}
                testId={'delete-media'}
                additionalClasses="shrink-0 bg-transparent"
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(mediaData);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }, [
    hideOverlay,
    testId,
    isSelected,
    mediaData,
    imageUploadedDate,
    onEdit,
    onShow,
    onDelete,
  ]);

  const tagsList = useMemo(() => {
    const tags = mediaData.tags;
    return (
      <>
        {onTagEdit && (
          <TagIcon
            className="min-w-[1rem] w-4 h-4 mr-3 cursor-pointer dark:text-slate-400"
            onClick={() => onTagEdit(mediaData)}
          />
        )}
        {tags?.length > 0 && (
          <div
            className="flex flex-wrap"
            {...getTestProps(testId, 'tags-list')}
          >
            {tags
              .filter((tag) => tag)
              .map((tag) => {
                return (
                  <StatusPill
                    onClick={() => onTagClick?.(tag.id, tag.name)}
                    key={tag.id}
                    status={tag.name}
                    containerClasses="mx-1 !p-0 !h-5 my-0.5 !px-1.5 !bg-gray-200 dark:!bg-gray-900 cursor-pointer
                    rounded leading-none flex items-center justify-center dark:text-slate-400 dark:border-slate-400"
                  />
                );
              })}
          </div>
        )}
      </>
    );
  }, [mediaData, onTagClick, onTagEdit, testId]);

  const activeSortValueLabel = useMemo(() => {
    if (activeSortOrder === 'internal.createdAt') {
      return (
        <DateContainer
          additionalClasses={'font-medium'}
          additionalTextClasses={'mr-2 font-normal'}
          date={mediaData.internal?.createdAt}
          text={t('Media.MediaCard.ImageData.ImageUploadedDate')}
          isSemibold={false}
        />
      );
    }

    if (activeSortOrder === 'extension') {
      return (
        <Trans i18nKey="Media.MediaCard.ImageData.ImageExtension">
          Extension:
          <span className="whitespace-nowrap ml-1 font-normal">
            {{ extension: mediaData.extension }}
          </span>
        </Trans>
      );
    }

    return (
      <Trans i18nKey="Media.MediaCard.ImageData.ImageByteSize">
        Size:
        <span className="whitespace-nowrap ml-1 font-normal">
          {{ size: getDataSize(mediaData.size) }}
        </span>
      </Trans>
    );
  }, [
    activeSortOrder,
    mediaData.extension,
    mediaData.internal?.createdAt,
    mediaData.size,
    t,
  ]);

  return (
    <Card
      testId={testId}
      darkenOnHover
      additionalContainerClasses={twMerge(
        'w-auto border border-slate-200 dark:border-0',
        additionalContainerClasses,
      )}
      additionalImageClasses={twMerge(
        'object-scale-down h-40 w-auto sm:h-52 dark:bg-gray-700',
        additionalImageClasses,
      )}
      additionalBodyClasses="h-full"
      additionalImageContainerClasses={
        mediaData.type !== 'media' ? 'h-40 w-auto sm:h-52 dark:bg-gray-700' : ''
      }
      body={
        <div
          className="w-full h-full p-2 pb-4 sm:p-4 text-xs sm:text-sm bg-white dark:bg-slate-950 font-light
          relative"
        >
          <div
            className="text-sm sm:text-base font-normal truncate dark:text-gray-200"
            title={mediaData.fileName}
          >
            {mediaData.fileName}
          </div>
          <div className="flex items-center pt-4 pb-3 relative">
            <div className="absolute left-0 text-sm sm:text-base dark:text-gray-200">
              {activeSortValueLabel}
            </div>
          </div>
          <div
            className="mt-3.5 border-t border-gray-200 dark:border-slate-800 px-1 pt-2.5 flex items-center cursor-auto"
            onClick={(event) => event.stopPropagation()}
          >
            {tagsList}
          </div>
        </div>
      }
      additionalThumbnailClasses={additionalThumbnailClasses}
      imageOverlay={!hideOverlay ? imageOverlay : ''}
      imageUrl={imageUrl}
      onClick={() => onClick?.(mediaData)}
      thumbnail={imageFileContainer}
      imageUrlAlt={mediaData.fileName}
    />
  );
};

MediaCard.propTypes = {
  /**
   * Object with image data {url, size, width,height, author, source}
   */
  mediaData: PropTypes.object,
  /**
   * If overlay should be hidden
   */
  hideOverlay: PropTypes.bool,
  /**
   * On card click callback
   */
  onClick: PropTypes.func,
  /**
   * If card is selected
   */
  isSelected: PropTypes.bool,
  /**
   * On delete button click callback
   */
  onDelete: PropTypes.func,
  /**
   * On delete button click callback
   */
  onEdit: PropTypes.func,
  /**
   * On delete button click callback
   */
  onShow: PropTypes.func,
  /**
   * On delete button click callback
   */
  onTagEdit: PropTypes.func,
  /**
   * On delete button click callback
   */
  onTagClick: PropTypes.func,
  /**
   * Card file container override
   */
  thumbnail: PropTypes.node,
  /**
   * Additional card container classes
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Additional image classes
   */
  additionalImageClasses: PropTypes.string,
  /**
   * Additional thumbnail classes
   */
  additionalThumbnailClasses: PropTypes.string,
  /**
   * Card test id
   */
  testId: PropTypes.string,
  /**
   * Active sort order
   */
  activeSortOrder: PropTypes.any,
};
MediaCard.defaultProps = {
  mediaData: {
    fileName: 'Unknown',
    size: 'Unknown',
    imageUrl: '#',
    source: 'Unknown',
    sourceUrl: '#',
    width: '#',
    height: '#',
    type: 'image',
    internal: {
      createdAt: null,
    },
  },
  additionalContainerClasses: '',
  thumbnail: '',
  hideOverlay: false,
  isSelected: false,
  additionalImageClasses: '',
  testId: '',
};

export default MediaCard;
