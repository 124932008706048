import { useMemo, useContext, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { twMerge } from 'tailwind-merge';
import TagManager from 'react-gtm-module';

// :: Component
import Heading from '../../components/Heading/Heading';
import RightSidebar from '../../components/RightSidebar/RightSidebar';
import Tiles from '../../components/Tile/Tiles';
import AccountTypePill from '../../components/AccountTypePill/AccountTypePill';
import StatusBar from '../../components/StatusBar/StatusBar';
import DataGrid from '../../components/DataGrid/DataGrid';
import Loader from '../../components/Loader/Loader';
import StatusPill from '../../components/StatusPill/StatusPill';
import DateContainer from '../../components/DateContainer/DateContainer';
import Tooltip from '../../components/Tooltip/Tooltip';
import DataGridActionMenu from '../../components/DataGrid/DataGridActionMenu/DataGridActionMenu';

// :: Utils
import { getLocalStorage } from '../../utils/localStorage';

// :: Lib
import {
  generateApiDocs,
  getObjectTitle,
  getTestProps,
  strToChecksum,
} from '../../lib/helpers';
import { markTaskAsDone } from '../../lib/flotiq-client/api-helpers';

// :: Sections
import AccountDetails from '../../sections/AccountDetails/AccountDetails';
import GetStarted from '../../sections/GetStarted/GetStarted';
import PackagesGrid from '../../sections/PackagesGrid/PackagesGrid';
import SocialsPanel from '../../sections/SocialsPanel/SocialsPanel';

// :: Hooks
import useOnce from '../../hooks/useOnce';
import {
  useContentTypes,
  useSearch,
  useConstraints,
  useUser,
} from '../../hooks/api';

// :: Context
import AppContext from '../../contexts/AppContext';

// :: Images
import {
  AngularLogo,
  CopyIcon,
  CSharpLogo,
  QuestionMarkWavedIcon,
  DiscordColorIcon,
  PencilIcon,
  BookIcon,
  FacebookColorIcon,
  GithubColorIcon,
  GoLogo,
  JavaLogo,
  LightningIcon,
  NodeJsLogo,
  PHPLogo,
  PostmanLogo,
  PythonLogo,
  RedditColorIcon,
  TwitterColorIcon,
  YoutubeColorIcon,
  LinkedinColorIcon,
  QuestionMarkCircleIcon,
  GithubColorIconWhite,
  RedditColorIconWhite,
} from '../../images/shapes';

const getProgressInformation = (count, limit, t, totalUnit = '') =>
  limit === -1
    ? {
        percent: 100,
        totalValue: t('Global.Unlimited'),
        hidePercentages: true,
        totalUnit: '',
      }
    : {
        percent: (count / limit) * 100,
        totalValue: limit,
        totalUnit: totalUnit,
      };

const LAST_CHANGES_LIMIT = 10;

const CTD_PARAMS = { limit: 10000 };

const Home = ({ testId }) => {
  const { t } = useTranslation();
  const { appContext, updateAppContext } = useContext(AppContext);
  const userRaw = getLocalStorage('cms.user');
  const userDetails = useMemo(() => JSON.parse(userRaw)?.data, [userRaw]);

  const { entity: ctoCount } = useConstraints('cto-count');
  const { entity: ctdCount } = useConstraints('ctd-count');
  const { entity: mediaCount } = useConstraints('media-sum-size');
  const { entity: apiKeysCount } = useConstraints('scoped-keys-count');

  const { entity: user, updateEntity: updateUser } = useUser(userDetails?.id);

  const [currentPage, setCurrentPage] = useState(1);

  const { data: contentTypes, isLoading: contentTypesLoading } =
    useContentTypes(CTD_PARAMS);

  const contentTypesDict = useMemo(
    () =>
      contentTypes.reduce((acc, ctd) => {
        acc[ctd.name] = ctd;
        return acc;
      }, {}),
    [contentTypes],
  );

  const searchParams = useMemo(
    () => ({
      q: '*',
      limit: LAST_CHANGES_LIMIT,
      order_by: 'internal.updatedAt',
      order_direction: 'desc',
      page: currentPage,
    }),
    [currentPage],
  );

  const {
    data: searchData,
    isLoading: searchLoading,
    pagination,
    reload,
  } = useSearch(searchParams);

  const allContentItems = useMemo(() => {
    return searchData.map((el) => {
      return {
        cto: getObjectTitle(
          el.item,
          contentTypesDict[el.item.internal.contentType],
        ),
        ctd: {
          label:
            contentTypesDict[el.item.internal.contentType]?.label ||
            el.item.internal.contentType,
          name: el.item.internal.contentType,
          internal:
            !contentTypesDict[el.item.internal.contentType] ||
            contentTypesDict[el.item.internal.contentType].internal,
        },
        updated_at: el.item.internal.updatedAt,
        action: `/content-type-objects/edit/${el.item.internal.contentType}/${el.item.id}`,
      };
    });
  }, [contentTypesDict, searchData]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'dashboard',
      topBar: {
        heading: t('Global.Dashboard'),
        buttons: [
          {
            label: t('Global.Documentation'),
            color: 'blue',
            key: 'Documentation',
            link: process.env.REACT_APP_DOCUMENTATION,
            target: '_blank',
            rel: 'noreferrer',
          },
        ],
      },
    }));
  }, [t, updateAppContext]);

  useOnce(handlePageUpdate);

  const actions = useCallback(
    (link) => [
      {
        label: t('Global.Edit'),
        icon: <PencilIcon className="w-3.5 mr-2" />,
        link,
      },
    ],
    [t],
  );

  const DEFAULT_TILES = useMemo(
    () => [
      {
        title: t('Global.Documentation'),
        icon: <BookIcon className="h-9 text-white mb-2" />,
        link: process.env.REACT_APP_DOCUMENTATION,
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        key: 'blog',
        title: t('Global.Blog'),
        icon: <CopyIcon className="h-8 text-white mb-2" />,
        link: process.env.REACT_APP_BLOG,
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        key: 'discord',
        title: t('Global.Discord'),
        icon: <QuestionMarkWavedIcon className="h-9 text-white mb-2" />,
        link: process.env.REACT_APP_DISCORD,
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        key: 'starters',
        title: t('Global.Starters'),
        icon: <LightningIcon className="h-9 text-white mb-2" />,
        link: '/starters',
      },
    ],
    [t],
  );

  const accountResources = useMemo(
    () => [
      {
        barColor: 'blue',
        label: t('Global.ContentTypeObjects'),
        value: ctoCount?.data,
        ...getProgressInformation(
          ctoCount?.data,
          userDetails?.limits_plan?.cto_limit,
          t,
        ),
        key: 'ContentTypeObjects',
      },
      {
        barColor: 'blue',
        label: t('Global.FileQuota'),
        value: mediaCount?.data,
        unit: 'MB',
        ...getProgressInformation(
          mediaCount?.data,
          userDetails?.limits_plan?.file_quota,
          t,
          'MB',
        ),
        key: 'FileQuota',
      },
      {
        barColor: 'blue',
        label: t('Global.ContentTypeDefinitions'),
        value: ctdCount?.data,
        ...getProgressInformation(
          ctdCount?.data,
          userDetails?.limits_plan?.ctd_limit,
          t,
        ),
        key: 'ContentTypeDefinitions',
      },
      {
        barColor: 'gradientBackground',
        label: t('Global.ScopedApiKeysCount'),
        value: apiKeysCount?.data,
        ...getProgressInformation(
          apiKeysCount?.data,
          userDetails?.limits_plan?.scoped_keys_limit,
          t,
        ),
        key: 'ScopedApiKeysCount',
      },
    ],
    [userDetails?.limits_plan, ctoCount, mediaCount, ctdCount, apiKeysCount, t],
  );

  const generateSDKUrl = useCallback(
    (lang) => {
      return process.env.REACT_APP_SDK_GENERATOR_URL
        ? `${process.env.REACT_APP_SDK_GENERATOR_URL}?lang=${lang}&token=${user?.apiToken}`
        : '';
    },
    [user],
  );
  const generatePostmanUrl = useCallback(() => {
    return process.env.REACT_APP_POSTMAN_GENERATOR_URL
      ? // eslint-disable-next-line max-len
        `${process.env.REACT_APP_POSTMAN_GENERATOR_URL}?token=${user?.apiToken}&api=${process.env.REACT_APP_FLOTIQ_API_URL}/v1/internal/open-api-schema.json`
      : '';
  }, [user]);

  const getCtdLink = useCallback((ctd) => {
    if (ctd.internal && ctd.name !== '_media') return '';
    return ctd.name === '_media'
      ? '/media'
      : `/content-type-objects/${ctd.name}`;
  }, []);

  const columns = useMemo(
    () => [
      {
        accessor: 'cto',
        label: t('Global.ObjectTitle'),
        minWidth: 140,
        render: (data) => (
          <span className="flex items-center h-full">
            <div className="truncate">{data}</div>
          </span>
        ),
        sortable: false,
      },
      {
        accessor: 'ctd',
        label: t('Global.ContentType'),
        minWidth: 110,
        render: (data) => (
          <StatusPill
            status={data.label}
            link={getCtdLink(data)}
            color={
              ['lime', 'blue', 'red', 'orange'][strToChecksum(data.name) % 4]
            }
            {...getTestProps(testId, `${data.name}-ctd`)}
            containerClasses={
              'flex items-center h-full text-xs lg:text-sm h-6 lg:h-7 truncate'
            }
            {...getTestProps(testId, `${data.name}-ctd`, 'testId')}
          />
        ),
        sortable: false,
      },
      {
        accessor: 'updated_at',
        label: t('Global.UpdatedAt'),
        minWidth: 100,
        render: (data) => <DateContainer date={data} isSemibold={false} />,
        sortable: false,
      },
      {
        accessor: 'action',
        label: '',
        minWidth: 50,
        width: 50,
        flexGrow: 0,
        notDraggable: true,

        render: (link, rowData) => (
          <div className={twMerge('flex items-center justify-start h-full')}>
            {rowData.ctd.internal ? (
              ''
            ) : (
              <DataGridActionMenu menuItems={actions(link)} />
            )}
          </div>
        ),
        sortable: false,
      },
    ],
    [actions, getCtdLink, t, testId],
  );

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      breadcrumbs: [],
    }));
  }, [t, updateAppContext]);

  return (
    <div className={twMerge('grid xl:grid-cols-4 w-full grow')}>
      <div
        className="min-h-screen xl:col-span-3 px-5 md:pl-7 md:pr-3.5 pt-7 pb-0 xl:pb-10"
        {...getTestProps(testId)}
      >
        <Helmet>
          <title>{t('Global.Dashboard')}</title>
        </Helmet>
        <Heading
          level={3}
          additionalClasses="pb-0 text-xl lg:text-3xl leading-none dark:text-white"
        >
          {`${t('Global.Hi')}, ${user?.firstName || ''} ${
            user?.lastName || ''
          }!`}
        </Heading>

        <p className="mt-2 lg:mt-3 text-base lg:text-lg dark:text-gray-200">
          {t('Global.WelcomeDashboard')}
        </p>

        <Tiles
          items={DEFAULT_TILES}
          layout={'grid'}
          additionalClasses="mt-5 lg:mt-10"
          {...getTestProps(testId, 'tiles', 'testId')}
        />
        <Heading level={5} additionalClasses="mt-7 pb-1 dark:text-white">
          {t('Global.YourLatestUpdates')}
        </Heading>
        <div className="bg-white dark:bg-gray-900 rounded-lg mt-3">
          <div className="h-[calc(100%-40px)] md:h-[calc(100%-72px)]">
            <DataGrid
              columns={columns}
              data={allContentItems}
              isLoading={contentTypesLoading || searchLoading}
              statusBar={
                <StatusBar
                  rows={pagination.count}
                  currentPage={currentPage}
                  pagesCount={pagination.total_pages}
                  handlePageChange={handlePageChange}
                  handleDataUpdate={reload}
                  resultsFrom={
                    (pagination.current_page - 1) * LAST_CHANGES_LIMIT + 1
                  }
                  resultsTo={
                    (pagination.current_page - 1) * LAST_CHANGES_LIMIT +
                    pagination.count
                  }
                  resultsTotalCount={pagination.total_count}
                />
              }
              noDataInfoText={t('ObjectsOfType.NoMatchingData')}
              loadingIcon={<Loader size={'small'} type={'spinner-grid'} />}
              {...getTestProps(testId, 'grid', 'testId')}
            />
          </div>
        </div>
      </div>

      <RightSidebar additionalClasses="h-fit md:pl-3.5 md:pr-7">
        {/* Account details */}
        <AccountDetails
          title={
            <div className="w-full flex flex-wrap justify-between gap-x-7">
              {t('Global.AccountDetails')}
              <AccountTypePill
                children={user?.limits_plan?.name}
                additionalClasses="w-max"
              />
            </div>
          }
          resources={accountResources}
          seeMoreText={t('Global.SeeMore')}
          seeMoreLink="/profile"
        />

        {/* Get started*/}
        <GetStarted
          title={t('Global.FourStepsToGetStarted')}
          readMoreText={t('Global.CheckOurDocumentation')}
          readMoreLink={process.env.REACT_APP_DOCUMENTATION}
          tasks={[
            {
              name: t('Global.CreateYourProjectStructure'),
              description: t('Global.GoToDefinitionBuilder'),
              link: '/content-type-definitions',
              done: user?.config?.todo?.contentType?.status === 'done',
              key: 'Create',
            },
            {
              name: t('Global.AddContentObjectToYourProject'),
              link: user?.config?.todo?.contentType?.contentTypeSlug
                ? `/content-type-objects/${user?.config?.todo?.contentType?.contentTypeSlug}`
                : '/content-type-definitions',
              done: user?.config?.todo?.contentObject?.status === 'done',
              key: 'Add',
            },
            {
              name: t('Global.VisitAPIDocumentation'),
              link: generateApiDocs(userDetails),
              done: user?.config?.todo?.publicDoc?.status === 'done',
              openInNewTab: true,
              onClick: () => {
                markTaskAsDone('publicDoc', user, updateUser);
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'apidocs_visit',
                  },
                });
              },
              key: 'Visit',
            },
            {
              name: t('Global.TryOutStarterProjects'),
              link: '/starters',
              done: user?.config?.todo?.gatsbyStarters?.status === 'done',
              onClick: () => markTaskAsDone('gatsbyStarters', user, updateUser),
              key: 'Try',
            },
          ]}
          {...getTestProps(testId, 'get-started', 'testId')}
        />

        {/* Api packages */}
        <PackagesGrid
          isOpen={false}
          isCollapsable={true}
          title={t('Global.YourAPIPackages')}
          helpPopup={
            <Tooltip tooltip={t('Global.YourAPIPackagesHelpText')}>
              <QuestionMarkCircleIcon className={'h-4 w-4 text-slate-400/80'} />
            </Tooltip>
          }
          packages={[
            {
              icon: <AngularLogo className="w-3/4 h-3/4" title="Angular" />,
              downloadUrl: generateSDKUrl('typescript-angular'),
              fileName: 'flotiq-angular-sdk.zip',
            },
            {
              icon: <CSharpLogo className="w-3/4 h-3/4" title="C#" />,
              downloadUrl: generateSDKUrl('csharp'),
              fileName: 'flotiq-csharp-sdk.zip',
            },
            {
              icon: <GoLogo className="w-3/4 h-3/4" title="Go" />,
              downloadUrl: generateSDKUrl('go'),
              fileName: 'flotiq-goicon-sdk.zip',
            },
            {
              icon: <JavaLogo className="w-3/4 h-3/4" title="Java" />,
              downloadUrl: generateSDKUrl('java'),
              fileName: 'flotiq-java-sdk.zip',
            },
            {
              icon: <NodeJsLogo className="w-3/4 h-3/4" title="NodeJS" />,
              downloadUrl: generateSDKUrl('javascript'),
              fileName: 'flotiq-node-sdk.zip',
            },
            {
              icon: <PHPLogo className="w-3/4 h-3/4" title="PHP" />,
              downloadUrl: generateSDKUrl('php'),
              fileName: 'flotiq-php-sdk.zip',
            },
            {
              icon: <PostmanLogo className="w-3/4 h-3/4" title="Postman" />,
              downloadUrl: generatePostmanUrl(),
              fileName: 'flotiq-postman-collection.zip',
            },
            {
              icon: <PythonLogo className="w-3/4 h-3/4" title="Python" />,
              downloadUrl: generateSDKUrl('python'),
              fileName: 'flotiq-python-sdk.zip',
            },
          ]}
          additionalClasses="p-0"
          {...getTestProps(testId, `packages`, 'testId')}
        />

        {/* Socials Panel */}
        <SocialsPanel
          title={t('Global.SocialWidgetTitle')}
          items={[
            {
              name: 'Github',
              icon: appContext?.darkMode ? (
                <GithubColorIconWhite className="w-[24px] md:w-[26px]" />
              ) : (
                <GithubColorIcon className="w-[24px] md:w-[26px]" />
              ),
              link: process.env.REACT_APP_GITHUB,
            },
            {
              name: 'Twitter',
              icon: <TwitterColorIcon className="w-[24px] md:w-[26px]" />,
              link: process.env.REACT_APP_TWITTER,
            },
            {
              name: 'Reddit',
              icon: appContext?.darkMode ? (
                <RedditColorIconWhite className="w-[24px] md:w-[26px]" />
              ) : (
                <RedditColorIcon className="w-[24px] md:w-[26px]" />
              ),
              link: process.env.REACT_APP_REDDIT,
            },
            {
              name: 'LinkedIn',
              icon: <LinkedinColorIcon className="w-[24px] md:w-[26px]" />,
              link: process.env.REACT_APP_LINKEDIN,
            },
            {
              name: 'YouTube',
              icon: <YoutubeColorIcon className="w-[24px] md:w-[26px]" />,
              link: process.env.REACT_APP_YOUTUBE,
            },
            {
              name: 'Facebook',
              icon: <FacebookColorIcon className="!w-[24px] md:w-[26px]" />,
              link: process.env.REACT_APP_FACEBOOK,
            },
            {
              name: 'Discord',
              icon: <DiscordColorIcon className="w-[24px] md:w-[26px]" />,
              link: process.env.REACT_APP_DISCORD,
            },
          ]}
        />
      </RightSidebar>
    </div>
  );
};

export default Home;

Home.propTypes = {
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

Home.defaultProps = {
  testId: '',
};
