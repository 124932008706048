import { createContext } from 'react';
import PropTypes from 'prop-types';

export const NewMediaContext = createContext({});

NewMediaContext.propTypes = {
  value: PropTypes.shape({
    /**
     * Array of uploaded media
     */
    newMedia: PropTypes.array,
    /**
     * Callback for changing new media
     */
    setNewMedia: PropTypes.func,
    /**
     * On new media upload
     */
    onUpload: PropTypes.func,
    /**
     * If media limit should be reloaded
     */
    reloadMediaLimit: PropTypes.bool,
    /**
     * Callback for changing if media limit should be reloaded
     */
    setReloadMediaLimit: PropTypes.func,
  }).isRequired,
};

export default NewMediaContext;
